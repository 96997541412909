export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(0)
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },

    active: {
        color: theme.palette.success.main,

    },
    activeTrack: {
        backgroundColor: theme.palette.success.main,

    },
    checked: {
        color: theme.palette.error.main + '!important',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        position: 'relative',
    },
    actions: {
        paddingTop: theme.spacing(3),
    },
    imagePhotoBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    imageAvatar: {
        width: '100%',
        height: 'auto',
    },
    actionsBlock: {
        '&:first-child': {
            justifyContent: 'flex-start',
        },
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionBlock: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    form: {
        // maxWidth: '80%'
    },
    updateProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: theme.spacing(2),
        width: '100%'
    },
    imageMap: {
        width: '100%',
        height: 'auto'
    },
});
