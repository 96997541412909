import React from "react";
import {useLocation, Redirect, Route} from "react-router-dom";

const PrivateRoute = ({children, hasAccess, to, ...props}) => {
    const location = useLocation();
    if (!hasAccess) {
        return (
            <Redirect
                to={{
                    pathname: to,
                    state: {from: location},
                }}
            />
        );
    }

    return <Route {...props}>{children}</Route>;
};

export default PrivateRoute;
