import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchImageFile} from "../store/actions/file";
import {getImageFile, getIsFetching} from "../store/reducers/file";

export const useImagePendingBase64 = (hash) => {
    const dispatch = useDispatch();
    const file = useSelector(getImageFile);
    const loadingFile = useSelector(getIsFetching);

    useEffect(() => {
        if (!!hash) {
            dispatch(fetchImageFile(hash, 'pending'))
        }
    }, [dispatch, hash]);
    return {
        file,
        loadingFile
    };
};
