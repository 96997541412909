import React, {Component} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import FlashMessage from "react-flash-message";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Copyright from "../../../common/Copyright";
import Recaptcha from "react-google-invisible-recaptcha";
import Container from "@material-ui/core/Container";
import {flushLoginErrors, resetPassword} from "../../../store/actions/auth";
import {getAuthorizationError, getIsFetching, authResultMessage} from "../../../store/reducers/auth";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Link, Redirect, withRouter} from "react-router-dom";


const initialState = {
    email: '',
    redirect: false,
    blocked: false,
};

class ForgotPass extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resetResult, forgotPasswordError} = this.props;
        if (prevProps.forgotPasswordError !== forgotPasswordError) {
            this.setState({
                errors: forgotPasswordError,
            });
        }
        if (prevState.email !== this.state.email) {
            this.setState({
                errors: "",
                message: '',
            });
        }
        if (prevProps.resetResult !== resetResult) {
            this.setState({
                message: resetResult
            });
        }
    }

    onChange(e) {
        const {name, value} = e.target;

        this.setState(state => ({
            ...state,
            blocked: false,
            [name]: value
        }));
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState(state => ({
            ...state,
            blocked: true
        }));
        this.recaptcha.execute();
    }

    onError() {
        this.setState(state => ({
            ...state,
            blocked: false,
        }));
    }

    onResolved() {
        // TODO: implement redirect after success
        const {email} = this.state;

        this.props.resetPassword({
            email: email,
            gcaptcha: this.recaptcha.getResponse()
        }).then(
            () => {
                setTimeout(() => {
                    this.props.flushLoginErrors();
                    return this.setState({redirect: true});
                }, 7000);
            }
        ).catch(() => {
            this.setState(state => ({
                ...state,
                blocked: false
            }));
        });
    }


    render() {
        const {classes, forgotPasswordError, isFetching, resetResult} = this.props;
        const {email, errors, message, redirect, blocked} = this.state;

        if (redirect) {
            return (<Redirect to="/login"/>)
        }

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Enter Your e-mail.
                    </Typography>
                    {isFetching &&
                    <div className={classes.onFetching}>
                        <CircularProgress/>
                    </div>}
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.onSubmit}
                        style={isFetching ? {opacity: "0.5"} : {}}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="user-email"
                            label="Email address"
                            name="email"
                            type="email"
                            autoFocus
                            value={email}
                            disabled={isFetching}
                            onChange={this.onChange}
                        />
                        {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={"alert alert-danger"}>Error: {forgotPasswordError}</h5></FlashMessage> : ''}
                        {message && <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={classes.alertSuccess}>{resetResult} Please check Your email.</h5></FlashMessage>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!email || blocked}
                            className={classes.submit}
                        >
                            Reset password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/login" variant="body2">
                                    Back to login.
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.MIX_GCAPTCHA_DEV_SITE_KEY}
                    onResolved={this.onResolved}/>
            </Container>
        )
    }
}

const mapDispatchToProps = {
    resetPassword: resetPassword,
    flushLoginErrors: flushLoginErrors,
};
const mapStateToProps = state => ({
    forgotPasswordError: getAuthorizationError(state),
    isFetching: getIsFetching(state),
    resetResult: authResultMessage(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPass))
