export const styles = theme => ({
    disabled: {
      backgroundColor: theme.palette.primary.main,
        color: '#fff',
        opacity: '1!important',
        '& svg': {
          fill: '#fff'
        }
    },
});
