import {createSelector} from 'reselect';
import {get, omit} from "lodash";

const baseState = state => get(state, 'streamsList');

const singleState = state => get(state, 'singleStream');

export const singleStreamSelector = createSelector(
  singleState,
  state => (
    {...omit(state , 'isFetching')}
  )
)

export const isFetchingSingleStream = createSelector(
  singleState,
  state => state.isFetching
)

export const isFetchingSelector = createSelector(
  baseState,
  state => state.isFetching
)

export const streamsSelector = createSelector(
  baseState,
  state => ({
    isFetching: state.isFetching || state.isUpdating,
    items: state.items,
    currentPage: state.currentPage,
    pages: state.pages,
    limit: state.limit,
    total: state.total,
  })
);
