import {
  LOAD_PENDING_EVENTS_SUCCESS,
  LOAD_PENDING_EVENTS_FAILURE,
  LOAD_PENDING_EVENTS_REQUEST,
} from '../../constants/events/eventsPending'
import {
  DELETE_EVENT_PENDING_FAILURE,
  DELETE_EVENT_PENDING_REQUEST,
  DELETE_EVENT_PENDING_SUCCESS,
  REMOVE_PENDING_EVENT_FROM_LIST, UPDATE_EVENT_PENDING_FAILURE,
  UPDATE_EVENT_PENDING_REQUEST,
  UPDATE_EVENT_PENDING_SUCCESS,
} from '../../constants/events/eventPending'

const initialState = {
  isFetching: false,
  isUpdating: false,
  message: '',
  errors: false,
  updateErrors: {},
  status: '',
  eventsPending: [],
  currentPage: 1,
  total: 0,
  pages: 1,
  limit: 10,
}

const eventsPending = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_PENDING_EVENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case LOAD_PENDING_EVENTS_SUCCESS:
      return {
        ...state,
        eventsPending: payload.data.data,
        currentPage: payload.data.current_page,
        limit: parseInt(payload.data.per_page),
        pages: payload.data.last_page,
        total: payload.data.total,
        isFetching: false,
      }
    case LOAD_PENDING_EVENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case REMOVE_PENDING_EVENT_FROM_LIST:
      return {
        ...state,
        isFetching: false,
        eventsPending: state.eventsPending.filter(
          item => !payload.includes(item.title)),
      }
    case DELETE_EVENT_PENDING_REQUEST:
    case UPDATE_EVENT_PENDING_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case DELETE_EVENT_PENDING_SUCCESS:
    case DELETE_EVENT_PENDING_FAILURE:
    case UPDATE_EVENT_PENDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case UPDATE_EVENT_PENDING_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return {
        ...state,
      }
  }

}

export default eventsPending
