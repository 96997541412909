import React from 'react';
import * as PropTypes from "prop-types";
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Layout from "./Layout";
import {hasAwaitingCode, hasToken} from "./store/reducers/auth";
import {connect} from "react-redux";
import LoginPage from "./components/Auth/LoginPage/LoginPage";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import baseTheme from "./baseTheme";
import ForgotPass from "./components/Auth/ForgotPass";
import {SnackbarProvider} from "notistack";
import ResetPassword from "./components/Auth/ResetPassword";
import PrivateRoute from "./common/PrivateRoute";
import AuthCode from "./components/Auth/AuthCode";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import CloseSnackbarButton from "./common/CloseSnackbarButton";
import SetUserPass from "./components/Auth/SetUserPass";


const theme = createMuiTheme({...baseTheme});

const Main = ({hasToken, hasAwaitingCode}) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={theme}>

                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    action={id => <CloseSnackbarButton id={id}/>}
                >
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" component={LoginPage}/>
                            <Route path="/forgotpass" component={ForgotPass}/>
                            <Route path="/reset_password_forgoten/:s" component={ResetPassword}/>
                            <Route path="/approve_invite/:s" component={SetUserPass}/>
                            <PrivateRoute path="/code" component={AuthCode} hasAccess={hasAwaitingCode}/>
                            <Route render={() => {
                                if (hasToken) {
                                    return <Layout/>
                                }
                                return <Redirect to="/login"/>
                            }}/>
                        </Switch>
                    </BrowserRouter>
                </SnackbarProvider>

            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

Main.propTypes = {
    hasToken: PropTypes.bool.isRequired,
    hasAwaitingCode: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    hasToken: hasToken(state),
    hasAwaitingCode: hasAwaitingCode(state),
});

export default connect(mapStateToProps)(Main);




