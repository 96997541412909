import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import Main from "./Main";
import configureStore from "./store/configureStore";

const store = configureStore();

if (document.getElementById('mainContainer')) {
    ReactDOM.render(
        <Provider store={store}>
            <Main/>
        </Provider>,
        document.getElementById('mainContainer'),
    )
}
