import React, {cloneElement, useCallback, useMemo} from 'react';
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 300 + theme.spacing(4)
  },
  buttonBlock: {
    padding: theme.spacing(2),
    textAlign: 'right',
    '& > button:first-child': {
      marginRight: theme.spacing(),
    }
  },
  filterWrapper: {
    '& > div': {
      minWidth: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  filtersBlock: {
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '300px',
    marginTop: theme.spacing()
  }
}));

const BulkActions = ({bulkActions , selectedItems}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenFilters = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback((onAction) => {
    onAction();
    handleCloseFilters()
  }, [bulkActions]);

  const isFiltersOpen = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const actions = useMemo(() => bulkActions.map((item, index) => (
    <ListItem key={index} button onClick={()=>handleClick(item.onAction)}>
      <ListItemText primary={item.label} />
    </ListItem>
    )), [bulkActions]
  );




  return (
    <React.Fragment>
      <Button  disabled={!selectedItems.length} aria-describedby={id} variant="contained" color="primary" onClick={handleOpenFilters}>
        Actions
      </Button>
      <Popover
        id={id}
        open={isFiltersOpen}
        onClose={handleCloseFilters}
        anchorEl={anchorEl}
      >
        <List component="nav" aria-label="main">
          {actions}
        </List>
      </Popover>
    </React.Fragment>
  );
};

export default BulkActions;
