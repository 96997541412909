import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid/Grid";
import Box from '@material-ui/core/Box'
import { useResource } from '../../../hooks/resource'
import { getIsFetching, videosSelector } from '../../../store/reducers/videos'
import { getVideosList } from '../../../store/actions/videos'
import ListActions from '../../ListActions'
import VideoList from './VideoList'

const VideoModal = ({open, onClose, onSuccess, classes, selected}) => {
  const [search, setSearch] = useState('')

  const {
    data: {
      videos,
    },
    loading,
  } = useResource(
    videosSelector,
    getVideosList,
    getIsFetching,
    {
      limit: -1,
      filter: [
        {key: 'status', value: 'active'}
      ]
    }
  )

  const items = useMemo(() => {
    const searchString = search.toLowerCase()
    const excluded = selected.map(item => item.id);
    return videos
    .filter(video => excluded.indexOf(video.id) === -1)
    .filter((video) => {
      return video.name.toLowerCase().indexOf(searchString) !== -1
    });
  }, [selected, videos, search]);

  const handleSelected = (item) => {
    onSuccess(selected.concat([item]))
  }

  if (!open) {
    return null;
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Select video"}</DialogTitle>
        <Box className={classes.container}>
          <ListActions searchHandler={setSearch} waitInterval={300} />
        </Box>
        <DialogContent className={classes.root}>
          <Grid container wrap={"wrap"} className={classes.grid}>
            <VideoList videos={items} onSelect={handleSelected} search={search}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoModal;

