import React from "react";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FlashMessage from "react-flash-message";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const SetPasswordAuthForm = ({
                                 classes,
                                 isValidHash,
                                 disabled,
                                 isLoading,
                                 password,
                                 confirmPassword,
                                 errors,
                                 onSubmit,
                                 onChange,
                                 blocked
                             }) => {
    return (
        <form
            className={classes.form}
            onSubmit={onSubmit}
            style={isLoading ? {opacity: "0.5"} : {}}
        >{isValidHash &&
        <>
            <TextField
                error={!!errors}
                autoComplete="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                id="password"
                label="New Password"
                name="password"
                autoFocus
                disabled={isLoading}
                value={password}
                onChange={onChange}
            />
            <TextField
                error={!!errors}
                autoComplete="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                disabled={isLoading}
                value={confirmPassword}
                onChange={onChange}
            />
        </>}
            {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                <h5 className={"alert alert-danger"}>Error: {errors}</h5></FlashMessage> : ''}
            {isValidHash &&
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled || blocked}
                className={classes.submit}
            >
                Set new password
            </Button>}
            <Grid container>
                <Grid item xs>
                    <Link to="/login" variant="body2">
                        Back to login.
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
}

export default SetPasswordAuthForm;


