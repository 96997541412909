import { useState } from "react";

const useSelectedItems = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSetSelectedItems = items => {
    setSelectedItems(items);
  };

  const handleReset = () => {
    setSelectedItems([]);
  };

  return [selectedItems, handleSetSelectedItems, handleReset];
};
export default useSelectedItems;
