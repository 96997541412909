import React from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Redirect, Switch} from 'react-router-dom';
import {hasAwaitingCode, hasToken} from "../../../store/reducers/auth";
import Login from "../Login/";

const LoginPage = ({hasToken, hasAwaitingCode}) => {
    return (
        <Switch>
            <Route render={() => {
                if (hasToken) {
                    return <Redirect to="/dashboard"/>
                }
                if (hasAwaitingCode) {
                    return <Redirect to="/code"/>
                }
                return <Login/>
            }}
            />
        </Switch>)
};

LoginPage.propTypes = {
    hasToken: PropTypes.bool.isRequired,
    hasAwaitingCode: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    hasToken: hasToken(state),
    hasAwaitingCode: hasAwaitingCode(state),
});

export default connect(mapStateToProps)(LoginPage);
