import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";


const EmptyTableBody = ({colSpan}) => {
    return (
        <TableBody>
            <TableRow>

            </TableRow>
        </TableBody>
    );
};

export default EmptyTableBody;


