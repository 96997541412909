import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getVideoData, getIsFetching} from "../store/reducers/video";
import {getVideo} from "../store/actions/video";

export const useFetchVideo = (id) => {
    const dispatch = useDispatch();
    const video = useSelector(getVideoData);
    const loading = useSelector(getIsFetching);
    useEffect(() => {
        dispatch(getVideo(id));
    }, [dispatch, id]);

    return {
        video,
        loading
    };
};
