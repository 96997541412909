import React from "react";
import * as PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const SideBarParentMenuItem = ({title, onClick, active, icon, classes}) => (
    <ListItem
        button
        onClick={onClick}
        disabled={active}
        className={active ? classes.disabled : ''}
    >
        <ListItemIcon>
            {icon}
        </ListItemIcon>
        <ListItemText primary={title}/>
        {active ? <ExpandLess/> : <ExpandMore/>}
    </ListItem>
);

SideBarParentMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    icon: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

export default SideBarParentMenuItem;


