import React from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";

const AddButton = ({ onClick }) => {
    const ColorButton = withStyles(theme => ({
        root: {
            color: "#fff",
            backgroundColor: "#4cae50",
            '&:hover': {
                backgroundColor: "#4c8c50",
            },
            minWidth: theme.spacing(11),
            textTransform: 'capitalize',
        },
    }))(Button);
    return (
        <ColorButton
            variant="contained"
            onClick={onClick}
        >
            + Add
        </ColorButton>
    )
};

AddButton.propTypes = {
    onClick: PropTypes.func
};

export default AddButton;
