import {
    LOAD_VIDEO_TAGS_SUCCESS,
    LOAD_VIDEO_TAGS_FAILURE,
    LOAD_VIDEO_TAGS_REQUEST
} from "../constants/videoTags";
import { loadVideosTagsList} from "../../helpers/api";


export const getVideoTagsList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search
) => dispatch => {
    dispatch({type: LOAD_VIDEO_TAGS_REQUEST});

    loadVideosTagsList(page, orderBy, order, limit, search).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_VIDEO_TAGS_FAILURE, message: message })
            }
            return dispatch({
                type: LOAD_VIDEO_TAGS_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_VIDEO_TAGS_FAILURE, message: error})
    )
};
