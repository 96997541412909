import {connectRouter} from "connected-react-router";
import {combineReducers} from "redux";
import { reducer as formReducer } from "redux-form";
import {auth} from "./auth";
import admins from "./admins";
import user from "./user";
import images from "./images";
import image from "./image";
import categories from "./categories";
import category from "./category";
import file from "./file";
import tags from "./tags";
import tag from "./tag";
import video_categories from "./videoCategories";
import video_category from "./videoCategory";
import video_tag from "./videoTag";
import video_tags from "./videoTags"
import taxonomies from "./taxonomies";
import newImage from "./newImage";
import imagesPending from "./imagesPending";
import pendingImage from "./imagePending";
import playlists from "./playlists";
import playlist from "./playlist";
import videos from "./videos";
import bulkActions from "./bulk";
import jobs from "./jobs";
import video from "./video";
import counts from "./counts";
import events from "./events/list";
import singleEvent from "./events/single";
import {bulkVideoUpload} from "./bulkVideoUpload";
import eventsPending from "./events/eventsPending";
import eventPending from "./events/eventPending";
import streamsList from "./streams/list";
import singleStream from "./streams/single";


const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth: auth,
        admins: admins,
        user: user,
        images: images,
        image: image,
        categories: categories,
        category: category,
        file: file,
        tags: tags,
        tag: tag,
        video_categories: video_categories,
        video_category: video_category,
        video_tags: video_tags,
        video_tag: video_tag,
        playlists: playlists,
        playlist: playlist,
        taxonomies:taxonomies,
        newImage: newImage,
        imagesPending: imagesPending,
        pendingImage: pendingImage,
        videos: videos,
        video: video,
        bulkActions: bulkActions,
        jobs: jobs,
        counts: counts,
        eventsList: events,
        singleEvent: singleEvent,
        streamsList,
        singleStream,
        form: formReducer,
        bulkVideoUpload: bulkVideoUpload,
        eventsPending: eventsPending,
        eventPending: eventPending
    });

export default createRootReducer;
