import React from 'react';
import {Field} from 'redux-form'
import FormTextField from "../../../../components/ReduxFormFields/FormTextField";
import {required , length} from 'redux-form-validators'

const Title = ({classes, ...rest}) => {
  return (
    <div className={classes.textField}>
      <Field
        name={'title'}
        label={"Title"}
        type={"text"}
        fullWidth
        variant="outlined"
        component={FormTextField}
        validate={[required({message: "Required field"}) , length({ min: 0, max: 250 , message: 'Max length 250 chars' })]}
        placeholder={"Title"}
        {...rest}
      />
    </div>
  );
};

export default Title;
