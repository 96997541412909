export const styles = theme => ({
    //TODO: improve styles
    onFetching: {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
        alignItems: 'center',
        // display: 'flex',
        // justifyContent: 'center'
        top: '40%',
        left: '45%',
        zIndex: 9999
    },
    title: {
        textAlign: "center"
    },
    content: {
        textAlign: "center"
    },
    actionsBlock: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    }
});
