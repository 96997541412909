import {
    BULK_UPLOAD_END,
    BULK_UPLOAD_START, BULK_UPLOAD_UPDATE, FLUSH_UPLOADED_ITEMS_STATE, REMOVE_UPLOADED_ITEM_FROM_STATE,
} from "../constants/bulkVideoUpload";

const initialState = {
    items: []
};

export const bulkVideoUpload = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case BULK_UPLOAD_UPDATE:
            return {
                items: mergeItems(
                    [].concat(state.items),
                    [].concat(payload),
                )
            };
        case REMOVE_UPLOADED_ITEM_FROM_STATE:
            return {
             ...state,
                items: [].concat(state.items).map(item => ({
                        files: [].concat(item.files).filter(file => file.id !== payload)
                    })
                )
            };
        case FLUSH_UPLOADED_ITEMS_STATE:
            return initialState;
        default:
            return {
                ...state
            }
    }
};

export const uploadItemUUid = (item) => (
    item.files.map(file => file.id).join('|')
);

export const uploadFindItem = (items, item) => (
    items.filter(innerItem => (
        uploadItemUUid(innerItem) === uploadItemUUid(item)
    )).pop()
);

const mergeItems = (oldItems, newItems) => {
    let items = oldItems.map((item) => {
        const newItem = uploadFindItem(newItems, item);
        return {
            ...item,
            ...(newItem ? {
                status: newItem.status,
                progress: newItem.progress,
                errors: [].concat(item.errors, newItem.errors).filter(item => item),
                message: newItem.message
            } : {})
        }
    });

    newItems.forEach((item => {
        const newItem = uploadFindItem(items, item);
        if(!newItem) {
            items.push(item);
        }
    }));

    return [].concat(items);
};



