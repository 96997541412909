import React, {useCallback, useEffect, useMemo, useState} from 'react';
import md5 from "md5";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ImageComponent from "../../common/ImageComponent/";
import LoadingFade from "../../common/LoadingFade";
import {useImagePendingBase64} from "../../hooks/imagePendingBase64";

const SingleImagePendingEditor = (
  {
    classes,
    url
  }
) => {
  const hash = useMemo(() => (
    md5(url.split('/').pop())
  ), [url]);

  const {file, loadingFile} = useImagePendingBase64(hash);
  const [base64, setBase64] = useState('');

  useEffect(() => {
    if (!loadingFile && !!file) {
      setBase64(file);
    }
  }, [file, loadingFile]);

  const onImageClick = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = url.split('/').pop();
    downloadLink.click();
  };

  return (
    <Grid item xs={12} md={6} lg={6} className={classes.gridCol50}>
      <Box className={classes.imageWrapper}>
        {
          loadingFile || !base64 ?
            (
              <LoadingFade loading={true} />
            ) :
            (
              <ImageComponent
                base64={base64}
                // loading={loading}
                onClick={onImageClick}
                disableEdit
              />
            )
        }
      </Box>
    </Grid>
  )
};

export default SingleImagePendingEditor;
