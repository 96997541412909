import React from "react";
import {Route, Switch} from "react-router-dom";
import StreamSingle from "./StreamSingle";
import StreamList from "./StreamList";

const Streams = () => {
    return (
      <Switch>
            <Route
                exact
                path="/streams/new"
                component={StreamSingle}
            />
            <Route
                exact
                path="/streams/:id/edit"
                component={StreamSingle}
            />
            <Route path="/streams" component={StreamList}/>
        </Switch>
    );
};

export default Streams;
