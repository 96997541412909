import {
    apiRequest,
    approveSinglePendingImage as apiApproveSinglePendingImage,
    fetchPendingImageData as apiFetchPendingImageData,
} from "../../helpers/api";
import {
    APPROVE_PENDING_IMAGE_FAILURE,
    APPROVE_PENDING_IMAGE_REQUEST,
    APPROVE_PENDING_IMAGE_SUCCESS,
    EDIT_APPROVED_IMAGE,
    EDIT_PENDING_IMAGE,
    FETCH_PENDING_IMAGE_DATA_FAILURE,
    FETCH_PENDING_IMAGE_DATA_REQUEST,
    FETCH_PENDING_IMAGE_DATA_SUCCESS,
    FLUSH_EDIT_PENDING_IMAGE_DATA,
    REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE
} from "../constants/images";

export const approveSingleImage = data => dispatch => {
    dispatch({type: APPROVE_PENDING_IMAGE_REQUEST});
    const request = () => apiApproveSinglePendingImage(data);

    return apiRequest(request, dispatch, APPROVE_PENDING_IMAGE_SUCCESS, APPROVE_PENDING_IMAGE_FAILURE);
};

export const removePendingImageFromListAfterApprove = link => dispatch => {
    dispatch({
        type: REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE,
        payload: link
    })
};

export const editPendingImage = link => dispatch => {
    // dispatch({
    //     type: EDIT_PENDING_IMAGE,
    //     payload: link
    // })
};

export const flushPendingImageState = () => dispatch => {
    dispatch({
        type: FLUSH_EDIT_PENDING_IMAGE_DATA
    });
};

export const editApprovedImage = (data) => dispatch => {
    dispatch({
        type: EDIT_APPROVED_IMAGE,
        payload: data
    });
};

export const fetchPendingImageData = imageUrl => dispatch => {
    dispatch({type: FETCH_PENDING_IMAGE_DATA_REQUEST});
    const request = () => apiFetchPendingImageData(imageUrl);

    return apiRequest(request, dispatch, FETCH_PENDING_IMAGE_DATA_SUCCESS, FETCH_PENDING_IMAGE_DATA_FAILURE);
};
