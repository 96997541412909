import React from "react";
import * as PropTypes from 'prop-types';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableRowCellData from "../../common/TableRowCellData";
import clsx from 'clsx';

const TableBodyComponent = ({
                                type,
                                classes,
                                rows,
                                handleRowClick,
                                dataProperties,
                                handleDeleteAction,
                                selected,
                                onSelect
                            }) => {
    const rowClickHandler = (id) => {
        handleRowClick(id)
    };

    const clickHandler = (propName, row) => {
        if (row.status === 'process') {
            return null;
        }

        switch (propName) {
            case 'userStatus':
            case 'delete':
            case 'bulk':
            case 'status':
                return null;
            default:
                if (type === 'imagesPending') {
                    return rowClickHandler(row.location)
                }
                return rowClickHandler(row.id);
        }
    };

    return (
        <TableBody>
            {rows
                .map((row, index) => {
                    row.rowIndexInList = index;
                    return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id ? row.id : index}
                                className={clsx(classes.tableRow, classes[row.status === 'process' ? 'pointerDefault' : ''])}
                            >
                            {dataProperties.map(prop => (
                                <TableCell
                                    key={prop}
                                    align="left"
                                    size={prop === 'id' ? 'small' : 'medium'}
                                    className={classes[prop] && classes[prop]}
                                    onClick={() => clickHandler(prop, row)}
                                >
                                    <TableRowCellData
                                        type={type}
                                        row={row}
                                        property={prop}
                                        deleteAction={handleDeleteAction}
                                        selected={selected}
                                        onSelect={onSelect}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
};

TableBodyComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string,
    rows: PropTypes.array.isRequired,
    handleRowClick: PropTypes.func.isRequired,
    dataProperties: PropTypes.array.isRequired,
    handleDeleteAction: PropTypes.func,
    selected: PropTypes.array,
    onSelect: PropTypes.func,
};
export default TableBodyComponent;
