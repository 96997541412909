import React from 'react';
import {Field} from 'redux-form'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormVideo from "../../../../components/ReduxFormFields/FormVideo";


const EventVideos = ({...rest}) => {
  return (
    <Box>
      <Typography>Videos</Typography>
      <Divider/>
      <Field
        name={"videos"}
        component={FormVideo}
        {...rest}
      />
    </Box>
  );
};

export default EventVideos;
