export const headCellsAdmins = [
    // { id: 'bulk', numeric: false, disablePadding: true, label: '', sorted: false, size: "small"},
    // { id: 'id', numeric: true, disablePadding: true, label: 'ID', sorted: true, size: "small"},
    { id: 'icon', numeric: false, disablePadding: true, label: '', sorted: false},
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sorted: true },
    { id: 'email', numeric: false, disablePadding: false, label: 'E-mail', sorted: true },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Date created', sorted: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sorted: true },
    // { id: 'delete', numeric: false, disablePadding: true, label: 'Remove', sorted: false },
];
