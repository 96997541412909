import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { CardMedia } from '@material-ui/core';
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    backgroundColor: theme.palette.background.paper,
  },
  thumbnail: {
    height: "60px",
    width: "60px",
    marginRight: "10px",
    borderRadius: "5px",
    background: "#D3D3D3",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }
}));

const VideoItem = ({ index, style, data }) => {
  const item = data.videos[index];
  const classes = data.classes;
  return (
    <ListItem
      button
      alignItems="flex-start"
      style={style}
      key={index}
      onClick={() => data.onSelect(item)}
    >
      <ListItemAvatar>
        <CardMedia
          image={item.thumbnail}
          title={item.name}
          className={classes.thumbnail}
        />
      </ListItemAvatar>
      <ListItemText
        primary={item.name}
      />
    </ListItem>
  )
}

const VideoList = ({videos, onSelect, search}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className="List"
            height={height}
            itemCount={videos.length}
            itemSize={90}
            width={width}
            itemData={{
              videos,
              classes,
              onSelect,
              search
            }}
          >
            {VideoItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}

export default VideoList

