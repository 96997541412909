import {logoutAction} from "../actions/auth";

const token = store => next => action => {
    if(typeof action === 'function'){
        let state = store.getState();
        const {auth} = state;
        // console.log(auth, 'logout')
        if(!auth.token){
            return next(action);
        }
        if(auth.token.access_token && auth.token.expires_in * 1000 < Date.now()){
           // TODO: implement refresh token
            return  next(logoutAction())
        }
    }

    return next(action);
};
export {token}
