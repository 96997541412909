import lightGreen from '@material-ui/core/colors/lightGreen';

export const styles = theme => ({
  root: {
    display: 'block',
  },
  wrap: {
    maxWidth: '470px',
    margin: '0 auto'
  },

  preview: {

  },

  previewImg: {
    height: 265,
  },

  thumbnailImg: {
    height: 80,
  },

  thumbnailIcon: {
    color: '#fff',
  }
})

