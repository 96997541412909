import {DialogMessagesStatuses} from "../../constatns/statuses";
import {
  DELETE_IMAGE_FAILURE, DELETE_IMAGE_REQUEST, DELETE_IMAGE_SUCCESS,
  FETCH_IMAGE_FAILURE,
  FETCH_IMAGE_REQUEST,
  FETCH_IMAGE_SUCCESS,
  FLUSH_IMAGE_ERRORS, FLUSH_IMAGE_STATE, UPDATE_IMAGE_FAILURE, UPDATE_IMAGE_REQUEST,
  UPDATE_IMAGE_SUCCESS
} from "../constants/images";

export const initialState = {
  isFetching: false,
  message: '',
  errors: false,
  updateErrors: {},
  status: '',
  data: {
    id: 0,
    hash: "",
    status: "",
    type: "",
    orientation: "",
    title: "",
    description: "",
    lat: "",
    lng: "",
    address: null,
    expired_at: null,
    created_at: null,
    updated_at: null,
    thumbnails: [],
    location: "",
    thumbnail: "",
    exif: [],
    categories: [],
    tags: [],
  },
};

const image = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_IMAGE_REQUEST:
    case UPDATE_IMAGE_REQUEST:
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: {
          id: null,
          status: '',
          name: '',
        }
      };
    case FETCH_IMAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: payload,
      };
    case UPDATE_IMAGE_FAILURE:
    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        updateErrors: payload,
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: '',
        status: DialogMessagesStatuses.success,
        message: payload.message,
        data: initialState.data,
      };
    case FETCH_IMAGE_SUCCESS:
      return {
        ...state,
        errors: '',
        status: '',
        isFetching: false,
        data: payload.data
      };
    case UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: '',
        status: DialogMessagesStatuses.success,
        message: payload.message,
        data: payload.data,
      };
    case FLUSH_IMAGE_ERRORS:
      return {
        ...state,
        errors: '',
        updateErrors: {},
      };
    case FLUSH_IMAGE_STATE:
      return {
        ...state,
        data: initialState.data
      };
    default:
      return state
  }
};

export default image;

export const getIsFetching = state => state.image.isFetching;
export const getImageErrors = state => state.image.errors;
export const getImageUpdateErrors = state => state.image.updateErrors;
export const getImageMessage = state => state.image.message;

export const getImageData = state => state.image.data;

