import grey from '@material-ui/core/colors/grey';
export const styles = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headCells: {
        backgroundColor: theme.palette.primary.lighter
    },
    id: {
        paddingRight: 0
    },
    delete: {
        textAlign: "center"
    }
});
