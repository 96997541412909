import {loadSingleStream} from "../../../helpers/api";
import {
  LOAD_STREAMS_SINGLE_FAILURE,
  LOAD_STREAMS_SINGLE_REQUEST,
  LOAD_STREAMS_SINGLE_SUCCESS
} from "../../constants/streams/single";



export const singleStream = (id) => dispatch => {
  dispatch({type: LOAD_STREAMS_SINGLE_REQUEST});


  return loadSingleStream(id).then(
    res => {
      if (res.data.status === "error") {
        return dispatch({type: LOAD_STREAMS_SINGLE_FAILURE, message: res.data.message})
      }
      return dispatch({
        type: LOAD_STREAMS_SINGLE_SUCCESS,
        payload: res.data
      })
    },
    error => dispatch({type: LOAD_STREAMS_SINGLE_FAILURE, message: error})
  )
};
