import React, {useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import TableComponent from "../TableComponent";
import {useHistory} from "react-router-dom";
import {useResource} from "../../hooks/resource";
import ListActions from "../ListActions";
import {headCellsVideos} from "../../constatns/playlists";
import {getIsFetching, videoPlaylistsSelector} from "../../store/reducers/playlists";
import {getVideoPlaylist} from "../../store/actions/playlists";
import LoadingFade from "../../common/LoadingFade";
import {
    bulkActionRemoveVideoPlayLists,
    updateVideoPlaylistsAfterBulk
} from "../../store/actions/bulk";
import {getErrorMessage} from "../../helpers/validation";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

const VideosPlaylist = ({classes}) => {
    const history = useHistory();
    const {
        data: {
            playlists,
            count,
        },
        loading,
        page,
        handleChangePage,
        order,
        handleChangeOrder,
        orderBy,
        handleChangeOrderBy,
        search,
        handleChangeSearch,
        rowsPerPage,
        handleChangeLimit
    } = useResource(videoPlaylistsSelector, getVideoPlaylist, getIsFetching);

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [flushTableComponentState, setFlushTableComponentState] = useState(false);

    useEffect(() => {
        setFlushTableComponentState(false)
    }, [playlists]);

    const handleRowClick = () => id => {
        history.push(`/videos/playlists/${id}/edit`)
    };

    const handleClick = () => {
        history.push('/videos/playlists/single')
    };

    const applyBulkAction = bulkData => {
        dispatch(bulkActionRemoveVideoPlayLists({ids: bulkData.selected})).then(res => {
            const {data} = res;
            setFlushTableComponentState(true);
            dispatch(updateVideoPlaylistsAfterBulk(bulkData.selected));
            dispatch(getVideoPlaylist(page, orderBy, order, rowsPerPage));
            enqueueSnackbar(data.message, {variant: 'success'});
        }).catch(error => {
            enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
        });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <LoadingFade loading={loading}/>
                <TableComponent
                    type="playlists"
                    rows={playlists}
                    count={count}
                    page={page}
                    limit={rowsPerPage}
                    order={order}
                    orderBy={orderBy}
                    actionButtons={<ListActions
                        searchRequest={search}
                        searchHandler={handleChangeSearch}
                        isAdd
                        onClickAdd={handleClick}
                    />}
                    headCells={headCellsVideos}
                    onChangePage={handleChangePage}
                    onChangeOrder={handleChangeOrder}
                    onChangeOrderBy={handleChangeOrderBy}
                    onChangeLimit={handleChangeLimit}
                    handleRowClick={handleRowClick()}
                    bulkActionsTypes={['delete']}
                    setInitialState={flushTableComponentState}
                    onApplyBulk={applyBulkAction}
                />
            </Paper>
        </div>
    )
};

VideosPlaylist.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default VideosPlaylist;
