import {
    LOAD_VIDEO_PLAYLIST_FAILURE,
    LOAD_VIDEO_PLAYLIST_REQUEST,
    LOAD_VIDEO_PLAYLIST_SUCCESS
} from "../constants/playlists";
import { loadVideosPlaylist } from "../../helpers/api";


export const getVideoPlaylist = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search
) => dispatch => {
    dispatch({type: LOAD_VIDEO_PLAYLIST_REQUEST});

    loadVideosPlaylist(page, orderBy, order, limit, search).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_VIDEO_PLAYLIST_FAILURE, message: message })
            }
            return dispatch({
                type: LOAD_VIDEO_PLAYLIST_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_VIDEO_PLAYLIST_FAILURE, message: error})
    )
};
