import React from "react";
// import * as PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";



const SingleImageSimpleForm = ({...props}) => {
    const {classes, disabled, errors, imageData, onChange, onFileUpload, base64} = props;
    // console.log(base64, 'form comp')
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6} className={classes.gridCol50}>
                <Box className={classes.fieldsWrapper}>
                    <div className={classes.textField}>
                        <TextField
                            disabled={disabled}
                            name="title"
                            error={errors && !!errors.name}
                            helperText={!!errors && !!errors.name && errors.name}
                            label="Image Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={imageData.title}
                            onChange={onChange('title')}
                        />
                    </div>
                    <div className={classes.textField}>
                        <TextField
                            disabled={disabled}
                            name="description"
                            label="Description"
                            multiline
                            rows="4"
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={imageData.description}
                            onChange={onChange('description')}
                        />
                    </div>
                    <div className={classes.textField}>
                        <Box className={classes.uploadBlock}>
                            <input
                                name="photo"
                                accept="image/*"
                                style={{display: 'none'}}
                                id="image-photo-upload"
                                type="file"
                                onChange={onFileUpload}
                                disabled={disabled}
                            />
                            <label htmlFor="image-photo-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Upload photo
                                </Button>
                            </label>
                        </Box>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.gridCol50}>
                <Box className={classes.fieldsWrapper}>
                    <Box className={classes.imageWrapper}>
                        {/*TODO: add lazy loading*/}
                        {imageData.file && base64 &&
                        <img src={base64}/>}
                    </Box>

                </Box>
            </Grid>
        </Grid>

    );
};

export default SingleImageSimpleForm;
