import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import TableComponent from "../TableComponent";
import {headCellsCategories} from "../../constatns/categories";
import {getCategoriesList} from '../../store/actions/categories';
import {getIsFetching, categoriesSelector} from '../../store/reducers/categories';
import {useResource} from "../../hooks/resource";
import ListActions from "../ListActions";
import LoadingComponent from "../../common/LoadingComponent";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {
    bulkActionRemoveForImagesCategories,
} from "../../store/actions/bulk";
import {getErrorMessage} from "../../helpers/validation";

const bulkActionsTypes = ['delete'];


const CategoryList = ({classes}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {
        data: {
            categories,
            count,
        },
        loading,
        page,
        handleChangePage,
        order,
        handleChangeOrder,
        orderBy,
        handleChangeOrderBy,
        search,
        handleChangeSearch,
        rowsPerPage,
        handleChangeLimit
    } = useResource(categoriesSelector, getCategoriesList, getIsFetching);

    const [flushTableComponentState, setFlushTableComponentState] = useState(false)
    useEffect(() => {
        setFlushTableComponentState(false)
    }, [categories]);

    const handleRowClick = () => id => {
        history.push(`/images/categories/${id}/edit`)
    };

    const handleClick = () => {
        history.push('/images/categories/single')
    };

    const applyBulkAction = bulkData => {
        dispatch(bulkActionRemoveForImagesCategories({ids: bulkData.selected})).then(res => {
            const {data} = res;
            enqueueSnackbar(data.message, {variant: 'success'});
            dispatch(getCategoriesList(page, orderBy, order, rowsPerPage, search));
            setFlushTableComponentState(true);
        }).catch(error => {
            enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
        });
    };

    // if (loading) {
    //     return (<LinearProgress/>)
    // }
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <LoadingComponent loading={loading}/>
                    <TableComponent
                        type="categories"
                        rows={categories}
                        count={count}
                        page={page}
                        limit={rowsPerPage}
                        order={order}
                        orderBy={orderBy}
                        bulkActionsTypes={bulkActionsTypes}
                        onApplyBulk={!flushTableComponentState ? applyBulkAction : () => {}}
                        actionButtons={<ListActions
                            searchRequest={search}
                            searchHandler={handleChangeSearch}
                            isAdd
                            onClickAdd={handleClick}
                        />}
                        headCells={headCellsCategories}
                        onChangePage={handleChangePage}
                        onChangeOrder={handleChangeOrder}
                        onChangeOrderBy={handleChangeOrderBy}
                        onChangeLimit={handleChangeLimit}
                        handleRowClick={handleRowClick()}
                        setInitialState={flushTableComponentState}
                    />
                </Paper>
        </div>
    )
};

CategoryList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default CategoryList;
