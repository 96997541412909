import React, { useCallback } from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useMediaQuery, useTheme } from '@material-ui/core'
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import icona from 'tui-image-editor/dist/svg/icon-a.svg'
import iconb from 'tui-image-editor/dist/svg/icon-b.svg'
import iconc from 'tui-image-editor/dist/svg/icon-c.svg'
import icond from 'tui-image-editor/dist/svg/icon-d.svg'

const ImageEditDialog = ({
  open,
  onClose,
  classes,
  imageUrl,
  onSave,
}) => {

  const closeDialog = () => {
    onClose()
  }
  const editorTheme = {
    'menu.normalIcon.path': iconb,
    'menu.normalIcon.name': 'icon-b',
    'menu.activeIcon.path': icona,
    'menu.activeIcon.name': 'icon-a',
    'submenu.normalIcon.path': iconc,
    'submenu.normalIcon.name': 'icon-a',
    'submenu.activeIcon.path': icond,
    'submenu.activeIcon.name': 'icon-c',

  }
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))
  const editorRef = React.createRef();
  const handleSaveAction = () => {
    const base64 = editorRef.current.getInstance().toDataURL();
    onSave(base64);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
      >
        <DialogTitle id="image-editor">Edit Image</DialogTitle>
        <DialogContent
          className={classes.editorTui}
        >
          <ImageEditor
            ref={editorRef}
            includeUI={{
              theme: editorTheme,
              loadImage: {
                path: imageUrl,
                name: 'SampleImage',
              },
              menu: [
                'crop',
                'flip',
                'rotate',
                'draw',
                'shape',
                'icon',
                'mask',
                'filter'],
              initMenu: 'crop',
              // uiSize: {
              //     width: '1000px',
              //     height: '700px'
              // },
              menuBarPosition: 'bottom',
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveAction}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>

        </DialogActions>
      </Dialog>
    </div>)
}

ImageEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
}

export default ImageEditDialog
