import {createSelector} from 'reselect';
import {
    LOAD_CATEGORIES_REQUEST,
    LOAD_CATEGORIES_SUCCESS,
    LOAD_CATEGORIES_FAILURE,
    LOAD_CATEGORIES_ERRORS,
    ADD_CATEGORY_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_SUCCESS,
} from "../constants/categories";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import {REMOVE_CATEGORY_FROM_LIST} from "../constants/category";
import {CREATE_CATEGORIES_BULK_ACTION_REQUEST, CREATE_CATEGORIES_BULK_ACTION_SUCCESS} from "../constants/bulk";

const initialState = {
    isFetching: false,
    categories: [],
    errors: false,
    status: '',
    message: '',
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10
};

const categories = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_CATEGORIES_REQUEST:
        case ADD_CATEGORY_REQUEST:
        case CREATE_CATEGORIES_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                categories: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                categories: [payload.data].concat(state.categories.slice(0, -1)),
                status: DialogMessagesStatuses.success,
            };
        case UPDATE_CATEGORY_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                categories: state.categories.map((category) => category.id === data.id ? data : category)
            };
        case REMOVE_CATEGORY_FROM_LIST:
            return {
                ...state,
                categories: state.categories.filter(category => category.id !== payload),
            };
        case CREATE_CATEGORIES_BULK_ACTION_SUCCESS:
            return {
                ...state,
                categories: state.categories.filter(category => payload.data.ids.indexOf(category.id))
            };
        case LOAD_CATEGORIES_FAILURE:
        case ADD_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case LOAD_CATEGORIES_ERRORS:
            return {
                ...state,
                errors: '',
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
            };
    }
};

export default categories;

export const getIsFetching = state => state.categories.isFetching;
export const getCategoriesOrderBy = state => state.categories.orderBy;
export const getCategoriesArr = state => state.categories.categories;
export const getCategoriesCount = state => state.categories.count;
export const getCategoriesPage = state => state.categories.page;
export const getCategoriesLimit = state => state.categories.limit;
export const getCategoriesOrder = state => state.categories.order;
export const getCategoriesErrors = state => state.categories.errors;

export const getCategoriesMessage = state => state.categories.message;
export const getCategoriesResult = state => state.categories.status;

export const categoriesSelector = createSelector([
    getCategoriesArr,
    getIsFetching,
    getCategoriesCount,
    getCategoriesPage,
    getCategoriesLimit,
    getCategoriesOrder,
    getCategoriesOrderBy,
], (categories,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
) => {
    return {
        categories,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
    };
});
