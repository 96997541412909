import {
    apiRequest,
    removePendingEvent,
    updatePendingEvent as apiUpdatePendingEvent
} from "../../../helpers/api";
import {
    DELETE_EVENT_PENDING_FAILURE,
    DELETE_EVENT_PENDING_SUCCESS,
    DELETE_EVENT_PENDING_REQUEST,
    UPDATE_EVENT_PENDING_FAILURE,
    UPDATE_EVENT_PENDING_REQUEST,
    UPDATE_EVENT_PENDING_SUCCESS, CHANGE_EVENT_PENDING_STATUS_TO_PROGRESS, REMOVE_PENDING_EVENT_FROM_LIST
} from "../../constants/events/eventPending";
import {REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE} from "../../constants/images";

export const deletePendingEvent = (dataTitle) => dispatch => {
    dispatch({type: DELETE_EVENT_PENDING_REQUEST});
    return removePendingEvent(dataTitle).then(
        res => {
            if (res.status === "error") {
                return dispatch({type: DELETE_EVENT_PENDING_FAILURE, message: res.message})
            }
            return dispatch({
                type: DELETE_EVENT_PENDING_SUCCESS,
                payload: res.data
            })
        },
        error => dispatch({type: DELETE_EVENT_PENDING_FAILURE, message: error})
    )
};

export const updatePendingEvent = (dataTitle) => dispatch => {
    dispatch({type: UPDATE_EVENT_PENDING_REQUEST});
    const request = () => apiUpdatePendingEvent(dataTitle);
    return apiRequest(request, dispatch, UPDATE_EVENT_PENDING_SUCCESS, UPDATE_EVENT_PENDING_FAILURE);
};

export const changeEventsStatusToProgress = items => dispatch => {
    dispatch({
        type: CHANGE_EVENT_PENDING_STATUS_TO_PROGRESS,
        payload: items
    });
};

export const removePendingEventFromList = title => dispatch => {
    dispatch({
        type: REMOVE_PENDING_EVENT_FROM_LIST,
        payload: title
    })
};

