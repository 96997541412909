import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NameStatusBadge from "../../common/NameStatusBadge";

const SingleImageToolbar = (
  {
      image,
      classes,
      onAction
  }
) => (
  <Toolbar className={classes.root}>
    <IconButton
      onClick={onAction}
      className={classes.closeButton}
    ><ArrowBackIcon/></IconButton>
    {
      (!!image.status) ? (
        <Typography>
          Photo - <NameStatusBadge status={image.status}>{` ${image.title || 'untitled'}`}</NameStatusBadge>
        </Typography>
      ) : ''
    }
  </Toolbar>
);

export default SingleImageToolbar;
