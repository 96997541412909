import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getIsFetching, imagesTaxonomiesSelector} from "../store/reducers/taxonomies";
import {fetchAllImagesCategories, fetchAllImagesTags} from "../store/actions/taxonomies";

export const useImagesTaxonomies = () => {
    const dispatch = useDispatch();
    const {imagesCategories, imagesTags} = useSelector(imagesTaxonomiesSelector);
    const loading = useSelector(getIsFetching);

    useEffect(() => {
        if (!imagesCategories.length) {
            dispatch(fetchAllImagesCategories());
        }
        if (!imagesTags.length) {
            dispatch(fetchAllImagesTags());
        }
    }, [dispatch]);

    return {
        imagesCategories,
        imagesTags,
        loading
    };
};
