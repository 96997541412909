export const styles = theme => ({
    imageData: {
        paddingTop: theme.spacing(3),
    },
    gridCol50: {
        justifyContent: 'center',
        display: 'flex'
    },
    fieldsWrapper: {
        maxWidth: '80%',
        flex: '1 1 auto'
    },
    textField: {
        marginBottom: theme.spacing(4)
    },
    formControl: {
        width: '100%',
    },
    datePicker: {
        width: '100%'
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem'
    },
    mapBlock: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
        paddingTop: 0,
    },
});
