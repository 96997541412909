import React, { Component, Fragment } from 'react'
import { map } from 'lodash'
import { initialState } from '../../store/reducers/image'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import {
  deleteImage,
  flushImageErrors,
  flushImageState,
  getImage,
  removeImageFromList,
  updateImage,
} from '../../store/actions/image'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import SingleImageSkeleton from './SingleImageSkeleton'
import SingleImageToolbar from './SingleImageToolbar'
import { flushImageFile } from '../../store/actions/file'
import NotFoundComponent from '../../common/NotFoundComponent'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import SingleImageForm from '../SingleImageForm'
import { getErrorMessage } from '../../helpers/validation'

class SingleImageNew extends Component {
  static getDerivedStateFromProps (props, state) {
    if (!state.image.id && !!props.imageData.id) {
      return {
        base64: null,
        image: {
          ...props.imageData,
        },
      }
    }
    return null
  }

  constructor (props) {
    super(props)
    this.state = {
      showDeleteDialog: false,
      base64: null,
      image: {
        ...initialState.data,
      },
    }
  }

  componentDidMount () {
    this.props.getImage(this.props.id)
  }

  isFetching = () => {
    return this.props.isFetching ||
      (!this.state.image.id && !this.props.errors)
  }

  isNotFound = () => {
    const { isFetching, imageData, errors } = this.props
    return !isFetching && !imageData.id && !!errors
  }

  isReadOnly = () => {
    return this.isFetching() || this.isNotFound() || this.state.image.status ===
      'in_process'
  }

  hasChanges = () => {
    return JSON.stringify(this.state.image) !==
      JSON.stringify(this.props.imageData) ||
      !!this.state.base64
  }

  handleBack = () => {
    this.props.flushImageErrors()
    this.props.flushImageState()
    this.props.flushImageFile()
    this.props.history.push(`/images/list/used`)
  }

  handleOpenDeleteDialog = () => {
    this.setState({ showDeleteDialog: true })
  }

  handleCloseDeleteDialog = () => {
    this.setState({ showDeleteDialog: false })
  }

  handleDelete = () => {
    this.props.deleteImage(this.state.image.id).then(
      res => {
        this.props.removeImageFromList(this.state.image.id)
        this.props.enqueueSnackbar(res.data.message, { variant: 'success' })
        this.handleBack();
      },
    ).catch(
      err => {
        this.props.enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      },
    )
  }

  handleSave = () => {
    const { image, base64 } = this.state
    const data = {
      ...image,
      tags: map(image.tags, 'id'),
      categories: map(image.categories, 'id'),
    }

    if (base64) {
      data.file = base64
    }

    this.props.updateImage(data).then(
      res => {
        console.group('SingleImageNew.js:93 - then')
        console.log(res)
        console.groupEnd()
        this.props.removeImageFromList(this.state.image.id)
        this.props.enqueueSnackbar(res.data.message, { variant: 'success' })
      }).catch(
      err => {
        console.group('SingleImageNew.js:93 - catch')
        console.log(err)
        console.groupEnd()
        this.props.enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      },
    )
  }

  handleChangeFields = name => event => {
    switch (name) {
      case 'geolocation':
        this.setState({
          image: {
            ...this.state.image,
            ...event,
          },
        })
        break
      case 'image':
        this.setState({
          base64: event,
        })
        break
      case 'expired_at':
      case 'categories':
      case 'tags':
        this.setState({
          image: {
            ...this.state.image,
            [name]: event,
          },
        })
        break
      default:
        this.setState({
          image: {
            ...this.state.image,
            [name]: event.target.value,
          },
        })
    }

  }

  renderContent () {
    const { classes, errors } = this.props
    const { image, showDeleteDialog } = this.state

    const content = !this.isNotFound() ?
      (
        <React.Fragment>
          <Divider/>
          <SingleImageForm
            disabled={this.isReadOnly()}
            image={image}
            onChange={this.handleChangeFields}
            errors={errors}
          />
          <Divider/>
          <Container className={classes.actions}>
            <Grid container spacing={3}>
              <Grid item lg={6} className={classes.actionsBlock}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleOpenDeleteDialog}
                >Delete</Button>
              </Grid>
              <Grid item lg={6} className={classes.actionsBlock}>
                <Button
                  disabled={this.isReadOnly() || !this.hasChanges()}
                  color="primary"
                  variant="contained"
                  onClick={this.handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Container>
          <ConfirmDeleteDialog
            id={image.id}
            open={showDeleteDialog}
            onClose={this.handleCloseDeleteDialog}
            deleteItemAction={this.handleDelete}
            title={image.title}
          />
        </React.Fragment>
      ) : (<NotFoundComponent/>)

    return (
      <React.Fragment>
        <SingleImageToolbar
          classes={classes}
          image={image}
          onAction={this.handleBack}
        />
        <Divider/>
        {content}
      </React.Fragment>
    )
  }

  render () {
    const { classes } = this.props
    return (
      <Paper className={classes.paper}>
        {
          this.isFetching() ? (<SingleImageSkeleton/>) : this.renderContent()
        }
      </Paper>
    )
  }
}

const mapStateToProps = ({ image }, { match: { params: { id } } }) => {
  return {
    id,
    isFetching: image.isFetching,
    imageData: image.data,
    errors: image.errors,
  }
}

const mapDispatchToProps = {
  deleteImage,
  flushImageErrors,
  flushImageState,
  getImage,
  removeImageFromList,
  updateImage,
  flushImageFile,
}

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SingleImageNew))
