export const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
});
