import React from "react";
import Box from "@material-ui/core/Box";

const Logo = ({classes}) => {
    return (
        <Box className={classes.logoWrapper}>
            <img src="/assets/images/w-logo.png"/>
        </Box>
    );
}

export default Logo;


