import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import ImageComponent from '../../common/ImageComponent/'
import { fileExtension } from '../../helpers/files'
import ImagePreloader from '../../common/ImagePreloader'
import { useImageBase64 } from '../../hooks/imageBase64'

const SingleImageEditor = (
  {
    classes,
    image,
    onChange
  },
) => {
  const { file, loadingFile } = useImageBase64(image.id)
  const [base64, setBase64] = useState('')

  useEffect(() => {
    if (!loadingFile && !!file) {
      setBase64(file)
    }
  }, [file, loadingFile])

  const handleImageClick = () => {
    const downloadLink = document.createElement('a')
    downloadLink.href = base64
    downloadLink.download = [image.hash, fileExtension(image.location)].join(
      '.')
    downloadLink.click()
  }

  return (
    <Box className={classes.imageWrapper}>
      {
        loadingFile || !base64 ?
          (
            <ImagePreloader />
          ) :
          (
            <ImageComponent
              base64={base64}
              onChange={onChange}
              // loading={loading}
              onClick={handleImageClick}
            />
          )
      }
    </Box>
  )
}

export default SingleImageEditor
