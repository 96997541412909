import {streamDelete} from "../../../helpers/api";
import {
  STREAMS_REMOVE_REQUEST,
  STREAMS_REMOVE_SUCCESS,
  STREAMS_REMOVE_FAILURE,
} from "../../constants/streams/remove";



export const removeStream = (eventId) => dispatch => {
  dispatch({type: STREAMS_REMOVE_REQUEST});


  return streamDelete(eventId).then(
    res => {
      if (res.data.status === "error") {
        return dispatch({type: STREAMS_REMOVE_FAILURE, message: res.data.message})
      }
      return dispatch({
        type: STREAMS_REMOVE_SUCCESS,
        payload: res.data
      })
    },
    error => dispatch({type: STREAMS_REMOVE_FAILURE, message: error})
  )
};
