import React from "react";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";

const TampleComponent = ({loading, classes}) => {
    if (!loading) {
        return null;
    }
    return (<Box className={classes.fadeWrapper}>
        <Box className={classes.circularWrapper}>
            <CircularProgress/>
        </Box>
        <Box className={classes.loadingFade}/>
    </Box>);
}

export default TampleComponent;
