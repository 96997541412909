import {
    FETCH_CATEGORY_REQUEST,
    FETCH_CATEGORY_FAILURE,
    FETCH_CATEGORY_SUCCESS,
    FLUSH_CATEGORY_ERRORS,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    FLUSH_CATEGORY_STATE,
    FLUSH_CATEGORY_MESSAGES,
    REMOVE_CATEGORY_FROM_LIST
} from "../constants/category";

import {deleteCategory as apiDeleteCategory, fetchCategory, apiRequest} from "../../helpers/api";

export const getCategory = id => dispatch => {
    dispatch({type: FETCH_CATEGORY_REQUEST});
    return fetchCategory(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_CATEGORY_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_CATEGORY_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_CATEGORY_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushCategoryErrors = () => dispatch => {
    dispatch({type: FLUSH_CATEGORY_ERRORS});
};

export const flushCategoryState = () => dispatch => {
    dispatch({type: FLUSH_CATEGORY_STATE});
};

export const deleteCategory = id => dispatch => {
    dispatch({type: DELETE_CATEGORY_REQUEST});
    const request = () => apiDeleteCategory(id);
    return apiRequest(request, dispatch, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE)
};

export const removeCategoryFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_CATEGORY_FROM_LIST,
        payload: id
    })
};

export const flushCategoryMessages = () => dispatch => {
    dispatch({type: FLUSH_CATEGORY_MESSAGES});
};
