import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'

const request = (
  [action, dispatch, page, rowsPerPage, order, orderBy, search, filter]
) => {
  const filterData = Object.assign({},
    ...filter.map(f => ({ [f.key]: f.value })))
  dispatch(
    action(
      page + 1, orderBy, order, rowsPerPage, search, filterData.from,
      filterData.to, filterData.categories, filterData.tags, filterData.excepts,
      filterData.status
    )
  )
}

export const useResource = (selector, action, isFetchingSelector, initParams = {}) => {
  const dispatch = useDispatch()
  const data = useSelector(selector)
  const loading = useSelector(isFetchingSelector)
  const [page, setPage] = useState(data.page ? data.page - 1 : 0)
  const [order, setOrder] = useState(data.order)
  const [orderBy, setOrderBy] = useState(data.orderBy)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(initParams.limit || data.limit)
  const [filter, setFilter] = useState(initParams.filter || [])

  const onChangeFilter = useCallback((filterData) => {
    setFilter(filterData)
  }, [filter, setFilter])

  const clearFilter = useCallback(() => {
    setFilter([])
  }, [setFilter])

  useEffect(() => {
    request([action, dispatch, page, rowsPerPage, order, orderBy, search, filter])
  }, [dispatch, page, rowsPerPage, order, orderBy, search, filter])

  // useEffect(() => {
  //     const filterData = Object.assign({}, ...filter.map(f => ({[f.key]: f.value})));
  //     dispatch(action(page + 1, orderBy, order, rowsPerPage, search, filterData.status, filterData.from, filterData.to, filterData.categories, filterData.tags))
  // }, [dispatch, page, rowsPerPage, order, orderBy, search, filter]);

  const handleChangeLimit = useCallback((newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  }, [rowsPerPage, setRowsPerPage])

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage)
  }, [page, setPage])

  const handleChangeOrder = useCallback((newOrder) => {
    setOrder(newOrder)
  }, [order, setOrder])

  const handleChangeOrderBy = useCallback((newOrderBy) => {
    setOrderBy(newOrderBy)
  }, [orderBy, setOrderBy])

  const handleChangeSearch = useCallback((newSearch) => {
    setSearch(newSearch)
  }, [search, setSearch])

  const handleRefresh = useCallback(() => {
    request([action, dispatch, page, rowsPerPage, order, orderBy, search, filter])
  }, [dispatch, page, rowsPerPage, order, orderBy, search, filter])

  return {
    data,
    loading,
    page,
    handleChangePage,
    order,
    handleChangeOrder,
    orderBy,
    handleChangeOrderBy,
    search,
    handleChangeSearch,
    rowsPerPage,
    handleChangeLimit,
    filter,
    onChangeFilter,
    clearFilter,
    handleRefresh,
  }
}
