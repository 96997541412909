export const styles = theme => ({
    dropzoneInput: {
        display: 'none',
        width: '100%',
        height: '100%'
    },
    uploadBlock: {
        border: '2px dashed #eeeeee',
        color: '#bdbdbd',
        borderRadius: '2px',
        width: '100%',
        minHeight: theme.spacing(10),
        textAlign: 'center',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    dropzoneBlock: {
        position: 'relative',
    },
    fadeBlock: {
        position: 'absolute',
        opacity: 0,
        zIndex: 999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    hiddenBlock: {
        display: 'none'
    }
});
