import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/Home'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import SettingsIcon from '@material-ui/icons/Settings'
import VideocamIcon from '@material-ui/icons/Videocam'
import WifiTetheringIcon from '@material-ui/icons/WifiTethering'
import SideBarMenuItem from '../../common/SideBarMenuItem'
import SideBarParentMenuItem from '../../common/SideBarParentMenuItem'
import { useDispatch } from 'react-redux'

const SidebarMenu = ({ classes }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [openVideoManager, setOpenVideoManager] = useState(false)

  const openImagesMenuHandler = () => {
    history.push('/images/list')
  }

  const openVideoMenuHandler = () => {
    history.push('/videos/video')
  }

  // TODO: improve function args
  const isActiveItem = current => location.pathname.split('/').
    includes(current)

  return (<div className="linksDiv">
    <SideBarMenuItem
      linkTo="/dashboard"
      title="Dashboard"
      icon={<HomeIcon/>}
      active={location.pathname === '/dashboard'}
    />
    <SideBarMenuItem
      linkTo="/users"
      title="Users"
      icon={<SupervisorAccountIcon/>}
      active={isActiveItem('users')}
    />
    <SideBarMenuItem
      linkTo="/streams"
      title="Streams"
      icon={<WifiTetheringIcon/>}
      active={isActiveItem('streams')}
    />
    <SideBarParentMenuItem
      title="Photo manager"
      onClick={openImagesMenuHandler}
      active={isActiveItem('images')}
      icon={<PhotoLibraryIcon/>}
    />
    <Collapse in={isActiveItem('images')}
              timeout="auto"
              unmountOnExit
    >
      <List component="div" disablePadding>
        <SideBarMenuItem
          linkTo="/images/list/used"
          title="Photos"
          active={isActiveItem('images') &&
          (isActiveItem('list') || isActiveItem('image'))}
        />
        <SideBarMenuItem
          linkTo="/images/categories"
          title="Category"
          active={isActiveItem('images') && isActiveItem('categories')}
        />
        <SideBarMenuItem
          linkTo="/images/tags"
          title="Tags"
          active={location.pathname === '/images/tags'}
        />
      </List>
    </Collapse>
    {/*<ListItem button onClick={() => setOpenVideoManager(!openVideoManager)}>*/}
    <SideBarParentMenuItem
      title="Video manager"
      onClick={openVideoMenuHandler}
      active={isActiveItem('videos')}
      icon={<VideocamIcon/>}
    />
    <Collapse
      in={isActiveItem('videos')}
      timeout="auto"
      unmountOnExit
    >
      <List component="div" disablePadding>
        <SideBarMenuItem
          linkTo="/videos/video"
          title="Video"
          active={isActiveItem('videos') && isActiveItem('video')}
        />
        <SideBarMenuItem
          linkTo="/videos/categories"
          title="Category"
          active={isActiveItem('videos') && isActiveItem('categories')}
        />
        <SideBarMenuItem
          linkTo="/videos/tags"
          title="Tags"
          active={isActiveItem('videos') && isActiveItem('tags')}
        />
        <SideBarMenuItem
          linkTo="/videos/playlists"
          title="Playlist"
          active={isActiveItem('videos') && isActiveItem('playlists')}
        />
      </List>
    </Collapse>
    <SideBarMenuItem
      linkTo="/events/in-use"
      title="Severe weather center"
      icon={<SettingsIcon/>}
      active={isActiveItem('events')}
    />
  </div>)
}

SidebarMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default SidebarMenu
