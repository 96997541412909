export const styles = theme => ({
    statusChip: {
        display: 'inline-block',
        borderRadius: '4px',
        padding: '0 6px',
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: '0.75em',
        lineHeight: '1.75em',
        color: '#fff',
        fontWeight: theme.typography.fontWeightMedium,
    },
    active: {
        backgroundColor: theme.palette.success.main,
    },
    pending: {
        backgroundColor: theme.palette.warning.main,
    },
    blocked: {
        backgroundColor: theme.palette.error.main,
    },
    malformed: {
        backgroundColor: theme.palette.error.main,
    },
    approved: {
        backgroundColor: theme.palette.success.main,
    },
    expired: {
        backgroundColor: theme.palette.info.main,
    },
    process: {
        backgroundColor: theme.palette.info.main,
    },
    in_process: {
        backgroundColor: theme.palette.info.main,
    },
    large: {
        padding: '6px 16px',
        fontSize: '0.875rem'
    }
});
