import {loadSingleEvent} from "../../../helpers/api";
import {
  LOAD_EVENTS_SINGLE_FAILURE,
  LOAD_EVENTS_SINGLE_REQUEST,
  LOAD_EVENTS_SINGLE_SUCCESS
} from "../../constants/events/single";



export const singleEvent = (eventId) => dispatch => {
  dispatch({type: LOAD_EVENTS_SINGLE_REQUEST});


  return loadSingleEvent(eventId).then(
    res => {
      if (res.data.status === "error") {
        return dispatch({type: LOAD_EVENTS_SINGLE_FAILURE, message: res.data.message})
      }
      return dispatch({
        type: LOAD_EVENTS_SINGLE_SUCCESS,
        payload: res.data
      })
    },
    error => dispatch({type: LOAD_EVENTS_SINGLE_FAILURE, message: error})
  )
};
