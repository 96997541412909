import {
    LOAD_PENDING_IMAGES_FAILURE,
    LOAD_PENDING_IMAGES_REQUEST,
    LOAD_PENDING_IMAGES_SUCCESS
} from "../constants/images";
import {loadPendingImagesList} from "../../helpers/api";

export const getPendingImagesList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search,
    status,
    dates
) => dispatch => {
    dispatch({type: LOAD_PENDING_IMAGES_REQUEST});
    //TODO: need refactor for use search
    loadPendingImagesList(page, orderBy, order, limit, search, status, dates).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_PENDING_IMAGES_FAILURE, message: message})
            }
            return dispatch({
                type: LOAD_PENDING_IMAGES_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_PENDING_IMAGES_FAILURE, message: error})
    )
};
