import React from 'react';
import {Field} from 'redux-form'
import FormTextField from "../../../../components/ReduxFormFields/FormTextField";
import {required, length, url} from 'redux-form-validators'

const Url = ({classes, ...rest}) => {
  return (
    <div className={classes.textField}>
      <Field
        name={'url'}
        label={"URL"}
        type={"text"}
        fullWidth
        variant="outlined"
        component={FormTextField}
        validate={
          [
            required(
            {message: "Required field"}
            ),
            url(
              {
                message: "Must be a valid URL"
              }
            )
          ]
        }
        placeholder={"URL"}
        {...rest}
      />
    </div>
  );
};

export default Url;
