import React from "react";
import * as PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {DialogMessagesStatuses} from "../../constatns/statuses";
import Box from "@material-ui/core/Box";

const {error, success} = DialogMessagesStatuses;

const DialogMessages = ({status, message, classes}) => {
    let icon = '';
    switch (status) {
        case success:
            icon = <CheckCircleOutlineIcon/>;
            break;
        case error:
            icon = <HighlightOffIcon/>;
            break;
        default:
            icon = <ErrorOutlineIcon/>;
            break;
    }
    return (
        <Box
            className={classes.root}
            bgcolor={status + '.main'}
            color={status + '.contrastText'}
            p={2}
        >
            <div className={classes.icon}>
                {icon}
            </div>
            {message}
        </Box>
    )
};

DialogMessages.propTypes = {
    // status: PropTypes.oneOf([error, info, success]).isRequired,
    // message: PropTypes.string.isRequired,
    // classes: PropTypes.object.isRequired,
};
export default DialogMessages;
