import {useCallback, useState} from "react";

const useOrder = (filter, order) => {
  const [orderState, setOrder] = useState({
    filter,
    order
  });

  const handleChangeOrder = useCallback((key, value) => {
    setOrder(state => {
      if (state.filter === key) {
        return {
          ...state,
          order: value
        }
      }

      return {
        ...state,
        filter: key,
        order:'asc'
      }

    })
  }, [setOrder]);

  return [orderState, handleChangeOrder]
};
export default useOrder;