import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import ImagesList from "../../ImagesList";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Chip from '@material-ui/core/Chip';
import ImagesListPending from "../../ImagesListPending";
import {useDispatch, useSelector} from "react-redux";
import {
    getIsFetching as approvedImagesFetching
} from "../../../store/reducers/images";
import {
    getIsFetching as pendingImagesFetching
} from "../../../store/reducers/imagesPending"
import {fetchCounts} from "../../../store/actions/counts";

const tabs = {
    used: 0,
    pending: 1
};

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
};

const LinkTab = (props) => {
    const history = useHistory();
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
                history.push(props.href)
            }}
            {...props}
        />
    );
}

const ImagesLists = ({classes}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentList = location.pathname.split('/').slice(-1).pop();
    const currentTab = currentList ? tabs[currentList] : 0;
    const [tab, setTab] = useState(currentTab);
    const loadingList = useSelector(approvedImagesFetching);
    const loadingListPending = useSelector(pendingImagesFetching);
    const hideIndicatorClassName = loadingList || loadingListPending ? 'hidden' : '';

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        dispatch(fetchCounts());
    }, [tab, dispatch]);

    const counts = useSelector(state => state.counts.counts);

    let pendingCount = _.get(counts, 'images.pending');

    return (
        <div className={classes.root}>
            <AppBar className={classes.shadow} position="static" color="default">
                {/*TODO: improve tab value set*/}
                <Tabs
                    value={tab ? tab : 0}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable prevent tabs example"
                    classes={{indicator: classes[hideIndicatorClassName]}}
                >
                    <LinkTab
                        label="In use" aria-label="used-images"
                        className={classes.linkStyle}
                        href="/images/list/used"
                        {...a11yProps(tabs.used)}
                        disabled={currentList === "used"}
                    />
                    <LinkTab label="Pending"
                             className={classes.linkStyle}
                             aria-label="pending-images"
                             icon={!!pendingCount && <Chip color="primary" className={classes.badge} label={pendingCount} />}
                             href="/images/list/pending"
                             {...a11yProps(tabs.pending)}
                             disabled={currentList === "pending"}
                    />
                </Tabs>
            </AppBar>
            <Switch>
                <Route exact path="/images/list/used" component={ImagesList}/>
                <Route exact path="/images/list/pending" component={ImagesListPending}/>
                <Redirect to="/images/list/used"/>
            </Switch>
        </div>
    );
};

export default ImagesLists;
