export const styles = theme => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  toolbar: {
    width: '100%',
    position: 'relative',
    display: "flex",
    justifyContent: "space-between",
    '& > div': {
      display: "flex",
      alignItems: "center",
      flexGrow: 1
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  closeButton: {
    marginRight: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(4)
  },
  select: {
    width: '100%',

  },
  label: {
    zIndex: 30,
    backgroundColor: '#fff'
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(4)

  },
  form: {
    margin: '20px',
    width: '100%',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
    padding: '10px'

  },
  inputWrap: {
    width: '80%',
    margin: '20px',
  },
  btn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  videoBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  }
});
