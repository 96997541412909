import {CREATE_IMAGE_FAILURE, CREATE_IMAGE_REQUEST, CREATE_IMAGE_SUCCESS} from "../constants/images";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
};
const newImage = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case CREATE_IMAGE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case CREATE_IMAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: payload.message,
                status: 'success',
                errors: false,
            };
        case CREATE_IMAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
                status: 'error',
                errors: payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default newImage;

export const getIsFetching = state => state.newImage.isFetching;
export const getNewImageErrors = state => state.newImage.errors;
export const getNewImageMessage = state => state.newImage.message;
