import {
    FETCH_USER_IMAGES_JOBS_FAILURE,
    FETCH_USER_IMAGES_JOBS_REQUEST,
    FETCH_USER_IMAGES_JOBS_SUCCESS, FLUSH_JOBS_STATE
} from "../constants/jobs";

const initialState = {
    isFetching: false,
    errors: false,
    data: []
};

const jobs =(state = initialState, action)=> {
    const {type, payload} = action;
    switch (type) {
        case FETCH_USER_IMAGES_JOBS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_USER_IMAGES_JOBS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: payload.data
            };
        case FETCH_USER_IMAGES_JOBS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload
            };
        case FLUSH_JOBS_STATE:
            return initialState;
        default:
            return {
                ...state,
            }
    }
};

export default jobs;

export const getUserJobs = state => state.jobs.data;
export const getIsFetching = state => state.jobs.isFetching;
