import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const SingleImagePendingToolbar = (
  {
      classes,
      title,
      onAction
  }
) => (
  <Toolbar className={classes.root}>
      <IconButton onClick={onAction} className={classes.closeButton}><ArrowBackIcon/></IconButton>
      <Typography>
          {title ? `Image title - ${title}` : 'New photo'}
      </Typography>
  </Toolbar>
);

export default SingleImagePendingToolbar;
