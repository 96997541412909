import {DialogMessagesStatuses} from "../../constatns/statuses";
import {
    ADD_VIDEO_PLAYLIST_SUCCESS,
    ADD_VIDEO_PLAYLIST_REQUEST,
    ADD_VIDEO_PLAYLIST_FAILURE,
    FLUSH_VIDEO_PLAYLIST_ERRORS,
    FLUSH_VIDEO_PLAYLIST_STATE,
    FETCH_VIDEO_PLAYLIST_REQUEST,
    FETCH_VIDEO_PLAYLIST_FAILURE,
    FETCH_VIDEO_PLAYLIST_SUCCESS,
    UPDATE_VIDEO_PLAYLIST_REQUEST,
    UPDATE_VIDEO_PLAYLIST_SUCCESS,
    DELETE_VIDEO_PLAYLIST_REQUEST,
    DELETE_VIDEO_PLAYLIST_SUCCESS,
    UPDATE_VIDEO_PLAYLIST_FAILURE,
    DELETE_VIDEO_PLAYLIST_FAILURE,
    FLUSH_VIDEO_PLAYLIST_MESSAGES
} from "../constants/playlist";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        name: '',
        playlist_parent: '',
        related: '',
        videos: []
    },
};

const playlist = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_VIDEO_PLAYLIST_REQUEST:
        case ADD_VIDEO_PLAYLIST_REQUEST:
        case UPDATE_VIDEO_PLAYLIST_REQUEST:
        case DELETE_VIDEO_PLAYLIST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_VIDEO_PLAYLIST_SUCCESS:
        case DELETE_VIDEO_PLAYLIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
            };
        case UPDATE_VIDEO_PLAYLIST_FAILURE:
        case DELETE_VIDEO_PLAYLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
                status: DialogMessagesStatuses.error,
                message: payload
            };
        case UPDATE_VIDEO_PLAYLIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message
            };
        case FETCH_VIDEO_PLAYLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_VIDEO_PLAYLIST_SUCCESS:
        case ADD_VIDEO_PLAYLIST_FAILURE:
            return {
                ...state,
                errors: '',
                status: '',
                isFetching: false,
                data: payload.data
            };
        case FLUSH_VIDEO_PLAYLIST_STATE:
            return {
                ...state,
                data: initialState.data
            };
        case FLUSH_VIDEO_PLAYLIST_MESSAGES:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        case FLUSH_VIDEO_PLAYLIST_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: ''
            };
    }
};

export default playlist;

export const getIsFetching = state => state.playlist.isFetching;

export const getVideoPlaylistData = state => state.playlist.data;
