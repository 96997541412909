import React, { useEffect, useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import { dataUrlToBase64 } from '../../helpers/files'
import useVideoFrames from '../../hooks/useVideoFrames'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { find } from 'lodash/collection'

const framesPreloader = () => (
  <Grid container justify="space-between">
    {[1, 2, 3, 4, 5].map(frame => (
      <Grid key={frame} item xs={2}>
        <Card>
          <Skeleton
            variant="rect"
            animation="wave"
            height={80}
            width={"100%"}
          />
        </Card>
      </Grid>
    ))}
  </Grid>
)

const SingleVideoThumbnails = (
  {
    classes,
    video,
    onChange,
  },
) => {
  const [frames, isFetching] = useVideoFrames(video)
  const [active, setActive] = useState('')


  const handleChange = (frameId) => {
    setActive(frameId)
    if ('' === frameId) {
      onChange(frameId)
    } else {
      onChange(dataUrlToBase64(
        frames.filter(frame => frame.id === frameId).pop().dataUrl))
    }
  }

  const previewUrl = useMemo(() => {
      return active === '' ? video.cover :
        find(frames, {id: active}).dataUrl
  }, [frames, active, video])

  return (
    <Box className={classes.root}>
      <Box className={classes.wrap}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Card className={classes.preview}>
              <CardMedia
                className={classes.previewImg}
                image={previewUrl}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            {
              isFetching ?
                framesPreloader() :
                (
                  <Grid container justify="space-between">
                    <Grid key={'main'} item xs={2}>
                      <Card>
                        <CardActionArea
                          onClick={() => handleChange('')}
                        >
                          <CardMedia
                            className={classes.thumbnailImg}
                            image={video.cover}
                          >
                            {active === '' && (
                              <StarIcon className={classes.thumbnailIcon} />
                            )}
                          </CardMedia>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    {frames.map(frame => (
                      <Grid key={frame.id} item xs={2}>
                        <Card>
                          <CardActionArea
                            onClick={() => handleChange(frame.id)}
                          >
                            <CardMedia
                              className={classes.thumbnailImg}
                              image={frame.dataUrl}
                            >
                              {active === frame.id && (
                                <StarIcon className={classes.thumbnailIcon} />
                              )}
                            </CardMedia>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SingleVideoThumbnails
