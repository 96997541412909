import React from 'react';
import {Field} from 'redux-form'
import FormSwitcher from "../../../../components/ReduxFormFields/FormSwitcher";

const Switcher = ({classes, ...rest}) => {
  return (
    <div className={classes.textField}>
      <Field
        name={'published'}
        label={"Publish"}
        variant="outlined"
        {...rest}
        component={FormSwitcher}
      />
    </div>
  );
};

export default Switcher;
