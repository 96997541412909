import {
  LOAD_EVENTS_LIST_REQUEST,
  LOAD_EVENTS_LIST_SUCCESS,
  LOAD_EVENTS_LIST_FAILURE
} from "../../constants/events/list";

import {
  EVENTS_UPDATE_REQUEST,
  EVENTS_UPDATE_SUCCESS,
  EVENTS_UPDATE_FAILURE,
} from "../../constants/events/update";

import {
  EVENTS_REMOVE_REQUEST,
  EVENTS_REMOVE_SUCCESS,
  EVENTS_REMOVE_FAILURE,
} from "../../constants/events/remove";

import {DialogMessagesStatuses} from "../../../constatns/statuses";

const initialState = {
  isFetching: false,
  isUpdating: false,
  message: '',
  errors: false,
  updateErrors: {},
  status: '',
  items: [],
  currentPage: 1,
  total:1,
  pages: 1,
  limit: 10
};

const events = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LOAD_EVENTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case EVENTS_UPDATE_REQUEST:
    case EVENTS_REMOVE_REQUEST:
      return {
        ...state,
        isUpdating: true
      };
    case EVENTS_UPDATE_SUCCESS:
      return{
        ...state,
        isUpdating: false
      }
    case EVENTS_UPDATE_FAILURE:
    case EVENTS_REMOVE_FAILURE:
    case EVENTS_REMOVE_SUCCESS:
      return {
        ...state,
        isUpdating: false
      };
    case LOAD_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        items: payload.data.data,
        currentPage: payload.data.current_page,
        limit: parseInt(payload.data.per_page),
        pages: payload.data.last_page,
        total: payload.data.total,
        isFetching: false
      };
    case LOAD_EVENTS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return {
        ...state
      }
  }

};

export default events;
