export const ADD_VIDEO_CATEGORY_FAILURE = 'ADD_VIDEO_CATEGORY_FAILURE';
export const ADD_VIDEO_CATEGORY_REQUEST = 'ADD_VIDEO_CATEGORY_REQUEST';
export const ADD_VIDEO_CATEGORY_SUCCESS = 'ADD_VIDEO_CATEGORY_SUCCESS';

export const UPDATE_VIDEO_CATEGORY_FAILURE = 'UPDATE_VIDEO_CATEGORY_FAILURE';
export const UPDATE_VIDEO_CATEGORY_REQUEST = 'UPDATE_VIDEO_CATEGORY_REQUEST';
export const UPDATE_VIDEO_CATEGORY_SUCCESS = 'UPDATE_VIDEO_CATEGORY_SUCCESS';

export const DELETE_VIDEO_CATEGORY_FAILURE = 'DELETE_VIDEO_CATEGORY_FAILURE';
export const DELETE_VIDEO_CATEGORY_REQUEST = 'DELETE_VIDEO_CATEGORY_REQUEST';
export const DELETE_VIDEO_CATEGORY_SUCCESS = 'DELETE_VIDEO_CATEGORY_SUCCESS';

export const FETCH_VIDEO_CATEGORY_FAILURE = 'FETCH_VIDEO_CATEGORY_FAILURE';
export const FETCH_VIDEO_CATEGORY_REQUEST = 'FETCH_VIDEO_CATEGORY_REQUEST';
export const FETCH_VIDEO_CATEGORY_SUCCESS = 'FETCH_VIDEO_CATEGORY_SUCCESS';

export const FLUSH_VIDEO_CATEGORY_ERRORS = 'FLUSH_VIDEO_CATEGORY_ERRORS';
export const FLUSH_VIDEO_CATEGORY_STATE = 'FLUSH_VIDEO_CATEGORY_STATE';

export const REMOVE_VIDEO_CATEGORY_FROM_LIST = 'REMOVE_VIDEO_CATEGORY_FROM_LIST';

export const FLUSH_VIDEO_CATEGORY_MESSAGES = 'FLUSH_VIDEO_CATEGORY_MESSAGES';
