export const locationsTitles = [
    { id: "images/list", title: "Photos" },
    { id: "images/image", title: "Photos"},
    { id: "images/categories", title: "Categories" },
    { id: "images/tags", title: "Tags" },
    { id: "videos/playlists", title: "Playlist" },
    { id: "videos/video", title: "Video" },
    { id: "videos/categories", title: "Categories" },
    { id: "videos/tags", title: "Tags" },
    { id: "users", title: "Users" },
    { id: "events", title: "Events" },
];
