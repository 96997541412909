import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Link} from "react-router-dom";
import {loginAction} from "../../../store/actions/auth";
import {getAuthorizationError, getIsFetching} from "../../../store/reducers/auth";
import {connect} from "react-redux";
import FlashMessage from "react-flash-message";
import Recaptcha from 'react-google-invisible-recaptcha';
import Copyright from "../../../common/Copyright";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialState = {
    email: '',
    password: '',
    remember_me: false,
    errors: '',
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleRemember = this.handleRemember.bind(this);
        this.state = initialState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loginError !== this.props.loginError) {
            this.setState({
                errors: this.props.loginError,
            });
        }
        if (prevState.email !== this.state.email || prevState.password !== this.state.password) {
            this.setState({
                errors: ""
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.recaptcha.execute();
    }

    onResolved() {
        const {email, password, remember_me} = this.state;
        this.props.loginAction({
            email: email,
            password: password,
            remember_me: remember_me,
            gcaptcha: this.recaptcha.getResponse()
        });
    }

    onChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    handleRemember(e) {
        this.setState({remember_me: e.target.checked})
    }

    render() {
        const {classes, loginError, isFetching} = this.props;
        const {email, password, errors, remember_me} = this.state;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {isFetching &&
                    <div className={classes.onFetching}>
                        <CircularProgress/>
                    </div>}
                    <form
                        className={classes.form}
                        onSubmit={this.onSubmit}
                        style={isFetching ? {opacity: "0.5"} : {}}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            disabled={isFetching}
                            value={email}
                            onChange={this.onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            disabled={isFetching}
                            value={password}
                            onChange={this.onChange}
                        />

                        <FormControlLabel
                            disabled={isFetching}
                            control={<Checkbox
                                checked={remember_me}
                                value="remember_me"
                                color="primary"
                                onChange={this.handleRemember}
                            />}
                            label="Remember me"
                        />
                        {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={"alert alert-danger"}>Error: {loginError}</h5></FlashMessage> : ''}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!email || !password}
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/forgotpass" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.MIX_GCAPTCHA_DEV_SITE_KEY}
                    onResolved={this.onResolved}/>
            </Container>
        )
    }
}

Login.propTypes = {
    loginAction: PropTypes.func.isRequired,
    loginError: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    loginAction: loginAction,
};

const mapStateToProps = state => ({
    loginError: getAuthorizationError(state),
    isFetching: getIsFetching(state)
});


export default connect(mapStateToProps, mapDispatchToProps)(Login);
