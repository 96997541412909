import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import TableComponent from "../TableComponent";
import {headCellsTags} from "../../constatns/tags";
import {getTagsList} from '../../store/actions/tags';
import {getIsFetching, tagsSelector} from '../../store/reducers/tags';
import {useResource} from "../../hooks/resource";
import ListActions from "../ListActions";
import LoadingComponent from "../../common/LoadingComponent";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {bulkActionRemoveForImagesTag} from "../../store/actions/bulk";
import {getErrorMessage} from "../../helpers/validation";

const bulkActionsTypes = ['delete'];

const TagsList = ({classes}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {
        data: {
            tags,
            count,
        },
        loading,
        page,
        handleChangePage,
        order,
        handleChangeOrder,
        orderBy,
        handleChangeOrderBy,
        search,
        handleChangeSearch,
        rowsPerPage,
        handleChangeLimit
    } = useResource(tagsSelector, getTagsList, getIsFetching);
    const [flushTableComponentState, setFlushTableComponentState] = useState(false)
    useEffect(() => {
        setFlushTableComponentState(false)
    }, [tags]);
    const handleRowClick = () => id => {
        history.push(`/images/tags/${id}/edit`)
    };

    const handleClick = () => {
        history.push('/images/tags/single')
    };

    const applyBulkAction = bulkData => {
        dispatch(bulkActionRemoveForImagesTag({ids: bulkData.selected})).then(res => {
            const {data} = res;
            enqueueSnackbar(data.message, {variant: 'success'});
            dispatch(getTagsList(page, orderBy, order, rowsPerPage, search));
            setFlushTableComponentState(true);
        }).catch(error => {
            enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
        });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <LoadingComponent loading={loading}/>
                <TableComponent
                    type="tags"
                    rows={tags}
                    count={count}
                    page={page}
                    limit={rowsPerPage}
                    order={order}
                    orderBy={orderBy}
                    bulkActionsTypes={bulkActionsTypes}
                    onApplyBulk={!flushTableComponentState ? applyBulkAction : () => {
                    }}
                    actionButtons={<ListActions
                        searchRequest={search}
                        searchHandler={handleChangeSearch}
                        isAdd
                        onClickAdd={handleClick}
                    />}
                    headCells={headCellsTags}
                    onChangePage={handleChangePage}
                    onChangeOrder={handleChangeOrder}
                    onChangeOrderBy={handleChangeOrderBy}
                    onChangeLimit={handleChangeLimit}
                    handleRowClick={handleRowClick()}
                    setInitialState={flushTableComponentState}
                />
            </Paper>
        </div>
    )
};

TagsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default TagsList;
