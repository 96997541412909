import React from "react";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";

const NameStatusBadge = ({classes, status, children}) => {
    const statusClassName = status && status.toLowerCase();

    return (
        <Badge className={clsx(classes.badge, classes[statusClassName])} badgeContent={status}>
            {children}
        </Badge>
    );
}

export default NameStatusBadge;


