import {
    FETCH_VIDEO_CATEGORY_FAILURE,
    FETCH_VIDEO_CATEGORY_REQUEST,
    FETCH_VIDEO_CATEGORY_SUCCESS,
    FLUSH_VIDEO_CATEGORY_ERRORS,
    FLUSH_VIDEO_CATEGORY_STATE,
    FLUSH_VIDEO_CATEGORY_MESSAGES,
    ADD_VIDEO_CATEGORY_FAILURE,
    ADD_VIDEO_CATEGORY_REQUEST,
    ADD_VIDEO_CATEGORY_SUCCESS,
    DELETE_VIDEO_CATEGORY_FAILURE,
    DELETE_VIDEO_CATEGORY_REQUEST,
    DELETE_VIDEO_CATEGORY_SUCCESS,
    UPDATE_VIDEO_CATEGORY_FAILURE,
    UPDATE_VIDEO_CATEGORY_REQUEST,
    UPDATE_VIDEO_CATEGORY_SUCCESS,
    REMOVE_VIDEO_CATEGORY_FROM_LIST
} from "../constants/videoCategory";

import {
    addVideoCategory as apiAddVideoCategory,
    fetchVideoCategory,
    updateVideoCategory as apiUpdateVideoCategory,
    deleteVideoCategory as apiDeleteVideoCategory,
    apiRequest,
} from "../../helpers/api";

export const getVideoCategory = id => dispatch => {
    dispatch({type: FETCH_VIDEO_CATEGORY_REQUEST});
    return fetchVideoCategory(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_VIDEO_CATEGORY_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_VIDEO_CATEGORY_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_VIDEO_CATEGORY_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushVideoCategoryErrors = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_CATEGORY_ERRORS});
};

export const flushVideoCategoryState = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_CATEGORY_STATE});
};

export const addVideoCategory = (data) => dispatch => {
    dispatch({type: 'ADD_VIDEO_CATEGORY_REQUEST'});
    return apiAddVideoCategory(data)
        .then(
            res => {
                if (res.data.status === "error") {
                    return dispatch({
                        type: 'ADD_VIDEO_CATEGORY_FAILURE',
                        message: res.data.message
                    });
                }
                if (res.data.status === 'success') {

                    return dispatch({
                        type: 'ADD_VIDEO_CATEGORY_SUCCESS',
                        payload: res.data
                    });
                }
            },
            error => {
                return dispatch({
                    type: 'ADD_VIDEO_CATEGORY_FAILURE',
                    payload: error.response.data.message
                })
            })
};

export const updateVideoCategory = (data) => dispatch => {
    dispatch({type: UPDATE_VIDEO_CATEGORY_REQUEST});
    const request = () => apiUpdateVideoCategory(data);
    return apiRequest(request, dispatch, UPDATE_VIDEO_CATEGORY_SUCCESS, UPDATE_VIDEO_CATEGORY_FAILURE);
};

export const deleteVideoCategory = id => dispatch => {
    dispatch({type: DELETE_VIDEO_CATEGORY_REQUEST});
    const request = () => apiDeleteVideoCategory(id);
    return apiRequest(request, dispatch, DELETE_VIDEO_CATEGORY_SUCCESS, DELETE_VIDEO_CATEGORY_FAILURE)
};

export const flushVideoCategoryMessages = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_CATEGORY_MESSAGES});
};

export const removeVideoCategoryFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_VIDEO_CATEGORY_FROM_LIST,
        payload: id
    })
};
