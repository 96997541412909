export const FETCH_IMAGES_TAX_CATEGORIES_REQUEST = 'FETCH_IMAGES_TAX_CATEGORIES_REQUEST';
export const FETCH_IMAGES_TAX_CATEGORIES_SUCCESS = 'FETCH_IMAGES_TAX_CATEGORIES_SUCCESS';
export const FETCH_IMAGES_TAX_CATEGORIES_FAILURE = 'FETCH_IMAGES_TAX_CATEGORIES_FAILURE';
export const FETCH_IMAGES_TAX_TAGS_REQUEST = 'FETCH_IMAGES_TAX_TAGS_REQUEST';
export const FETCH_IMAGES_TAX_TAGS_SUCCESS = 'FETCH_IMAGES_TAX_TAGS_SUCCESS';
export const FETCH_IMAGES_TAX_TAGS_FAILURE = 'FETCH_IMAGES_TAX_TAGS_FAILURE';
export const FETCH_VIDEOS_TAX_CATEGORIES_REQUEST = 'FETCH_VIDEOS_TAX_CATEGORIES_REQUEST';
export const FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS = 'FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS';
export const FETCH_VIDEOS_TAX_CATEGORIES_FAILURE = 'FETCH_VIDEOS_TAX_CATEGORIES_FAILURE';
export const FETCH_VIDEOS_TAX_TAGS_REQUEST = 'FETCH_VIDEOS_TAX_TAGS_REQUEST';
export const FETCH_VIDEOS_TAX_TAGS_SUCCESS = 'FETCH_VIDEOS_TAX_TAGS_SUCCESS';
export const FETCH_VIDEOS_TAX_TAGS_FAILURE = 'FETCH_VIDEOS_TAX_TAGS_FAILURE';

export const FLUSH_VIDEOS_TAX_STATE = 'FLUSH_VIDEOS_TAX_STATE';
export const FLUSH_IMAGES_TAX_STATE = 'FLUSH_IMAGES_TAX_STATE';
