import React from 'react';
import {Field} from 'redux-form'
import FormSwitcher from "../../../../components/ReduxFormFields/FormSwitcher";

const WarningFlag = ({classes, ...rest}) => {
  return (
    <div className={classes.textField}>
      <Field
        name={'warning_flag'}
        label={"Warning Flag"}
        fullWidth
        variant="outlined"
        component={FormSwitcher}
        color="secondary"
        {...rest}
      />
    </div>
  );
};

export default WarningFlag;
