import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {eventsList} from "../../../store/actions/events/list";
import Paper from "@material-ui/core/Paper/Paper";
import LoadingFade from "../../../common/LoadingFade";
import ResourceList from "../../../components/ResourceList";
import {eventsSelector} from "../../../store/reducers/events/selectors";
import useSelectedItems from "../../../hooks/useSelectedItems";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import useSearch from "../../../hooks/useSearch";
import NewFilters from "../../../components/NewFilters";
import useFilters from "../../../hooks/useFilters";
import EventListIItem from "./EventListIItem";
import Status from "../../../components/NewFilters/Items/Status";
import DateFilter from "../../../components/NewFilters/Items/Date";
import SelectFilter from "../../../components/NewFilters/Items/SelectFilter";
import {reduce, debounce} from 'lodash'
import {bulkUpdate} from "../../../store/actions/events/bulkUpdate";
import useToggle from "../../../hooks/useToggle";
import useOrder from "../../../hooks/useOrder";
import AddButton from "../../../common/AddButton/AddButton";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

const EventsList = ({classes }) => {
  const history = useHistory();
  const {items, isFetching, currentPage, limit, pages, total} = useSelector(eventsSelector);
  const [selectedItems, handleChangeSelectedItems, handleReset] = useSelectedItems();
  const [triggerDelered, handleTrigger] = useToggle();
  const {enqueueSnackbar} = useSnackbar();
  const [searchValue, handleChangeSearch] = useSearch();
  const [order, handleChangeOrder] = useOrder('id', 'desc');
  const [tempSearch, setTempSearch] = useSearch();
  const [page, perPage, handleChangePage, handleChangeLimit] = usePagination(currentPage, limit, pages);
  const [appliedFilters, handleChangeFilters] = useFilters();
  const dispatch = useDispatch();
  const headers = [
    {label: 'ID', name: 'id', ordered: true},
    {label: 'Thumbnail', name: 'thumbnail', ordered: false},
    {label: 'Title', name: 'title', ordered: true},
    {label: 'Type', name: 'type', ordered: true},
    {label: 'Start date', name: 'start_date', ordered: false},
    {label: 'End date', name: 'end_date', ordered: false},
    {label: 'Status', name: 'status', ordered: true},
    {label: 'Published', name: 'published', ordered: true}
  ];

  const filter = useMemo(() => {
    return {
      page,
      limit: perPage,
      search: searchValue,
      ...order,
      ...reduce(appliedFilters, (acc, {key, value}) => {
        if (key === 'published') {
          return {
            ...acc,
            [key]: value === "Published"
          }
        }
        return {
          ...acc,
          [key]: value
        }
      }, {})
    }
  }, [page, perPage, searchValue, appliedFilters, order]);

  const handleSearch = useCallback(
    debounce(value => {
      handleChangeSearch({target: {value}})
    }, 500),
    [handleChangeSearch]
  );

  useEffect(() => {
    handleSearch(tempSearch)
  }, [tempSearch]);

  useEffect(() => {
    dispatch(eventsList({...filter}))
  }, [filter, triggerDelered]);


  useEffect(() => {
    handleChangePage(0)
  }, [perPage, searchValue, appliedFilters]);

  const handleChangeStatus = useCallback((id, status) => {
    dispatch(bulkUpdate({
      ids: [id],
      status: status === 'yes' ? 'publish' : 'unpublish'
    }))
      .then(({data: {message}}) => {
        handleTrigger();
        enqueueSnackbar(message, {variant: 'success'})
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'})

      })
  }, []);

  const handleChangeRoute = useCallback((url)=>{
    history.push(url)
  }, [])


  const renderItem = useCallback((item) => {
    return (
      <EventListIItem key={item.id}  onClick={() => handleChangeRoute(`/events/${item.id}/edit`)} item={item} handleChangeStatus={handleChangeStatus}/>
    );
  }, [items]);

  const handleChangeSelectedAll = useCallback(() => {
    if (selectedItems.length !== items.length || !selectedItems.length) {
      handleChangeSelectedItems([...items.map(item => item.id)]);
      return;
    }
    handleChangeSelectedItems([])

  }, [selectedItems, handleChangeSelectedItems]);

  const filters = useMemo(() => ([
    {
      key: "published", label: "Published", component: <SelectFilter options={[
        {label: 'Yes', value: "Published"},
        {label: 'No', value: "Unpublished"}
      ]}/>
    },
    {key: "from", label: "Start Date", component: <DateFilter/>},
    {key: "to", label: "End Date", component: <DateFilter/>},
    {
      key: "type", label: "Type", component: <SelectFilter options={[
        {label: 'Tropical Coverage', value: "Tropical_Coverage"},
        {label: 'Winter Storm', value: "Winter_Storm"},
        {label: 'Storm Coverage', value: "Storm_Coverage"},
        {label: 'Severe Weather', value: "Severe_Weather"}
      ]}/>
    }

  ]), []);

  const handleRemove = useCallback(() => {
    dispatch(bulkUpdate({
      ids: selectedItems,
      status: 'delete'
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        handleReset();
        if (page === 1) {
          handleTrigger()
        } else {
          handleChangePage(0)
        }
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'});

        if (page === 1) {
          handleTrigger()
        } else {
          handleChangePage(0)

        }

      })

  }, [selectedItems, handleReset, handleChangePage, handleTrigger]);

  const handlePublish = useCallback(() => {
    dispatch(bulkUpdate({
      ids: selectedItems,
      status: 'publish'
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        handleReset()

        handleTrigger();
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'});

        handleReset()
      })

  }, [selectedItems, handleReset]);

  const handleUnpublish = useCallback(() => {
    dispatch(bulkUpdate({
      ids: selectedItems,
      status: 'unpublish'
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        handleReset()
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'});

        handleReset()
      })
  }, [selectedItems, handleReset]);

  const filterControl = useMemo(() => (
    <NewFilters
      appliedFilters={appliedFilters}
      handleChangeFilters={handleChangeFilters}
      filters={filters}
      clearFilters={() => handleChangeFilters([])}

    />
  ), [appliedFilters, handleChangeFilters, filters]);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={isFetching}/>
        <ResourceList
          items={items}
          order={order}
          handleChangeOrder={handleChangeOrder}
          renderItem={renderItem}
          selectedItems={selectedItems}
          appliedFilters={appliedFilters}
          handleChangeFilters={handleChangeFilters}
          handleChangeSelectedAll={handleChangeSelectedAll}
          handleChangeSelectedItems={handleChangeSelectedItems}
          headers={headers}
          searchValue={tempSearch}
          handleChangeSearch={setTempSearch}
          primaryAction={<AddButton onClick={() => handleChangeRoute('/events/new')}/>}
          filterControl={filterControl}
          bulkActions={[
            {label: "Remove", onAction: handleRemove},
            {label: "Publish", onAction: handlePublish},
            {label: "Unpublish", onAction: handleUnpublish},
          ]}
        />
        <Pagination
          pages={pages}
          colSpan={headers.length}
          handlePageChange={handleChangePage}
          handleRowsChange={handleChangeLimit}
          limit={perPage}
          total={total}
          page={page}
        />
      </Paper>
    </div>
  );
};

export default EventsList;

