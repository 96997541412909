import localStorageHelper from "../helpers/localStorage";
import {
    BACK_TO_LOGIN,
    CHECK_CHANGE_PASSWORD_TOKEN_FAILURE,
    CHECK_CHANGE_PASSWORD_TOKEN_REQUEST,
    CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS,
    CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE,
    CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST,
    CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS,
    FETCH_AUTH_USER_DATA_FAILURE,
    FETCH_AUTH_USER_DATA_REQUEST,
    FETCH_AUTH_USER_DATA_SUCCESS,
    FLUSH_LOGIN_ERRORS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    SEND_CODE_FAILURE,
    SEND_CODE_REQUEST,
    SEND_CODE_SUCCESS, SET_AUTH_DATA,
    SET_AUTH_USER_ID, SET_FIRST_PASSWORD_HASH,
    SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE,
    SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST,
    SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS,
    SET_RESET_PASSWORD_HASH, SET_USER_FIRST_PASSWORD_FAILURE
} from "../constants/auth";

const initialState = {
    isFetching: false,
    isLogged: false,
    userId: localStorageHelper.get('user_id'),
    data: {
        email: '',
        name: '',
    },
    loginError: '',
    token: {
        access_token: localStorageHelper.get('access_token'),
        expires_in: localStorageHelper.get('expires_in')
    },
    message: '',
    hasAwaitingCode: false,
    canResetPassword: false,
    hasResetHash: false,
    resetPasswordHash: '',
    newUserPasswordHash: '',
    hasNewUserPasswordHash: false,
    canSetNewPassword: false,
    errors: false,
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case SEND_CODE_REQUEST:
        case RESET_PASSWORD_REQUEST:
        case CHECK_CHANGE_PASSWORD_TOKEN_REQUEST:
        case CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST:
        case SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST:
        case FETCH_AUTH_USER_DATA_REQUEST:
            return {
                ...state,
                isFetching: true,
                isLogged: false,
                loginError: ''
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isLogged: false,
                hasAwaitingCode: true,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: action.payload.message
            };
        case SEND_CODE_SUCCESS:
        case SET_AUTH_DATA:
            return {
                ...state,
                isFetching: false,
                isLogged: true,
                hasAwaitingCode: false,
                token: {
                    access_token: action.payload.access_token,
                    expires_in: action.payload.expires_in,
                }
            };
        case LOGIN_FAILURE:
        case SEND_CODE_FAILURE:
        case RESET_PASSWORD_FAILURE:
        case SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE:
        case SET_USER_FIRST_PASSWORD_FAILURE:
            return {
                ...state,
                isLogged: false,
                isFetching: false,
                loginError: action.payload ? action.payload : ''
            };
        case LOGOUT:
            return {
                ...state,
                isLogged: false,
                token: {
                    access_token: '',
                    expires_in: null,
                },
                hasAwaitingCode: false,
                loginError: '',
            };
        case CHECK_CHANGE_PASSWORD_TOKEN_FAILURE:
        case CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE:
            return {
                ...state,
                isFetching: false,
                loginError: action.payload
            };
        case CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                canResetPassword: true,
                message: action.payload.message,
            };
        case CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                canSetNewPassword: true,
                message: action.payload.message,
            };
        case SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: action.payload.message
            };
        case SET_RESET_PASSWORD_HASH:
            return {
                ...state,
                resetPasswordHash: action.payload,
                hasResetHash: true,
            };
        case SET_FIRST_PASSWORD_HASH:
            return {
                ...state,
                newUserPasswordHash: action.payload,
                hasNewUserPasswordHash: true,
            };
        case FLUSH_LOGIN_ERRORS:
            return {
                ...state,
                loginError: '',
                message: ''
            };
        case BACK_TO_LOGIN:
            return {
                ...state,
                hasAwaitingCode: false
            };
        case FETCH_AUTH_USER_DATA_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.payload,
            };
        case FETCH_AUTH_USER_DATA_SUCCESS:
            return {
                ...state,
                errors: '',
                isFetching: false,
                data: action.payload.data
            };
        case SET_AUTH_USER_ID:
            return {
                ...state,
                userId: action.payload
            };
        default:
            return {
                ...state,
                isFetching: false,
                loginError: ''
            }
    }

};

export default auth;

export const getLoginState = state => state.auth.isLogged;
export const hasToken = state => !!state.auth.token.access_token;
export const getAuthorizationError = state => state.auth.loginError;
export const getIsFetching = state => state.auth.isFetching;
export const getAccessToken = state => state.auth.token && state.auth.token.access_token;
export const hasAwaitingCode = state => state.auth.hasAwaitingCode;
export const authResultMessage = state => state.auth.message;
export const canUserResetPassword = state => state.auth.canResetPassword;
export const hasResetPasswordHash = state => state.auth.hasResetHash;
export const getResetPasswordHash = state => state.auth.resetPasswordHash;
export const getAuthUserId = state => state.auth.userId;
export const getAuthUserData = state => state.auth.data;
export const canUserSetFirstPassword = state => state.auth.canSetNewPassword;
export const hasFirstPasswordHash = state => state.auth.hasNewUserPasswordHash;
export const getFirstPasswordHash = state => state.auth.newUserPasswordHash;
