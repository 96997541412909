import React from 'react';
import {Field} from 'redux-form'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Form from './Form';

const required = value => {
  if(!value || !value.id){
    return 'You must select picture'
  }
};

const Image = ({...rest}) => {

  return (
    <Box>
      <Typography>Thumbnail</Typography>
      <Divider/>
      <Field
        name={"image"}
        component={Form}
        validate={required}
        {...rest}
      />
    </Box>
  );
};

export default Image;
