import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { useResource } from '../../hooks/resource'
import { getIsFetching, videosSelector } from '../../store/reducers/videos'
import { getVideosList } from '../../store/actions/videos'
import { headCellsVideo } from '../../constatns/video'
import TableComponent from '../TableComponent'
import ListActions from '../ListActions'
import LoadingFade from '../../common/LoadingFade'
import NewFilters from '../NewFilters'
import DateFilter from '../NewFilters/Items/Date'
import Taxonomies from '../NewFilters/Items/Taxonomies'
import { useVideosTaxonomies } from '../../hooks/videoTaxonomies'
import {
  bulkActionForVideos,
  changeVideosStatusToProgress,
} from '../../store/actions/bulk'
import { getErrorMessage } from '../../helpers/validation'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

const bulkActionsTypes = ['delete']
const filterBy = ['date']

const VideoList = ({ classes }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: {
      videos,
      count,
    },
    loading,
    page,
    handleChangePage,
    order,
    handleChangeOrder,
    orderBy,
    handleChangeOrderBy,
    search,
    handleChangeSearch,
    rowsPerPage,
    handleChangeLimit,
    filter,
    onChangeFilter,
    clearFilter,
    handleRefresh,
  } = useResource(videosSelector, getVideosList, getIsFetching)

  // const [flushTableComponentState, setFlushTableComponentState] = useState(false);

  // useEffect(() => {
  //     setFlushTableComponentState(false)
  // }, [videos]);

  const { videosCategories, videosTags } = useVideosTaxonomies()

  const handleRowClick = () => id => {
    history.push(`/videos/video/${id}`)
  }

  const handleAddClick = () => {
    history.push('/videos/video/upload')
  }

  const applyBulkAction = bulkData => {
    const data = {
      ids: bulkData.selected,
    }
    dispatch(bulkActionForVideos(data)).then(res => {
      const { data } = res
      dispatch(changeVideosStatusToProgress(bulkData.selected))
      handleRefresh();
      // setFlushTableComponentState(true);
      enqueueSnackbar(data.message, { variant: 'success' })
    }).catch(error => {
      console.log(error)
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={loading}/>
        <TableComponent
          type="videos"
          rows={videos}
          count={count}
          page={page}
          limit={rowsPerPage}
          order={order}
          orderBy={orderBy}
          filterBy={filterBy}
          bulkActionsTypes={bulkActionsTypes}
          headCells={headCellsVideo}
          onChangePage={handleChangePage}
          onChangeOrder={handleChangeOrder}
          onChangeOrderBy={handleChangeOrderBy}
          onChangeLimit={handleChangeLimit}
          handleRowClick={handleRowClick()}
          onApplyBulk={applyBulkAction}
          actionButtons={<ListActions
            searchRequest={search}
            searchHandler={handleChangeSearch}
            isAdd
            onClickAdd={handleAddClick}
          />}
          chipsData={filter}
          onDeleteChips={onChangeFilter}
          filtersComponent={<NewFilters
            filters={[
              { key: 'from', label: 'From', component: <DateFilter/> },
              { key: 'to', label: 'To', component: <DateFilter/> },
              {
                key: 'categories',
                label: 'Category',
                taxonomyOptions: videosCategories,
                component: <Taxonomies/>,
              },
              {
                key: 'tags',
                label: 'Tag',
                taxonomyOptions: videosTags,
                component: <Taxonomies/>,
              },
            ]}
            handleChangeFilters={onChangeFilter}
            appliedFilters={filter}
            clearFilters={clearFilter}
          />}
        />
      </Paper>
    </div>
  )
}

VideoList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default VideoList
