import React, {cloneElement, useMemo} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import EmptyTableBody from "../../common/EmptyTableBody";
import ResourceListHeader from "./ResourceListHeader";
import ResourceToolbar from "./ResourceToolbar";

const ResourceList = ({
                        items = [],
                        renderItem,
                        classes,
                        headers,
                        selectedItems,
                        handleChangeSelectedAll,
                        searchValue,
                        handleChangeSearch,
                        handleChangeSelectedItems,
                        filterControl,
                        appliedFilters,
                        handleChangeFilters,
                        bulkActions,
                        order,
                        handleChangeOrder,
                        primaryAction
                      }) => {


  const resourceItems = useMemo(() => {
    if (!items.length) {
      return <EmptyTableBody colSpan={headers.length}/>
    }

    return <TableBody>{items.map((item, index) => cloneElement(
      renderItem(item, index),
      {selectedItems, handleChangeSelectedItems}))}
    </TableBody>

  }, [items, renderItem, selectedItems, handleChangeSelectedItems]);


  return (
    <React.Fragment>
      <ResourceToolbar
        searchValue={searchValue}
        handleChangeSearch={handleChangeSearch}
        filterControl={filterControl}
        appliedFilters={appliedFilters}
        handleChangeFilters={handleChangeFilters}
        selectedItems={selectedItems}
        bulkActions={bulkActions}
        primaryAction={primaryAction}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="table"
        >
          <ResourceListHeader
            items={items}
            selectedItems={selectedItems}
            handleChangeSelectedAll={handleChangeSelectedAll}
            headers={headers}
            order={order}
            handleChangeOrder={handleChangeOrder}
          />
            {resourceItems}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ResourceList;
