import React, {useEffect, useState} from "react";
import SinglePage from "../../SinglePage";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useCategory} from "../../../hooks/category";
import {useTag} from "../../../hooks/tag";
import {
    ADD_CATEGORY_FAILURE, ADD_CATEGORY_SUCCESS,
} from "../../../store/constants/categories";
import {
    deleteCategory,
    flushCategoryErrors,
    flushCategoryState,
    flushCategoryMessages,
    removeCategoryFromList
} from "../../../store/actions/category";
import {updateCategory, addCategory} from '../../../store/actions/categories';
import {
    deleteTag,
    updateTag,
    addTag,
    flushTagErrors,
    flushTagState,
    removeTagFromList,
    flushTagMessages
} from "../../../store/actions/tag";
import {
    ADD_TAG_FAILURE,
    ADD_TAG_SUCCESS,
} from "../../../store/constants/tag";
import {flushImagesTaxState} from "../../../store/actions/taxonomies";

export const SinglePageCategoryActions = ({match}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const {category, loading} = useCategory(match.params.id);

    const [data, setData] = useState('');
    const [disableDelete, setDisableDelete] = useState(false);

    const closeLink = () => {
        dispatch(flushCategoryErrors());
        dispatch(flushCategoryState());
        dispatch(flushImagesTaxState());
        history.push('/images/categories');
    };

    const deleteCategoryId = () => {
        dispatch(deleteCategory(category.id)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(flushCategoryMessages());
                dispatch(removeCategoryFromList(category.id));
                closeLink();
            }
        ).catch(err => {
                enqueueSnackbar(err.data.data, {variant: 'error'})
            }
        );
    };

    const updateCategoryName = (name) => {
        dispatch(updateCategory({category_id: category.id, name: name})).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(flushCategoryMessages());
            }
        ).catch(err => {
                enqueueSnackbar(err.data.message, {variant: 'error'})
            }
        );
        setData(name);
        setDisableDelete(false);
    };

    useEffect(() => {
        return () => {
            dispatch(flushCategoryState());
            dispatch(flushImagesTaxState());
        }
    }, []);

    return (<SinglePage
        loading={loading}
        flushState={flushCategoryState}
        flushErrors={flushCategoryErrors}
        closeLink={closeLink}
        deleteAction={deleteCategoryId}
        updateAction={updateCategoryName}
        id={match.params.id}
        data={data}
        disableDelete={disableDelete}
        setDisableDelete={setDisableDelete}
        useSelector={match.params.id * 1 === category.id ? category.name : ''}
        title="Category"
        item={category.name}
    />)
};

export const SinglePageCategoryAddActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const loading = useSelector(state => state.categories.isFetching);


    const closeLink = () => {
        dispatch(flushCategoryErrors());
        dispatch(flushCategoryState());
        dispatch(flushImagesTaxState());
        history.push('/images/categories');
    };
    // TODO: add include new item in current list reducer
    const addNewCategory = (name) => {
        dispatch(addCategory({name: name})).then(
            res => {
                if (res.type === ADD_CATEGORY_FAILURE) {
                    enqueueSnackbar(res.payload, {variant: 'error'});
                }

                if (res.type === ADD_CATEGORY_SUCCESS) {
                    enqueueSnackbar(res.payload.message, {variant: 'success'});
                    closeLink();
                }
            }
        ).catch(err => enqueueSnackbar(err.message, {variant: 'error'}))
    };

    return (<SinglePage
        loading={loading}
        flushState={flushCategoryState}
        flushErrors={flushCategoryErrors}
        closeLink={closeLink}
        addAction={addNewCategory}
        title="Category"
    />)
};

export const SinglePageTagsActions = ({match}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const [data, setData] = useState('');

    const [disableDelete, setDisableDelete] = useState(false);

    const {tag, loading} = useTag(match.params.id);

    const closeLink = () => {
        dispatch(flushTagErrors());
        dispatch(flushTagState());
        dispatch(flushImagesTaxState());
        history.push('/images/tags');
    };

    const deleteTagId = () => {
        dispatch(deleteTag(tag.id)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(removeTagFromList(tag.id));
                dispatch(flushTagMessages());
                closeLink();
            }
        ).catch(err => {
                enqueueSnackbar(err.data.data, {variant: 'error'})
            }
        );
    };

    const updateTagName = (name) => {
        dispatch(updateTag({tag_id: tag.id, name: name})).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(flushTagMessages());
            }
        ).catch(err => {
                enqueueSnackbar(err.data.message, {variant: 'error'})
            }
        );
        setData(name);
        setDisableDelete(false);
    };

    useEffect(() => {
        return () => {
            dispatch(flushTagState());
            dispatch(flushImagesTaxState());
        }
    }, []);

    return (<SinglePage
        loading={loading}
        flushState={flushTagState}
        flushErrors={flushTagErrors}
        closeLink={closeLink}
        deleteAction={deleteTagId}
        updateAction={updateTagName}
        id={match.params.id}
        disableDelete={disableDelete}
        setDisableDelete={setDisableDelete}
        data={data}
        useSelector={match.params.id * 1 === tag.id ? tag.name : ''}
        title="Tag"
        item={tag.name}
    />)
};

export const SinglePageAddTagsActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const loading = useSelector(state => state.tag.isFetching);

    const closeLink = () => {
        dispatch(flushTagErrors());
        dispatch(flushTagState());
        dispatch(flushImagesTaxState());
        history.push('/images/tags');
    };

    const addNewTag = (name) => {
        dispatch(addTag({name: name})).then(
            res => {
                if (res.type === ADD_TAG_FAILURE) {
                    enqueueSnackbar(res.payload, {variant: 'error'});
                }

                if (res.type === ADD_TAG_SUCCESS) {
                    enqueueSnackbar(res.payload.message, {variant: 'success'});
                    closeLink()
                }
            }
        ).catch(err => enqueueSnackbar(err.message, {variant: 'error'}))
    };
    return (<SinglePage
        loading={loading}
        flushState={flushTagState}
        flushErrors={flushTagErrors}
        closeLink={closeLink}
        addAction={addNewTag}
        title="Tag"
    />)
};

