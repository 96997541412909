import React, {useCallback, useMemo} from 'react';
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const Taxonomies = ({
                        appliedFilters,
                        handleChangeFilters,
                        classes,
                        label,
                        filterKey,
                        taxonomyOptions,
                    }) => {
    const filter = useMemo(
        () => appliedFilters.find(filter => filter.key === filterKey),
        [appliedFilters, filterKey]);

    const handleChange = useCallback((e, newValue) => {
        if (filter) {
            handleChangeFilters([
                ...appliedFilters.map((filter => {
                    if (filter.key === filterKey) {
                        return {
                            ...filter,
                            value: newValue
                        }
                    }
                    return filter;
                }))

            ])
        } else {
            handleChangeFilters([
                ...appliedFilters,
                {
                    key: filterKey,
                    value: newValue
                }

            ])
        }
    }, [handleChangeFilters, appliedFilters, filter]);

    return (
        <Box className={classes.filterWrapper}>
            <Autocomplete
                multiple
                name={filterKey}
                options={taxonomyOptions}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                value={filter ? filter.value : []}
                onChange={(e, value) => handleChange(e, value)}
                renderInput={params => (<TextField
                    {...params}
                    label={label}
                    placeholder={label}
                    fullWidth
                    variant="outlined"
                />)}
            />
        </Box>
    );
};

export default Taxonomies;
