import React, {Fragment, useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableToolbar from "../TableToolbar";
import TableHeadComponent from "../TableHeadComponent";
import TableBodyComponent from "../TableBodyComponent";
import EmptyTableBody from "../../common/EmptyTableBody";
import * as _ from 'lodash'
import {flushBulkActionsState} from "../../store/actions/bulk";
import {useDispatch, useSelector} from "react-redux";
import {bulkActionsSelector} from "../../store/reducers/bulk";
import BulkAction from "../BulkAction";

const initialState = {
    actionType: '',
    selected: []
};

const TableComponent = ({
                            type,
                            classes,
                            headCells,
                            rows,
                            title,
                            actionButtons,
                            handleRowClick,
                            onChangePage,
                            onChangeOrder,
                            onChangeOrderBy,
                            onChangeLimit,
                            page,
                            limit,
                            order,
                            orderBy,
                            count,
                            disablePagination,
                            deleteItemAction,
                            statuses,
                            bulkActionsTypes,
                            onApplyBulk,
                            setInitialState,
                            filtersComponent,
                            chipsData,
                            onDeleteChips
                        }) => {

        const [state, setState] = useState(initialState);
        const dispatch = useDispatch();
        const {result} = useSelector(bulkActionsSelector);

        useEffect(() => {
            if (setInitialState) {
                setState(initialState);
            }
        }, [setInitialState]);

        const handleRequestSort = (event, property) => {
            const isDesc = orderBy === property && order === 'desc';
            onChangeOrder(isDesc ? 'asc' : 'desc');
            onChangeOrderBy(property);
        };

        const handleChangePage = (event, newPage) => {
            onChangePage(newPage);
        };

        const handleChangeRowsPerPage = event => {
            onChangeLimit(parseInt(event.target.value, 10));
            onChangePage(0);
        };

        const addOrRemoveItem = (id, arr) => {
            let newArr = [].concat(arr);
            if (newArr.indexOf(id) === -1) {
                newArr.push(id)
            } else {
                newArr = newArr.filter(item => item !== id);
            }
            return newArr;
        };

        const handleSelectItem = () => id => {
            setState({
                ...state,
                selected: addOrRemoveItem(id, state.selected)
            });
        };

        const handleSelectAll = () => {
            let allPageItems;
            if (type === 'imagesPending') {
                allPageItems = rows.filter(item => item.status !== 'process').map(item => item.location);
            } else {
                allPageItems = rows.filter(item => item.status !== 'process').map(item => item.id);
            }
            const selected = [].concat(state.selected)
            if (allPageItems.length === selected.length) {
                setState({
                    ...state,
                    selected: []
                });
            } else {
                setState({
                    ...state,
                    selected: _.uniq(allPageItems.concat(selected))
                })
            }
        };

        const applyBulk = () => {
            onApplyBulk(state)
        };

        const onChangeBulkAction = () => e => {
            const {value} = e.target;
            setState({
                ...state,
                actionType: value
            });
        };

        const isSelectedAllChecked = () => {
            // TODO: improve check
            if (!Array.isArray(rows)) {
                return false;
            }

            if (type === 'tags' || type === 'categories') {
                return rows.length === state.selected.length;
            }

            const proccessedItemsCount = rows.filter(item => item.status === 'process').length;
            const selectedItemsCount = state.selected.length;
            const totalCount = rows.length;
            if (proccessedItemsCount === totalCount) {
                return false;
            }
            if (totalCount === selectedItemsCount) {
                return true
            }

            if (totalCount === proccessedItemsCount + selectedItemsCount) {
                return true
            }
        };

        useEffect(() => {
            if (result === 'success') {
                setState(initialState)
                dispatch(flushBulkActionsState())
            }
        }, [result]);

        return (
            <Fragment>
                <TableToolbar
                    title={title}
                    actionButtons={actionButtons}
                    bulk={<BulkAction
                        actionType={state.actionType}
                        onApplyBulk={applyBulk}
                        onChangeBulkAction={onChangeBulkAction}
                        bulkActionTypes={bulkActionsTypes}
                        statuses={statuses}
                        disabledBulkApplyAction={!state.selected.length}
                    />}
                    filters={filtersComponent}
                    chipsData={chipsData}
                    onDeleteChips={onDeleteChips}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="table"
                    >
                        <TableHeadComponent
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                            onSelectAllClick={handleSelectAll}
                            selectedAll={isSelectedAllChecked() || false}
                        />
                        {rows && Array.isArray(rows) && rows.length ?
                            <TableBodyComponent
                                type={type}
                                rows={rows}
                                order={order}
                                orderBy={orderBy}
                                page={page}
                                rowsPerPage={limit}
                                handleRowClick={handleRowClick}
                                handleDeleteAction={deleteItemAction}
                                dataProperties={headCells.map(cell => cell.id)}
                                selected={state.selected}
                                onSelect={handleSelectItem()}
                            /> :
                            <EmptyTableBody colSpan={headCells.length}/>}
                    </Table>
                </TableContainer>
                {!disablePagination &&
                <TablePagination
                    colSpan={headCells.length}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Fragment>);
    }
;

TableComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    rows: PropTypes.any,
    headCells: PropTypes.array.isRequired,
    title: PropTypes.string,
    actionButtons: PropTypes.object,
    onChangePage: PropTypes.func.isRequired,
    onChangeOrder: PropTypes.func.isRequired,
    onChangeOrderBy: PropTypes.func.isRequired,
    onChangeLimit: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    count: PropTypes.number.isRequired,
    disablePagination: PropTypes.bool,
    type: PropTypes.string.isRequired,
    filters: PropTypes.object,
    deleteItemAction: PropTypes.func
};

TableComponent.defaultProps = {};


export default TableComponent;
