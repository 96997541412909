import {createSelector} from "reselect";
import {
    LOAD_IMAGES_FAILURE,
    LOAD_IMAGES_REQUEST,
    LOAD_IMAGES_SUCCESS,
    REMOVE_IMAGE_FROM_LIST, UPDATE_IMAGE_FAILURE, UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS
} from "../constants/images";
import {
  CHANGE_IMAGES_STATUS_TO_PROGRESS, CREATE_IMAGES_BULK_ACTION_FAILURE,
  CREATE_IMAGES_BULK_ACTION_REQUEST,
  CREATE_IMAGES_BULK_ACTION_SUCCESS,
} from '../constants/bulk'

const initialState = {
    isFetching: false,
    images: [],
    errors: false,
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'id',
    limit: 10,
    filterDates: {
        from: '',
        to: ''
    },
    filterStatus: '',
    filterTaxonomies: {
        categories: [],
        tags: []
    },
};

const images = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_IMAGES_REQUEST:
        case UPDATE_IMAGE_REQUEST:
        case CREATE_IMAGES_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_IMAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        case LOAD_IMAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload
            };
        case LOAD_IMAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                images: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case UPDATE_IMAGE_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                images: state.images.map((admin) => admin.id === data.id ? data : admin)
            };
        case REMOVE_IMAGE_FROM_LIST:
            return {
                ...state,
                images: state.images.filter(image => image.id !== payload),
            };
        // case CHANGE_IMAGES_STATUS_TO_PROGRESS:
        //     return {
        //         ...state,
        //         isFetching: false,
        //         images: state.images.map(image => {
        //             if (payload.includes(image.id)) {
        //                 return {...image, status: 'process'}
        //             }
        //             return image;
        //         })
        //     };
        case CREATE_IMAGES_BULK_ACTION_SUCCESS:
        case CREATE_IMAGES_BULK_ACTION_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return {
                ...state,
                // isFetching: false,
                // errors: '',
                // filterDates: initialState.filterDates,
                // filterStatus: '',
                // filterTaxonomies: initialState.filterTaxonomies,
            };
    }
};

export default images;

export const getIsFetching = state => state.images.isFetching;
export const getImagesErrors = state => state.images.errors;
export const getImagesArr = state => state.images.images;
export const getImagesCount = state => state.images.count;
export const getImagesPage = state => state.images.page;
export const getImagesLimit = state => state.images.limit;
export const getImagesOrder = state => state.images.order;
export const getImagesOrderBy = state => state.images.orderBy;
export const getImagesFilterStatus = state => state.images.filterStatus;
export const getImagesFilterDates = state => state.images.filterDates;
export const getImagesFilterTaxonomies = state => state.images.filterTaxonomies;

export const imagesSelector = createSelector([
    getImagesArr,
    getIsFetching,
    getImagesCount,
    getImagesPage,
    getImagesLimit,
    getImagesOrder,
    getImagesOrderBy,
    getImagesFilterStatus,
    getImagesFilterDates,
    getImagesFilterTaxonomies
], (images,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
    filterStatus,
    filterDates,
    filterTaxonomies
    ) => {
    return {
        images,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
        filterStatus,
        filterDates,
        filterTaxonomies
    };
});

