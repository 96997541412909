import React from 'react';
import {Field} from 'redux-form'
import FormDate from "../../../../components/ReduxFormFields/FormDate";
import FormControl from "@material-ui/core/FormControl/FormControl";

const StartDate = ({classes, name, label, ...rest}) => {
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Field
        name={name}
        label={label}
        {...rest}
        component={FormDate}
      />
    </FormControl>
  );
};

export default StartDate;