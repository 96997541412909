import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TableComponent from "../TableComponent";
import ListActions from "../ListActions";
import {headCellsCategories} from "../../constatns/categories";
import {useHistory} from "react-router-dom";
import {useResource} from "../../hooks/resource";
import {videoCategoriesSelector, getIsFetching} from "../../store/reducers/videoCategories";
import {getVideoCategoriesList} from "../../store/actions/videoCategories";
import * as PropTypes from "prop-types";
import LoadingComponent from "../../common/LoadingComponent";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {
    bulkActionRemoveVideoCategories,
    updateVideoCategoriesAfterBulk,
} from "../../store/actions/bulk";
import {getErrorMessage} from "../../helpers/validation";

const VideosCategoryList = ({classes}) => {
    const history = useHistory();
    const {
        data: {
            video_categories,
            count,
        },
        loading,
        page,
        handleChangePage,
        order,
        handleChangeOrder,
        orderBy,
        handleChangeOrderBy,
        search,
        handleChangeSearch,
        rowsPerPage,
        handleChangeLimit
    } = useResource(videoCategoriesSelector, getVideoCategoriesList, getIsFetching);

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [flushTableComponentState, setFlushTableComponentState] = useState(false);

    useEffect(() => {
        setFlushTableComponentState(false)
    }, [video_categories]);

    const handleRowClick = () => id => {
        history.push(`/videos/categories/${id}/edit`)
    };

    const handleClick = () => {
        history.push('/videos/categories/single')
    };

    const applyBulkAction = bulkData => {
        dispatch(bulkActionRemoveVideoCategories({ids: bulkData.selected})).then(res => {
            const {data} = res;
            setFlushTableComponentState(true);
            dispatch(updateVideoCategoriesAfterBulk(bulkData.selected));
            dispatch(getVideoCategoriesList(page, orderBy, order, rowsPerPage));
            enqueueSnackbar(data.message, {variant: 'success'});
        }).catch(error => {
            enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
        });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <LoadingComponent loading={loading}/>
                <TableComponent
                    type="videos"
                    rows={video_categories}
                    count={count}
                    page={page}
                    limit={rowsPerPage}
                    order={order}
                    orderBy={orderBy}
                    actionButtons={<ListActions
                        searchRequest={search}
                        searchHandler={handleChangeSearch}
                        isAdd
                        onClickAdd={handleClick}
                    />}
                    headCells={headCellsCategories}
                    onChangePage={handleChangePage}
                    onChangeOrder={handleChangeOrder}
                    onChangeOrderBy={handleChangeOrderBy}
                    onChangeLimit={handleChangeLimit}
                    handleRowClick={handleRowClick()}
                    bulkActionsTypes={['delete']}
                    setInitialState={flushTableComponentState}
                    onApplyBulk={applyBulkAction}
                />
            </Paper>
        </div>
    )
};

VideosCategoryList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default VideosCategoryList;
