import {
    FETCH_TAG_FAILURE,
    FETCH_TAG_REQUEST,
    FETCH_TAG_SUCCESS,
    FLUSH_TAG_ERRORS,
    FLUSH_TAG_STATE,
    FLUSH_TAG_MESSAGES,
    ADD_TAG_REQUEST,
    ADD_TAG_FAILURE,
    ADD_TAG_SUCCESS,
    UPDATE_TAG_FAILURE,
    UPDATE_TAG_REQUEST,
    UPDATE_TAG_SUCCESS,
    DELETE_TAG_FAILURE,
    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS,
    REMOVE_TAG_FROM_LIST
} from "../constants/tag";

import {
    addTag as apiAddTag,
    fetchTag,
    updateTag as apiUpdateTag,
    deleteTag as apiDeleteTag,
    apiRequest,
} from "../../helpers/api";

export const getTag = id => dispatch => {
    dispatch({type: FETCH_TAG_REQUEST});
    return fetchTag(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_TAG_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_TAG_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_TAG_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushTagErrors = () => dispatch => {
    dispatch({type: FLUSH_TAG_ERRORS});
};

export const flushTagState = () => dispatch => {
    dispatch({type: FLUSH_TAG_STATE});
};

export const addTag = (data) => dispatch => {
    dispatch({type: 'ADD_TAG_REQUEST'});
    return apiAddTag(data)
        .then(
            res => {
                if (res.data.status === "error") {
                    return dispatch({
                        type: 'ADD_TAG_FAILURE',
                        message: res.data.message
                    });
                }
                if (res.data.status === 'success') {

                    return dispatch({
                        type: 'ADD_TAG_SUCCESS',
                        payload: res.data
                    });
                }
            },
            error => {
                return dispatch({
                    type: 'ADD_TAG_FAILURE',
                    payload: error.response.data.message
                })
            })
};

export const updateTag = (data) => dispatch => {
    dispatch({type: UPDATE_TAG_REQUEST});
    const request = () => apiUpdateTag(data);
    return apiRequest(request, dispatch, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAILURE)
};

export const flushTagMessages = () => dispatch => {
    dispatch({type: FLUSH_TAG_MESSAGES});
};

export const deleteTag = id => dispatch => {
    dispatch({type: DELETE_TAG_REQUEST});
    const request = () => apiDeleteTag(id);
    return apiRequest(request, dispatch, DELETE_TAG_SUCCESS, DELETE_TAG_FAILURE);
};

export const removeTagFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_TAG_FROM_LIST,
        payload: id
    })
};


