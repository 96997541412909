import React from "react";
import {Redirect, Switch, withRouter} from "react-router-dom";
import {hasAwaitingCode, hasToken} from "./store/reducers/auth";
import {connect} from "react-redux";
import * as PropTypes from 'prop-types'
import PrivateRoute from "./common/PrivateRoute";
import Header from "./components/Header/Header";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AdminList from "./components/AdminList";
import SingleAdmin from "./components/SingleAdmin";
import {makeStyles} from "@material-ui/core/styles";
import NetworkDetector from "./hoc/NetworkDetector";
import {useSnackbar} from "notistack";
import Images from "./components/Images/ImagesRoutes";
import Videos from "./components/Videos/Videos";
import Events from "./pages/Events";
import Streams from "./pages/Streams";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const Layout = ({hasToken, isDisconnected}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    if (isDisconnected) {
        enqueueSnackbar('Internet connection lost!', {variant: 'error'});
    }

    return (
        <div className="Maindashcontainer">
            <Header/>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <Switch>
                                <PrivateRoute
                                    path="/dashboard"
                                    component={() => (<div>Dashboard soon!</div>)}
                                    hasAccess={hasToken}
                                />
                                <PrivateRoute
                                    exact
                                    path="/users"
                                    component={AdminList}
                                    hasAccess={hasToken}
                                />
                                <PrivateRoute
                                    path="/users/admin/:id"
                                    component={SingleAdmin}
                                    hasAccess={hasToken}
                                />
                                <PrivateRoute
                                    path="/images"
                                    component={Images}
                                    hasAccess={hasToken}
                                />
                                <PrivateRoute
                                    path="/videos"
                                    component={Videos}
                                    hasAccess={hasToken}
                                />
                                <PrivateRoute
                                  path="/streams"
                                  component={Streams}
                                  hasAccess={hasToken}
                                />
                                <PrivateRoute
                                  path="/events"
                                  component={Events}
                                  hasAccess={hasToken}
                                />
                                <Redirect to="/dashboard"/>
                            </Switch>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
};

Layout.propTypes = {
    hasToken: PropTypes.bool.isRequired,
    hasAwaitingCode: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    hasToken: hasToken(state),
    hasAwaitingCode: hasAwaitingCode(state),
});


export default NetworkDetector(withRouter(connect(mapStateToProps)(Layout)));
