export const styles = theme => ({
  //TODO: improve styles
  onFetching: {
    position: 'absolute',
    // width: '100%',
    // height: '100%',
    alignItems: 'center',
    // display: 'flex',
    // justifyContent: 'center'
    top: '40%',
    left: '45%',
    zIndex: 9999
  },
  root: {
    minWidth: '600px',
    width: "100%"
  },
  grid: {
    marginTop: '10px',
    '& > *': {
      marginRight: "10px"
    }
  }
});
