import {useDispatch, useSelector} from "react-redux";
import {getIsFetching, getUserData} from "../store/reducers/user";
import {useEffect} from "react";
import {getUser} from "../store/actions/user";

export const useUser = (id) => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const loading = useSelector(getIsFetching);

    useEffect(() => {
        dispatch(getUser(id));
    }, [dispatch, id]);

    return {
        user,
        loading
    };
};
