import {
    FLUSH_VIDEO_PLAYLIST_ERRORS,
    FLUSH_VIDEO_PLAYLIST_STATE,
    FLUSH_VIDEO_PLAYLIST_MESSAGES,
    FETCH_VIDEO_PLAYLIST_FAILURE,
    FETCH_VIDEO_PLAYLIST_REQUEST,
    FETCH_VIDEO_PLAYLIST_SUCCESS,
    ADD_VIDEO_PLAYLIST_FAILURE,
    ADD_VIDEO_PLAYLIST_REQUEST,
    ADD_VIDEO_PLAYLIST_SUCCESS,
    UPDATE_VIDEO_PLAYLIST_REQUEST,
    DELETE_VIDEO_PLAYLIST_REQUEST,
    DELETE_VIDEO_PLAYLIST_SUCCESS,
    DELETE_VIDEO_PLAYLIST_FAILURE,
    REMOVE_VIDEO_PLAYLIST_FROM_LIST
} from "../constants/playlist";
import {
    addVideoPlaylist as apiAddVideo,
    updateVideoPlaylist as apiUpdateVideo,
    deleteVideoPlaylist as apiDeleteVideo,
    apiRequest,
    fetchVideoPlaylist,
} from "../../helpers/api";

export const addPlaylist = (data) => dispatch => {
    dispatch({type: 'ADD_VIDEO_PLAYLIST_REQUEST'});
    return apiAddVideo(data)
        .then(
            res => {
                if (res.data.status === "error") {
                    return dispatch({
                        type: 'ADD_VIDEO_PLAYLIST_FAILURE',
                        message: res.data.message
                    });
                }
                if (res.data.status === 'success') {

                    return dispatch({
                        type: 'ADD_VIDEO_PLAYLIST_SUCCESS',
                        payload: res.data
                    });
                }
            },
            error => {
                return dispatch({
                    type: 'ADD_VIDEO_PLAYLIST_FAILURE',
                    payload: error.response.data.message
                })
            })
};

export const getVideoPlaylist = id => dispatch => {
    dispatch({type: FETCH_VIDEO_PLAYLIST_REQUEST});
    return fetchVideoPlaylist(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_VIDEO_PLAYLIST_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_VIDEO_PLAYLIST_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_VIDEO_PLAYLIST_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushVideoPlaylistErrors = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_PLAYLIST_ERRORS});
};

export const flushVideoPlaylist = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_PLAYLIST_STATE});
};


export const updatePlaylist = (data) => dispatch => {
    dispatch({type: UPDATE_VIDEO_PLAYLIST_REQUEST});
    const request = () => apiUpdateVideo(data);
    return apiRequest(request, dispatch, FETCH_VIDEO_PLAYLIST_SUCCESS, FETCH_VIDEO_PLAYLIST_FAILURE)
};

export const flushVideoMessages = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_PLAYLIST_MESSAGES});
};

export const deletePlaylist = id => dispatch => {
    dispatch({type: DELETE_VIDEO_PLAYLIST_REQUEST});
    const request = () => apiDeleteVideo(id);
    return apiRequest(request, dispatch, DELETE_VIDEO_PLAYLIST_SUCCESS, DELETE_VIDEO_PLAYLIST_FAILURE);
};

export const removePlaylistFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_VIDEO_PLAYLIST_FROM_LIST,
        payload: id
    })
};
