import React, { useEffect, useState, useMemo, Fragment } from 'react'
import { format } from 'date-fns'
import { get } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { DateTimePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { useImagesTaxonomies } from '../../hooks/imageTaxonomies'
import GeoMap from '../../common/GeoMap'
import AlgoliaPlaces from 'algolia-places-react'
import SingleImageEditor from '../SingleImage/SingleImageEditor'

const prepareAutocompleteChange = (cb) => {
  return (e, value) => {
    cb(value)
  }
}

const SingleImageForm = (
  {
    image,
    classes,
    onChange,
    disabled,
    errors,
  },
) => {
  const { imagesCategories, imagesTags } = useImagesTaxonomies();


  const optionsCats = useMemo(() => {
    return imagesCategories.filter(
      tax => !image.categories.map(item => item.id).includes(tax.id))
  }, [imagesCategories, image.categories])

  const optionsTags = useMemo(() => {
    return imagesTags.filter(
      tax => !image.tags.map(item => item.id).includes(tax.id))
  }, [imagesTags, image.tags])

  const geolocationValue = useMemo(() => {
    return image.lat ? image.lat + ', ' + image.lng : ''
  }, [image.lat])

  const street = image.address || '';

  const handleOnAddressChange = () => {

  };

  return (
    <Container maxWidth="lg" className={classes.imageData}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6} className={classes.gridCol50}>
          <Box className={classes.fieldsWrapper}>
            <div className={classes.textField}>
              <TextField
                disabled={disabled}
                name="title"
                error={!!errors && !!errors.title}
                helperText={!!errors && !!errors.title && errors.title}
                label="Image Title"
                type="text"
                fullWidth
                variant="outlined"
                value={image.title ? image.title : ''}
                onChange={onChange('title')}
              />
            </div>
            <div className={classes.textField}>
              <TextField
                disabled={disabled}
                name="description"
                label="Description"
                multiline
                rows="4"
                fullWidth
                variant="outlined"
                type="text"
                value={image.description ? image.description : ''}
                onChange={onChange('description')}
              />
            </div>
            <div className={classes.textField}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel variant="outlined" id="status-label">
                  Status
                </InputLabel>
                <Select
                  disabled={disabled}
                  labelId="status-label"
                  id="status-select-outlined"
                  name="status"
                  value={image.status}
                  onChange={onChange('status')}
                  renderValue={() => image.status.toUpperCase()}
                  labelWidth={50}
                >
                  <MenuItem value="approved">APPROVED</MenuItem>
                  <MenuItem value="blocked">BLOCKED</MenuItem>
                  <MenuItem value="expired" disabled
                            style={{ display: 'none' }}>EXPIRED</MenuItem>
                  <MenuItem value="pending" disabled
                            style={{ display: 'none' }}>PENDING</MenuItem>
                  <MenuItem value="in_process" disabled
                            style={{ display: 'none' }}>PROCESS</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.textField}>
              <DateTimePicker
                clearable
                ampm={false}
                disabled={disabled}
                name="expired_at"
                className={classes.datePicker}
                label="Expired At"
                inputVariant="outlined"
                value={image.expired_at}
                format="yyyy-MM-dd HH:mm:ss"
                onChange={(date) => {
                  if (!date) {
                    onChange('expired_at')(null)
                  } else {
                    onChange('expired_at')(format(date, 'yyyy-MM-dd HH:mm:ss'))
                  }
                }}
              />
            </div>
            <div className={classes.textField}>
              <Autocomplete
                disabled={disabled}
                multiple
                name="categories"
                options={optionsCats}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                value={image.categories}
                onChange={prepareAutocompleteChange(onChange('categories'))}
                renderInput={params => (<TextField
                  {...params}
                  variant="outlined"
                  label="Categories"
                  placeholder="Category name"
                  fullWidth
                />)}
              />
            </div>
            <div className={classes.textField}>
              <Autocomplete
                disabled={disabled}
                multiple
                name="tags"
                options={optionsTags}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                value={image.tags}
                onChange={prepareAutocompleteChange(onChange('tags'))}
                renderInput={params => (<TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  placeholder="Tag name"
                  fullWidth
                />)}
              />
            </div>
            <div className={classes.textField}>
              <AlgoliaPlaces
                placeholder={street ? street : (geolocationValue
                  ? geolocationValue
                  : 'GEO Location')}
                style={{ height: '57px', fontSize: '16px' }}
                options={{}}
                onChange={({ rawAnswer, query, suggestions }) => {
                  console.group('SingleImageForm.js:182 - ')
                  console.log(rawAnswer, query, suggestions)
                  console.groupEnd()
                  const hit = rawAnswer.hits[0]
                  if (!hit) {
                    return
                  }

                  onChange('geolocation')({
                    lat: get(hit, '_geoloc.lat'),
                    lng: get(hit, '_geoloc.lng'),
                    address: [
                      get(hit, 'locale_names', []).join(', '),
                      get(hit, 'city', []).join(', '),
                      get(hit, 'administrative', []).join(', '),
                      get(hit, 'country'),
                    ].filter(item => !!item).join(', '),
                  })
                }}
                onClear={() => {
                  onChange('geolocation')({
                    lat: null,
                    lng: null,
                    address: null,
                  })
                }}
                onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => {
                  console.group('SingleImageForm.js:205 - ');
                  console.log(rawAnswer, query, suggestion, suggestonIndex);
                  console.groupEnd();
                }}
              />
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className={clsx(classes.imagePhotoBlock, classes.gridCol50)}
        >
          <Box className={classes.fieldsWrapper}>
            <SingleImageEditor
              classes={classes}
              image={image}
              onChange={(base64) => onChange('image')(base64)}
            />
            <div className={classes.textField}>
              <TextField
                id="created-date"
                label="Uploaded"
                value={image.created_at ? image.created_at : ''}
                variant="outlined"
                fullWidth
                disabled
              />
            </div>
            {image.orientation &&
            <div className={classes.textField}>
              <TextField
                label="Orientation"
                type="text"
                variant="outlined"
                fullWidth
                value={image.orientation ? image.orientation : ''}
                disabled
              />
            </div>}
            {image.type &&
            <div className={classes.textField}>
              <TextField
                label="MIME type"
                type="text"
                variant="outlined"
                fullWidth
                value={image.type ? image.type : ''}
                disabled
              />
            </div>}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box className={classes.mapBlock}>
            <GeoMap
              lng={image.lng || ''}
              lat={image.lat || ''}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SingleImageForm


