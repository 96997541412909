import React from 'react';
import {Field} from 'redux-form'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormImages from "../../../../components/ReduxFormFields/FormImages";

const required = value => {
  //
  if(value && !value.primary_image){
    return 'You must select main image'
  }

  return;
};

const EventImages = ({...rest}) => {

  return (
    <Box>
      <Typography>Images</Typography>
      <Divider/>
      <Field
        name={"images"}
        component={FormImages}
        validate={required}
        {...rest}
      />
    </Box>
  );
};

export default EventImages;
