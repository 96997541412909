import {createSelector} from 'reselect';
import {get, omit} from "lodash";

const baseState = state => get(state, 'eventsList');

const singleState = state => get(state, 'singleEvent');

const pendingEventsState = state => get(state, 'eventsPending');

export const singleEventSelector = createSelector(
  singleState,
  state => (
    {...omit(state , 'isFetching')}
  )
)

export const isFetchingSingleEvent = createSelector(
  singleState,
  state => state.isFetching
)

export const eventsSelector = createSelector(
  baseState,
  state => ({
    isFetching: state.isFetching || state.isUpdating,
    items: state.items,
    currentPage: state.currentPage,
    pages: state.pages,
    limit: state.limit,
    total: state.total,
  })
);

export const pendingEventsSelector = createSelector(
    pendingEventsState,
    state => ({
        isFetching: state.isFetching || state.isUpdating,
        eventsPending: state.eventsPending,
        currentPage: state.currentPage,
        pages: state.pages,
        limit: state.limit,
        total: state.total,
    })
);
