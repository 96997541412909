import React, {useCallback, useMemo} from 'react';
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ucFirst} from "../../../helpers/strings";

const SelectFilter = ({
                        appliedFilters,
                        handleChangeFilters,
                        options,
                        classes,
                        label,
                        filterKey
                      }) => {
  const filter = useMemo(
    () => appliedFilters.find(filter => filter.key === filterKey),
    [appliedFilters, filterKey]);

  const handleChange = useCallback((e) => {
    const {value} = e.target;

    if (filter) {
      handleChangeFilters([
        ...appliedFilters.map((filter => {
          if (filter.key === filterKey) {
            return {
              ...filter,
              value: value
            }
          }
          return filter;
        }))

      ])
    } else {
      handleChangeFilters([
        ...appliedFilters,
        {
          key: filterKey,
          value: value
        }

      ])
    }
  }, [handleChangeFilters, appliedFilters, filter]);

  return (
    <Box className={classes.filterWrapper}>
      <Select
        labelId={`${filterKey}-filter-label`}
        id={`${filterKey}-filter`}
        value={filter ? filter.value : ''}
        onChange={(value) =>  handleChange(value)}
        variant="outlined"
      >
        {options.map((item, i) => (
          <MenuItem key={item + i} value={item.value}>{ucFirst(item.label)}</MenuItem>))}
      </Select>
    </Box>
  );
};

export default SelectFilter;
