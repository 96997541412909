import React from "react";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a color="inherit" target="_blank" href="https://www.weathernationtv.com/">
                Weather Nation
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;


