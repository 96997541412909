import {createSelector} from 'reselect';
import {
    LOAD_TAGS_ERRORS,
    LOAD_TAGS_FAILURE,
    LOAD_TAGS_REQUEST,
    LOAD_TAGS_SUCCESS
} from "../constants/tags";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import {UPDATE_TAG_SUCCESS, REMOVE_TAG_FROM_LIST, ADD_TAG_SUCCESS} from "../constants/tag";
import {
    CREATE_TAGS_BULK_ACTION_REQUEST,
    CREATE_TAGS_BULK_ACTION_SUCCESS
} from "../constants/bulk";

const initialState = {
    isFetching: false,
    tags: [],
    errors: false,
    updateErrors: {},
    status: '',
    message: '',
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10,
};

const tags = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_TAGS_REQUEST:
        case CREATE_TAGS_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                tags: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case UPDATE_TAG_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                tags: state.tags.map((tag) => tag.id === data.id ? data : tag)
            };
        case REMOVE_TAG_FROM_LIST:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id !== payload),
            };
        case LOAD_TAGS_FAILURE :
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case CREATE_TAGS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                tags: state.tags.filter(tag => payload.data.filter(item => item.id !== tag.id).length)
            };
        case LOAD_TAGS_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        case ADD_TAG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                tags: [payload.data].concat(state.tags.slice(0, -1)),
                status: DialogMessagesStatuses.success,
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
            };
    }
};

export default tags;

export const getIsFetching = state => state.tags.isFetching;
export const getTagsOrderBy = state => state.tags.orderBy;
export const getTagsArr = state => state.tags.tags;
export const getTagsCount = state => state.tags.count;
export const getTagsPage = state => state.tags.page;
export const getTagsLimit = state => state.tags.limit;
export const getTagsOrder = state => state.tags.order;
export const getTagsErrors = state => state.tags.errors;
export const getTagsResult = state => state.tags.status;

export const tagsSelector = createSelector([
    getTagsArr,
    getIsFetching,
    getTagsCount,
    getTagsPage,
    getTagsLimit,
    getTagsOrder,
    getTagsOrderBy,
], (tags,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
) => {
    return {
        tags,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
    };
});
