import React from 'react';
import TablePagination from "@material-ui/core/TablePagination/TablePagination";

const Pagination = ({page , pages, total, limit, colSpan , handlePageChange , handleRowsChange}) => {
  return (
    <TablePagination
      colSpan={colSpan}
      rowsPerPageOptions={[ 5, 10, 25]}
      component="div"
      count={total}
      rowsPerPage={limit}
      page={page - 1}
      onChangePage={(event, page) => handlePageChange(page)}
      onChangeRowsPerPage={(event) => handleRowsChange(event.target.value)}
    />
  );
};

export default Pagination;