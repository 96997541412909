import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {singleEvent} from "../../../store/actions/events/single";
import EventSingleInner from "./EventSingleInner";
import {isFetchingSingleEvent, singleEventSelector} from "../../../store/reducers/events/selectors";
import {useParams, useHistory} from "react-router-dom";
import {createEvent} from "../../../store/actions/events/create";
import {removeEvent} from "../../../store/actions/events/remove";
import {updateEvent} from "../../../store/actions/events/update";
import {useSnackbar} from "notistack";
import { getDateSaveFormattedString, getDate } from '../../../helpers/date'


const EventsSingle = ({classes}) => {

  const {eventId} = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();


  const initialValues = useSelector(singleEventSelector);
  const isFetching = useSelector(isFetchingSingleEvent);

  useEffect(() => {
    if (typeof eventId !== 'undefined') {
      dispatch(singleEvent(eventId));
    }

    return () => dispatch({type: 'REST_EVENT_TO_DEFAULT'})
  }, [eventId]);

  const handleUpdate = useCallback((values) => {
    dispatch(updateEvent({
      ...values,
      start_at: getDateSaveFormattedString(getDate(values.start_at)),
      end_at: getDateSaveFormattedString(getDate(values.end_at)),
      primary_image: values.images.primary_image.id,
      videos: [...values.videos.map((i => i.id))],
      streams: [...values.streams.map(i => i.id)],
      images: undefined
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'})
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, [eventId]);

  const handleRemove = useCallback(() => {
    dispatch(removeEvent(eventId))
      .then(() => {
        enqueueSnackbar("Event successfully removed", {variant: 'success'});
        historyChange('/events')
      })
      .catch(({message}) => {
        historyChange('/events');
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, [eventId]);

  const handleCreate = useCallback((values) => {
    dispatch(createEvent({
      ...values,
      start_at: getDateSaveFormattedString(values.start_at),
      end_at: getDateSaveFormattedString(values.end_at),
      primary_image: values.images.primary_image.id,
      videos: [...values.videos.map((i => i.id))],
      streams: [...values.streams.map(i => i.id)],
      images: undefined
    }))
      .then(({data: {data: {id}, message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        historyChange(`/events/${id}/edit`)
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, []);

  const handleSave = useCallback((values) => {
    if (!eventId) {
      handleCreate(values);
    } else {
      handleUpdate(values);
    }
  }, [eventId, handleCreate, handleUpdate]);


  const title = useMemo(() => {
    return typeof eventId === 'undefined' ? "Create New Event" : initialValues.title
  }, [eventId, initialValues]);

  const historyChange = useCallback((url) => {
    //history.push(url)
      history.push(`/events/in-use`)
  }, []);

  const formInitialValues = useMemo(() => {
    return {
      ...initialValues,
      images: {
        primary_image: initialValues.primary_image,
      }
    }
  }, [initialValues])

  return (
    <EventSingleInner
      disabled={initialValues.status === 'in_process' || isFetching}
      item={initialValues}
      isFetching={isFetching}
      initialValues={formInitialValues}
      historyChange={historyChange}
      classes={classes}
      eventId={eventId}
      onSubmit={handleSave}
      handleRemove={handleRemove}
      title={title}
      createdAt={initialValues.created_at}
    />
  );
};

export default EventsSingle;
