import {createSelector} from 'reselect';
import {
    LOAD_VIDEOS_FAILURE,
    LOAD_VIDEOS_REQUEST,
    LOAD_VIDEOS_SUCCESS
} from "../constants/videos";
import {CHANGE_VIDEOS_STATUS_TO_PROGRESS, CREATE_VIDEOS_BULK_ACTION_REQUEST} from "../constants/bulk";

const initialState = {
    isFetching: false,
    videos: [],
    errors: false,
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10,
};

const videos = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_VIDEOS_REQUEST:
        case CREATE_VIDEOS_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_VIDEOS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload
            };
        case LOAD_VIDEOS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                videos: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case CHANGE_VIDEOS_STATUS_TO_PROGRESS:
            return {
                ...state,
                isFetching: false,
                videos: state.videos.map(video => {
                    if (payload.includes(video.id)) {
                        return {...video, status: 'process'}
                    }
                    return video;
                })
            };
        default:
            return {
                ...state,
            };
    }
};

export default videos;

export const getIsFetching = state => state.videos.isFetching;
export const getVideosErrors = state => state.videos.errors;
export const getVideosArr = state => state.videos.videos;
export const getVideosCount = state => state.videos.count;
export const getVideosPage = state => state.videos.page;
export const getVideosLimit = state => state.videos.limit;

export const videosSelector = createSelector([
    getVideosArr,
    getIsFetching,
    getVideosCount,
    getVideosPage,
    getVideosLimit,
    getVideosErrors
], (videos,
    isFetching,
    count,
    page,
    limit,
    errors
) => {
    return {
        videos,
        isFetching,
        count,
        page,
        limit,
        errors
    };
});
