class LocalStorageHelper {
    constructor() {
        this.storage = localStorage;
        this.authFiled = ['access_token', 'expires_in', 'user_id'];
    }

    set(key, value) {
        this.storage.setItem(key, value)
    }

    get(key) {
        return this.storage.getItem(key);
    }

    setToken(data) {
        Object.keys(data).map(key => this.set(key, data[key]));
    }

    setUserId(token){
        return this.storage.setItem('user_id', JSON.parse(atob(token.split('.')[1])).sub);
    }

    remove(key) {
        this.storage.removeItem(key);
    }

    removeToken() {
        this.authFiled.map((key) => this.remove(key))
    }
}

const localStorageHelper = new LocalStorageHelper();

export default localStorageHelper;
