import {
  LOAD_EVENTS_SINGLE_REQUEST,
  LOAD_EVENTS_SINGLE_SUCCESS,
  LOAD_EVENTS_SINGLE_FAILURE
} from "../../constants/events/single";

import {EVENTS_CREATE_REQUEST, EVENTS_CREATE_SUCCESS, EVENTS_CREATE_FAILURE} from "../../constants/events/create";
import {EVENTS_REMOVE_REQUEST, EVENTS_REMOVE_FAILURE, EVENTS_REMOVE_SUCCESS} from "../../constants/events/remove";
import {EVENTS_UPDATE_REQUEST, EVENTS_UPDATE_FAILURE, EVENTS_UPDATE_SUCCESS} from "../../constants/events/update";

const initialState = {
  isFetching: false,
  id: 0,
  title: "",
  type: "winter_storm",
  start_at: new Date(),
  end_at: new Date(),

  published: false,
  created_at: "",
  updated_at: "",
  information: null,
  description: null,
  primary_image: null,
  secondary_image: null,
  videos: [],
  streams: [],
};

const singleEvent = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LOAD_EVENTS_SINGLE_REQUEST:
    case EVENTS_CREATE_REQUEST:
    case EVENTS_REMOVE_REQUEST:
    case EVENTS_UPDATE_REQUEST:

      return {
        ...state,
        isFetching: true
      };

    case LOAD_EVENTS_SINGLE_SUCCESS:
    case EVENTS_UPDATE_SUCCESS:
      return {
        ...state,
        ...payload.data,
        isFetching: false
      };
    case LOAD_EVENTS_SINGLE_FAILURE:
    case EVENTS_CREATE_SUCCESS:
    case EVENTS_CREATE_FAILURE:
    case EVENTS_REMOVE_SUCCESS:
    case EVENTS_REMOVE_FAILURE:
    case EVENTS_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case 'REST_EVENT_TO_DEFAULT' : {
      return {
        ...initialState,
        isFetching: false
      };
    }
    default:
      return {
        ...state
      }
  }

};

export default singleEvent;
