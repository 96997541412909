import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getCategoryData} from "../store/reducers/category";
import {getCategory} from "../store/actions/category";

export const useCategory = (id) => {
    const dispatch = useDispatch();
    const category = useSelector(getCategoryData);
    const loading = useSelector(state => state.category.isFetching);
    useEffect(() => {
        dispatch(getCategory(id));
    }, [dispatch, id]);

    return {
        category,
        loading
    };
};
