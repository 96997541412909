import React, { useMemo } from 'react'
import List from '@material-ui/core/List'
import ImageFile from './Variants/ImageFile'
import VideoFile from './Variants/VideoFile'
import UnknownFile from './Variants/UnknownFile'

const EventPendingItemFiles = ({ item: { images, videos, unknown }, classes }) => {

  const imageList = useMemo(() => {
    return images.map((item) => (
      <ImageFile key={item.id} item={item} classes={classes}/>
    ))
  }, [images])

  const videoList = useMemo(() => {
    return videos.map((item) => (
      <VideoFile key={item.id} item={item} classes={classes}/>
    ))
  }, [videos])

  const unknownList = useMemo(() => {
    return unknown.map((item) => (
      <UnknownFile key={item.id} item={item} classes={classes}/>
    ))
  }, [unknown])

  return (
    <List className={classes.root} dense={true}>
      {imageList}
      {videoList}
      {unknownList}
    </List>
  )
}

export default EventPendingItemFiles
