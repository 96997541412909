import React from "react";
import Avatar from "@material-ui/core/Avatar";

const GravatarIcon = ({hash, size, variant, forceSize, classes}) => {
    let str = !!hash ? hash : '341be97d9aff90c9978347f66f945b77';
    str += !!size ? `?s=${size}` : '';

    return (<Avatar variant={variant} src={`https://s.gravatar.com/avatar/${str}`} className={forceSize ? classes.xtraLarge : ''}/>);
};

export default GravatarIcon;


