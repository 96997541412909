import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import * as PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";
import {debounce} from "lodash";
import {imagesSelector, getIsFetching} from "../../store/reducers/images";
import {getApprovedImagesList} from "../../store/actions/images";
import {useResource} from "../../hooks/resource";
import Grid from "@material-ui/core/Grid/Grid";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";

const AutocompleteImages = ({classes, handleChange, orientation}) => {
  const {
    data: {
      images,
    },
    loading,
    handleChangeSearch,
  } = useResource(
    imagesSelector,
    getApprovedImagesList,
    getIsFetching
  );
  let mounted = true;
  const [searched, setSearched] = useState(false);


  let options = images
  .filter(image => image.status === 'approved')
  .filter(image => !!orientation ? image.orientation === orientation : true);

  const onInputChange = useCallback(debounce((e, value) => {
    if (mounted) {
      handleChangeSearch(value);
    }

  }, 1000), [searched]);

  const changeAutocomplete = useCallback((e, value) => {
    mounted=false;
    setSearched(false);
    handleChange(value)
  }, [setSearched]);

  return (
    <Fragment>
      <Box className={classes.container}>
        <Autocomplete
          id="image-list"
          className={classes.sectionInput}
          options={options}
          getOptionLabel={option => option.title}
          renderOption={(option) => {
            return <Grid container spacing={1}>
              <Grid item xs={2}> <Avatar variant="rounded" src={option.thumbnail}/></Grid>
              <Grid item xs={10}> <Typography> {option.title}</Typography></Grid>
            </Grid>
          }}
          onInputChange={onInputChange}
          onChange={changeAutocomplete}
          disableClearable={true}
          loading={loading}
          renderInput={params => {
            if (!searched) {
              params.inputProps.value = '';
            }
            return (
              <TextField
                {...params}
                label="Search Images"
                variant="outlined"
                placeholder="Images"
                fullWidth
                onChange={() => setSearched(true)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )
          }}
        />
      </Box>
    </Fragment>
  );
};

AutocompleteImages.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AutocompleteImages;
