import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import TableComponent from '../TableComponent'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import { useResource } from '../../hooks/resource'
import { getIsFetching, imagesSelector } from '../../store/reducers/images'
import { getImagesList } from '../../store/actions/images'
import { headCellsImages } from '../../constatns/images'
import ListActions from '../ListActions'
import LoadingFade from '../../common/LoadingFade'
import Linear from '../../common/Linear'
import { useDispatch } from 'react-redux'
import {
  bulkActionForImagesInUse,
  changeImagesStatusToProgress,
} from '../../store/actions/bulk'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../helpers/validation'
import NewFilters from '../NewFilters'
import DateFilter from '../NewFilters/Items/Date'
import Taxonomies from '../NewFilters/Items/Taxonomies'
import { useImagesTaxonomies } from '../../hooks/imageTaxonomies'
import Status from '../NewFilters/Items/Status'

const bulkActionsTypes = ['approve', 'block', 'delete']
const filterBy = ['date', 'status', 'taxonomies']
const statuses = ['approved', 'blocked', 'expired', 'process']

const ImagesList = ({ classes }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: {
      images,
      count,
    },
    loading,
    page,
    handleChangePage,
    order,
    handleChangeOrder,
    orderBy,
    handleChangeOrderBy,
    search,
    handleChangeSearch,
    rowsPerPage,
    handleChangeLimit,
    filter,
    onChangeFilter,
    clearFilter,
    handleRefresh,
  } = useResource(imagesSelector, getImagesList, getIsFetching)

  // const [flushTableComponentState, setFlushTableComponentState] = useState(false);
  const { imagesCategories, imagesTags } = useImagesTaxonomies()

  // useEffect(() => {
  //     setFlushTableComponentState(false)
  // }, [images]);

  const handleRowClick = () => id => {
    history.push(`/images/image/${id}`)
  }

  const handleAddClick = () => {
    history.push('/images/image/new')
  }

  const applyBulkAction = bulkData => {
    const data = {
      ids: bulkData.selected,
      type: bulkData.actionType,
    }
    dispatch(bulkActionForImagesInUse(data)).then(res => {
      const { data } = res
      handleRefresh();
      enqueueSnackbar(data.message, { variant: 'success' })
    }).catch(error => {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    })
  }

  if (loading && !count) {
    return (<Linear height={2}/>)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={loading}/>
        <TableComponent
          type="images"
          rows={images || []}
          count={count}
          page={page}
          limit={rowsPerPage}
          order={order}
          orderBy={orderBy}
          bulkActionsTypes={bulkActionsTypes}
          filterBy={filterBy}
          statuses={statuses}
          chipsData={filter}
          onDeleteChips={onChangeFilter}
          filtersComponent={<NewFilters
            filters={[
              {
                key: 'status',
                label: 'Status',
                component: <Status statuses={statuses}/>,
              },
              { key: 'from', label: 'From', component: <DateFilter/> },
              { key: 'to', label: 'To', component: <DateFilter/> },
              {
                key: 'categories',
                label: 'Category',
                taxonomyOptions: imagesCategories,
                component: <Taxonomies/>,
              },
              {
                key: 'tags',
                label: 'Tag',
                taxonomyOptions: imagesTags,
                component: <Taxonomies/>,
              },
            ]}
            handleChangeFilters={onChangeFilter}
            appliedFilters={filter}
            clearFilters={clearFilter}
          />}
          // filter={filter}
          // onChangeFilter={handleFilterChange}
          // onChangeFilter={onChangeFilter}
          // onClearFilter={clearFilter}
          // onRemoveFilter={onRemoveFilter}

          // onSubmitFilter={handleFilterSubmit}
          onApplyBulk={applyBulkAction}
          headCells={headCellsImages}
          onChangePage={handleChangePage}
          onChangeOrder={handleChangeOrder}
          onChangeOrderBy={handleChangeOrderBy}
          onChangeLimit={handleChangeLimit}
          handleRowClick={handleRowClick()}
          // setInitialState={flushTableComponentState}
          actionButtons={<ListActions
            searchRequest={search}
            searchHandler={handleChangeSearch}
            isAdd
            onClickAdd={handleAddClick}
          />}
        />
      </Paper>
    </div>)

}

ImagesList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default ImagesList
