import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import MovieIcon from '@material-ui/icons/Movie'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const VideoFile = ({ item, classes }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <MovieIcon/>
      </ListItemIcon>
      <ListItemText primary={item.title}/>
    </ListItem>
  )
}

export default VideoFile
