import React from "react";
import * as PropTypes from 'prop-types';
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Recaptcha from "react-google-invisible-recaptcha";
import Copyright from "../../../common/Copyright";
import Box from "@material-ui/core/Box";
import {backToLogin, getTokenAction} from "../../../store/actions/auth";
import {connect} from "react-redux";
import FlashMessage from "react-flash-message";
import {getAuthorizationError, getIsFetching} from "../../../store/reducers/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

const initialState = {
    code: '',
    errors: '',
};

class AuthCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.backLinkHandle = this.backLinkHandle.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authError !== this.props.authError) {
            this.setState({
                errors: this.props.authError,
            });
        }
        if (prevState.code !== this.state.code) {
            this.setState({
                errors: ""
            });
        }
    }

    onChange(e) {
        const {name, value} = e.target;

        this.setState({[name]: value});
    }

    onSubmit(e) {
        e.preventDefault();
        this.recaptcha.execute();
    }

    onResolved() {
        const {code} = this.state;
        this.props.getToken({
            access_code: code,
            gcaptcha: this.recaptcha.getResponse()
        });
    }

    backLinkHandle(e) {
        e.preventDefault();
        this.props.backToLogin();
    }

    render() {
        const {classes, authError, isFetching} = this.props;
        const {code, errors} = this.state;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Enter verification code
                    </Typography>
                    {isFetching &&
                    <div className={classes.onFetching}>
                        <CircularProgress/>
                    </div>}
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.onSubmit}
                        style={isFetching ? {opacity: "0.5"} : {}}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="verification-code"
                            label="Verification code"
                            name="code"
                            autoFocus
                            value={code}
                            disabled={isFetching}
                            onChange={this.onChange}
                        />
                        {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={"alert alert-danger"}>Error: {authError}</h5></FlashMessage> : ''}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!code}
                            className={classes.submit}
                        >
                            Enter
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/login" variant="body2" onClick={this.backLinkHandle}>
                                    Back to login.
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.MIX_GCAPTCHA_DEV_SITE_KEY}
                    onResolved={this.onResolved}/>
            </Container>
        );
    }
}

AuthCode.propTypes = {
    classes: PropTypes.object.isRequired,
    getToken: PropTypes.func.isRequired,
    authError: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    backToLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getToken: getTokenAction,
    backToLogin: backToLogin,
};
const mapStateToProps = state => ({
    authError: getAuthorizationError(state),
    isFetching: getIsFetching(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthCode);
