import {
    FETCH_VIDEO_FAILURE,
    FETCH_VIDEO_REQUEST,
    FETCH_VIDEO_SUCCESS,
    FLUSH_VIDEO_ERRORS,
    FLUSH_VIDEO_STATE,

    UPDATE_VIDEO_FAILURE,
    UPDATE_VIDEO_REQUEST,
    UPDATE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAILURE,
    DELETE_VIDEO_REQUEST,
    DELETE_VIDEO_SUCCESS,
    FLUSH_VIDEO_MESSAGES
} from "../constants/video";
import {DialogMessagesStatuses} from "../../constatns/statuses";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        name: '',
        desc: ''
    },
};

const video = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_VIDEO_REQUEST:
        case UPDATE_VIDEO_REQUEST:
        case DELETE_VIDEO_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case DELETE_VIDEO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
            };
        case UPDATE_VIDEO_FAILURE:
        case DELETE_VIDEO_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
                status: DialogMessagesStatuses.error,
                message: payload
            };
        case UPDATE_VIDEO_SUCCESS:
        case FETCH_VIDEO_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload
            };
        case FETCH_VIDEO_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FLUSH_VIDEO_STATE:
            return {
                ...state,
                data: initialState.data
            };
        case FLUSH_VIDEO_MESSAGES:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        case FLUSH_VIDEO_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
            };
    }
};

export default video;

export const getIsFetching = state => state.video.isFetching;

export const getVideoData = state => state.video.data;
