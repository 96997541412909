import React from "react";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const CloseSnackbarButton = ({id}) => {
    const {closeSnackbar} = useSnackbar();
    return (
        <IconButton
            aria-label="Close notification"
            color="inherit"
            onClick={() => closeSnackbar(id)}
        >
            <CloseIcon fontSize="small"/>
        </IconButton>
    );
};

export default CloseSnackbarButton;


