export const styles = theme => ({
  root: {
    display: 'flex',
    minWidth: '150px',
    maxWidth: '150px',
    maxHeight: '150px',
    minHeight: '150px',
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.process.dark,
    alignItems: 'center',
    justifyContent: 'center',
    cursor:"pointer",
    borderRadius: '3px',
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    "&:hover":{
      backgroundColor: theme.palette.process.main,
    }
  },
  icon: {
    fontSize: '3rem',
  }
});
