import React from 'react';
import InputBase from "@material-ui/core/InputBase/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import Box from "@material-ui/core/Box/Box";

const Search = ({searchValue, handleChangeSearch, classes}) => {

  return (
    <Box className={classes.actionBlock}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon/>
        </div>
        <InputBase
          value={searchValue}
          onChange={handleChangeSearch}
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{'aria-label': 'search'}}
        />
      </div>
    </Box>
  );
};

export default Search;