import React from "react";
import FilterFormControlItem from "../../common/FilterFormControlItem";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const BulkAction = ({
                     classes,
                     actionType,
                     bulkActionTypes,
                     onChangeBulkAction,
                     onApplyBulk,
                     disabledBulkApplyAction,
                 }) => {
    return (<Box>
        {bulkActionTypes && bulkActionTypes.length && onChangeBulkAction && onApplyBulk &&
        <Box className={classes.bulkBlock}>
            <FilterFormControlItem
                name="bulk"
                value={actionType}
                menuItems={bulkActionTypes}
                onChange={onChangeBulkAction}
                hasEmpty
                disabled={disabledBulkApplyAction}
            />
            <Button
                variant="contained"
                onClick={onApplyBulk}
                disabled={disabledBulkApplyAction || !actionType}
            >
                Apply
            </Button>
        </Box>}
    </Box>);
};

export default BulkAction;


