import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getVideoCategoryData, getIsFetching} from "../store/reducers/videoCategory";
import {getVideoCategory} from "../store/actions/videoCategory";

export const useVideoCategory = (id) => {
    const dispatch = useDispatch();
    const category = useSelector(getVideoCategoryData);
    const loading = useSelector(state => state.video_category.isFetching);
    useEffect(() => {
        dispatch(getVideoCategory(id));
    }, [dispatch, id]);

    return {
        category,
        loading
    };
};
