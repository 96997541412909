import React, { useCallback, useMemo, useState } from 'react'
import Chip from '@material-ui/core/Chip/Chip'
import Button from '@material-ui/core/Button/Button'
import clsx from 'clsx'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Box from '@material-ui/core/Box/Box'

const PublishStatus = ({ id, status, published, classes, handleChangeStatus }) => {

  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = event => {
    if (status !== 'approved') {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getLabel = useCallback((value) => {

    if (value) {
      return 'Yes'
    }

    return 'No'
  }, [published])

  const getText = useCallback((value) => {
    if (value) {
      return 'Publish'
    }
    return 'Unpublish'
  }, [published])

  const styles = useMemo(() => {

    if (published) {
      return classes.published
    }

    return {}

  }, [published])

  const handleChange = useCallback(() => {
    handleChangeStatus(id, getLabel(!published).toLowerCase())
    handleClose()
  }, [handleChangeStatus, id, getLabel])

  return (
    <Box key={''}>
      <Button
        onClick={onClick}
        className={clsx(
          classes.statusBadge,
          styles,
        )}
      >
        {getLabel(published)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleChange}>{getText(!published)}</MenuItem>
      </Menu>
    </Box>
  )
}

export default PublishStatus
