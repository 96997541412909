import React from 'react';
import {getDateSaveFormattedString} from "../../helpers/date";
import {DateTimePicker} from "@material-ui/pickers";
import { format } from "date-fns"

const FormDate = ({input: {value, onChange, onBlur}, meta, label, options, minDate, maxDate, ...rest}) => {

  return (
    <DateTimePicker
      ampm={false}
      label={label}
      {...rest}
      inputVariant="outlined"
      value={value || new Date()}
      format="yyyy-MM-dd HH:mm:ss"
      onChange={onChange}
      minDate={minDate || undefined}
      maxDate={maxDate || undefined}
    />
  );
};

export default FormDate;
