export const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    onFetching: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    alertSuccess: {
        padding: '10px',
        borderRadius: '4px',
        color: theme.palette.success.main,
        border: '1px solid',
        borderColor: theme.palette.success.main
    }
});
