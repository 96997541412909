export const DELETE_EVENT_PENDING_FAILURE = "DELETE_EVENT_PENDING_FAILURE";
export const DELETE_EVENT_PENDING_REQUEST = "DELETE_EVENT_PENDING_REQUEST";
export const DELETE_EVENT_PENDING_SUCCESS = "DELETE_EVENT_PENDING_SUCCESS";

export const UPDATE_EVENT_PENDING_FAILURE = "UPDATE_EVENT_PENDING_FAILURE";
export const UPDATE_EVENT_PENDING_REQUEST = "UPDATE_EVENT_PENDING_REQUEST";
export const UPDATE_EVENT_PENDING_SUCCESS = "UPDATE_EVENT_PENDING_SUCCESS";

export const CHANGE_EVENT_PENDING_STATUS_TO_PROGRESS = "CHANGE_EVENT_PENDING_STATUS_TO_PROGRESS";

export const REMOVE_PENDING_EVENT_FROM_LIST = "REMOVE_PENDING_EVENT_FROM_LIST";
