export const styles = theme => ({
  statusBadge: {
    display: 'inline-block',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    borderRadius: '4px',
    color: '#fff',
    padding: 0,
    fontSize: '0.75em',
    backgroundColor: theme.palette.process.dark,

  },
  published: {
    backgroundColor: theme.palette.success.main,
    color: '#fff'
  }
});
