import React, {useCallback, useMemo, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import {makeStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import useToggle from '../../../../../hooks/useToggle'
import ImageEmpty from './Empty'
import ImageItem from './Item'
import ImagesModal from '../../../../../components/Modal/ImagesModal'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    '& > *': {
      marginRight: "10px",
      marginBottom: "10px"
    }
  },
  swapItem: {
    display: 'flex',
    alignItems: 'center'
  },
  swapButton: {
    display: 'flex',
    height: '55px'
  },
  swapIcon: {
    fontSize: '2rem',
  }
}));

const ImageForm = ({input: {value, onChange, onBlur}, meta, label, options, disabled, ...rest}) => {

  const isError = (meta.dirty || meta.touched || meta.submitting) && meta.invalid;
  const classes = useStyles();

  const [open, handleToggle] = useToggle();

  const handleChange = (value) => {
    if(!disabled) {
      onChange(value);
    }
  };

  const handleOpen = useCallback(() => {
    if(disabled) {
      return;
    }
    handleToggle();
  }, [handleToggle]);

  const handleSuccess = useCallback((newValue) => {
    handleOpen();
    handleChange({
      ...newValue
    });
  }, [value, handleOpen, onChange]);


  const imageComponent = useMemo(() => {
    if (!value) {
      return <ImageEmpty onClick={() => handleOpen()}/>
    }

    return <ImageItem
      src={value.thumbnail}
      onClick={() => handleOpen()}
    />

  }, [value, handleOpen]);

  return (
    <Box className={classes.root}>
      <Grid container wrap={"wrap"} className={classes.root}>
        <Grid item className={classes.swapItem}>
          {imageComponent}
        </Grid>
        <ImagesModal
          orientation="landscape"
          open={!disabled && open}
          onClose={handleToggle}
          onSuccess={handleSuccess}
        />
      </Grid>
      {isError && (
        <Typography color={"error"}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export default ImageForm;
