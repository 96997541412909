import React from "react";
import clsx from "clsx";

const StatusChip = ({classes, status, size, text}) => {
    const statusClassName = status && status.toLowerCase();
    return (
        <span className={clsx(classes.statusChip, classes[statusClassName], classes[size])}>
            {text ? text : status}
        </span>
    );
};

export default StatusChip;


