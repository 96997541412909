import React, {useState} from "react";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import Box from "@material-ui/core/Box";
import {updateImage} from "../../store/actions/image";
import {getErrorMessage} from "../../helpers/validation";
import {approveSingleImage, removePendingImageFromListAfterApprove} from "../../store/actions/imagePending";
import {getPendingImagesList} from "../../store/actions/imagesPending";

const StatusButtonImage = ({status, children, classes, target, type , disabled}) => {
    const dispatch = useDispatch();
    const statusClassName = status ? status.toLowerCase() : '';
    const [anchorEl, setAnchorEl] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const [updateProcess, setUpdateProcess] = useState('');

    const setUpdateStatus = (status) => status.toLowerCase() === 'approved' ? 'blocked' : 'approved';

    let statusTitle;
    switch (type) {
        case 'images':
            statusTitle = statusClassName === 'approved' || statusClassName === 'pending' ? 'Blocked' : 'Approved';
            break;
        case 'imagesPending':
            statusTitle = 'Approved';
            break;
        default:
            break;
    }

    const handleClick = event => {
      return null;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeApprovedStatus = () => {
        setUpdateProcess('process');
        dispatch(updateImage({
            image_id: target.id,
            status: setUpdateStatus(status)
        })).then(
            res => {
                setUpdateProcess('');
                enqueueSnackbar(res.data.message, {variant: 'success'});
            }
        ).catch(
            err => {
                setUpdateProcess('');
                enqueueSnackbar(getErrorMessage(err), {variant: 'error'})
            }
        );
        handleClose();
    };

    const handleChangePendingImageStatus = () => {
        setUpdateProcess('process');
        dispatch(approveSingleImage({
            link: target.location,
        })).then(
            res => {
                setUpdateProcess('');
                dispatch(removePendingImageFromListAfterApprove(target.location));
                dispatch(getPendingImagesList());
                enqueueSnackbar(res.data.message, {variant: 'success'});
            }
        ).catch(
            err => {
                setUpdateProcess('');
                enqueueSnackbar(getErrorMessage(err), {variant: 'error'})
            }
        );
        handleClose();
    };

    const handleChangeStatus = () => {
      return null;
        switch (type) {
            case 'images':
                return handleChangeApprovedStatus();
            case 'imagesPending':
                return handleChangePendingImageStatus();
            default:
                return null
        }
    };

    const formatText = text => {
        switch (text) {
            case "in_process": {
                return "in process"
            }
            default : {
                return text;
            }
        }
    };

    return (
        <Box key={target}>
            <Button
                disabled={disabled || updateProcess === 'in_process' || status === 'expired'}
                onClick={handleClick}
                className={clsx(
                    classes.statusBadge,
                    classes.disabled,
                    classes[statusClassName],
                    classes[updateProcess]
                )}
            >
                {formatText(children)}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleChangeStatus}>{statusTitle}</MenuItem>
            </Menu>
        </Box>
    );
};

export default StatusButtonImage;


