import {
    LOAD_PENDING_EVENTS_FAILURE,
    LOAD_PENDING_EVENTS_SUCCESS,
    LOAD_PENDING_EVENTS_REQUEST
} from "../../constants/events/eventsPending";
import {loadPendingEventsList} from "../../../helpers/api";

const queryToString = (filter) => {

    let query = [];

    let keys = Object.keys(filter);
    for (let i = 0 ; i < keys.length; i++){
        if(!filter[keys[i]]){
            continue;
        }
        query.push(`${keys[i]}=${filter[keys[i]]}`)
    }

    return query.join('&')
};

export const eventsPendingsList = (filters) => dispatch => {
    dispatch({type: LOAD_PENDING_EVENTS_REQUEST});

    return loadPendingEventsList(queryToString(filters)).then(
        res => {
            if (res.data.status === "error") {
                return dispatch({type: LOAD_PENDING_EVENTS_FAILURE, message: res.data.message})
            }
            return dispatch({
                type: LOAD_PENDING_EVENTS_SUCCESS,
                payload: res.data
            })
        },
        error => dispatch({type: LOAD_PENDING_EVENTS_FAILURE, message: error})
    )
};
