import {
    FETCH_IMAGE_FILE_FAILURE,
    FETCH_IMAGE_FILE_REQUEST,
    FETCH_IMAGE_FILE_SUCCESS, FLUSH_EDIT_PENDING_IMAGE_DATA,
    FLUSH_IMAGE_FILE_DATA
} from "../constants/images";

const initialState = {
    isFetching: false,
    base64: null,
    errors: false,
    message: '',
};

const file = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case FETCH_IMAGE_FILE_REQUEST:
            return {
                ...state,
                isFetching: true,
                base64: null
            };
        case FETCH_IMAGE_FILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_IMAGE_FILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
              base64: payload
            };
        case FLUSH_IMAGE_FILE_DATA:
        case FLUSH_EDIT_PENDING_IMAGE_DATA:
            return initialState;
        default:
            return {
                ...state,
            }
    }
};

export default file;

export const getIsFetching = state => state.file.isFetching;
export const getImageFile = state => state.file.base64;
