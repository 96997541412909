import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import CreateIcon from '@material-ui/icons/Create'
import RestoreIcon from '@material-ui/icons/Restore'
import VisibilityIcon from '@material-ui/icons/Visibility'
import clsx from 'clsx'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import * as PropTypes from 'prop-types'
import ImageEditDialog from '../../components/ImageEditDialog'

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>{children}</div>}
    </Typography>
  )
}

const a11yProps = ({ index }) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const ImageComponent = (
  {
    base64,
    loading,
    classes,
    onClick,
    disableEdit,
    thumbnailImg,
    onChange,
  },
) => {
  const [downloadOverlay, setDownloadOverlay] = useState('')
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const [activeEditDialog, showEditDialog] = useState(false)
  const [changedBase64, setChangedBase64] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleHover = event => {
    setDownloadOverlay('overlay')
  }
  const handleLeave = event => {
    setDownloadOverlay('')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnChange = (base64) => {
    setChangedBase64(base64);
    onChange(base64);
  }

  const handleOnReset = () => {
    setChangedBase64(null);
    onChange(null);
  }

  const clickImageHandler = () => {
    if (base64 && !loading) {
      onClick(changedBase64 || base64)
    }
  }

  const imagePreview = thumbnailImg && thumbnailImg.map((obj, index) => (
    <TabPanel key={index} value={value} index={index} onClick={handleClose}>
      <img src={obj.link} alt="" style={{
        backgroundColor: 'rgb(192,192,192, 0.5)',
        margin: '20px',
      }}/>
    </TabPanel>
  ))

  const imageEditor = !disableEdit && (
    <ImageEditDialog
      open={activeEditDialog}
      onClose={() => showEditDialog(false)}
      imageUrl={changedBase64 || base64}
      onSave={handleOnChange}
    />
  );

  const editButton = !disableEdit && (
    <CreateIcon
      fontSize="large"
      className={classes.overlayIcon}
      onClick={() => showEditDialog(true)}
    />
  );

  const resetButton = (!disableEdit && !!changedBase64) && (
    <RestoreIcon
      fontSize="large"
      className={classes.overlayIcon}
      onClick={handleOnReset}
    />
  );

  const thumbnailListDialog = !!thumbnailImg && (
    <Dialog
      fullScreen
      open={open}
      style={{ padding: '20px' }}
    >
      <div className={classes.previewImage} onClick={handleClose}>
        <div className={classes.previewInner}>
          {imagePreview}
        </div>
      </div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {thumbnailImg && thumbnailImg.map((obj, index) => (
            <Tab
              key={obj.id}
              index={index}
              label={<img src={obj.link} alt={obj.size_name} style={{
                width: '200px',
                height: '150px',
                backgroundColor: 'rgb(192,192,192, 0.5)',
              }}/>}
              icon={obj.size_name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
    </Dialog>
  )

  const previewDialog = !thumbnailImg && (
    <Dialog
      fullScreen
      open={open}
      style={{ padding: '20px' }}
    >
      <div className={classes.previewImage} onClick={handleClose}>
        <div className={classes.previewInner}>
          <TabPanel onClick={handleClose}>
            <img src={changedBase64 || base64} alt="" style={{
              backgroundColor: 'rgb(192,192,192, 0.5)',
              margin: '20px',
            }}/>
          </TabPanel>
        </div>
      </div>

    </Dialog>
  );

  const content = loading ?
    <Box className={classes.circularWrapper}>
      <CircularProgress/>
    </Box> :
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.downloadOverlay, classes[downloadOverlay])}>
        <SystemUpdateAltIcon fontSize="large" className={classes.overlayIcon}
                             onClick={clickImageHandler}/>
        {editButton}
        {resetButton}
        <VisibilityIcon fontSize="large" className={classes.overlayIcon}
                        onClick={handleClickOpen}/>
        {thumbnailListDialog}
        {previewDialog}
      </Box>
      <img src={changedBase64 || base64}/>
      {imageEditor}
    </Box>

  return (
    <Box
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      className={classes.wrapper}
    >
      {content}
    </Box>
  )
}

ImageComponent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
}

export default ImageComponent


