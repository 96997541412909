import {
    inviteUser as apiInviteUser,
    fetchUser as apiFetchUser,
    updateUser as apiUpdateUser,
    blockUser as apiBlockUser,
    deleteUser as apiDeleteUser,
    apiRequest,
} from "../../helpers/api";
import {
    BLOCK_USER_FAILURE,
    BLOCK_USER_REQUEST,
    BLOCK_USER_SUCCESS, DELETE_USER_FAILURE, DELETE_USER_REQUEST, DELETE_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FLUSH_USER_ERRORS, FLUSH_USER_MESSAGES, FLUSH_USER_STATE,
    INVITE_USER_FAILURE,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS, REMOVE_USER_FROM_LIST,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS
} from "../constants/user";

export const inviteUser = (email, phone) => dispatch => {
    dispatch({type: INVITE_USER_REQUEST});
    return apiInviteUser({
        email: email,
        phone: phone
    }).then(
        res => {
            if (res.data.status === "error") {
                return dispatch({
                    type: INVITE_USER_FAILURE,
                    message: res.data.message
                });
            }
            return dispatch({
                type: INVITE_USER_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: INVITE_USER_FAILURE,
            payload: error.response.data.message
        })
    )
};

export const getUser = id => dispatch => {
    dispatch({type: FETCH_USER_REQUEST});
    return apiFetchUser(id).then(
        res => {
            // TODO: report of missing status in response
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_USER_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_USER_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_USER_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const updateUser = (data) => dispatch => {
    dispatch({type: UPDATE_USER_REQUEST});

    const request = () => apiUpdateUser(data);

    return apiRequest(request, dispatch, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE);
};

export const flushUserErrors = () => dispatch => {
    dispatch({type: FLUSH_USER_ERRORS});
};

export const flushUserState = () => dispatch => {
    dispatch({type: FLUSH_USER_STATE});
};

export const flushUserMessages = () => dispatch => {
    dispatch({type: FLUSH_USER_MESSAGES});
};

export const blockUser = (id, status) => dispatch => {
    dispatch({type: BLOCK_USER_REQUEST});
    return apiBlockUser(id, status).then(
        res => {
            if (!res.data || !!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: BLOCK_USER_FAILURE,
                    payload: res.data,
                });
            }
            return dispatch({
                type: BLOCK_USER_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: BLOCK_USER_FAILURE,
            payload: error.response.data.errors
        })
    );
};

export const deleteUser = (id) => dispatch => {
    dispatch({type: DELETE_USER_REQUEST});
    const request = () => apiDeleteUser(id);

    return apiRequest(request, dispatch, DELETE_USER_SUCCESS, DELETE_USER_FAILURE);
};

export const removeUserFromList = (id) => dispatch => {
  dispatch({
      type: REMOVE_USER_FROM_LIST,
      payload: id
  })
};
