import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import WarningIcon from '@material-ui/icons/Warning'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const UnknownFile = ({ item, classes }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <WarningIcon />
      </ListItemIcon>
      <ListItemText primary={item.name}/>
    </ListItem>
  )
}

export default UnknownFile
