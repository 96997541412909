import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import {
  flushVideoPlaylistErrors,
  removePlaylistFromList,
} from '../../store/actions/playlist'
import { useVideo } from '../../hooks/video'
import {
  deletePlaylist,
  flushVideoPlaylist,
  updatePlaylist,
} from '../../store/actions/playlist'
import { useSnackbar } from 'notistack'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import LoadingFade from '../../common/LoadingFade'

import { makeStyles } from '@material-ui/core'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import EventVideoItem
  from '../../pages/Events/EventsSingle/EventSingleItems/EventVideoItem'
import List from '@material-ui/core/List'
import arrayMove from 'array-move'
import AddItem
  from '../../pages/Events/EventsSingle/EventSingleItems/EventVideo/AddItem'
import VideoModal from '../Modal/VideoModal'

const initialState = {
  name: '',
  videos: [],
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
  },
  list: {
    width: "100%"
  }
}));

const SortableItem = SortableElement(
  ({value, item, onRemove}) =>
    <EventVideoItem
      value={value}
      item={item}
      src={item.thumbnail}
      onRemove={onRemove}
      draggable={true}
    />
);

const SortableList = SortableContainer(({items, onRemove}) => {
  const classes = useStyles();
  return (
    <List className={classes.list} >
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          value={items}
          item={item}
          onRemove={onRemove}
        />
      ))}
    </List>
  );
});

const SingleVideo = ({ classes }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { playlist, loading } = useVideo(id)
  const { enqueueSnackbar } = useSnackbar()
  const [nameError, changeNameError] = useState(false)
  const [state, setState] = useState(initialState)
  const [data, setData] = useState('')
  const [open, setOpen] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const isFetch = useSelector(state => state.playlist.isFetching)
  const name = playlist.name

  const closeLink = () => {
    dispatch(flushVideoPlaylistErrors())
    dispatch(flushVideoPlaylist())
    history.push('/videos/playlists')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeFields = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const handleChange = (value) => {
    setState({
      ...state,
      videos: [].concat(value),
    })
  }

  useEffect(() => {
    setState({
      ...state,
      name: name,
      videos: playlist.videos,
    })
  }, [name])

  useEffect(() => {
    return () => {
      dispatch(flushVideoPlaylist())
    }
  }, [])

  const deleteVideoPlaylist = () => {
    dispatch(deletePlaylist(playlist.id)).then(
      res => {
        enqueueSnackbar(res.data.message, { variant: 'success' })
        dispatch(removePlaylistFromList(playlist.id))
        closeLink()
      },
    ).catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' })
      },
    )
  }

  const updateVideoPlaylistName = (id) => {
    const videos = state.videos.map(element => element.id)
    dispatch(updatePlaylist({
      id: playlist.id,
      name: state.name,
      videos,
    })).then(
      res => {
        enqueueSnackbar(res.data.message, { variant: 'success' })
      },
    ).catch(err => {
        enqueueSnackbar(err.data.message, { variant: 'error' })
      },
    )
    setData(state.name)
  }

  const disableName = playlist.name === ''

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={isFetch}/>
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={closeLink}
                      className={classes.closeButton}><ArrowBackIcon/></IconButton>
          <Box className={classes.container}>
            <Typography>
              Playlist - {` ${playlist.name}`}
            </Typography>
          </Box>
        </Toolbar>
        <Divider/>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <form>
                <div className={classes.textField}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={nameError}
                    className={classes.inputPadding}
                    value={state.name || ''}
                    onChange={handleChangeFields('name')}
                    disabled={loading}
                  />
                </div>
              </form>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <SortableList
                items={state.videos}
                onRemove={(newValue) => handleChange(newValue)}
                onSortEnd={({oldIndex, newIndex}) => {
                  handleChange(arrayMove(state.videos, oldIndex, newIndex))
                }}
              />

              <AddItem onClick={() => setOpenAdd(true)}/>
              <VideoModal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                selected={state.videos}
                onSuccess={(newValue) => {
                  handleChange(newValue);
                  setOpenAdd(false)
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Divider/>
        <Container className={classes.actions}>
          <Grid container spacing={3}>
            <Grid item lg={6} className={classes.actionsBlock}>
              <Button
                color="secondary"
                variant="contained"
                disabled={loading || state.name === ''}
                onClick={() => handleClickOpen()}
              >
                Delete
              </Button>
              <ConfirmDeleteDialog
                open={open}
                onClose={handleClose}
                id={id}
                title={name}
                deleteItemAction={deleteVideoPlaylist}
              />
            </Grid>
            <Grid item lg={6} className={classes.actionsBlock}>
              <Button
                color="primary"
                variant="contained"
                onClick={updateVideoPlaylistName}
                disabled={loading || disableName}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  )
}

SingleVideo.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default SingleVideo
