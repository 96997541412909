import React, {Fragment, useMemo} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button/Button";
import ConfirmDeleteDialog from "../../../components/ConfirmDeleteDialog";
import {reduxForm} from "redux-form";
import Title from "./EventSingleItems/Title";
import Switcher from "./EventSingleItems/Swithcer";
import EventType from "./EventSingleItems/EventType";
import StartDate from "./EventSingleItems/StartDate";
import EventVideos from "./EventSingleItems/EventVideos";
import EventImages from "./EventSingleItems/EventImages";
import LoadingFade from "../../../common/LoadingFade";
import {useSelector} from "react-redux";
import getFormValues from "redux-form/es/getFormValues";
import useToggle from "../../../hooks/useToggle";
import StatusChip from '../../../common/StatusChip'
import EventStreams from './EventSingleItems/EventStreams'

const EventSingleInner = (
  {
    item,
    initialValue,
    eventId,
    isFetching,
    historyChange,
    classes,
    handleSubmit,
    handleRemove,
    title,
    createdAt,
    dirty,
    disabled,
    form
  }
) => {

  const [open, handleToggle] = useToggle();

  const formValues = useSelector(getFormValues(form));

  const minDate = useMemo(() => {
    return formValues.start_at
  }, [formValues]);

  const maxDate = useMemo(() => {
    return formValues.end_at
  }, [formValues]);

  const invalid = useMemo(() => {
    return (formValues.streams.length === 0 && formValues.videos.length === 0) ||
      !formValues.start_at ||
      !formValues.end_at;
  }, [formValues])

  return (
    <Fragment>
      <LoadingFade loading={isFetching}/>
      <Paper>
        <Toolbar className={classes.toolbar}>
          <div>
            <IconButton
              onClick={() => historyChange('/events')}
              className={classes.closeButton}
            ><ArrowBackIcon/></IconButton>
            <Grid container spacing={0} justify="space-between">
              <Grid item>
                <Typography>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <StatusChip status={item.status}/>
              </Grid>
            </Grid>
          </div>
        </Toolbar>

        <Divider/>

        <Grid container spacing={1} className={classes.root}>
          <Grid item sm={8}>
            <div className={classes.inputWrap}>
              <Title classes={classes} disabled={disabled}/>
              <EventImages classes={classes} disabled={disabled}/>
              <EventVideos disabled={disabled}/>
              <EventStreams disabled={disabled}/>
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={classes.inputWrap}>
              <Switcher classes={classes} disabled={disabled || item.status === 'malformed'}/>
              <EventType classes={classes} disabled={disabled}/>
              <StartDate classes={classes} name={"start_at"} label={"Start Date"} disabled={disabled}/>
              <StartDate classes={classes} name={"end_at"} label={"End Date"} minDate={minDate} disabled={disabled}/>
              <StartDate classes={classes} name={"created_at"} disabled={true} label={"Create Date"}/>
            </div>
          </Grid>
        </Grid>
        <Divider/>
        <div className={classes.btnWrap}>
          <Button
            color="primary"
            variant="contained"
            disabled={isFetching || !dirty || invalid || disabled}
            onClick={handleSubmit}
          >
            Save
          </Button>
          {eventId && (
          <Button
          color="secondary"
          variant="contained"
          disabled={isFetching}
          onClick={handleToggle}
          >
          Delete
          </Button>
          )}

        </div>
        {eventId && (
          <ConfirmDeleteDialog
            open={open}
            onClose={handleToggle}
            id={'111'}
            title={title}
            deleteItemAction={handleRemove}
          />
        )}
      </Paper>

    </Fragment>
  );
};

export default reduxForm({
  form: "singleEvent",
  enableReinitialize: true,
})(EventSingleInner);
