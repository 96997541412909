import { useEffect, useState } from 'react'
import { videoGetFrame } from '../helpers/api'
import md5 from "md5";

const makeTimestamps = (video, count) => {
   const duration = Math.round(video.duration * 1000);
   const step = Math.round(duration / (count + 1));
   let result = [];
   for (let i = 1; i <= count; i++) {
     result.push(step * i);
   }

   return result;
}

const useVideoFrames = (video, timestamps = []) => {
  const [frames, setFrames] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if(timestamps.length === 0) {
      timestamps = makeTimestamps(video, 4)
    }
    Promise.all(
      timestamps.map(
        timestamp => videoGetFrame({id: video.id, timestamp})
      )
    ).then(
      (results) => {
        setFrames(
          results
          .map(response => response.data.data)
          .map(dataUrl => ({id: md5(dataUrl), dataUrl}))
        )
      }
    ).finally(
      () => {
        setIsFetching(false);
      }
    )
  }, [video])

  return [frames , isFetching];
};
export default useVideoFrames;
