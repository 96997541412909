import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const ImageEmpty = ({classes, onClick}) => {
  return (
    <div className={classes.root} onClick={onClick}>
      <AddCircleOutlineIcon className={classes.icon}/>
    </div>
  );
};

export default ImageEmpty;
