export const styles = theme => ({
    logoWrapper: {
        // width: theme.spacing(12),
        height: 'auto',
        padding: theme.spacing(),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&>img': {
            margin: 'auto',
            width: '100%',
        }
    }
});
