import React, { useCallback, useMemo, useState } from 'react'
import Button from '@material-ui/core/Button/Button'
import clsx from 'clsx'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Box from '@material-ui/core/Box/Box'

const PublishStatusEvent = ({ id, status, classes, handleChangeStatus }) => {

  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  const handleChange = useCallback((value) => {
    handleChangeStatus(id, value)
    handleClose()
  }, [handleChangeStatus, id])

  const approveAction = useMemo(() => {
    return status !== 'malformed' && (
      <MenuItem onClick={() => handleChange('approve')}>{'Approve'}</MenuItem>
    )
  }, [status, handleChange])

  return (
    <Box>
      <Button
        onClick={onClick}
        className={clsx(
          classes.statusBadge,
          classes[status] || {},
        )}
      >
        {status}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {approveAction}
        <MenuItem onClick={() => handleChange('remove')}>{'Remove'}</MenuItem>
      </Menu>
    </Box>
  )
}

export default PublishStatusEvent
