import axios from 'axios'
import {stringify} from 'query-string'
import configureStore from '../store/configureStore'
import localStorageHelper from '../store/helpers/localStorage'
import { logoutAction } from '../store/actions/auth'

export const apiUrl = process.env.MIX_API_URL

const axiosInstance = axios.create({
  baseURL: apiUrl,
})

axiosInstance.interceptors.request.use((config) => {
  // TODO: implement use expires_in
  const token = localStorageHelper.get('access_token')

  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 &&
      error.response.config.url.indexOf('logout') === -1) {
      const appStore = configureStore()
      appStore.dispatch(logoutAction())
      return
    }

    return Promise.reject(error)
  })

export default axiosInstance

export const checkInternetConnection = () => {
  return axiosInstance.get('/auth/status')
}

export const apiRequest = (request, dispatch, onSuccess, onFail) => {

  return new Promise((resolve, reject) => {
    request().then((res) => {
        if (!!res.data.errors) {
          dispatch({
            type: onFail,
            payload: res.data.errors,
          })
          reject(res)
        }
        dispatch({
          type: onSuccess,
          payload: res.data,
        })
        resolve(res)

      },
      error => {
        dispatch({
          type: onFail,
          payload: !!error.response ? error.response.data.errors : error,
        })
        reject(error.response)
      }).catch(error => {
      dispatch({
        type: onFail,
        payload: !!error.response ? error.response.data.errors : error,
      })
    })
  })
}

const getResource = (url, params) => {
  const {
    page,
    search,
    status,
    dates,
    orderBy,
    order,
    limit,
    taxonomies,
  } = params
  // TODO: improve query building
  let query = '?'
  query += `page=${page}&`
  query += !!limit ? `limit=${limit}` : ''
  query += order === 'asc' ? '&order=asc' : '&order=desc'
  query += !!orderBy ? `&filter=${orderBy}` : ''
  query += !!search ? `&search=${search}` : ''
  query += !!status ? `&status=${status}` : ''
  query += !!dates && dates.from ? `&from=${dates.from}` : ''
  query += !!dates && dates.to ? `&to=${dates.to}` : ''
  query += !!taxonomies && taxonomies.categories.length
    ? `&cat_id=${JSON.stringify(taxonomies.categories.map(item => item.id))}`
    : ''
  query += !!taxonomies && taxonomies.tags.length ? `&tag_id=${JSON.stringify(
    taxonomies.tags.map(item => item.id))}` : ''

  return axiosInstance.get(url + query)
}

export const userLogin = (data) => {
  return axiosInstance.post('/auth/login', data)
}

// TODO: implement logout on api
export const userLogout = () => {
  return axiosInstance.post('/auth/logout')
}

export const checkHash = (endpoint, data) => {
  return axiosInstance.post(endpoint, data)
}

export const getAccessToken = data => {
  return axiosInstance.post('/auth/code', data)
}

export const resetPassword = data => {
  return axiosInstance.post('/auth/forgot_password', data)
}

export const checkChangePasswordToken = token => {
  return axiosInstance.post('/auth/forgot_password_verify', { token: token })
}

export const setNewUserPassword = data => {
  return axiosInstance.post('/auth/reset_password', data)
}

export const setFirstPassword = data => {
  return axiosInstance.post('/auth/invitation_approve', data)

}

export const loadAdmins = (
  page, orderBy, order, limit, search, status, from, to) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
    status: status,
    from: from,
    to: to,
  }
  return getResourceNew('/users', params)
}

export const inviteUser = data => {
  return axiosInstance.post('/users/invite', data)
}

export const fetchUser = id => {
  return axiosInstance.get(`/users/${id}`)
}

export const updateUser = data => {
  if (typeof data.blocked !== 'undefined') {
    data.status = !data.blocked ? 'active' : 'blocked'
  }
  return axiosInstance.put(`/users/${data.user_id}`, data)
}

export const blockUser = (user_id, blocked) => {
  return updateUser({ user_id, blocked })
}

const getResourceNew = (url, params) => {
  const {
    page,
    search,
    status,
    orderBy,
    order,
    limit,
    from,
    to,
    categories,
    tags,
    excepts,
  } = params
  // TODO: improve query building

  const query = stringify(
    {
      page,
      search: !!search && search || undefined,
      status,
      orderBy,
      order,
      limit,
      from,
      to,
      categories: categories && categories.length && categories.map(item => item.id) || undefined,
      tags: tags && tags.length && tags.map(item => item.id) || undefined,
      excepts: excepts && excepts.length && excepts || undefined,
    }
  )

  // let query = '?'
  // query += `page=${page}&`
  // query += !!limit ? `limit=${limit}` : ''
  // query += order === 'asc' ? '&order=asc' : '&order=desc'
  // query += !!orderBy ? `&orderBy=${orderBy}` : ''
  // query += !!search ? `&search=${search}` : ''
  // query += !!status ? `&status=${status}` : ''
  // query += !!from && from ? `&from=${from}` : ''
  // query += !!to && to ? `&to=${to}` : ''
  // // query += !!categories && categories.length ? `&cat_id=${JSON.stringify(categories.map(item => item.id))}` : '';
  // query += !!categories && categories.length ? categories.map(
  //   item => `&categories[]=${item.id}`).join('') : ''
  // query += !!excepts && excepts.length ? excepts.map(
  //   item => `&excepts[]=${item}`).join('') : ''
  // // query += !!tags && tags.length ? `&tag_id=${JSON.stringify(tags.map(item => item.id))}` : '';
  // query += !!tags && tags.length ? tags.map(item => `&tags[]=${item.id}`).
  //   join('') : ''
  return axiosInstance.get(url + '?' +query)
}

const getList = (url, query) => {
  return axiosInstance.get(url + '?' + query)
}

export const loadImagesList = (
  page, orderBy, order, limit, search, status, from, to, categories, tags) => {
  const params = {
    page: page,
    search: search,
    status: status,
    orderBy: orderBy,
    order: order,
    limit: limit,
    from: from,
    to: to,
    categories: categories,
    tags: tags,
  }
  return getResourceNew('/images', params)
}

export const loadCategoriesList = (page, orderBy, order, limit, search) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/images/categories/filter', params)
}

export const addCategory = data => {
  return axiosInstance.post('/images/categories', data)
}

export const updateCategory = data => {
  return axiosInstance.put(`/images/categories/${data.category_id}`, data)
}

export const deleteCategory = id => {
  return axiosInstance.delete(`/images/categories/${id}`)
}

export const fetchCategory = id => {
  return axiosInstance.get(`/images/categories/${id}`)
}

export const fetchImage = id => {
  return axiosInstance.get(`/images/${id}`)
}

export const fetchImageBase64 = id => {
  return axiosInstance.get(`/images/${id}/data_url`)
}

export const updateImage = data => {
  return axiosInstance.put(`/images/${data.id}`, data)
}

export const deleteImage = id => {
  return axiosInstance.delete(`/images/${id}`)
}

export const deleteUser = id => {
  return axiosInstance.delete(`/users/${id}`)
}

export const fetchAllImagesCategories = () => {
  return axiosInstance.get('/images/categories')
}

export const fetchAllImagesTags = () => {
  return axiosInstance.get('/images/tags')
}

export const fetchAllVideosCategories = () => {
  return axiosInstance.get('/videos/categories')
}

export const fetchAllVideosTags = () => {
  return axiosInstance.get('/videos/tags')
}

export const eventUpdate = (data) => {
  return axiosInstance.put(`/events/${data.id}`, data)
}

export const loadSingleEvent = (eventId) => {
  return axiosInstance.get(`/events/${eventId}`)
}

export const eventCreate = (data) => {
  return axiosInstance.post('/events', data)
}

export const eventBulkUpdate = (data) => {
  switch (data.status) {
    case "publish":
      return axiosInstance.post('/events/bulk/publish', data);
    case "unpublish":
      return axiosInstance.post('/events/bulk/unpublish', data);
    case "delete":
      return axiosInstance.post('/events/bulk/delete', data);
  }
}

export const eventDelete = (eventId) => {
  return axiosInstance.delete(`/events/${eventId}`)
}

export const streamUpdate = (data) => {
  return axiosInstance.put(`/streams/${data.id}`, data)
}

export const loadSingleStream = (eventId) => {
  return axiosInstance.get(`/streams/${eventId}`)
}

export const streamCreate = (data) => {
  return axiosInstance.post('/streams', data)
}

export const streamDelete = (eventId) => {
  return axiosInstance.delete(`/streams/${eventId}`)
}

export const createImage = data => {
  let formData = new FormData()
  formData.append(`file`, data.file)
  formData.append('title', data.title)
  formData.append('description', data.description)
  return axiosInstance.post('/images/tmp', formData)
}

export const loadTagsList = (page, orderBy, order, limit, search) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/images/tags/filter', params)
}

export const addTag = data => {
  return axiosInstance.post('/images/tags', data)
}

export const updateTag = data => {
  return axiosInstance.put(`/images/tags/${data.tag_id}`, data)
}

export const deleteTag = id => {
  return axiosInstance.delete(`/images/tags/${id}`)
}

export const fetchTag = id => {
  return axiosInstance.get(`/images/tags/${id}`)
}

export const loadVideosPlaylist = (page, orderBy, order, limit, search) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/videos/playlists/filter', params)
}

export const addVideoPlaylist = data => {
  return axiosInstance.post('/videos/playlists', data)
}

export const fetchVideoPlaylist = id => {
  return axiosInstance.get(`/videos/playlists/${id}`)
}

export const updateVideoPlaylist = data => {
  return axiosInstance.put(`/videos/playlists/${data.id}`, data)
}

export const deleteVideoPlaylist = id => {
  return axiosInstance.delete(`/videos/playlists/${id}`)
}

export const loadVideosCategoryList = (page, orderBy, order, limit, search) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/videos/categories/filter', params)
}

export const addVideoCategory = data => {
  return axiosInstance.post('/videos/categories', data)
}

export const updateVideoCategory = data => {
  return axiosInstance.put(`/videos/categories`, data)
}

export const deleteVideoCategory = id => {
  return axiosInstance.delete(`/videos/categories/${id}`)
}

export const fetchVideoCategory = id => {
  return axiosInstance.get(`/videos/categories/${id}`)
}

export const loadVideosTagsList = (page, orderBy, order, limit, search) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/videos/tags/filter', params)
}

export const addVideoTag = data => {
  return axiosInstance.post('/videos/tags', data)
}

export const updateVideoTag = data => {
  return axiosInstance.put(`/videos/tags/${data.tag_id}`, data)
}

export const deleteVideoTag = id => {
  return axiosInstance.delete(`/videos/tags/${id}`)
}

export const fetchVideoTag = id => {
  return axiosInstance.get(`/videos/tags/${id}`)
}

export const loadPendingImagesList = (
  page, orderBy, order, limit, search, status, dates) => {
  const params = {
    page: page,
    search: search,
    status: status,
    dates: dates,
    orderBy: orderBy,
    order: order,
    limit: limit,
  }
  return getResource('/images/tmp', params)
}

export const approveSinglePendingImage = data => {
  return axiosInstance.put(`/images/tmp/${data.hash}`, data)
}

export const loadVideosList = (
  page, orderBy, order, limit, search, from, to, categories, tags, excepts, status
) => {
  const params = {
    page: page,
    search: search,
    orderBy: orderBy,
    order: order,
    limit: limit,
    from: from,
    to: to,
    categories: categories,
    tags: tags,
    excepts: excepts,
    status,
  }

  return getResourceNew('/videos', params)
}

export const loadEventsList = (url, query) => {
  return getList(url, query)
}

export const loadStreamsList = (url, query) => {
  return getList(url, query)
}

export const fetchVideo = id => {
  return axiosInstance.get(`/videos/${id}`)
}

export const updateVideo = data => {
  return axiosInstance.put(`/videos/${data.video_id}`, data)
}

export const deleteVideo = id => {
  return axiosInstance.delete(`/videos/${id}`)
}

export const videoGetFrame = data => {
  return axiosInstance.get(
    `/videos/${data.id}/frame?timestamp=${data.timestamp}`)
}

export const bulkActionImagesInUse = ({ type, ids }) => {
  switch (type) {
    case 'approve':
      return axiosInstance.post('/images/bulk/approve', { ids })
    case 'block':
      return axiosInstance.post('/images/bulk/block', { ids })
    case 'delete':
      return axiosInstance.post('/images/bulk/remove', { ids })
  }
  return null
}

export const bulkActionVideos = (data) => {
  return axiosInstance.post('/videos/bulk_delete', data)
}

export const bulkActionApproveImagesPending = ({ hashes }) => {
  return axiosInstance.post('/images/tmp/bulk/approve', { hashes })
}

export const bulkActionRemoveImagesPending = ({ hashes }) => {
  return axiosInstance.post('/images/tmp/bulk/remove', { hashes })
}

export const fetchUserJobs = (userId) => {
  return axiosInstance.get(`/images/jobs/${userId}`)
}

export const bulkActionRemoveImagesCategories = (data) => {
  return axiosInstance.post('/images/categories/bulk_delete', data)
}

export const bulkActionRemoveVideoPlayLists = (data) => {
  return axiosInstance.post('/videos/playlists/bulk_delete', data)
}

export const bulkActionRemoveVideoCategories = (data) => {
  return axiosInstance.post('/videos/categories/bulk_delete', data)
}

export const bulkActionRemoveVideoTags = (data) => {
  return axiosInstance.post('/videos/tags/bulk_delete', data)
}

export const bulkActionRemoveImagesTags = (data) => {
  return axiosInstance.post('/images/tags/bulk_delete', data)
}

export const fetchPendingImageData = (imageHash) => {
  return axiosInstance.get(`/images/tmp/${imageHash}`)
}

export const fetchPendingImageBase64 = (imageHash) => {
  return axiosInstance.get(`/images/tmp/${imageHash}/data_url`)
}

export const fetchAllCounts = (data) => {
  return axiosInstance.get(`/count`, data)
}

export const loadPendingEventsList = (query) => {
    return getList(`/events/tmp`, query);
};

export const removePendingEvent = (data) => {
    return axiosInstance.post(`/events/tmp/bulk_delete`, data);
};

export const updatePendingEvent = (data) => {
    return axiosInstance.post(`/events/tmp/bulk_approve`, data);
};

export const bulkRemoveStream = (data) => {
  return axiosInstance.post(`/streams/bulk_delete`, data);
};
