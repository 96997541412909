export const styles = theme => ({
    dropZone: {
        width: '200px',
        height: '200px',
        backgroundColor: "#f5f5f5",
        '&>input': {
            width: '100%',
            height: '100%'
        }
    }
});
