import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchAllVideosCategories, fetchAllVideosTags} from "../store/actions/taxonomies";
import {videosTaxonomiesSelector, getIsFetching} from "../store/reducers/taxonomies";


//TODO: create common hook for taxonomies
export const useVideosTaxonomies = () => {
    const dispatch = useDispatch();
    const {videosCategories, videosTags} = useSelector(videosTaxonomiesSelector);
    const loading = useSelector(getIsFetching);

    useEffect(() => {
        if (!videosCategories.length) {
            dispatch(fetchAllVideosCategories());
        }
        if (!videosTags.length) {
            dispatch(fetchAllVideosTags());
        }
    }, [dispatch]);

    return {
        videosCategories,
        videosTags,
        loading
    };
};
