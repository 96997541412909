import React, {useCallback, useMemo} from 'react';
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import TableSortLabel from '@material-ui/core/TableSortLabel';

import useToggle from "../../../hooks/useToggle";

const ResourceListHeader = ({selectedItems, items, handleChangeSelectedAll, headers, order, handleChangeOrder}) => {
  const isSelected = selectedItems.length === items.length;

  const onChange = useCallback(() => {
    handleChangeSelectedAll();
  }, [selectedItems, handleChangeSelectedAll, isSelected]);

  const bulk = useMemo(() => {
    if (typeof selectedItems === "undefined" || typeof handleChangeSelectedAll !== "function") {
      return null;
    }

    return <TableCell padding="checkbox">
      <Checkbox
        checked={isSelected}
        color="primary"
        onChange={() => onChange()}
      />
    </TableCell>

  }, [selectedItems, handleChangeSelectedAll, isSelected, onChange]);

  return (
    <TableHead>
      <TableRow
      >
        {bulk}
        {headers.map(header => (
          <TableCell
            align="left"
            key={header.name}
          >
            {header.ordered
              ? <TableSortLabel
                active={order.filter === header.name}
                direction={order.filter === header.name ? order.order : 'asc'}
                onClick={() => handleChangeOrder(header.name, order.order === 'asc' ? 'desc' : 'asc')}
              >
                {header.label}
              </TableSortLabel>
              : header.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ResourceListHeader;