import {createSelector} from "reselect";
import {
    BLOCK_USER_SUCCESS,
    INVITE_USER_SUCCESS,
    REMOVE_USER_FROM_LIST,
    UPDATE_USER_SUCCESS
} from "../constants/user";
import {LOAD_ADMINS_FAILURE, LOAD_ADMINS_REQUEST, LOAD_ADMINS_SUCCESS} from "../constants/admins";

const initialState = {
    isFetching: false,
    admins: [],
    errors: false,
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'id',
    limit: 10,
    filterDates: {
        from: '',
        to: ''
    },
    filterStatus: '',
};

const admins = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_ADMINS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_ADMINS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload
            };
        case LOAD_ADMINS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                admins: payload.data.data,
                page: payload.data.current_page,
                count: payload.data.total,
                limit: parseInt(payload.data.per_page)
            };
        case INVITE_USER_SUCCESS:
            let newUser = _.cloneDeep(payload.data);
            if (!newUser.status) {
                newUser.status = "pending";
            }
            return {
                ...state,
                admins: [newUser].concat(state.admins).slice(0, state.limit)
            };
        case REMOVE_USER_FROM_LIST:
            return {
                ...state,
                admins: state.admins.filter(admin => admin.id !== payload),
            };
        case BLOCK_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                admins: state.admins.map((admin) => admin.id === data.id ? data : admin)
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                filterDates: initialState.filterDates,
                filterStatus: '',
            };
    }
};

export default admins;

export const getIsFetching = state => state.admins.isFetching;
export const getAdminsArr = state => state.admins.admins;
export const getAdminsCount = state => state.admins.count;
export const getAdminsPage = state => state.admins.page;
export const getAdminsLimit = state => state.admins.limit;
export const getAdminsOrder = state => state.admins.order;
export const getAdminsOrderBy = state => state.admins.orderBy;
export const getAdminsErrors = state => state.admins.orderBy;
export const getAdminsFilterStatus = state => state.admins.filterStatus;
export const getAdminsFilterDates = state => state.admins.filterDates;

export const adminsSelector = createSelector([
        getAdminsArr,
        getIsFetching,
        getAdminsPage,
        getAdminsOrder,
        getAdminsOrderBy,
        getAdminsLimit,
        getAdminsCount,
        getAdminsFilterStatus,
        getAdminsFilterDates,
    ],
    (admins,
     isLoading,
     page,
     order,
     orderBy,
     limit,
     count,
     filterStatus,
     filterDates,
    ) => {
        return {
            admins,
            isLoading,
            page,
            order,
            orderBy,
            limit,
            count,
            filterStatus,
            filterDates,
        }
    });



