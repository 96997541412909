import React, {Fragment, useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {inviteUser} from "../../store/actions/user";
import {connect} from "react-redux";
import FlashMessage from "react-flash-message";
import {getInviteResult, getIsFetching, getUserErrors, getUserMessage} from "../../store/reducers/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import DialogMessages from "../../common/DialogMessages";

const {success} = DialogMessagesStatuses;

const InviteDialog = ({
                          open,
                          onClose,
                          inviteUserAction,
                          inviteErrors,
                          isFetching,
                          classes,
                          inviteResult,
                          inviteMessage,
                      }) => {
    const [userEmail, setUserEmail] = useState('');
    const [inviteStatus, setInviteStatus] = useState('')
    const [errors, setErrors] = useState('');

    const inviteAction = (e) => {
        e.preventDefault();
        // TODO: implement on submit verification
        // TODO: add include new item in current list reducer
        inviteUserAction(userEmail);
    };
    const onChangeUserEmail = (e) => {
        setUserEmail(e.target.value)
    };

    const closeDialog = () => {
        setUserEmail('');
        setInviteStatus('');
        onClose();
    };
    useEffect(() => {
        setErrors(inviteErrors);
    }, [inviteErrors]);

    useEffect(() => {
        setErrors('');
    }, [userEmail]);

    useEffect(() => {
        setInviteStatus(inviteResult);
    }, [inviteResult]);

    const DialogMessage = () => (
        <Fragment>
            <DialogMessages status={inviteStatus} message={inviteMessage}/>
            <DialogActions className={classes.actionsBlock}>
                <Button
                    onClick={closeDialog}
                    color="primary"
                    variant="contained"
                >
                    Ok
                </Button>
            </DialogActions>
        </Fragment>
    );

    return (
        <div>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <form onSubmit={inviteAction}>
                    <DialogTitle id="form-dialog-title">Invite user</DialogTitle>
                    {inviteStatus !== success ?
                        <Fragment>
                            <DialogContent>
                                {isFetching &&
                                <div className={classes.onFetching}>
                                    <CircularProgress/>
                                </div>}
                                <DialogContentText>
                                    Enter the email address of the user you want to invite.
                                </DialogContentText>
                                <TextField
                                    required
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    value={userEmail}
                                    disabled={isFetching}
                                    onChange={onChangeUserEmail}
                                />
                                {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                                    <h5 className={"alert alert-danger"}>Error: {inviteErrors}</h5></FlashMessage> : ''}
                            </DialogContent>
                            <DialogActions className={classes.actionsBlock}>
                                <Button
                                    onClick={closeDialog}
                                    color="secondary"
                                    variant="contained"
                                    disabled={isFetching}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={isFetching || !!errors}
                                >
                                    Send invite
                                </Button>
                            </DialogActions>
                        </Fragment> : <DialogMessage/>}
                </form>
            </Dialog>
        </div>);
};

InviteDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    inviteUserAction: PropTypes.func.isRequired,
    inviteErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    isFetching: PropTypes.bool.isRequired,
    inviteResult: PropTypes.string,
    inviteMessage: PropTypes.string
};

const mapDispatchToProps = {
    inviteUserAction: inviteUser
};

const mapStateToProps = state => ({
    isFetching: getIsFetching(state),
    inviteErrors: getUserErrors(state),
    inviteResult: getInviteResult(state),
    inviteMessage: getUserMessage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteDialog);
