import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {find} from "lodash/collection";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    details: {
        // display: 'flex',
        // flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        // display: 'flex',
        // alignItems: 'center',
        // paddingLeft: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    thumbnail: {
        height: 100,
        width: 100,
    },
    progressBar: {
        width: '100%',
        marginRight: theme.spacing(2),
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    progressBarItem: {
        height: 16,
    },
    errorMessage: {
        color: theme.palette.error.main
    },
    successMessage: {
        color: theme.palette.success.main
    },
    progressBlock: {
        display: 'flex',
        alignItems: 'center',
    }
}));


const UploadItemCard = ({...props}) => {
    const classes = useStyles();
    const theme = useTheme();
    const {item, onCancel} = props;
    const [progressBar, setVisible] = useState(false)

    useEffect(() => {
        if (item.progress < 100) {
            setVisible(true);
        } else if (item.progress === 100) {
            setTimeout(() => {
                setVisible(false);
            }, 2000);
        }
    }, [item.progress])

    return (
        <Box className={classes.root}>
            {progressBar &&
            <Box className={classes.progressBlock}>
                <div className={classes.progressBar}>
                    <LinearProgress className={classes.progressBarItem} variant="determinate" value={item.progress}/>
                </div>
                <Button onClick={onCancel} variant="contained" color="secondary">Cancel</Button>
            </Box>}
            <div className={classes.details}>
                {item.files.map((file) => {
                    const error = find(item.errors, file.id);
                    return (
                        <Typography key={file.id} component="h6" variant="h6">
                            {file.name} {!!error ? (
                            <span className={classes.errorMessage}> - {error[file.id]}</span>) : ''}
                        </Typography>
                    )
                })}
            </div>

        </Box>
    );
}

export default UploadItemCard;
