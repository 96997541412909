import {createSelector} from 'reselect';
import {DialogMessagesStatuses} from "../../constatns/statuses";
import {
    LOAD_VIDEO_PLAYLIST_SUCCESS,
    LOAD_VIDEO_PLAYLIST_FAILURE,
    LOAD_VIDEO_PLAYLIST_REQUEST,
    LOAD_VIDEO_PLAYLIST_ERRORS
} from "../constants/playlists";
import {UPDATE_VIDEO_PLAYLIST_SUCCESS, REMOVE_VIDEO_PLAYLIST_FROM_LIST} from "../constants/playlist";
import {
    CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST, CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS,
    UPDATE_VIDEO_PLAYLISTS_AFTER_BULK
} from "../constants/bulk";


const initialState = {
    isFetching: false,
    playlists: [],
    errors: false,
    updateErrors: {},
    status: '',
    message: '',
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10,
    filterDates: {
        from: '',
        to: ''
    },
};

const playlists = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_VIDEO_PLAYLIST_REQUEST:
        case CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_VIDEO_PLAYLIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                playlists: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case UPDATE_VIDEO_PLAYLIST_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                video_tags: state.playlists.map(playlist => playlist.id === data.id ? data : playlist)
            };
        case REMOVE_VIDEO_PLAYLIST_FROM_LIST:
            return {
                ...state,
                video_tags: state.playlists.filter(playlist => playlist.id !== payload),
            };
        case LOAD_VIDEO_PLAYLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_VIDEO_PLAYLISTS_AFTER_BULK:
            return {
                ...state,
                playlists: state.playlists.filter(playlist => !payload.includes(playlist.id))
            };
        case LOAD_VIDEO_PLAYLIST_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
                filterDates: initialState.filterDates,
            };
    }
};

export default playlists;

export const getIsFetching = state => state.playlists.isFetching;
export const getVideoPlaylistsOrderBy = state => state.playlists.orderBy;
export const getVideoPlaylistsArr = state => state.playlists.playlists;
export const getVideoPlaylistsCount = state => state.playlists.count;
export const getVideoPlaylistsPage = state => state.playlists.page;
export const getVideoPlaylistsLimit = state => state.playlists.limit;
export const getVideoPlaylistsOrder = state => state.playlists.order;
export const getVideoPlaylistsErrors = state => state.playlists.errors;
export const getVideoPlaylistsResult = state => state.playlists.status;
export const getVideoPlaylistsFilterDates = state => state.playlists.filterDates;

export const videoPlaylistsSelector = createSelector([
    getVideoPlaylistsArr,
    getIsFetching,
    getVideoPlaylistsCount,
    getVideoPlaylistsPage,
    getVideoPlaylistsLimit,
    getVideoPlaylistsOrder,
    getVideoPlaylistsOrderBy,
    getVideoPlaylistsFilterDates
], (playlists,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
    filterDates
) => {
    return {
        playlists,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
        filterDates
    };
});
