import React, {useCallback, useMemo, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import VideoEmptyItem from "../../pages/Events/EventsSingle/EventSingleItems/EventVideo";
import {makeStyles} from "@material-ui/core";
import useToggle from "../../hooks/useToggle";
import ImagesModal from "../Modal/ImagesModal/";
import EventImage from "../../pages/Events/EventsSingle/EventSingleItems/EventImage";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    '& > *': {
      marginRight: "10px",
      marginBottom: "10px"
    }
  },
  swapItem: {
    display: 'flex',
    alignItems: 'center'
  },
  swapButton: {
    display: 'flex',
    height: '55px'
  },
  swapIcon: {
    fontSize: '2rem',
  }
}));

const FormImages = ({input: {value, onChange, onBlur}, meta, label, options, disabled, ...rest}) => {

  const isError = (meta.dirty || meta.touched || meta.submitting) && meta.invalid;
  const classes = useStyles();

  const [open, handleToggle] = useToggle();
  const [targets, setTarget] = useState(null);

  const handleChange = (value) => {
    if(!disabled) {
      onChange(value);
    }
  };

  const handleOpen = useCallback((t) => {
    if(disabled) {
      return;
    }
    setTarget(t);
    handleToggle();
  }, [setTarget, handleToggle]);

  const handleSuccess = useCallback((newValue) => {
    handleOpen(null);
    handleChange({
      ...value,
      [targets]: newValue
    });
  }, [value, targets, handleOpen, onChange]);

  const onRemove = useCallback((key) => {
    handleChange({
      ...value,
      [key]: null
    });
  }, [onChange, value]);

  const mainImage = useMemo(() => {
    if (!value.primary_image) {
      return <VideoEmptyItem onClick={() => handleOpen('primary_image')}/>
    }

    return <EventImage src={value.primary_image.thumbnail} onClick={() => handleOpen('primary_image')} onRemove={onRemove}
                       imageKey={'primary_image'}/>

  }, [value, handleOpen]);


  return (
    <Box className={classes.root}>
      <Grid container wrap={"wrap"} className={classes.root}>
        <Grid item>
          <Typography>Main</Typography>
          {mainImage}
        </Grid>
        <ImagesModal
          orientation="landscape"
          open={!disabled && open}
          onClose={handleToggle}
          onSuccess={handleSuccess}
        />
      </Grid>
      {isError && (
        <Typography color={"error"}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export default FormImages;
