import {
    FETCH_COUNTS_FAILURE,
    FETCH_COUNTS_REQUEST,
    FETCH_COUNTS_SUCCESS
} from "../constants/counts";
import {apiRequest, fetchAllCounts as apiFetchAllCounts} from "../../helpers/api";

export const fetchCounts = () => dispatch => {
    dispatch({type: FETCH_COUNTS_REQUEST});

    const request = () => apiFetchAllCounts();

    return apiRequest(request, dispatch, FETCH_COUNTS_SUCCESS, FETCH_COUNTS_FAILURE);
};
