import React, {useCallback} from 'react';
import Grid from '@material-ui/core/Grid'
import {makeStyles} from "@material-ui/core";
import useToggle from "../../hooks/useToggle";
import VideoModal from "../Modal/VideoModal";
import EventVideoItem from "../../pages/Events/EventsSingle/EventSingleItems/EventVideoItem";
import AddItem from "../../pages/Events/EventsSingle/EventSingleItems/EventVideo/AddItem";
import Typography from "@material-ui/core/Typography/Typography";
import Box from '@material-ui/core/Box';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    // '& > *': {
    //   marginRight: "10px",
    //   marginBottom: "10px",
    //   padding: 0
    // }
  },
  list: {
    width: "100%"
  }
}));

const SortableItem = SortableElement(
  ({value, item, onRemove}) =>
    <EventVideoItem
      value={value}
      item={item}
      src={item.thumbnail}
      onRemove={onRemove}
      draggable={true}
    />
);

const SortableList = SortableContainer(({items, onRemove}) => {
  const classes = useStyles();
  return (
    <List className={classes.list} >
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          value={items}
          item={item}
          onRemove={onRemove}
        />
      ))}
    </List>
  );
});

const FormVideo = ({input: {value, onChange, onBlur}, meta, label, options, disabled, ...rest}) => {
  const isError = (meta.dirty || meta.touched || meta.submitting) && meta.invalid;
  const classes = useStyles();
  const [open, handleToggle] = useToggle();

  const handleChange = (value) => {
    if(!disabled) {
      onChange(value);
    }
  };

  const handleSuccess = useCallback((value) => {
    handleChange(value);
    handleToggle();
  }, [onChange, handleToggle]);

  const onRemove = useCallback((newValue) => {
    handleChange(newValue);
  }, [onChange, handleToggle]);

  return (
    <Box>
      <Grid container wrap={"wrap"} className={classes.root}>
        <SortableList
          items={value}
          onRemove={onRemove}
          onSortEnd={({oldIndex, newIndex}) => {
            handleChange(arrayMove(value, oldIndex, newIndex))
          }}
        />

        <AddItem onClick={handleToggle}/>
        <VideoModal
          open={!disabled && open}
          onClose={handleToggle}
          selected={value}
          onSuccess={handleSuccess}
        />
      </Grid>
      {isError && (
        <Typography color={"error"}>{meta.error}</Typography>
      )}
    </Box>

  );
};

export default FormVideo;
