import {
    CREATE_CATEGORIES_BULK_ACTION_FAILURE,
    CREATE_CATEGORIES_BULK_ACTION_REQUEST,
    CREATE_CATEGORIES_BULK_ACTION_SUCCESS,
    CREATE_IMAGES_BULK_ACTION_FAILURE,
    CREATE_IMAGES_BULK_ACTION_REQUEST,
    CREATE_IMAGES_BULK_ACTION_SUCCESS,
    CREATE_TAGS_BULK_ACTION_FAILURE,
    CREATE_TAGS_BULK_ACTION_REQUEST,
    CREATE_TAGS_BULK_ACTION_SUCCESS,
    CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE,
    CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST,
    CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS,
    CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE,
    CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST,
    CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS, CREATE_VIDEOS_BULK_ACTION_FAILURE, CREATE_VIDEOS_BULK_ACTION_SUCCESS,
    FLUSH_BULK_ACTION_STATE
} from "../constants/bulk";
import {createSelector} from "reselect";

const initialState = {
    isFetching: false,
    result: '',
    message: ''
};

const bulkActions = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_IMAGES_BULK_ACTION_REQUEST:
        case CREATE_CATEGORIES_BULK_ACTION_REQUEST:
        case CREATE_TAGS_BULK_ACTION_REQUEST:
        case CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST:
        case CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case CREATE_IMAGES_BULK_ACTION_SUCCESS:
        case CREATE_CATEGORIES_BULK_ACTION_SUCCESS:
        case CREATE_TAGS_BULK_ACTION_SUCCESS:
        case CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS:
        case CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS:
        case CREATE_VIDEOS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                result: payload.status,
                message: payload.message,
                isFetching: false
            };
        case CREATE_IMAGES_BULK_ACTION_FAILURE:
        case CREATE_CATEGORIES_BULK_ACTION_FAILURE:
        case CREATE_TAGS_BULK_ACTION_FAILURE:
        case CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE:
        case CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE:
        case CREATE_VIDEOS_BULK_ACTION_FAILURE:
            return {
                ...state,
                result: 'error',
                isFetching: false,
            };
        case FLUSH_BULK_ACTION_STATE:
            return initialState;
        default:
            return {
                ...state
            }
    }
};

export default bulkActions;


export const getBulkActionResult = state => state.bulkActions.result;

export const bulkActionsSelector = createSelector(
    [getBulkActionResult],
    (result) => ({
        result
    }));
