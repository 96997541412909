import React, {useCallback, useMemo, useState} from 'react';
import {DatePicker} from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import {getDateSaveFormattedString} from "../../../helpers/date";

const DateFilter = ({appliedFilters, handleChangeFilters, classes, label, filterKey}) => {
    const filter = useMemo(
        () => appliedFilters.find(filter => filter.key === filterKey),
        [appliedFilters, filterKey]);

    const handleChange = useCallback((newValue) => {
        if (filter) {
            handleChangeFilters([
                ...appliedFilters.map((filter => {
                    if (filter.key === filterKey) {
                        return {
                            ...filter,
                            value: newValue
                        }
                    }
                    return filter;
                }))

            ])
        } else {
            handleChangeFilters([
                ...appliedFilters,
                {
                    key: filterKey,
                    value: newValue
                }

            ])
        }
    }, [handleChangeFilters, appliedFilters, filter]);

    return (
        <Box className={classes.filterWrapper}>
            <DatePicker
                inputVariant="outlined"
                name={filterKey}
                label={label}
                value={filter ? filter.value : null}
                format="MMM d, Y"
                maxDate={new Date()}
                onChange={(value) => handleChange(getDateSaveFormattedString(value, false))}
            />
        </Box>
    );
};

export default DateFilter;
