import React, { useEffect, useMemo, useState } from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid/Grid";
import Box from '@material-ui/core/Box'
import {
  streamsSelector
} from '../../../store/reducers/streams/selectors'
import { streamsList } from '../../../store/actions/streams/list'
import ListActions from '../../ListActions'
import StreamList from './StreamList'
import { useDispatch, useSelector } from 'react-redux'

const StreamModal = ({open, onClose, onSuccess, classes, selected}) => {
  const [search, setSearch] = useState('')

  const {items: streams} = useSelector(streamsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(streamsList({
      page: 1,
      limit: -1,
    }))
  }, []);

  const items = useMemo(() => {
    const searchString = search.toLowerCase()
    const excluded = selected.map(item => item.id);
    return streams
    .filter(stream => !!stream.image)
    .filter(stream => excluded.indexOf(stream.id) === -1)
    .filter((stream) => {
      return stream.title.toLowerCase().indexOf(searchString) !== -1
    });
  }, [selected, streams, search]);

  const handleSelected = (item) => {
    onSuccess(selected.concat([item]))
  }

  if (!open) {
    return null;
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Select video"}</DialogTitle>
        <Box className={classes.container}>
          <ListActions searchHandler={setSearch} waitInterval={300} />
        </Box>
        <DialogContent className={classes.root}>
          <Grid container wrap={"wrap"} className={classes.grid}>
            <StreamList streams={items} onSelect={handleSelected} search={search}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StreamModal;

