import {
    FETCH_VIDEO_TAG_FAILURE,
    FETCH_VIDEO_TAG_REQUEST,
    FETCH_VIDEO_TAG_SUCCESS,
    FLUSH_VIDEO_TAG_ERRORS,
    FLUSH_VIDEO_TAG_STATE,
    FLUSH_VIDEO_TAG_MESSAGES,
    ADD_VIDEO_TAG_FAILURE,
    ADD_VIDEO_TAG_REQUEST,
    ADD_VIDEO_TAG_SUCCESS,
    UPDATE_VIDEO_TAG_FAILURE,
    UPDATE_VIDEO_TAG_REQUEST,
    UPDATE_VIDEO_TAG_SUCCESS,
    DELETE_VIDEO_TAG_FAILURE,
    DELETE_VIDEO_TAG_REQUEST,
    DELETE_VIDEO_TAG_SUCCESS,
    REMOVE_VIDEO_TAG_FROM_LIST
} from "../constants/videoTag";

import {
    addVideoTag as apiAddVideoTag,
    fetchVideoTag,
    updateVideoTag as apiUpdateVideoTag,
    deleteVideoTag as apiDeleteVideoTag,
    apiRequest,
} from "../../helpers/api";

export const getVideoTag = id => dispatch => {
    dispatch({type: FETCH_VIDEO_TAG_REQUEST});
    return fetchVideoTag(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_VIDEO_TAG_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_VIDEO_TAG_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_VIDEO_TAG_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushVideoTagErrors = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_TAG_ERRORS});
};

export const flushVideoTagState = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_TAG_STATE});
};

export const addVideoTag = (data) => dispatch => {
    dispatch({type: ADD_VIDEO_TAG_REQUEST});
    return apiAddVideoTag(data)
        .then(
            res => {
                if (res.data.status === "error") {
                    return dispatch({
                        type: ADD_VIDEO_TAG_FAILURE,
                        message: res.data.message
                    });
                }
                if (res.data.status === 'success') {

                    return dispatch({
                        type: ADD_VIDEO_TAG_SUCCESS,
                        payload: res.data
                    });
                }
            },
            error => {
                return dispatch({
                    type: ADD_VIDEO_TAG_FAILURE,
                    payload: error.response.data.message
                })
            })
};

export const updateVideoTag = (data) => dispatch => {
    dispatch({type: UPDATE_VIDEO_TAG_REQUEST});
    const request = () => apiUpdateVideoTag(data);
    return apiRequest(request, dispatch, UPDATE_VIDEO_TAG_SUCCESS, UPDATE_VIDEO_TAG_FAILURE);
};

export const deleteVideoTag = id => dispatch => {
    dispatch({type: DELETE_VIDEO_TAG_REQUEST});
    const request = () => apiDeleteVideoTag(id);
    return apiRequest(request, dispatch, DELETE_VIDEO_TAG_SUCCESS, DELETE_VIDEO_TAG_FAILURE);
};

export const flushVideoTagMessages = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_TAG_MESSAGES});
};

export const removeVideoTagFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_VIDEO_TAG_FROM_LIST,
        payload: id
    })
};
