import React, {useEffect, useState} from "react";
import SinglePage from "../../SinglePage";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useVideoCategory} from "../../../hooks/videoCategory";
import {
    deleteVideoCategory,
    updateVideoCategory,
    addVideoCategory,
    flushVideoCategoryErrors,
    flushVideoCategoryState,
    removeVideoCategoryFromList,
    flushVideoCategoryMessages
} from "../../../store/actions/videoCategory";

import {
    ADD_VIDEO_CATEGORY_SUCCESS,
    ADD_VIDEO_CATEGORY_FAILURE,
} from "../../../store/constants/videoCategory";

import {
    deleteVideoTag,
    updateVideoTag,
    addVideoTag,
    flushVideoTagErrors,
    flushVideoTagState,
    removeVideoTagFromList
} from "../../../store/actions/videoTag";
import {useVideoTag} from "../../../hooks/videoTags";

import {
    ADD_VIDEO_TAG_FAILURE,
    ADD_VIDEO_TAG_SUCCESS,
} from "../../../store/constants/videoTag";

import {addPlaylist, flushVideoPlaylist, flushVideoPlaylistErrors} from "../../../store/actions/playlist";
import {ADD_VIDEO_PLAYLIST_FAILURE, ADD_VIDEO_PLAYLIST_SUCCESS} from "../../../store/constants/playlist";
import {flushVideosTaxState} from "../../../store/actions/taxonomies";

export const SinglePageCategoryActions = ({match}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const [data, setData] = useState('');
    const [disableDelete, setDisableDelete] = useState(false);

    const {category, loading} = useVideoCategory(match.params.id);
    const closeLink = () => {
        dispatch(flushVideoCategoryErrors());
        history.push('/videos/categories');
    };

    //console.log(loading)
    const deleteVideoCategoryId = () => {
        dispatch(deleteVideoCategory(category.id)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(removeVideoCategoryFromList(category.id));
                closeLink();
            }
        ).catch(err => {
                enqueueSnackbar(err.data.data, {variant: 'error'})
            }
        );
    };

    const updateVideoCategoryName = (name) => {
        dispatch(updateVideoCategory({category_id: category.id, name: name})).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(flushVideoCategoryMessages());
            }
        ).catch(err => {
                enqueueSnackbar(err.data.message, {variant: 'error'})
            }
        );
        setData(name);
        setDisableDelete(false);
    };

    useEffect(() => {
        return () => {
            dispatch(flushVideoCategoryState());
            dispatch(flushVideosTaxState());
        }
    }, []);

    return (<SinglePage
        loading={loading}
        flushState={flushVideoCategoryState}
        flushErrors={flushVideoCategoryErrors}
        closeLink={closeLink}
        deleteAction={deleteVideoCategoryId}
        updateAction={updateVideoCategoryName}
        id={match.params.id}
        data={data}
        disableDelete={disableDelete}
        setDisableDelete={setDisableDelete}
        useSelector={match.params.id * 1 === category.id ? category.name : ''}
        title="Category"
        item={category.name}
    />)
};

export const SinglePageCategoryAddActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const loading = useSelector(state => state.video_category.isFetching);

    const closeLink = () => {
        dispatch(flushVideoCategoryErrors());
        history.push('/videos/categories');
    };

    const addNewVideoCategory = (name) => {
        dispatch(addVideoCategory({name: name})).then(
            res => {
                if (res.type === ADD_VIDEO_CATEGORY_FAILURE) {
                    enqueueSnackbar(res.payload, {variant: 'error'});
                }

                if (res.type === ADD_VIDEO_CATEGORY_SUCCESS) {
                    enqueueSnackbar(res.payload.message, {variant: 'success'});
                    closeLink()
                }
            }
        ).catch(err => enqueueSnackbar(err.message, {variant: 'error'}))
    };

    return (<SinglePage
        loading={loading}
        flushState={flushVideoCategoryState}
        flushErrors={flushVideoCategoryErrors}
        closeLink={closeLink}
        addAction={addNewVideoCategory}
        title="Category"
    />)
};


export const SinglePageTagsActions = ({match}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const [data, setData] = useState('');
    const [disableDelete, setDisableDelete] = useState(false);

    const {tag, loading} = useVideoTag(match.params.id);

    const closeLink = () => {
        dispatch(flushVideoTagErrors());
        history.push('/videos/tags');
    };

    const deleteVideoTagId = () => {
        dispatch(deleteVideoTag(tag.id)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(removeVideoTagFromList(tag.id));
                closeLink();
            }
        ).catch(err => {
                enqueueSnackbar(err.data.data, {variant: 'error'})
            }
        );
    };

    const updateVideoTagName = (name) => {
        dispatch(updateVideoTag({tag_id: tag.id, name: name})).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
            }
        ).catch(err => {
                enqueueSnackbar(err.data.message, {variant: 'error'})
            }
        );
        setData(name);
        setDisableDelete(false);
    };

    useEffect(() => {
        return () => {
            dispatch(flushVideoTagState());
            dispatch(flushVideosTaxState());
        }
    }, []);

    return (<SinglePage
        loading={loading}
        flushState={flushVideoTagState}
        flushErrors={flushVideoTagErrors}
        closeLink={closeLink}
        deleteAction={deleteVideoTagId}
        updateAction={updateVideoTagName}
        id={match.params.id}
        data={data}
        disableDelete={disableDelete}
        setDisableDelete={setDisableDelete}
        useSelector={tag.name}
        title="Tag"
        item={tag.name}
    />)
};

export const SinglePageTagsAddActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const loading = useSelector(state => state.video_tag.isFetching);

    const closeLink = () => {
        dispatch(flushVideoTagErrors());
        history.push('/videos/tags');
    };

    const addNewVideoTag = (name) => {
        dispatch(addVideoTag({name: name})).then(
            res => {
                if (res.type === ADD_VIDEO_TAG_FAILURE) {
                    enqueueSnackbar(res.payload, {variant: 'error'});
                }

                if (res.type === ADD_VIDEO_TAG_SUCCESS) {
                    enqueueSnackbar(res.payload.message, {variant: 'success'});
                    closeLink()
                }
            }
        ).catch(err => enqueueSnackbar(err.response.data.message, {variant: 'error'}))
    };

    return (<SinglePage
        loading={loading}
        flushState={flushVideoTagState}
        flushErrors={flushVideoTagErrors}
        closeLink={closeLink}
        addAction={addNewVideoTag}
        title="Tag"
    />)
};
