import {
    LOAD_VIDEOS_FAILURE,
    LOAD_VIDEOS_REQUEST,
    LOAD_VIDEOS_SUCCESS
} from "../constants/videos";
import {loadVideosList} from "../../helpers/api";

export const getVideosList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search,
    from,
    to,
    categories,
    tags,
    excepts,
    status
) => dispatch => {
    dispatch({type: LOAD_VIDEOS_REQUEST});
    loadVideosList(page, orderBy, order, limit, search, from, to, categories, tags, excepts, status).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_VIDEOS_FAILURE, message: message})
            }
            return dispatch({
                type: LOAD_VIDEOS_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_VIDEOS_FAILURE, message: error})
    )
};
