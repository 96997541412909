import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import Image from "../../Image";
import CategoryList from "../../CategoryList";
import TagsList from "../../TagsList";
import {SinglePageCategoryActions, SinglePageTagsActions, SinglePageCategoryAddActions, SinglePageAddTagsActions} from "../SinglePageActions/SinglePageActions";
import ImagesLists from "../ImagesLists";

const Images = () => {
    return (<Switch>
            <Route path="/images/list" component={ImagesLists}/>
            <Route path="/images/image" component={Image}/>
            <Route
                path="/images/categories/:id/edit"
                component={SinglePageCategoryActions}
            />
            <Route
                exact
                path="/images/categories"
                component={CategoryList}
            />
            <Route
                path="/images/categories/single"
                component={SinglePageCategoryAddActions}
            />
            <Route
                exact
                path="/images/tags"
                component={TagsList}
            />
            <Route
                path="/images/tags/:id/edit"
                component={SinglePageTagsActions}
            />
            <Route
                path="/images/tags/single"
                component={SinglePageAddTagsActions}
            />

        </Switch>
    );
};

export default Images;
