import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useDispatch, useSelector} from "react-redux";
import {
    getIsFetching as approvedImagesFetching
} from "../../../store/reducers/images";
import {
    getIsFetching as pendingImagesFetching
} from "../../../store/reducers/imagesPending"
import {fetchCounts} from "../../../store/actions/counts";
import EventsList from "../EventsList";
import EventsPendingList from "../EventsPendingList";
import Chip from "@material-ui/core/Chip";

const tabs = {
    used: 0,
    pending: 1
};

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
};

const LinkTab = (props) => {
    const history = useHistory();
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
                history.push(props.href)
            }}
            {...props}
        />
    );
}

const EventsTabs = ({classes}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const total = useSelector(state => state.eventsPending.eventsPending);
    const currentList = location.pathname.split('/').slice(-1).pop();
    const currentTab = currentList ? tabs[currentList] : 0;
    const [tab, setTab] = useState(currentTab);
    const loadingList = useSelector(approvedImagesFetching);
    const loadingListPending = useSelector(pendingImagesFetching);
    const hideIndicatorClassName = loadingList || loadingListPending ? 'hidden' : '';

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        dispatch(fetchCounts());
    }, [tab, dispatch]);

    return (
        <div>
            <AppBar className={classes.shadow} position="static" color="default">
                <Tabs
                    value={tab ? tab : 0}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable prevent tabs example"
                    classes={{indicator: classes[hideIndicatorClassName]}}
                >
                    <LinkTab
                        label="In use" aria-label="used-images"
                        className={classes.linkStyle}
                        href="/events/in-use"
                        {...a11yProps(tabs.used)}
                        disabled={currentList === "in-use"}
                    />
                    <LinkTab label="Pending"
                             className={classes.linkStyle}
                             aria-label="pending-images"
                             icon={!!total.length && <Chip color="primary" className={classes.badge} label={total.length} />}
                             href="/events/pending"
                             {...a11yProps(tabs.pending)}
                             disabled={currentList === "pending"}
                    />
                </Tabs>
            </AppBar>
            <Switch>
                <Route exact path="/events/in-use" component={EventsList}/>
                <Route exact path="/events/pending" component={EventsPendingList}/>
                <Redirect to="/events/in-use"/>
            </Switch>
        </div>
    );
};

export default EventsTabs;
