export const styles = theme => ({
    root: {
      display: 'flex',
      minWidth: '150px',
      maxWidth: '150px',
      maxHeight: '150px',
      minHeight: '150px',
      height: '100%',
      width: '100%',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: "pointer",
      borderRadius: '3px',
      '&:hover $overlay': {
        opacity: '1'
      }
    },
    image: {
      backgroundRepeat: "no-repeat",
      position: 'relative',
      backgroundSize: "cover",
      width: '100%',
      minWidth: '150px',
      maxWidth: '150px',
      maxHeight: '150px',
      minHeight: '150px',
      height: '100%',
      borderRadius: '3px',
      transition: "background 1s linear",

    },
    overlay: {
      position: 'absolute',
      opacity: '0',
      width: '100%',
      minWidth: '150px',
      maxWidth: '150px',
      maxHeight: '150px',
      minHeight: '150px',
      height: '100%',
      borderRadius: '3px',
      transition: "opacity .3s linear",
      backgroundColor: 'rgba(0,0,0, .5)',
      display:"flex",
      justifyContent: "flex-end"
    },
    icon: {
      margin: '1px',
      fill: 'white'
    }
  });
