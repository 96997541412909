import React from 'react';
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const FormSwitcher = ({input: {value, onChange, onBlur}, meta, type, label, disabled, color, ...rest}) => {

  return (
    <FormControlLabel
      control={
        <Switch disabled={disabled} checked={value} onChange={onChange} color={color || "primary"}/>
      }
      label={label}
    />
  );
};

export default FormSwitcher;
