import React, {useCallback} from 'react';
import Avatar from '@material-ui/core/Avatar'
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

const Item = ({classes, src, onClick}) => {

  return (
    <div className={classes.root}  onClick={onClick}>
      <Avatar className={classes.image} src={src}/>
      <div className={classes.overlay}>
        <EditIcon className={classes.icon}/>
      </div>
    </div>
  );
};

export default Item;
