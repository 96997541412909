import React from 'react';
import TextField from "@material-ui/core/TextField/TextField";

const FormTextField = ({input: {value, onChange, onBlur}, meta, type, disabled, ...rest}) => {
  const isError = (meta.dirty || meta.touched || meta.submitting) && meta.invalid;
  return (
    <TextField
      value={value}
      error={isError}
      helperText={isError ? meta.error : ''}
      type={type}
      onChange={onChange}
      {...rest}
      onBlur={onBlur}
      disabled={disabled}
      margin="dense"
      fullWidth
    />

  );
};

export default FormTextField;
