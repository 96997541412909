import { useCallback } from 'react'

const addZero = (item) => parseInt(item) < 10 ? "0" + item : item;
export const getDateSaveFormattedString = (date, getTime = true) => {
    const dateString = `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
    const timeString = ` ${addZero(date.getHours())}:${addZero(date.getMinutes())}:${addZero(date.getSeconds())}`;
    return dateString + (getTime ? timeString : '');
};

export const getTimestamp = (value) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'string') {
    try {
      return new Date(value).getTime() / 1000
    } catch {
      return null;
    }
  }

  if (typeof value === 'object') {
    try {
      return value.getTime() / 1000;
    } catch {
      return null;
    }
  }
};

export const getDate = (value) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'string') {
    try {
      return new Date(value)
    } catch {
      return null;
    }
  }

  if (typeof value === 'object') {
    try {
      return value;
    } catch {
      return null;
    }
  }
};
