import {
    apiRequest, bulkActionApproveImagesPending,
    bulkActionImagesInUse as apiBulkActionImagesInUse,
    bulkActionRemoveImagesCategories as apiBulkActionRemoveImagesCategories,
    bulkActionRemoveImagesPending,
    bulkActionRemoveImagesTags as apiBulkActionRemoveImagesTags,
    bulkActionRemoveVideoCategories as apiBulkActionRemoveVideoCategories,
    bulkActionRemoveVideoPlayLists as apiBulkActionRemoveVideoPlayLists,
    bulkActionRemoveVideoTags as apiBulkActionRemoveVideoTags, bulkActionVideos,
} from "../../helpers/api";
import {
  CHANGE_IMAGES_STATUS_TO_PROGRESS,
  CHANGE_VIDEOS_STATUS_TO_PROGRESS,
  CREATE_CATEGORIES_BULK_ACTION_FAILURE,
  CREATE_CATEGORIES_BULK_ACTION_REQUEST,
  CREATE_CATEGORIES_BULK_ACTION_SUCCESS,
  CREATE_IMAGES_BULK_ACTION_FAILURE,
  CREATE_IMAGES_BULK_ACTION_REQUEST,
  CREATE_IMAGES_BULK_ACTION_SUCCESS,
  CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE,
  CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST,
  CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS,
  CREATE_TAGS_BULK_ACTION_FAILURE,
  CREATE_TAGS_BULK_ACTION_REQUEST,
  CREATE_TAGS_BULK_ACTION_SUCCESS,
  CREATE_VIDEO_CATEGORIES_BULK_ACTION_FAILURE,
  CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST,
  CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS,
  CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE,
  CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST,
  CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS,
  CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE,
  CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST,
  CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS,
  CREATE_VIDEOS_BULK_ACTION_FAILURE,
  CREATE_VIDEOS_BULK_ACTION_REQUEST,
  CREATE_VIDEOS_BULK_ACTION_SUCCESS,
  FLUSH_BULK_ACTION_STATE,
  UPDATE_VIDEO_CATEGORIES_AFTER_BULK,
  UPDATE_VIDEO_PLAYLISTS_AFTER_BULK,
  UPDATE_VIDEO_TAGS_AFTER_BULK,
} from '../constants/bulk'

export const bulkActionForImagesInUse = data => dispatch => {
    dispatch({type: CREATE_IMAGES_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionImagesInUse(data);

    return apiRequest(request, dispatch, CREATE_IMAGES_BULK_ACTION_SUCCESS, CREATE_IMAGES_BULK_ACTION_FAILURE);
};

export const bulkActionForVideos = data => dispatch => {
    dispatch({type: CREATE_VIDEOS_BULK_ACTION_REQUEST});
    const request = () => bulkActionVideos(data);

    return apiRequest(request, dispatch, CREATE_VIDEOS_BULK_ACTION_SUCCESS, CREATE_VIDEOS_BULK_ACTION_FAILURE);
};

export const changeImagesStatusToProgress = items => dispatch => {
    dispatch({
        type: CHANGE_IMAGES_STATUS_TO_PROGRESS,
        payload: items
    });
};

export const changeVideosStatusToProgress = items => dispatch => {
    dispatch({
        type: CHANGE_VIDEOS_STATUS_TO_PROGRESS,
        payload: items
    });
};

export const flushBulkActionsState = () => dispatch => {
    dispatch({type: FLUSH_BULK_ACTION_STATE})
};

export const bulkActionForImagesPending = data => dispatch => {
    dispatch({type: CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST});
    let request;
    if (data.type === 'approve') {
        request = () => bulkActionApproveImagesPending(data);
    } else {
        request = () => bulkActionRemoveImagesPending(data);
    }

    return apiRequest(request, dispatch, CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE, CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS);
};

export const bulkActionRemoveForImagesCategories = data => dispatch => {
    dispatch({type: CREATE_CATEGORIES_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionRemoveImagesCategories(data);

    return apiRequest(request, dispatch, CREATE_CATEGORIES_BULK_ACTION_SUCCESS, CREATE_CATEGORIES_BULK_ACTION_FAILURE);
};

export const bulkActionRemoveVideoPlayLists = data => dispatch => {
    dispatch({type: CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionRemoveVideoPlayLists(data);

    return apiRequest(request, dispatch, CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS, CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE);
};

export const updateVideoPlaylistsAfterBulk = (data) => dispatch => {
    dispatch({
        type: UPDATE_VIDEO_PLAYLISTS_AFTER_BULK,
        payload: data
    })
};

export const bulkActionRemoveVideoCategories = data => dispatch => {
    dispatch({type: CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionRemoveVideoCategories(data);

    return apiRequest(request, dispatch, CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS, CREATE_VIDEO_CATEGORIES_BULK_ACTION_FAILURE);
};

export const updateVideoCategoriesAfterBulk = (data) => dispatch => {
    dispatch({
        type: UPDATE_VIDEO_CATEGORIES_AFTER_BULK,
        payload: data
    })
};

export const bulkActionRemoveVideoTags = data => dispatch => {

    dispatch({type: CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionRemoveVideoTags(data);

    return apiRequest(request, dispatch, CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS, CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE);
};

export const updateVideoTagsAfterBulk = (data) => dispatch => {
    dispatch({
        type: UPDATE_VIDEO_TAGS_AFTER_BULK,
        payload: data
    })
};

export const bulkActionRemoveForImagesTag = data => dispatch => {
    dispatch({type: CREATE_TAGS_BULK_ACTION_REQUEST});
    const request = () => apiBulkActionRemoveImagesTags(data);

    return apiRequest(request, dispatch, CREATE_TAGS_BULK_ACTION_SUCCESS, CREATE_TAGS_BULK_ACTION_FAILURE);
};
