import React, {useEffect, useState} from "react";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import {Box} from "@material-ui/core";


const GeoMap = ({lng, lat, zoom, classes}) => {
    const [zoomVal, setZoom] = useState(13);

    let position = [lat, lng];

    useEffect(() => {
        setZoom(zoom);
    }, [zoom]);

    // TODO: improve set coordinates on slow connection
    if (!lat || !lng) {
        position = [0, 0];
    }

    return (
        <Box className={classes.containerWrapper}>
            <Map
                center={position}
                zoom={zoomVal}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                </Marker>
            </Map>
        </Box>
    );
}

export default GeoMap;

