export const styles = theme => ({
    //TODO: improve styles
    onFetching: {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
        alignItems: 'center',
        // display: 'flex',
        // justifyContent: 'center'
        top: '40%',
        left: '45%',
        zIndex: 9999
    },
    editorTui:{
        '& .tui-image-editor-header-logo, & .tui-image-editor-header-buttons': {
            display: 'none'
        }
    }
});
