import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

const SingleImagePendingForm = (
  {
    classes,
    title,
    description,
    disabled,
    errors,
    onChange
  }
) => (
  <Grid item xs={12} md={6} lg={6} className={classes.gridCol50}>
    <Box className={classes.fieldsWrapper}>
      <div className={classes.textField}>
        <TextField
          disabled={disabled}
          name="title"
          error={errors && !!errors.title}
          helperText={!!errors && !!errors.title}
          label="Image Title"
          type="text"
          fullWidth
          variant="outlined"
          value={title}
          onChange={onChange('title')}
        />
      </div>
      <div className={classes.textField}>
        <TextField
          disabled={disabled}
          name="description"
          label="Description"
          multiline
          rows="4"
          fullWidth
          variant="outlined"
          type="text"
          value={description}
          onChange={onChange('description')}
        />
      </div>
    </Box>
  </Grid>
);

export default SingleImagePendingForm;
