import {
    deleteImage as apiDeleteImage,
    fetchImage as apiFetchImage,
    apiRequest,
    createImage as apiCreateImage,
    updateImage as apiUpdateImage
} from "../../helpers/api";
import {
    CREATE_IMAGE_FAILURE,
    CREATE_IMAGE_REQUEST, CREATE_IMAGE_SUCCESS,
    DELETE_IMAGE_FAILURE,
    DELETE_IMAGE_REQUEST, DELETE_IMAGE_SUCCESS,
    FETCH_IMAGE_FAILURE,
    FETCH_IMAGE_REQUEST,
    FETCH_IMAGE_SUCCESS,
    FLUSH_IMAGE_ERRORS, FLUSH_IMAGE_STATE, REMOVE_IMAGE_FROM_LIST, UPDATE_IMAGE_FAILURE,
    UPDATE_IMAGE_REQUEST, UPDATE_IMAGE_SUCCESS
} from "../constants/images";

export const getImage = id => dispatch => {
    dispatch({type: FETCH_IMAGE_REQUEST});
    return apiFetchImage(id).then(
        res => {
            // TODO: report of missing status in response
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_IMAGE_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_IMAGE_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_IMAGE_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushImageErrors = () => dispatch => {
    dispatch({type: FLUSH_IMAGE_ERRORS});
};

export const flushImageState = () => dispatch => {
    dispatch({type: FLUSH_IMAGE_STATE});
};

export const updateImage = data => dispatch => {
    dispatch({type: UPDATE_IMAGE_REQUEST});
    const request = () => apiUpdateImage(data);

    return apiRequest(request, dispatch, UPDATE_IMAGE_SUCCESS, UPDATE_IMAGE_FAILURE);
};

export const deleteImage = (id) => dispatch => {
    dispatch({type: DELETE_IMAGE_REQUEST});
    const request = () => apiDeleteImage(id);

    return apiRequest(request, dispatch, DELETE_IMAGE_SUCCESS, DELETE_IMAGE_FAILURE);
};

export const removeImageFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_IMAGE_FROM_LIST,
        payload: id
    })
};

export const createImage = data => dispatch => {
    dispatch({type: CREATE_IMAGE_REQUEST});
    const request = () => apiCreateImage(data);

    return apiRequest(request, dispatch, CREATE_IMAGE_SUCCESS, CREATE_IMAGE_FAILURE);
};
