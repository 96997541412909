import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getAuthUser} from "../store/actions/auth";
import {getAuthUserData, getIsFetching} from "../store/reducers/auth";


export const useAuthUser = (id) => {
    const dispatch = useDispatch();
    const user = useSelector(getAuthUserData);
    const loading = useSelector(getIsFetching);

    useEffect(() => {
        if (!!id) {
            dispatch(getAuthUser(id));
        }
    }, [dispatch, id]);

    return {
        user,
        loading
    };
};
