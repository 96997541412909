export const ADD_TAG_FAILURE = 'ADD_TAG_FAILURE';
export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';

export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';

export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';

export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE';
export const FETCH_TAG_REQUEST = 'FETCH_TAG_REQUEST';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';

export const FLUSH_TAG_ERRORS = 'FLUSH_TAG_ERRORS';
export const FLUSH_TAG_STATE = 'FLUSH_TAG_STATE';

export const REMOVE_TAG_FROM_LIST = 'REMOVE_TAG_FROM_LIST';

export const FLUSH_TAG_MESSAGES = 'FLUSH_TAG_MESSAGES';
