import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {headCellsAdmins} from "../../constatns/admins";
import TableComponent from "../TableComponent";
import Paper from "@material-ui/core/Paper";
import InviteDialog from "../InviteDialog";
import {useHistory} from 'react-router-dom';
import {useResource} from "../../hooks/resource";
import {adminsSelector, getIsFetching} from "../../store/reducers/admins";
import {getAdminsList} from "../../store/actions/admins";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import ListActions from "../ListActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import NewFilters from "../NewFilters";
import Status from "../NewFilters/Items/Status";
import DateFilter from "../NewFilters/Items/Date";

const initialState = {
    deleteDialog: false,
    deleteUserId: null,
};
const statuses = ['active', 'blocked', 'pending'];
const filterBy = ['date', 'status'];

const AdminList = ({classes}) => {
    const history = useHistory();
    const {
        data: {
            admins,
            count,
        },
        loading,
        page,
        handleChangePage,
        order,
        handleChangeOrder,
        orderBy,
        handleChangeOrderBy,
        search,
        handleChangeSearch,
        rowsPerPage,
        handleChangeLimit,
        filter,
        onChangeFilter,
        clearFilter
    } = useResource(adminsSelector, getAdminsList, getIsFetching);
    const [open, setOpenDialog] = useState(false);
    const [state, setState] = useState(initialState);

    const openDialog = () => {
        setOpenDialog(true);
    };
    const closeDialog = () => {
        setOpenDialog(false);
    };
    const handleRowClick = () => id => {
        history.push(`/users/admin/${id}`)
    };

    const handleDeleteImage = () => id => {
        setState({
            deleteDialog: !state.deleteDialog,
            deleteUserId: id,
        });
    };

    const deleteUser = () => (id) => {
        // TODO: implement delete action
        console.log('delete user #' + id)
    };

    if (loading && !count) {
        return (<LinearProgress/>)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableComponent
                    type="admins"
                    rows={admins}
                    count={count}
                    page={page}
                    limit={rowsPerPage}
                    order={order}
                    orderBy={orderBy}
                    headCells={headCellsAdmins}
                    actionButtons={<ListActions
                        isInvite
                        searchRequest={search}
                        searchHandler={handleChangeSearch}
                        openInviteDialog={openDialog}
                    />}
                    onChangePage={handleChangePage}
                    onChangeOrder={handleChangeOrder}
                    onChangeOrderBy={handleChangeOrderBy}
                    onChangeLimit={handleChangeLimit}
                    handleRowClick={handleRowClick()}
                    deleteItemAction={handleDeleteImage()}
                    filterBy={filterBy}
                    statuses={statuses}
                    chipsData={filter}
                    onDeleteChips={onChangeFilter}
                    filtersComponent={<NewFilters
                        filters={[
                            {key: "status", label: "Status", component: <Status statuses={statuses}/>},
                            {key: "from", label: "From", component: <DateFilter/>},
                            {key: "to", label: "To", component: <DateFilter/>},
                        ]}
                        handleChangeFilters={onChangeFilter}
                        appliedFilters={filter}
                        clearFilters={clearFilter}
                    />}
                />
            </Paper>
            <InviteDialog
                open={open}
                onClose={closeDialog}
            />
            <ConfirmDeleteDialog
                id={state.deleteUserId}
                open={state.deleteDialog}
                onClose={() => setState({...state, deleteDialog: false})}
                deleteItemAction={deleteUser()}
            />
        </div>);
};

AdminList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default AdminList;
