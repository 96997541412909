import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {streamsList} from "../../../store/actions/streams/list";
import Paper from "@material-ui/core/Paper/Paper";
import LoadingFade from "../../../common/LoadingFade";
import ResourceList from "../../../components/ResourceList";
import {streamsSelector} from "../../../store/reducers/streams/selectors";
import useSelectedItems from "../../../hooks/useSelectedItems";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import useSearch from "../../../hooks/useSearch";
import useFilters from "../../../hooks/useFilters";
import StreamListItem from "./StreamListItem";
import {reduce, debounce} from 'lodash'
import {bulkUpdate} from "../../../store/actions/events/bulkUpdate";
import useToggle from "../../../hooks/useToggle";
import useOrder from "../../../hooks/useOrder";
import AddButton from "../../../common/AddButton/AddButton";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

const StreamsList = ({classes }) => {
  const history = useHistory();
  const {items, isFetching, currentPage, limit, pages, total} = useSelector(streamsSelector);
  const [selectedItems, handleChangeSelectedItems, handleReset] = useSelectedItems();
  const [triggerDelered, handleTrigger] = useToggle();
  const {enqueueSnackbar} = useSnackbar();
  const [searchValue, handleChangeSearch] = useSearch();
  const [order, handleChangeOrder] = useOrder('id', 'desc');
  const [tempSearch, setTempSearch] = useSearch();
  const [page, perPage, handleChangePage, handleChangeLimit] = usePagination(currentPage, limit, pages);
  const [appliedFilters, handleChangeFilters] = useFilters();
  const dispatch = useDispatch();
  const headers = [
    {label: 'ID', name: 'id', ordered: true},
    {label: 'Thumbnail', name: 'thumbnail', ordered: false},
    {label: 'Title', name: 'title', ordered: true},
    {label: 'URL', name: 'url', ordered: false},
    {label: 'Created', name: 'created_at', ordered: false},
  ];

  const filter = useMemo(() => {
    return {
      page,
      limit: perPage,
      search: searchValue,
      ...order,
      ...reduce(appliedFilters, (acc, {key, value}) => {
        return {
          ...acc,
          [key]: value
        }
      }, {})
    }
  }, [page, perPage, searchValue, appliedFilters, order]);

  const handleSearch = useCallback(
    debounce(value => {
      handleChangeSearch({target: {value}})
    }, 500),
    [handleChangeSearch]
  );

  useEffect(() => {
    handleSearch(tempSearch)
  }, [tempSearch]);

  useEffect(() => {
    dispatch(streamsList({...filter}))
  }, [filter, triggerDelered]);

  useEffect(() => {
    handleChangePage(0)
  }, [perPage, searchValue, appliedFilters]);


  const handleChangeRoute = useCallback((url)=>{
    history.push(url)
  }, [])


  const renderItem = useCallback((item) => {
    return (
      <StreamListItem key={item.id}  onClick={() => handleChangeRoute(`/streams/${item.id}/edit`)} item={item}/>
    );
  }, [items]);

  const handleChangeSelectedAll = useCallback(() => {
    if (selectedItems.length !== items.length || !selectedItems.length) {
      handleChangeSelectedItems([...items.map(item => item.id)]);
      return;
    }
    handleChangeSelectedItems([])

  }, [selectedItems, handleChangeSelectedItems]);

  const handleRemove = useCallback(() => {
    dispatch(bulkUpdate({
      ids: selectedItems,
      status: 'delete'
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        handleReset();
        if (page === 1) {
          handleTrigger()
        } else {
          handleChangePage(0)
        }
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'});

        if (page === 1) {
          handleTrigger()
        } else {
          handleChangePage(0)

        }

      })

  }, [selectedItems, handleReset, handleChangePage, handleTrigger]);



  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={isFetching}/>
        <ResourceList
          items={items}
          order={order}
          handleChangeOrder={handleChangeOrder}
          renderItem={renderItem}
          selectedItems={selectedItems}
          appliedFilters={appliedFilters}
          handleChangeFilters={handleChangeFilters}
          handleChangeSelectedAll={handleChangeSelectedAll}
          handleChangeSelectedItems={handleChangeSelectedItems}
          headers={headers}
          searchValue={tempSearch}
          handleChangeSearch={setTempSearch}
          primaryAction={<AddButton onClick={() => handleChangeRoute('/streams/new')}/>}
          bulkActions={[
            {label: "Remove", onAction: handleRemove},
          ]}
        />
        <Pagination
          pages={pages}
          colSpan={headers.length}
          handlePageChange={handleChangePage}
          handleRowsChange={handleChangeLimit}
          limit={perPage}
          total={total}
          page={page}
        />
      </Paper>
    </div>
  );
};

export default StreamsList;

