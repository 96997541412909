import {
    FETCH_IMAGE_FILE_FAILURE,
    FETCH_IMAGE_FILE_REQUEST,
    FETCH_IMAGE_FILE_SUCCESS, FLUSH_IMAGE_FILE_DATA
} from "../constants/images";

import { fetchImageBase64, fetchPendingImageBase64 } from '../../helpers/api'

export const fetchImageFile = (id, type) => dispatch => {
    dispatch({type: FETCH_IMAGE_FILE_REQUEST});
    let promise;

    if(type === 'pending') {
      promise = fetchPendingImageBase64(id)
    }

    if(type === 'approved') {
      promise = fetchImageBase64(id)
    }

    if(!promise) {
      return dispatch({
        type: FETCH_IMAGE_FILE_FAILURE,
        message: error
      })
    }

    return promise
        .then(res => res.data.data)
        .then(base64 => {
        dispatch({
            type: FETCH_IMAGE_FILE_SUCCESS,
            payload: base64
        })
    }).catch(error => dispatch({
        type: FETCH_IMAGE_FILE_FAILURE,
        message: error
    }))
};

export const flushImageFile = () => dispatch => {
  dispatch({type: FLUSH_IMAGE_FILE_DATA});
};
