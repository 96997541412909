export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(0)
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        position: 'relative',
    },
    imageData: {
        paddingTop: theme.spacing(3),
    },
    actions: {
        paddingTop: theme.spacing(3),
    },
    actionsBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionBlock: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    updateProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: theme.spacing(2),
        width: '100%'
    },
    fieldsWrapper: {
        maxWidth: '80%',
        flex: '1 1 auto'
    },
    textField: {
        marginBottom: theme.spacing(4)
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        height: '300px',
        paddingBottom: theme.spacing(3)
    },
    uploadBlock: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});
