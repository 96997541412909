import {
    FETCH_VIDEO_FAILURE,
    FETCH_VIDEO_REQUEST,
    FETCH_VIDEO_SUCCESS,
    FLUSH_VIDEO_ERRORS,
    FLUSH_VIDEO_STATE,
    FLUSH_VIDEO_MESSAGES,
    UPDATE_VIDEO_FAILURE,
    UPDATE_VIDEO_REQUEST,
    UPDATE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAILURE,
    DELETE_VIDEO_REQUEST,
    DELETE_VIDEO_SUCCESS,
    REMOVE_VIDEO_FROM_LIST
} from "../constants/video";

import {
    fetchVideo,
    updateVideo as apiUpdateVideo,
    deleteVideo as apiDeleteVideo,
    apiRequest,
} from "../../helpers/api";

export const getVideo = id => dispatch => {
    dispatch({type: FETCH_VIDEO_REQUEST});
    return fetchVideo(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_VIDEO_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_VIDEO_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_VIDEO_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const flushVideoErrors = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_ERRORS});
};

export const flushVideoState = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_STATE});
};

export const updateVideoState = data => dispatch => {
    dispatch({type: UPDATE_VIDEO_REQUEST});
    const request = () => apiUpdateVideo(data);
    return apiRequest(request, dispatch, UPDATE_VIDEO_SUCCESS, UPDATE_VIDEO_FAILURE)
};

export const flushVideoStateMessages = () => dispatch => {
    dispatch({type: FLUSH_VIDEO_MESSAGES});
};

export const deleteVideoState = id => dispatch => {
    dispatch({type: DELETE_VIDEO_REQUEST});
    const request = () => apiDeleteVideo(id);
    return apiRequest(request, dispatch, DELETE_VIDEO_SUCCESS, DELETE_VIDEO_FAILURE);
};

export const removeVideoFromList = (id) => dispatch => {
    dispatch({
        type: REMOVE_VIDEO_FROM_LIST,
        payload: id
    })
};

