import {eventDelete, loadSingleEvent} from "../../../helpers/api";
import {
  EVENTS_REMOVE_REQUEST,
  EVENTS_REMOVE_SUCCESS,
  EVENTS_REMOVE_FAILURE,
} from "../../constants/events/remove";



export const removeEvent = (eventId) => dispatch => {
  dispatch({type: EVENTS_REMOVE_REQUEST});


  return eventDelete(eventId).then(
    res => {
      if (res.data.status === "error") {
        return dispatch({type: EVENTS_REMOVE_FAILURE, message: res.data.message})
      }
      return dispatch({
        type: EVENTS_REMOVE_SUCCESS,
        payload: res.data
      })
    },
    error => dispatch({type: EVENTS_REMOVE_FAILURE, message: error})
  )
};
