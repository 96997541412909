import {lighten} from "@material-ui/core/styles";

export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    buttons: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        // height: '100%',
    },
    filterButton: {
        display: 'flex',
        alignItems: 'flex-end'
    },
});
