import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Redirect, withRouter} from "react-router-dom";
import {
    checkSetFirstPasswordToken,
    flushLoginErrors, setAuthData, setFirstPassword, setFirstPasswordHash,
} from "../../../store/actions/auth";
import {
    authResultMessage, canUserSetFirstPassword,
    getAuthorizationError, getFirstPasswordHash,
    getIsFetching, hasFirstPasswordHash
} from "../../../store/reducers/auth";
import {connect} from "react-redux";
import Recaptcha from 'react-google-invisible-recaptcha';
import Copyright from "../../../common/Copyright";
import {withSnackbar} from "notistack";
import {checkPassword} from "../../../helpers/validation";
import {messages} from "../../../helpers/constants";
import SetPasswordAuthForm from "../../../common/SetPasswordAuthForm";
import LoadingFade from "../../../common/LoadingFade";

const initialState = {
    password: '',
    confirmPassword: '',
    errors: '',
    message: '',
    redirect: false,
    status: '',
    blocked: false,
};

class SetUserPass extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onError = this.onError.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = initialState;
    }

    componentDidMount() {
        const {match, setFirstPasswordHash, checkSetFirstPasswordToken} = this.props;
        if (!match.params.s) {
            return <Redirect to="/login"/>
        }
        setFirstPasswordHash(match.params.s);
        checkSetFirstPasswordToken({
            token: match.params.s
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setFirstPasswordErrors, hasFirstPasswordHash, canUserSetFirstPassword, isFetching} = this.props;

        if (prevProps.setFirstPasswordErrors !== setFirstPasswordErrors) {
            this.setState({
                errors: setFirstPasswordErrors.message,
            });
        }
        if (prevState.password !== this.state.password ||
            prevState.confirmPassword !== this.state.confirmPassword) {
            this.setState({
                errors: ""
            });
        }
        if (prevProps.resultMessage !== this.props.resultMessage) {
            this.setState({
                message: this.props.resultMessage
            });
        }
        if (hasFirstPasswordHash && !canUserSetFirstPassword && !isFetching) {
            this.props.flushLoginErrors();
            return (this.props.history.push('/login'));
        }
    }

    // TODO: implement circular process on request

    onSubmit(e) {
        e.preventDefault();
        this.setState(state => ({
            ...state,
            blocked: true,
        }));
        const {password, confirmPassword} = this.state;
        if (password !== confirmPassword) {
            return this.setState({errors: messages.auth.passwordsMatch})
        }
        if ((password && !checkPassword(password)) || password.length > 16) {
            return this.setState({
                errors: messages.auth.passwordRequirements
            })
        }
        this.recaptcha.execute();
    }

    onError() {
        this.setState(state => ({
            ...state,
            blocked: false,
        }));
    }

    onResolved() {
        this.props.setNewPassword({
            token: this.props.firstPasswordHash,
            password: this.state.password,
            gcaptcha: this.recaptcha.getResponse()
        }).then((res) => {
            if (this.props.setFirstPasswordErrors.status !== 'error') {
                this.props.setAuthData(res.data);
                this.props.enqueueSnackbar(messages.auth.passwordChangeSuccess, {variant: 'success'});
                return setTimeout(() => {
                    this.props.flushLoginErrors();
                    return this.setState({redirect: true})
                }, 2000);
            }
            this.props.enqueueSnackbar(this.props.setFirstPasswordErrors.message, {variant: 'error'});
        }, error => {
            // TODO: improve error handler
            this.props.enqueueSnackbar(messages.common.errors, {variant: 'error'});
            this.setState(state => ({
                ...state,
                blocked: false,
            }));
        });
    }

    onChange(e) {
        const {name, value} = e.target;
        this.setState(state => ({
            ...state,
            blocked: false,
            [name]: value
        }));
    }

    render() {
        const {classes, isFetching, canUserSetFirstPassword} = this.props;
        const {password, confirmPassword, errors, redirect, blocked} = this.state;

        if (redirect) {
            return (<Redirect to="/login"/>)
        }

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Set New Password
                    </Typography>
                    <SetPasswordAuthForm
                        isValidHash={canUserSetFirstPassword}
                        disabled={!confirmPassword || !password || isFetching}
                        isLoading={isFetching}
                        password={password}
                        confirmPassword={confirmPassword}
                        errors={errors}
                        onSubmit={this.onSubmit}
                        onChange={this.onChange}
                        blocked={blocked}
                    />
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <LoadingFade loading={isFetching}/>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.MIX_GCAPTCHA_DEV_SITE_KEY}
                    onResolved={this.onResolved}/>
            </Container>
        )
    }
}

SetUserPass.propTypes = {
    checkSetFirstPasswordToken: PropTypes.func.isRequired,
    setFirstPasswordErrors: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    hasFirstPasswordHash: PropTypes.bool.isRequired,
    setFirstPasswordHash: PropTypes.func.isRequired,
    firstPasswordHash: PropTypes.string,
    resultMessage: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
    setAuthData: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    checkSetFirstPasswordToken: checkSetFirstPasswordToken,
    setFirstPasswordHash: setFirstPasswordHash,
    flushLoginErrors: flushLoginErrors,
    setNewPassword: setFirstPassword,
    setAuthData: setAuthData,
};

const mapStateToProps = state => ({
    setFirstPasswordErrors: getAuthorizationError(state),
    resultMessage: authResultMessage(state),
    canUserSetFirstPassword: canUserSetFirstPassword(state),
    isFetching: getIsFetching(state),
    hasFirstPasswordHash: hasFirstPasswordHash(state),
    firstPasswordHash: getFirstPasswordHash(state),
});


export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SetUserPass)));
