export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CHECK_CHANGE_PASSWORD_TOKEN_REQUEST = 'CHECK_CHANGE_PASSWORD_TOKEN_REQUEST';
export const CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS = 'CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS';
export const CHECK_CHANGE_PASSWORD_TOKEN_FAILURE = 'CHECK_CHANGE_PASSWORD_TOKEN_FAILURE';
export const SET_RESET_PASSWORD_HASH = 'SET_RESET_PASSWORD_HASH';
export const FLUSH_LOGIN_ERRORS = 'FLUSH_LOGIN_ERRORS';
export const SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST = 'SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST';
export const SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS = 'SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS';
export const SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE = 'SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE';
export const BACK_TO_LOGIN = 'BACK_TO_LOGIN';
export const FETCH_AUTH_USER_DATA_REQUEST = 'FETCH_AUTH_USER_DATA_REQUEST';
export const FETCH_AUTH_USER_DATA_FAILURE = 'FETCH_AUTH_USER_DATA_FAILURE';
export const FETCH_AUTH_USER_DATA_SUCCESS = 'FETCH_AUTH_USER_DATA_SUCCESS';
export const SET_AUTH_USER_ID = 'SET_AUTH_USER_ID';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SEND_CODE_REQUEST = 'SEND_CODE_REQUEST';
export const SEND_CODE_FAILURE = 'SEND_CODE_FAILURE';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const CHECK_PRE_AUTH_ACTION_REQUEST = 'CHECK_PRE_AUTH_ACTION_REQUEST';
export const CHECK_PRE_AUTH_ACTION_SUCCESS = 'CHECK_PRE_AUTH_ACTION_SUCCESS';
export const CHECK_PRE_AUTH_ACTION_FAILURE = 'CHECK_PRE_AUTH_ACTION_FAILURE';
export const CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST = 'CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST';
export const CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS = 'CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS';
export const CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE = 'CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE';
export const SET_FIRST_PASSWORD_HASH = 'SET_FIRST_PASSWORD_HASH';
export const SET_USER_FIRST_PASSWORD_REQUEST = 'SET_USER_FIRST_PASSWORD_REQUEST';
export const SET_USER_FIRST_PASSWORD_SUCCESS = 'SET_USER_FIRST_PASSWORD_SUCCESS';
export const SET_USER_FIRST_PASSWORD_FAILURE = 'SET_USER_FIRST_PASSWORD_FAILURE';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
