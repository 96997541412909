import {createSelector} from 'reselect';
import {
    APPROVE_PENDING_IMAGE_FAILURE,
    APPROVE_PENDING_IMAGE_REQUEST,
    APPROVE_PENDING_IMAGE_SUCCESS,
    LOAD_PENDING_IMAGES_FAILURE,
    LOAD_PENDING_IMAGES_REQUEST,
    LOAD_PENDING_IMAGES_SUCCESS, REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE
} from "../constants/images";
import {
  CHANGE_IMAGES_STATUS_TO_PROGRESS,
  CREATE_IMAGES_BULK_ACTION_REQUEST,
  CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE,
  CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST,
  CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS,
} from '../constants/bulk'

const initialState = {
    isFetching: false,
    images: [],
    errors: false,
    count: 0,
    page: 0,
    limit: 10,
};

const imagesPending = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST:
        case LOAD_PENDING_IMAGES_REQUEST:
        case APPROVE_PENDING_IMAGE_REQUEST:
        case CREATE_IMAGES_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case APPROVE_PENDING_IMAGE_FAILURE:
        case APPROVE_PENDING_IMAGE_SUCCESS:
        case CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE:
        case CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case LOAD_PENDING_IMAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload
            };
        case LOAD_PENDING_IMAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                images: payload.data,
                page: payload.current_page,
                count: payload.total ? payload.total : 0,
                limit: payload.per_page ? parseInt(payload.per_page) : 10
            };
        default:
            return {
                ...state,
            };
    }
};

export default imagesPending;

export const getIsFetching = state => state.imagesPending.isFetching;
export const getPendingImagesErrors = state => state.imagesPending.errors;
export const getPendingImagesArr = state => state.imagesPending.images;
export const getPendingImagesCount = state => state.imagesPending.count;
export const getPendingImagesPage = state => state.imagesPending.page;
export const getPendingImagesLimit = state => state.imagesPending.limit;

export const pendingImagesSelector = createSelector([
    getPendingImagesArr,
    getIsFetching,
    getPendingImagesCount,
    getPendingImagesPage,
    getPendingImagesLimit,
    getPendingImagesErrors
], (images,
    isLoading,
    count,
    page,
    limit,
    errors
) => {
    return {
        images,
        isLoading,
        count,
        page,
        limit,
        errors
    };
});
