import React, {useCallback} from 'react';
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia'


const EventVideoItem = ({classes, src, item, onRemove, value, draggable = false}) => {

  const handleClick = useCallback(() => {
    if (typeof onRemove !== 'function') {
      return false;
    }
    onRemove([].concat(value.filter((i) => i.id !== item.id)))
  }, [value, item, onRemove]);

  // return (
  //   <div className={classes.root}>
  //     <Avatar className={classes.image} src={src}/>
  //     <div className={classes.overlay}>
  //       <ClearIcon className={classes.icon} onClick={handleClick}/>
  //     </div>
  //   </div>
  // );

  return (
    <ListItem className={draggable && classes.draggable || undefined}>
      <ListItemAvatar>
        <Avatar variant="rounded" >
          { src && <CardMedia
            image={src}
            title={item.name || item.title}
            className={classes.thumbnail}
          />
          }
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item.name || item.title}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onPointerDown={handleClick}
          // onClick={handleClick}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
};

export default EventVideoItem;
