export const styles = theme => ({
    containerWrapper: {
        height: '300px',
        width: '100%',
        '& .leaflet-container': {
            height: '300px',
            width: '100%'
        }
    }
});
