import {
    FETCH_TAG_FAILURE,
    FETCH_TAG_SUCCESS,
    FLUSH_TAG_ERRORS,
    FLUSH_TAG_STATE,
    FLUSH_TAG_MESSAGES,
    FETCH_TAG_REQUEST,
    UPDATE_TAG_FAILURE,
    UPDATE_TAG_REQUEST,
    UPDATE_TAG_SUCCESS,
    ADD_TAG_FAILURE,
    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    DELETE_TAG_FAILURE,
    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS
} from "../constants/tag";
import {DialogMessagesStatuses} from "../../constatns/statuses";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        name: '',
        tag_parent: '',
        related: ''
    },
};

const tag = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_TAG_REQUEST:
        case ADD_TAG_REQUEST:
        case UPDATE_TAG_REQUEST:
        case DELETE_TAG_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TAG_SUCCESS:
        case DELETE_TAG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
            };
        case UPDATE_TAG_FAILURE:
        case DELETE_TAG_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
                status: DialogMessagesStatuses.error,
                message: payload
            };
        case UPDATE_TAG_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message
            };
        case FETCH_TAG_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_TAG_SUCCESS:
        case ADD_TAG_FAILURE:
            return {
                ...state,
                errors: '',
                status: '',
                isFetching: false,
                data: payload.data
            };
        case FLUSH_TAG_STATE:
            return {
                ...state,
                data: initialState.data
            };
        case FLUSH_TAG_MESSAGES:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        case FLUSH_TAG_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: ''
            };
    }
};

export default tag;

export const getIsFetching = state => state.tag.isFetching;

export const getTagData = state => state.tag.data;

