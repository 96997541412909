export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(0)
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        position: 'relative',
    },
    container: {
        paddingTop: theme.spacing(3),
    },
    form: {
        margin: '20px'
    },
    btnWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '35px',
    },
    inputWrap: {
        width: '35%',
        margin: '20px',
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});
