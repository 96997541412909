export const styles = theme => ({
        fadeWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        loadingFade: {
            position: 'relative',
            backgroundColor: '#fff',
            zIndex: 10,
            opacity: 0.5,
            cursor: 'default',
            width: '100%',
            height: '100%'
        },
        circularWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        }
    }
);
