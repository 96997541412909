import {
    FETCH_IMAGES_TAX_CATEGORIES_FAILURE,
    FETCH_IMAGES_TAX_CATEGORIES_REQUEST,
    FETCH_IMAGES_TAX_CATEGORIES_SUCCESS,
    FETCH_IMAGES_TAX_TAGS_FAILURE,
    FETCH_IMAGES_TAX_TAGS_REQUEST,
    FETCH_IMAGES_TAX_TAGS_SUCCESS,
    FETCH_VIDEOS_TAX_CATEGORIES_FAILURE,
    FETCH_VIDEOS_TAX_CATEGORIES_REQUEST,
    FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS,
    FETCH_VIDEOS_TAX_TAGS_FAILURE,
    FETCH_VIDEOS_TAX_TAGS_REQUEST,
    FETCH_VIDEOS_TAX_TAGS_SUCCESS, FLUSH_IMAGES_TAX_STATE,
    FLUSH_VIDEOS_TAX_STATE
} from "../constants/taxonomies";
import {createSelector} from "reselect";
import {ADD_CATEGORY_SUCCESS} from "../constants/categories";
import {REMOVE_CATEGORY_FROM_LIST} from "../constants/category";
import {ADD_TAG_SUCCESS, REMOVE_TAG_FROM_LIST} from "../constants/tag";
import {REMOVE_VIDEO_CATEGORY_FROM_LIST} from "../constants/videoCategory";
import {DELETE_VIDEO_TAG_SUCCESS} from "../constants/videoTag";
import {
    CREATE_CATEGORIES_BULK_ACTION_SUCCESS,
    CREATE_TAGS_BULK_ACTION_SUCCESS,
    CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS,
    CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS
} from "../constants/bulk";
import {ADD_VIDEO_TAG_SUCCESS} from "../constants/videoTag";
import {ADD_VIDEO_CATEGORY_SUCCESS} from "../constants/videoCategory";

const initialState = {
    isFetching: false,
    imagesCategories: [],
    imagesTags: [],
    videosCategories: [],
    videosTags: [],
    errors: false,
};
const taxonomies = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_IMAGES_TAX_CATEGORIES_REQUEST:
        case FETCH_IMAGES_TAX_TAGS_REQUEST:
        case FETCH_VIDEOS_TAX_CATEGORIES_REQUEST:
        case FETCH_VIDEOS_TAX_TAGS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_IMAGES_TAX_CATEGORIES_FAILURE:
        case FETCH_IMAGES_TAX_TAGS_FAILURE:
        case FETCH_VIDEOS_TAX_CATEGORIES_FAILURE:
        case FETCH_VIDEOS_TAX_TAGS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_IMAGES_TAX_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                imagesCategories: payload.data
            };
        case FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                videosCategories: payload.data
            };
        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                imagesCategories: state.imagesCategories.concat([payload.data])
            };
        case CREATE_CATEGORIES_BULK_ACTION_SUCCESS:
            return {
                ...state,
                imagesCategories: state.imagesCategories.filter(item => !payload.data.ids.includes(item.id))
            };
        case CREATE_TAGS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                imagesTags: state.imagesTags.filter(item => !payload.data.ids.includes(item.id))
            };
        case CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS:
            return {
                ...state,
                videosCategories: state.videosCategories.filter(item => !payload.data.ids.includes(item.id))
            };
        case CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                videosTags: state.videosTags.filter(item => !payload.data.ids.includes(item.id))
            };
        case ADD_VIDEO_CATEGORY_SUCCESS:
            return {
                ...state,
                videosCategories: state.videosCategories.concat([payload.data])
            };
        case ADD_VIDEO_TAG_SUCCESS:
            return {
                ...state,
                videosTags: state.videosTags.concat([payload.data])
            };
        case REMOVE_CATEGORY_FROM_LIST:
            return {
                ...state,
                imagesCategories: state.imagesCategories.filter(category => category.id !== payload)
            };
        case REMOVE_VIDEO_CATEGORY_FROM_LIST:
            return {
                ...state,
                videosCategories: state.videosCategories.filter(category => category.id !== payload)
            };
        case ADD_TAG_SUCCESS:
            return {
                ...state,
                imagesTags: state.imagesTags.concat([payload.data])
            };
        case REMOVE_TAG_FROM_LIST:
            return {
                ...state,
                imagesTags: state.imagesTags.filter(tag => tag.id !== payload)
            };
        case DELETE_VIDEO_TAG_SUCCESS:
            console.log(payload, 'ptert')
            return {
                ...state,
                videosTags: state.videosTags.filter(tag => tag.id !== payload)
            };
        case FETCH_IMAGES_TAX_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                imagesTags: payload.data
            };
        case FETCH_VIDEOS_TAX_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                videosTags: payload.data
            };
        case FLUSH_VIDEOS_TAX_STATE:
            return {
                ...state,
                videosCategories: initialState.videosCategories,
                videosTags: initialState.videosTags,
            };
        case FLUSH_IMAGES_TAX_STATE:
            return {
                ...state,
                imagesCategories: initialState.imagesCategories,
                imagesTags: initialState.imagesTags,
            };
        default:
            return {
                ...state,
                isFetching: false,
            };
    }
};
export default taxonomies;

export const getIsFetching = state => state.taxonomies.isFetching;
export const getTaxErrors = state => state.taxonomies.errors;
export const getImagesTaxCategories = state => state.taxonomies.imagesCategories;
export const getImagesTaxTags = state => state.taxonomies.imagesTags;
export const getVideosTaxCategories = state => state.taxonomies.videosCategories;
export const getVideosTaxTags = state => state.taxonomies.videosTags;

export const imageCategoriesSelector = createSelector(getImagesTaxCategories, categories => categories);
export const imageTagsSelector = createSelector(getImagesTaxTags, tags => tags);
export const videosCategoriesSelector = createSelector(getVideosTaxCategories, categories => categories);
export const videosTagsSelector = createSelector(getVideosTaxTags, tags => tags);

export const imagesTaxonomiesSelector = createSelector(
    imageCategoriesSelector,
    imageTagsSelector, (imagesCategories, imagesTags) => ({imagesCategories, imagesTags})
);

export const videosTaxonomiesSelector = createSelector(
    videosCategoriesSelector,
    videosTagsSelector, (videosCategories, videosTags) => ({videosCategories, videosTags})
);
