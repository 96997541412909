import React from 'react'
import Typography from '@material-ui/core/Typography/'
import Avatar from '@material-ui/core/Avatar/'
import { makeStyles } from '@material-ui/core/styles'
import PublishStatusEvent from '../../../../common/PublishStatusEvent'
import ResourceListItem
  from '../../../../components/ResourceList/ResourceListItem'
import EventPendingItemFiles from './EventPendingItemFiles'

const useStyles = makeStyles(theme => ({
  size: {
    width: '100px',
    height: '100px',
  },
}))

const EventPendingListIItem = ({ item, handleChangeStatus, index, ...rest }) => {
  const thumbnail = item.images.map(obj => obj.location)
  const classes = useStyles()
  return (
    <ResourceListItem
      key={item.id}
      id={item.id}
      {...rest}
    >
      <Avatar
        className={classes.size}
        variant={'square'}
        sizes={'100px'}
        src={typeof thumbnail[0] !== 'undefined' ? thumbnail[0] : null}/>
      <Typography>{item.title}</Typography>
      <PublishStatusEvent
        id={item.id}
        status={item.status}
        handleChangeStatus={handleChangeStatus}
        onClick={() => false}/>
      <EventPendingItemFiles item={item} />
    </ResourceListItem>
  )
}

export default EventPendingListIItem
