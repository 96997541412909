import React, {useState} from "react";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {blockUser as apiBlockUser} from "../../store/actions/user";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import Box from "@material-ui/core/Box";
import {getAuthUserId} from "../../store/reducers/auth";

const StatusButton = ({status, children, classes, targetId}) => {
    const dispatch = useDispatch();
    const statusClassName = status && status.toLowerCase();
    const setStatus = (statusClassName === 'active' || statusClassName === 'pending') || !(statusClassName === 'blocked');
    const [anchorEl, setAnchorEl] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const currentUserId = useSelector(getAuthUserId);
    const [updateProcess, setUpdateProcess] = useState('');
    const disableCurrentUserChangeStatus = !!currentUserId && parseInt(currentUserId) === targetId;
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBlock = () => {
        setUpdateProcess('process');
        dispatch(apiBlockUser(targetId, setStatus)).then(
            res => {
                const {status, message} = res.payload;
                setUpdateProcess('');
                if (status === "error") {
                    return enqueueSnackbar(message, {variant: 'error'})
                }
                enqueueSnackbar(message, {variant: 'success'});
                // TODO: decide to use the download after updating the item
                // dispatch(getAdminsList());
            },
            err => {
                setUpdateProcess('');
                enqueueSnackbar(err.message, {variant: 'error'})
            }
        );
        handleClose();
    };
    // TODO: improve block/active menu render

    return (
        <Box key={targetId}>
            <Button
                disabled={updateProcess === 'process' || status === 'pending' || disableCurrentUserChangeStatus}
                onClick={handleClick}
                className={clsx(
                    classes.statusBadge,
                    classes.disabled,
                    classes[statusClassName],
                    classes[updateProcess]
                )}
            >
                {children}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {statusClassName === 'active' || statusClassName === 'pending' ?
                    <MenuItem onClick={handleBlock}>Block</MenuItem> :
                    <MenuItem onClick={handleBlock}>Active</MenuItem>}
            </Menu>
        </Box>
    );
};

export default StatusButton;


