import {apiUrl} from "./api";

export const checkHashEndpoints = {
    forgotPassword: '/auth/forgot_password_verify',
    approve_invite: '/auth/invitation_verify'
};

export const settings = {
    video: {
        uploading: {
            maxFileSize: 1024000000,
            endpoint: apiUrl + '/videos/upload_resumable',
            fileTypes: ["mp4", "MP4", "avi"],
            maxFiles: 5,
            maxChunkRetries: 10
        }
    }
};

export const messages = {
    common: {
        errors: 'Errors!',
    },
    auth: {
        passwordsMatch: 'Passwords do not match!',
        passwordRequirements: 'The password must contain numbers, upper and lower case letters, one of special characters !@#$%^&*; and at least 8 and no more than 16 characters!',
        passwordChangeSuccess: 'Password was changed!',
    },
    bulkUpload: {
        start: 'Unloading process started!',
        dropzone: 'Drag \'n\' drop video files here, or click to select files',
        dropzoneAdditionalText: '(Only *.mp4 and *.avi videos will be accepted)',
        maxFilesText: 'The maximum number of uploaded files is ',
        errors: {
            fileType: 'File type not supported!',
            maxFiles: 'Maximum number of uploaded files '
        },
    },
    statuses: {
        process: 'In progress'
    }
};
