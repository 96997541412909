import {createSelector} from 'reselect';
import {
    LOAD_VIDEO_CATEGORIES_REQUEST,
    LOAD_VIDEO_CATEGORIES_ERRORS,
    LOAD_VIDEO_CATEGORIES_FAILURE,
    LOAD_VIDEO_CATEGORIES_SUCCESS
} from "../constants/videoCategories";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import {REMOVE_VIDEO_CATEGORY_FROM_LIST, UPDATE_VIDEO_CATEGORY_SUCCESS} from "../constants/videoCategory";
import {
    CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST,
    CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS,
    UPDATE_VIDEO_CATEGORIES_AFTER_BULK
} from "../constants/bulk";

const initialState = {
    isFetching: false,
    video_categories: [],
    errors: false,
    updateErrors: {},
    status: '',
    message: '',
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10,
};

const video_categories = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_VIDEO_CATEGORIES_REQUEST:
        case CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_VIDEO_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                video_categories: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case UPDATE_VIDEO_CATEGORY_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                video_categories: state.video_categories.map((category) => category.id === data.id ? data : category)
            };
        case REMOVE_VIDEO_CATEGORY_FROM_LIST:
            return {
                ...state,
                video_categories: state.video_categories.filter(category => category.id !== payload),
            };
        case LOAD_VIDEO_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_VIDEO_CATEGORIES_AFTER_BULK:
            return {
                ...state,
                video_categories: state.video_categories.filter(category => !payload.includes(category.id))
            };
        case LOAD_VIDEO_CATEGORIES_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
            };
    }
};

export default video_categories;

export const getIsFetching = state => state.video_categories.isFetching;
export const getVideoCategoryOrderBy = state => state.video_categories.orderBy;
export const getVideoCategoryArr = state => state.video_categories.video_categories;
export const getVideoCategoryCount = state => state.video_categories.count;
export const getVideoCategoryPage = state => state.video_categories.page;
export const getVideoCategoryLimit = state => state.video_categories.limit;
export const getVideoCategoryOrder = state => state.video_categories.order;

export const videoCategoriesSelector = createSelector([
    getVideoCategoryArr,
    getIsFetching,
    getVideoCategoryCount,
    getVideoCategoryPage,
    getVideoCategoryLimit,
    getVideoCategoryOrder,
    getVideoCategoryOrderBy,
], (video_categories,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
) => {
    return {
        video_categories,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
    };
});
