import axios from "axios";

export const getImageFromUrl = (url) => {
    return fetch(url);
    // return axios.get(url, {headers:{
    //         Origin: window.location.origin
    //     }});
};

export const getFileNameFromUrl = url => {
    return url.split('/').pop();
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
        resolve(reader.result)
    };
    reader.onerror = error => reject(error);
});

export const createFileObject = async (file) => {
    await toBase64(file);
};

export const handleFileDataAll = (file, callback) => {
    const promises = [
        new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                resolve({
                    base64: reader.result,
                    type: fileExtension(file.type)
                });
            };
        }),
        new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                resolve({
                    arrayBuffer: reader.result,
                    type: fileExtension(file.name)
                });
            };
        })
    ];

    Promise.all(promises).then((data) => {
        callback(data);
    })
};

export const handleFileData = (file, handler) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
        handler(reader.result);
    };
};

export const handleFileDataAsArray = (file, handler) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
        handler(reader.result);
    };
};

export const fileExtension = (fileName) => {
    if (typeof fileName !== 'string') {
        return null;
    }
    return fileName.split('.').pop();
};

export const getMimeType = dataUrl => {
    let res = null;

    if (typeof dataUrl !== 'string') {
        return res;
    }

    const mimeType = dataUrl.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mimeType && mimeType.length) {
        res = mimeType[1];
    }

    return res;
};

export const dataUrlToBase64 = (dataUrl) => {
  return dataUrl.slice(dataUrl.indexOf(','))
}

export const dataUrlToFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], filename, {type: mimeType})));
};
