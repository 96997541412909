import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchImageFile} from "../store/actions/file";
import {getImageFile, getIsFetching} from "../store/reducers/file";

export const useImageBase64 = (id) => {
  const dispatch = useDispatch();
  const file = useSelector(getImageFile);
  const loadingFile = useSelector(getIsFetching);

  useEffect(() => {
    if (!!id) {
      dispatch(fetchImageFile(id, 'approved'))
    }
  }, [dispatch, id]);
  return {
    file,
    loadingFile
  };
};
