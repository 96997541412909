import thunk from "redux-thunk";
import {createLogger} from "redux-logger";
import {applyMiddleware, createStore} from "redux";
import {createBrowserHistory} from "history";
import createRootReducer from "./reducers/";
import {routerMiddleware} from "connected-react-router";
import {composeWithDevTools} from "redux-devtools-extension";
import {token} from "./middlewares/token";

export const history = createBrowserHistory();

const configureStore = () => {
    const reducer = createRootReducer(history);

    let middleWares = [thunk, routerMiddleware(history)];

    if (process.env.NODE_ENV !== 'production') {
        const logger = createLogger({
            collapsed: true,
        });
        middleWares.push(logger);
    }

    const store = createStore(reducer, composeWithDevTools(applyMiddleware(token, ...middleWares)));

    return store;
};

export default configureStore;
