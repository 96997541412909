import React, {useEffect} from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {Field} from 'redux-form';
import FormSelect from "../../../../components/ReduxFormFields/FormSelect";

const EventType = ({classes, ...rest}) => {

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="event-type-label" ref={inputLabel}>
        Type
      </InputLabel>
      <Field
        name={"type"}
        component={FormSelect}
        labelId={"event-type-label"}
        labelWidth={labelWidth}
        id={"event-type"}
        options={[
          {label: 'Tropical Coverage', value: "tropical_coverage"},
          {label: 'Winter Storm', value: "winter_storm"},
          {label: 'Storm Coverage', value: "storm_coverage"},
          {label: 'Severe Weather', value: "severe_weather"}
        ]}
        {...rest}
      />
    </FormControl>
  );
};

export default EventType;
