export const styles = theme => ({
    badge: {
        verticalAlign: 'inherit',
        '&>span': {
            right: -theme.spacing(6),
            color: '#fff',
            borderRadius: theme.spacing()/2,
            textTransform: 'uppercase',
        }
    },
    active: {
        '&>span': {
            backgroundColor: theme.palette.success.main
        }
    },
    pending: {
        '&>span': {
            backgroundColor: theme.palette.warning.main
        }
    },
    in_process: {
        '&>span': {
            backgroundColor: theme.palette.info.main
        }
    },
    blocked: {
        '&>span': {
            backgroundColor: theme.palette.error.main
        }
    },
    approved: {
        '&>span': {
            backgroundColor: theme.palette.success.main
        }
    },
    expired: {
        '&>span': {
            backgroundColor: theme.palette.process.main,
            color: theme.palette.process.contrastText
        }
    }
});
