export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary,
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    tableRow: {
        cursor: 'pointer'
    },
    id: {
        paddingRight: 0
    },
    delete: {
      textAlign: "center"
    },
    pointerDefault: {
        cursor: 'not-allowed'
    },
});
