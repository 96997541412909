import {loadEventsList} from "../../../helpers/api";
import {
  LOAD_EVENTS_LIST_FAILURE,
  LOAD_EVENTS_LIST_REQUEST,
  LOAD_EVENTS_LIST_SUCCESS
} from "../../constants/events/list";

const queryToString = (filter) => {

  let query = [];

  let keys = Object.keys(filter);
  for (let i = 0 ; i < keys.length; i++){
    if(!filter[keys[i]]){
      continue;
    }
     query.push(`${keys[i]}=${filter[keys[i]]}`)
  }

  return query.join('&')
};

export const eventsList = (filters) => dispatch => {
  dispatch({type: LOAD_EVENTS_LIST_REQUEST});


  return loadEventsList('/events', queryToString(filters)).then(
    res => {
      if (res.data.status === "error") {
        return dispatch({type: LOAD_EVENTS_LIST_FAILURE, message: res.data.message})
      }
      return dispatch({
        type: LOAD_EVENTS_LIST_SUCCESS,
        payload: res.data
      })
    },
    error => dispatch({type: LOAD_EVENTS_LIST_FAILURE, message: error})
  )
};
