export const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    toolbar: {
        paddingLeft: theme.spacing(0),
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    updateProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: theme.spacing(2),
        width: '100%'
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    actionsBlock: {
        '&:first-child' : {
            justifyContent: 'flex-start',
        },
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actions: {
        paddingTop: theme.spacing(3),
    },
    textField: {
        margin: theme.spacing(2),
    },
    inputPadding: {
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: "19px",
        },
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px',
    },
    containerSelect: {
        display: "flex",
        alignItems: "center",
        margin: "25px"
    },
    sectionInput: {
        marginRight: "15px",
        width: "70%",
    },
    list: {
        padding: '10px',
        width: "80%",
    },
});
