import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/";

const FormSelect = ({input: {value, onChange, onBlur}, meta, type, options, ...rest}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      {...rest}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
      ))}
    </Select>

  );
};

export default FormSelect;