import React, {Fragment, useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from "react-redux";
import FlashMessage from "react-flash-message";
import {getInviteResult, getIsFetching, getUserErrors, getUserMessage} from "../../store/reducers/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import DialogMessages from "../../common/DialogMessages";

const {success} = DialogMessagesStatuses;

const ConfirmDeleteDialog = ({
                                 id,
                                 title,
                                 open,
                                 onClose,
                                 deleteItemAction,
                                 deleteErrors,
                                 isFetching,
                                 classes,
                                 deleteResult,
                                 deleteMessage,
                             }) => {
    const [deleteStatus, setDeleteStatus] = useState('')
    const [errors, setErrors] = useState('');

    const deleteAction = (e) => {
        e.preventDefault();
        deleteItemAction(id);
        onClose()
    };

    const closeDialog = () => {
        setDeleteStatus('');
        onClose();
    };
    useEffect(() => {
        setErrors(deleteErrors);
    }, [deleteErrors]);


    useEffect(() => {
        setDeleteStatus(deleteResult);
    }, [deleteResult]);

    const DialogMessage = () => (
        <Fragment>
            <DialogMessages status={deleteStatus} message={deleteMessage}/>
            <DialogActions className={classes.actionsBlock}>
                <Button
                    onClick={closeDialog}
                    color="primary"
                    variant="contained"
                >
                    Ok
                </Button>
            </DialogActions>
        </Fragment>
    );
    // TODO: implement more data display in dialog
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <form onSubmit={deleteAction}>
                    <DialogTitle className={classes.title}>Remove</DialogTitle>
                    {deleteStatus !== success ?
                        <Fragment>
                            <DialogContent className={classes.content}>
                                {isFetching &&
                                <div className={classes.onFetching}>
                                    <CircularProgress/>
                                </div>}
                                <DialogContentText>
                                   Confirm delete {title}.
                                </DialogContentText>

                                {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                                    <h5 className={"alert alert-danger"}>Error: {deleteErrors}</h5></FlashMessage> : ''}
                            </DialogContent>
                            <DialogActions className={classes.actionsBlock}>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={isFetching}
                                >
                                    Confirm Delete
                                </Button>
                                <Button
                                    onClick={closeDialog}
                                    color="primary"
                                    variant="contained"
                                    disabled={isFetching}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Fragment> : <DialogMessage/>}
                </form>
            </Dialog>
        </div>);
};

ConfirmDeleteDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    deleteItemAction: PropTypes.func.isRequired,
    deleteErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    isFetching: PropTypes.bool.isRequired,
    deleteResult: PropTypes.string,
    deleteMessage: PropTypes.string,
    title: PropTypes.string,
};

const mapStateToProps = state => ({
    isFetching: getIsFetching(state),
    deleteErrors: getUserErrors(state),
    deleteResult: getInviteResult(state),
    deleteMessage: getUserMessage(state),
});

export default connect(mapStateToProps, {})(ConfirmDeleteDialog);
