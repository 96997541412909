export const LOAD_IMAGES_REQUEST = 'LOAD_IMAGES_REQUEST';
export const LOAD_IMAGES_FAILURE = 'LOAD_IMAGES_FAILURE';
export const LOAD_IMAGES_SUCCESS = 'LOAD_IMAGES_SUCCESS';
export const FETCH_IMAGE_SUCCESS = 'FETCH_IMAGE_SUCCESS';
export const FETCH_IMAGE_REQUEST = 'FETCH_IMAGE_REQUEST';
export const FETCH_IMAGE_FAILURE = 'FETCH_IMAGE_FAILURE';
export const FLUSH_IMAGE_ERRORS = 'FLUSH_IMAGE_ERRORS';
export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST';
export const UPDATE_IMAGE_FAILURE = 'UPDATE_IMAGE_FAILURE';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const FETCH_IMAGE_FILE_REQUEST = 'FETCH_IMAGE_FILE_REQUEST';
export const FETCH_IMAGE_FILE_SUCCESS = 'FETCH_IMAGE_FILE_SUCCESS';
export const FETCH_IMAGE_FILE_FAILURE = 'FETCH_IMAGE_FILE_FAILURE';
export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const REMOVE_IMAGE_FROM_LIST = 'REMOVE_IMAGE_FROM_LIST';
export const FLUSH_IMAGE_STATE = 'FLUSH_IMAGE_STATE';
export const CREATE_IMAGE_REQUEST = 'CREATE_IMAGE_REQUEST';
export const CREATE_IMAGE_SUCCESS = 'CREATE_IMAGE_SUCCESS';
export const CREATE_IMAGE_FAILURE = 'CREATE_IMAGE_FAILURE';
export const FLUSH_IMAGE_FILE_DATA = 'FLUSH_IMAGE_FILE_DATA';
export const LOAD_PENDING_IMAGES_REQUEST = 'LOAD_PENDING_IMAGES_REQUEST';
export const LOAD_PENDING_IMAGES_SUCCESS = 'LOAD_PENDING_IMAGES_SUCCESS';
export const LOAD_PENDING_IMAGES_FAILURE = 'LOAD_PENDING_IMAGES_FAILURE';
export const APPROVE_PENDING_IMAGE_REQUEST = 'APPROVE_PENDING_IMAGE_REQUEST';
export const APPROVE_PENDING_IMAGE_FAILURE = 'APPROVE_PENDING_IMAGE_FAILURE';
export const APPROVE_PENDING_IMAGE_SUCCESS = 'APPROVE_PENDING_IMAGE_SUCCESS';
export const REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE = 'REMOVE_PENDING_IMAGE_FROM_LIST_AFTER_APPROVE';
export const EDIT_PENDING_IMAGE = 'EDIT_PENDING_IMAGE';
export const FLUSH_EDIT_PENDING_IMAGE_DATA = 'FLUSH_EDITING_PENDING_IMAGE_DATA';
export const EDIT_APPROVED_IMAGE = 'EDIT_APPROVED_IMAGE';
export const FETCH_PENDING_IMAGE_DATA_REQUEST = 'FETCH_PENDING_IMAGE_DATA_REQUEST';
export const FETCH_PENDING_IMAGE_DATA_SUCCESS = 'FETCH_PENDING_IMAGE_DATA_SUCCESS';
export const FETCH_PENDING_IMAGE_DATA_FAILURE = 'FETCH_PENDING_IMAGE_DATA_FAILURE';
