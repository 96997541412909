import React from 'react'
import TableCell from '@material-ui/core/TableCell/'
import Typography from '@material-ui/core/Typography/'
import Avatar from '@material-ui/core/Avatar/'
import { makeStyles } from '@material-ui/core/styles'
import PublishStatus from '../../../../common/PublishStatus'
import ResourceListItem
  from '../../../../components/ResourceList/ResourceListItem'
import StatusChip from '../../../../common/StatusChip'

const useStyles = makeStyles(theme => ({
  size: {
    width: '100px',
    height: '100px',
  },
}))

const EventListIItem = ({ item, handleChangeStatus, ...rest }) => {
  const classes = useStyles()

  return (
    <ResourceListItem
      key={item.id}
      {...rest}
      id={item.id}
    >
      <Typography>{item.id}</Typography>
      <Avatar className={classes.size} variant={'square'} sizes={'100px'}
              src={!!item.primary_image ? item.primary_image.thumbnail : null}/>
      <Typography>{item.title}</Typography>
      <Typography>{item.type || '-'}</Typography>
      <Typography>{item.start_at || '-'}</Typography>
      <Typography>{item.end_at || '-'}</Typography>
      <StatusChip status={item.status} />
      <PublishStatus id={item.id}
                     published={item.published}
                     status={item.status}
                     handleChangeStatus={handleChangeStatus}
                     onClick={() => false}/>
    </ResourceListItem>
  )
}

export default EventListIItem
