import React, {Fragment, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import StatusButton from "../StatusButton";
import Chip from "@material-ui/core/Chip";
import {timeago} from "../../helpers/timeago";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {Checkbox, IconButton} from "@material-ui/core";
import GravatarIcon from "../GravatarIcon";
import TrimmedTitle from "../TrimmedTitle";
import StatusButtonImage from "../StatusButtonImage";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import StatusChip from "../StatusChip";
import {messages} from "../../helpers/constants";

const useStyles = makeStyles(theme => ({
    paperWrap: {
        padding: '20px',
        width: '90vh',
        maxWidth: '1200px',
        maxHeight: '1200px',
        position: 'relative',
        zIndex: "9999",
        '&::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        }
    },
    imageBlock: {
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'absolute',
        left: '20px',
        top: '20px'
    }
}));

const Icon = ({data, size, type}) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {full_image, location, thumbnail} = data;
    const preview = full_image ? full_image : location;
    const thumbSrc = thumbnail ? thumbnail : preview;

    if (!data) {
        return null;
    }

    const handleHover = event => {
        if (type === 'videos' && data.status === 'process') {
            return null
        }
        setAnchorEl(event.currentTarget);
        setShow(true);
    };
    const handleLeave = event => {
        if (type === 'videos' && data.status === 'process') {
            return null
        }
        setAnchorEl(null);
        setShow(false);
    };

    if (type === 'eventsPending') {
        const avatar = data.images && data.images.map(image => image.location);
        return ( <Avatar
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            style={{width: '100px', height: '100px'}} variant="square" src={avatar[0]}
        />)
    }

    if (thumbSrc) {

        return (<Fragment>
                <Popper
                    open={show}
                    anchorEl={anchorEl}
                    placement={`${data.rowIndexInList < 3 ? 'right' : 'right-end'}`}
                    transition
                    style={{ zIndex: 9999, marginTop: "20px"}}
                >
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.paperWrap}>
                                <div className={classes.imageBlock}
                                    style={{backgroundImage: `url("${full_image ? full_image : location}")`}}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                <Avatar
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    style={{width: '100px', height: '100px'}} variant="square" src={thumbSrc}
                />
            </Fragment>
        )
    }

    return (<GravatarIcon hash={data.gravatar} size={size}/>);
};

const TaxonomiesChips = ({data}) => {
    if (!data || !Array.isArray(data) || !data.length) {
        return null;
    }

    return (data.map((item, i) => (
        <Chip
            key={item.name + i}
            size="small"
            variant="outlined"
            style={{margin: '0 2px 2px 0'}}
            label={item.name}>
        </Chip>)));
};

const TableRowCellData = ({type, row, property, deleteAction, selected, onSelect}) => {
    if (!row) {
        return null;
    }
    const disabledInProcess = row.status && row.status === 'process';

    const onSelectItem = row => {
        const target = type === 'imagesPending' ? row.location : row.id;
        onSelect(target)
    };

    const checkboxValue = type === 'imagesPending' ? row.location : row.id;

    switch (true) {
        case property === 'id':
            return (
                <Typography># {row[property]}</Typography>);
        case property === 'icon' || property === 'thumbnail':
            return (<Icon data={row} type={type}/>);
        case type === 'admins' && property === 'status':
            return (
                <StatusButton
                    status={row[property]}
                    targetId={row.id}
                >
                    {row[property]}
                </StatusButton>
            );
        case type === 'videos' && property === 'status':
            return (<StatusChip
                    status={row[property]}
                    text={messages.statuses[row[property]]}
                />);
        case property === 'status' && type !== 'videos':
            return (<StatusButtonImage
                status={row[property]}
                target={row}
                type={type}
                disabled={disabledInProcess}
            >
                {row[property]}
            </StatusButtonImage>);
        case property === 'categories' || property === 'tags':
            return (<TaxonomiesChips data={row[property]}/>);
        case property === 'created_at':
            if (isNaN(Date.parse(row[property]))) {
                return '';
            }
            if (type === 'admins') {
                return new Date(row[property]).toLocaleDateString("en-US", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
            }
            return timeago(row[property]);
        case property === 'delete':
            return (<IconButton onClick={() => deleteAction(row.id)}>
                <DeleteForeverIcon/>
            </IconButton>);
        case property === 'bulk':
            return (<Checkbox
                value={checkboxValue}
                color="primary"
                checked={Array.isArray(selected) && selected.indexOf(checkboxValue) !== -1}
                disabled={disabledInProcess}
                onChange={() => onSelectItem(row)}
            />);
        case property === 'name':
            if (type === 'images') {
                return (<TrimmedTitle length={15} title={row[property]}/>);
            }
            return row[property];
        case property === 'title':
            if (type === 'images') {
                return (<TrimmedTitle length={15} title={row[property]}/>);
            }
            return row[property];
        case property === 'location':
            return row[property].split('/').slice(-1).pop();
        default:
            return row[property];
    }
};

export default TableRowCellData;


