export const styles = theme => ({
    wrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '50%'
    },
    holder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  }
)
