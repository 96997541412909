import localStorageHelper from "../helpers/localStorage";
import {
    getAccessToken as apiGetAccessToken,
    userLogin as apiUserLogin,
    resetPassword as apiResetPassword,
    checkChangePasswordToken as apiCheckChangePasswordToken,
    setNewUserPassword as apiSetNewUserPassword,
    fetchUser as apiFetchUser,
    checkHash as apiCheckHash,
    apiRequest,
    setFirstPassword as apiSetFirstPassword, userLogout,
} from "../../helpers/api";
import {
    BACK_TO_LOGIN,
    CHECK_CHANGE_PASSWORD_TOKEN_FAILURE,
    CHECK_CHANGE_PASSWORD_TOKEN_REQUEST,
    CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS,
    CHECK_PRE_AUTH_ACTION_FAILURE,
    CHECK_PRE_AUTH_ACTION_REQUEST,
    CHECK_PRE_AUTH_ACTION_SUCCESS,
    CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE,
    CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST,
    CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS,
    FETCH_AUTH_USER_DATA_FAILURE,
    FETCH_AUTH_USER_DATA_REQUEST,
    FETCH_AUTH_USER_DATA_SUCCESS,
    FLUSH_LOGIN_ERRORS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    SEND_CODE_FAILURE,
    SEND_CODE_REQUEST,
    SEND_CODE_SUCCESS, SET_AUTH_DATA,
    SET_AUTH_USER_ID,
    SET_FIRST_PASSWORD_HASH,
    SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE,
    SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST,
    SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS,
    SET_RESET_PASSWORD_HASH,
    SET_USER_FIRST_PASSWORD_FAILURE,
    SET_USER_FIRST_PASSWORD_REQUEST,
    SET_USER_FIRST_PASSWORD_SUCCESS
} from "../constants/auth";
import {checkHashEndpoints} from "../../helpers/constants";


export const logoutAction = () => (dispatch) => {
    console.log('logout action call');
    userLogout().finally(() => {
        dispatch({type: LOGOUT});
        window.location.href = '/';
        localStorageHelper.removeToken();
    });
};

export const loginAction = (data) => (dispatch) => {
    dispatch({type: LOGIN_REQUEST});
    apiUserLogin(data).then(
        response => {
            const {status} = response.data;

            if (status !== "success") {
                return dispatch({type: LOGIN_FAILURE, payload: response.data});
            }

            dispatch({type: LOGIN_SUCCESS, payload: response.data});
        },
        error => dispatch({type: LOGIN_FAILURE, payload: error.response.data.message})
    )
};

export const checkHashAction = (actionEndpoint, data) => (dispatch) => {
    dispatch({type: CHECK_PRE_AUTH_ACTION_REQUEST});

    const request = () => apiCheckHash(actionEndpoint, data);
    return apiRequest(request, dispatch, CHECK_PRE_AUTH_ACTION_SUCCESS, CHECK_PRE_AUTH_ACTION_FAILURE);
};

export const setAuthData = (data) => (dispatch) => {
    const {access_token, expires_in} = data;
    localStorageHelper.setToken({access_token, expires_in});
    localStorageHelper.setUserId(access_token);

    dispatch({
        type: SET_AUTH_DATA,
        payload: data
    });

};

export const checkSetFirstPasswordToken = (data) => (dispatch) => {
    dispatch({type: CHECK_SET_FIRST_PASSWORD_TOKEN_REQUEST});
    return apiCheckHash(checkHashEndpoints.approve_invite, data).then(
        res => {
            if (res.data.status !== "success") {
                return dispatch({type: CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE, payload: res.data})
            }
            dispatch({type: CHECK_SET_FIRST_PASSWORD_TOKEN_SUCCESS, payload: res.data});
        },
        error => {
            return dispatch({type: CHECK_SET_FIRST_PASSWORD_TOKEN_FAILURE, payload: error.response.data.message});
        }
    );
};

export const getTokenAction = data => dispatch => {
    dispatch({type: SEND_CODE_REQUEST});
    apiGetAccessToken(data).then(
        res => {
            const {access_token, expires_in, status} = res.data;

            if (status !== "success") {
                return dispatch({type: SEND_CODE_FAILURE, payload: res.data});
            }

            localStorageHelper.setToken({access_token, expires_in});
            localStorageHelper.setUserId(access_token);
            dispatch({type: SEND_CODE_SUCCESS, payload: res.data});
        },
        error => dispatch({type: SEND_CODE_FAILURE, payload: error.response.data.message})
    )
};

export const resetPassword = data => dispatch => {
    dispatch({type: RESET_PASSWORD_REQUEST});
    return apiResetPassword(data).then(
        res => {
            const {status} = res.data;

            if (status !== "success") {
                return dispatch({type: RESET_PASSWORD_FAILURE, payload: res.data});
            }

            dispatch({type: RESET_PASSWORD_SUCCESS, payload: res.data});
        },
        error => dispatch({type: RESET_PASSWORD_FAILURE, payload: error.response.data.message})
    )
};

export const checkChangePasswordToken = token => dispatch => {
    dispatch({type: CHECK_CHANGE_PASSWORD_TOKEN_REQUEST});
    return apiCheckChangePasswordToken(token).then(
        res => {
            if (res.data.status !== "success") {
                return dispatch({type: CHECK_CHANGE_PASSWORD_TOKEN_FAILURE, payload: res.data})
            }
            dispatch({type: CHECK_CHANGE_PASSWORD_TOKEN_SUCCESS, payload: res.data});
        },
        error => {
            return dispatch({type: CHECK_CHANGE_PASSWORD_TOKEN_FAILURE, payload: error.response.data.message});
        }
    )
};

export const setResetPasswordHash = hash => dispatch => {
    dispatch({type: SET_RESET_PASSWORD_HASH, payload: hash});
};

export const setFirstPasswordHash = hash => dispatch => {
    dispatch({type: SET_FIRST_PASSWORD_HASH, payload: hash});
};

export const flushLoginErrors = () => dispatch => {
    dispatch({type: FLUSH_LOGIN_ERRORS});
};

export const backToLogin = () => dispatch => {
    dispatch({type: BACK_TO_LOGIN});
};

export const getAuthUser = id => dispatch => {
    dispatch({type: FETCH_AUTH_USER_DATA_REQUEST});
    return apiFetchUser(id).then(
        res => {
            if (!!res.data.errors || res.data.status === "error") {
                return dispatch({
                    type: FETCH_AUTH_USER_DATA_FAILURE,
                    payload: res.data.message
                })
            }
            return dispatch({
                type: FETCH_AUTH_USER_DATA_SUCCESS,
                payload: res.data
            });
        },
        error => dispatch({
            type: FETCH_AUTH_USER_DATA_FAILURE,
            payload: error.response.data.message
        })
    );
};

export const setNewPasswordAfterReset = data => dispatch => {
    dispatch({type: SET_NEW_USER_PASSWORD_AFTER_RESET_REQUEST});
    return apiSetNewUserPassword(data).then(
        res => {
            if (res.data.status !== "success") {
                return dispatch({type: SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE, payload: res})
            }
            dispatch({type: SET_NEW_USER_PASSWORD_AFTER_RESET_SUCCESS, payload: res.data});
        },
        error => {
            return dispatch({type: SET_NEW_USER_PASSWORD_AFTER_RESET_FAILURE, payload: error.response.data});
        }
    )
};

export const setFirstPassword = data => dispatch => {
    dispatch({type: SET_USER_FIRST_PASSWORD_REQUEST});

    const request = () => apiSetFirstPassword(data);
    return apiRequest(request, dispatch, SET_USER_FIRST_PASSWORD_SUCCESS, SET_USER_FIRST_PASSWORD_FAILURE);

};

export const setAuthUserId = () => dispatch => {
    dispatch({
        type: SET_AUTH_USER_ID,
        payload: localStorageHelper.get('user_id')
    })
};
