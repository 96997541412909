import React from "react";
import Chip from "@material-ui/core/Chip";
import {ucFirst} from "../../helpers/strings";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const FilterChips = ({classes, chipsData, onDeleteChips}) => {

    return (
        <Box>
            {chipsData.map(item => {
                if (Array.isArray(item.value)) {
                    return (item.value.map(t => (<Chip
                        key={t.id}
                        // label={`${ucFirst(item.key)}:${t.name}`}
                        label={t.name}
                        onDelete={() => onDeleteChips(
                            [...chipsData.map(applied => {
                                if (applied.key === item.key) {
                                    return {
                                        ...applied,
                                        value: [...applied.value.filter(i => i.id !== t.id)]
                                    }
                                }
                                return applied;
                            })]
                        )}
                        className={clsx(classes.filterChip, classes[item.key])}
                    />)))
                }
                return (<Chip
                    key={item.key}
                    // label={`${ucFirst(item.key)}:${ucFirst(item.value)}`}
                    label={ucFirst(item.value).replace('_', ' ')}
                    onDelete={() => onDeleteChips([...chipsData.filter(t => t.key !== item.key)])}
                    className={clsx(classes.filterChip, classes[item.key])}
                />)
            })}
        </Box>
    );
}

export default FilterChips;


