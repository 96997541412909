import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventsPendingsList } from '../../../store/actions/events/eventsPending'
import Paper from '@material-ui/core/Paper/Paper'
import LoadingFade from '../../../common/LoadingFade'
import ResourceList from '../../../components/ResourceList'
import { pendingEventsSelector } from '../../../store/reducers/events/selectors'
import useSelectedItems from '../../../hooks/useSelectedItems'
import Pagination from '../../../components/Pagination'
import usePagination from '../../../hooks/usePagination'
import useSearch from '../../../hooks/useSearch'
import useFilters from '../../../hooks/useFilters'
import { reduce, debounce } from 'lodash'
import {
  changeEventsStatusToProgress,
  deletePendingEvent, removePendingEventFromList,
  updatePendingEvent,
} from '../../../store/actions/events/bulkDelete'
import useToggle from '../../../hooks/useToggle'
import useOrder from '../../../hooks/useOrder'
import { useSnackbar } from 'notistack'
import EventPendingListItem from './EventPendingListItem'
import {
  DELETE_EVENT_PENDING_FAILURE,
  DELETE_EVENT_PENDING_SUCCESS,
} from '../../../store/constants/events/eventPending'
import { fetchCounts } from '../../../store/actions/counts'
import { getErrorMessage } from '../../../helpers/validation'

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const EventsListPending = ({ classes }) => {
  const { eventsPending, isFetching, currentPage, limit, pages, total } = useSelector(
    pendingEventsSelector)
  const [selectedItems, handleChangeSelectedItems, handleReset] = useSelectedItems()
  const [triggerDeleted, handleTrigger] = useToggle()
  const { enqueueSnackbar } = useSnackbar()
  const [searchValue, handleChangeSearch] = useSearch()
  const [tempSearch, setTempSearch] = useSearch()
  const [page, perPage, handleChangePage, handleChangeLimit] = usePagination(
    currentPage, limit, pages)
  const [appliedFilters, handleChangeFilters] = useFilters()
  const [eventsData, setEventsData] = useState([])
  const [order, handleChangeOrder] = useOrder('id', 'desc')
  const [paged, setPage] = React.useState(0)
  const dispatch = useDispatch()
  const headers = [
    { label: 'Thumbnail', name: 'thumbnail', ordered: false },
    { label: 'Title', name: 'title', ordered: false },
    { label: 'Status', name: 'type', ordered: false },
    { label: 'Files', name: 'files', ordered: false },
  ]

  const filter = useMemo(() => {
    return {
      page,
      limit: perPage,
      search: searchValue,
      ...order,
      ...reduce(appliedFilters, (acc, { key, value }) => {
        if (key === 'type') {
          return {
            ...acc,
            [key]: value === 'pending' ? '1' : '0',
          }
        }
        return {
          ...acc,
          [key]: value,
        }
      }, {}),
    }
  }, [page, perPage, searchValue, appliedFilters, order])

  const handleSearch = useCallback(
    debounce(value => {
      handleChangeSearch({ target: { value } })
    }, 500),
    [handleChangeSearch],
  )

  useEffect(() => {
    handleSearch(tempSearch)
  }, [tempSearch])

  useEffect(() => {
    const events = eventsPending && eventsPending.filter(item =>
      item.title.includes(tempSearch),
    )
    setEventsData(events)
  }, [eventsPending, setEventsData])

  useEffect(() => {
    dispatch(eventsPendingsList({ ...filter }))
  }, [filter, triggerDeleted])

  useEffect(() => {
    handleChangePage(0)
  }, [perPage, searchValue, appliedFilters])

  const renderItem = useCallback((item, index) => {
    return (
      <EventPendingListItem
        key={item.id}
        index={index}
        item={item}
        handleChangeStatus={handleChangeStatus}
      />
    )
  }, [eventsPending])

  useEffect(() => {
    const sort = stableSort(eventsPending,
      getComparator(order.order, order.filter)).
      slice(paged * limit, paged * limit + limit).
      map((row, index) => row)
    setEventsData(sort)
  }, [eventsPending, setEventsData, paged])

  const handleChangeSelectedAll = useCallback(() => {
    if (selectedItems.length !== eventsPending.length ||
      !selectedItems.length) {
      handleChangeSelectedItems(
        [...eventsPending.map((_item, index) => _item.id)])
      return
    }
    handleChangeSelectedItems([])

  }, [selectedItems, handleChangeSelectedItems])

  const handleRemove = useCallback((ids) => {
    dispatch(deletePendingEvent({
      hashes: ids || selectedItems,
    })).then(
      res => {
        if (res.type === DELETE_EVENT_PENDING_FAILURE) {
          enqueueSnackbar(getErrorMessage(res.message), { variant: 'error' })
          handleReset()
        }
        if (res.type === DELETE_EVENT_PENDING_SUCCESS) {
          enqueueSnackbar(res.payload.message, { variant: 'success' })
          // dispatch(removePendingEventFromList(selectedItems))
          handleTrigger()
          handleReset()
        }
      },
    ).catch(err => enqueueSnackbar(err.message, { variant: 'error' }))

  }, [selectedItems, handleReset, handleChangePage, handleTrigger])

  const handleApprove = useCallback((ids) => {
    dispatch(updatePendingEvent({
      hashes: ids || selectedItems,
    })).then(({ data: { message } }) => {
      enqueueSnackbar(message, { variant: 'success' })
      dispatch(fetchCounts())
      handleReset()
      console.group('EventsPendingList.js:182 - ')
      console.log(page)
      console.groupEnd()
      if (page === 1) {
        handleTrigger()
      } else {
        handleChangePage(0)
      }
    }).catch(({ data: { message } }) => {
      enqueueSnackbar(message, { variant: 'error' })
      handleReset()
      if (page === 1) {
        handleTrigger()
      } else {
        handleChangePage(0)
      }
    })

  }, [selectedItems, handleReset, handleChangePage, handleTrigger])

  const handleChangeStatus = useCallback((id, status) => {
    if(status === 'remove') {
      handleRemove([id]);
    }
    if(status === 'approve') {
      handleApprove([id]);
    }
  }, [handleRemove, handleApprove])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={isFetching}/>
        <ResourceList
          items={eventsData}

          order={order}
          handleChangeOrder={handleChangeOrder}


          renderItem={renderItem}
          selectedItems={selectedItems}
          appliedFilters={appliedFilters}
          handleChangeFilters={handleChangeFilters}
          handleChangeSelectedAll={handleChangeSelectedAll}
          handleChangeSelectedItems={handleChangeSelectedItems}
          headers={headers}
          searchValue={tempSearch}
          handleChangeSearch={setTempSearch}
          bulkActions={[
            { label: 'Remove', onAction: handleRemove },
            { label: 'Approve', onAction: handleApprove },
          ]}
        />
        <Pagination
          pages={pages}
          colSpan={headers.length}
          handlePageChange={handleChangePage}
          handleRowsChange={handleChangeLimit}
          limit={perPage}
          total={total}
          page={page}
        />
      </Paper>
    </div>
  )
}

export default EventsListPending

