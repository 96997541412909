export const styles = theme => ({
    list: {
        padding: '10px',
    },
    container: {
        display: "flex",
        alignItems: "center",
        margin: "25px"
    },
    sectionInput: {
        marginRight: "15px",
        width: "70%",
        // '& .MuiAutocomplete-popupIndicator': {
        //     display: "none",
        // },
    },
});
