import React from "react";

export const styles = theme => ({
    wrapper: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        borderRadius: '4px',
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& img': {
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderRadius: theme.spacing()/2,
        },
    },
    downloadOverlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '4px',
        transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            backgroundColor: 'rgb(104,104,104,0.7)',
        },
        "&:hover $overlayIcon": {
            fill: '#fff',
        }
    },
    overlayIcon: {
        marginRight: '20px',
        marginLeft: '20px',
        fill: 'transparent',
        cursor: 'pointer',
        "&:hover": {
            opacity: 0.5,
        }
    },
    circularWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    previewImage: {
        flex: 1,
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cdcdcd",
            borderRadius: 4,
            cursor: "pointer",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#878787",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#F5F5F5",
            borderRadius: 4,
        },
    },
    previewInner: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
