import {loadAdmins} from "../../helpers/api";
import {LOAD_ADMINS_FAILURE, LOAD_ADMINS_REQUEST, LOAD_ADMINS_SUCCESS} from "../constants/admins";

export const getAdminsList = (
    page = 1,
    search,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    status,
    from,
    to,
) => dispatch => {
    dispatch({type: LOAD_ADMINS_REQUEST});

    return loadAdmins(page, search, orderBy, order, limit,status, from, to).then(
        res => {
            if (res.data.status === "error") {
                return dispatch({type: LOAD_ADMINS_FAILURE, message: res.data.message})
            }
            return dispatch({
                type: LOAD_ADMINS_SUCCESS,
                payload: res.data
            })
        },
        error => dispatch({type: LOAD_ADMINS_FAILURE, message: error})
    )
};
