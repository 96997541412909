import React, {Fragment, useCallback, useEffect, useState} from "react";
// import * as PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import ImageEditDialog from "../ImageEditDialog";
import {createImage} from "../../store/actions/image";
import {useHistory} from "react-router-dom";
import {getIsFetching} from "../../store/reducers/newImage";
import {dataUrlToFile, toBase64} from "../../helpers/files";
import {getErrorMessage} from "../../helpers/validation";
import SingleImageSimpleForm from "../SingleImageSimpleForm";
import {allowedImagesTypes} from "../../constatns/images";
import {cloneDeep} from "lodash";
import LoadingFade from "../../common/LoadingFade";

const initialState = {
    imageData: {
        title: '',
        description: '',
        file: '',
    },
    base64: '',
    hasChanges: false,
    editDialog: false,
    disabled: false,
    errors: false,
};

const SingleImageCreate = ({classes, type}) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const loading = useSelector(getIsFetching);
        const {enqueueSnackbar} = useSnackbar();
        const [imageState, setImage] = useState(cloneDeep(initialState));

        const handleChangeFields = name => event => {
            setImage({
                ...imageState, imageData: {
                    ...imageState.imageData,
                    [name]: event.target.value
                }
            });
        };

        const createImageAction = () => {
            const {file, title, description} = imageState.imageData;
            setImage({
                ...imageState,
                disabled: true
            });
            const data = {
                file: file,
                title: title,
                description: description
            };
            dispatch(createImage(data)).then(res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                closeLink();
            }).catch(err => {
                setImage({
                    ...imageState,
                    disabled: false
                });
                enqueueSnackbar(getErrorMessage(err), {variant: 'error'})
            });
        };

        const createFileObject = async (file) => await toBase64(file);

        const handleUploadFile = e => {
            e.persist();
            const file = e.target.files[0];
            if (!allowedImagesTypes.includes(file.type)) {
                enqueueSnackbar('File type not supported!', {variant: 'error'})
                return;
            }

            createFileObject(file).then(res => {
                setImage({
                    ...imageState,
                    base64: res,
                    imageData: {
                        ...imageState.imageData,
                        file: file,
                    }
                });
            });
        };

        const handleUpdateFile = (file) => {
            setImage(state => ({
                ...state,
                hasChanges:false,
                imageData: {
                    ...state.imageData,
                    file: file
                }
            }))
        };

        const closeLink = () => {
            history.push(`/images/list/pending`)
        };

        const editDialogHandleToggle = () => {
            setImage(state => ({
                ...state,
                editDialog: !imageState.editDialog
            }));
        };
        const {disabled, errors, imageData, base64, editDialog, hasChanges} = imageState;

        const updateImageFile = useCallback((fileData, changed) => {
            setImage((state) => {
                return {
                    ...state,
                    base64: fileData,
                    hasChanges: changed,
                }
            });
        }, [imageState, setImage]);

        useEffect(() => {
            if (base64 && hasChanges) {
                dataUrlToFile(base64, imageData.file.name, imageData.file.type)
                    .then(file => handleUpdateFile(file))
            }
        }, [hasChanges]);

        return (
            <Fragment>
                <Paper className={classes.paper}>
                    <LoadingFade loading={loading}/>
                    <Toolbar className={classes.root}>
                        <IconButton onClick={closeLink} className={classes.closeButton}><ArrowBackIcon/></IconButton>
                        <Typography>
                            {imageData.title ? `Image title - ${imageData.title}` : 'New photo'}
                        </Typography>
                        <Box className={classes.actionBlock}>
                            <Button disabled={disabled || !imageData.file} variant="contained"
                                    onClick={editDialogHandleToggle}>Edit</Button>
                        </Box>
                    </Toolbar>
                    <Divider/>
                    <Container maxWidth="lg" className={classes.imageData}>
                        <SingleImageSimpleForm
                            disabled={disabled}
                            errors={errors}
                            imageData={imageData}
                            onChange={handleChangeFields}
                            onFileUpload={handleUploadFile}
                            base64={base64}
                            type={type}
                        />
                    </Container>
                    <Divider/>
                    <Container className={classes.actions}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} className={classes.actionsBlock}>
                                <Button
                                    disabled={imageState.disabled || !imageState.imageData.file}
                                    variant="contained"
                                    onClick={createImageAction}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
                <ImageEditDialog
                    open={editDialog}
                    onClose={editDialogHandleToggle}
                    imageUrl={base64}
                    onSave={updateImageFile}
                />
            </Fragment>
        );
    }
;

export default SingleImageCreate;
