import {fade} from "@material-ui/core";

export const styles = theme => ({
  actionBlock: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: '10px',
  },
  searchIcon: {
    height: '100%',
    width: theme.spacing(7),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    marginRight: theme.spacing(),
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
});
