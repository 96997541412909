import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SidebarMenu from '../SidebarMenu/';
import {logoutAction, setAuthUserId} from "../../store/actions/auth";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import Logo from "../../common/Logo";
import Tooltip from "@material-ui/core/Tooltip";
import {useLocation} from "react-router-dom";
import {locationsTitles} from "../../constatns/locations";
import {getAuthUserId} from "../../store/reducers/auth";
import {useAuthUser} from "../../hooks/authUser";
import GravatarIcon from "../../common/GravatarIcon";


// TODO: make responsive
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxHeight: '64px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        overflowX: 'hidden',
        boxShadow: '2px 4px 5px 0px rgba(0,0,0,0.14)',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    profileListItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    profileName: {
        marginLeft: theme.spacing(2),
    }
}));

function Dashboard({logoutAction, container, currentUser, setAuthUserId}) {
    const classes = useStyles();
    const location = useLocation();
    const {user} = useAuthUser(currentUser);
    const logOut = () => {
        console.log('logout')
        logoutAction();
    };

    useEffect(() => {
        if (!currentUser) {
            setAuthUserId();
        }
    }, [currentUser]);

    // // TODO: improve title building
    // let title = !!locationsTitles.filter(item => item.id === location.pathname.split('/')[1])[0] && locationsTitles.filter(item => item.id === location.pathname.split('/')[1])[0].title;
    // if (title === 'Photos' && location.pathname.split('/').length > 2) {
    //     const nextLocationTitle = locationsTitles.filter(item => item.id === location.pathname.split('/')[2])[0];
    //     title = nextLocationTitle ? nextLocationTitle.title : title;
    // }

    const [title, setTitle] = useState();

    useEffect(() => {
        const locationTitle = locationsTitles.find(
            ({ id }) => location.pathname.indexOf(id) === 1
        );

        setTitle(locationTitle ? locationTitle.title : "Dashboard");
    }, [location]);

    return (
        // <div className={classes.root}>
        <React.Fragment>
            <CssBaseline/>
            <AppBar position="absolute"
                    className={
                        clsx(classes.appBar, classes.appBarShift
                            // , open && classes.appBarShift
                        )}>
                <Toolbar className={classes.toolbar}>
                    {/*<IconButton*/}
                    {/*    edge="start"*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="open drawer"*/}
                    {/*    onClick={handleDrawerOpen}*/}
                    {/*    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}*/}
                    {/*>*/}
                    {/*    <MenuIcon/>*/}
                    {/*</IconButton>*/}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {title}
                    </Typography>
                    {/*<IconButton color="inherit">*/}
                    {/*    <Badge badgeContent={0} color="secondary">*/}
                    {/*        <NotificationsIcon/>*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <Tooltip title="Logout" aria-label="logout" placement="left">
                        <IconButton onClick={logOut} color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <ExitToAppIcon/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="persistent"
                container={container}
                classes={{
                    paper: clsx(
                        classes.drawerPaper
                        // , !open && classes.drawerPaperClose
                    ),
                }}
                open={true}
            >
                <div className={classes.toolbarIcon}>
                    <Logo/>
                    {/*<Typography variant="h6">News Management System</Typography>*/}

                    {/*<IconButton onClick={handleDrawerClose}>*/}
                    {/*    <CloseIcon/>*/}
                    {/*</IconButton>*/}
                </div>

                <Divider/>
                <div className={classes.profileListItem}>
                    <GravatarIcon variant="rounded" hash={user.gravatar} size={40}/>
                    <Box className={classes.profileName}>
                        <Typography>{user.name}</Typography>
                    </Box>
                </div>
                <Divider/>
                <List>
                    <SidebarMenu/>
                </List>
            </Drawer>
        </React.Fragment>
        // </div>
    );
}

Dashboard.propTypes = {
    logoutAction: PropTypes.func.isRequired,
    setAuthUserId: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    currentUser: getAuthUserId(state)
});

const mapDispatchToProps = {logoutAction, setAuthUserId};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
