import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutocompleteImages from "../../AutocompleteImages";

const ImagesModal = ({open, onClose, onSuccess, classes, orientation}) => {


  if (!open) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Select Image"}</DialogTitle>
        <AutocompleteImages
          orientation={orientation}
          handleChange={onSuccess}
        />
        <DialogActions className={classes.root}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImagesModal;

