import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import TampleComponent from '../../common/TampleComponent'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {
  addPlaylist,
  flushVideoPlaylist,
  flushVideoPlaylistErrors,
} from '../../store/actions/playlist'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  ADD_VIDEO_PLAYLIST_FAILURE,
  ADD_VIDEO_PLAYLIST_SUCCESS,
} from '../../store/constants/playlist'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import List from '@material-ui/core/List'
import EventVideoItem
  from '../../pages/Events/EventsSingle/EventSingleItems/EventVideoItem'
import arrayMove from 'array-move'
import AddItem
  from '../../pages/Events/EventsSingle/EventSingleItems/EventVideo/AddItem'
import VideoModal from '../Modal/VideoModal'

const initialState = {
  name: '',
}

const videoState = {
  videos: [],
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    // '& > *': {
    //   marginRight: "10px",
    //   marginBottom: "10px",
    //   padding: 0
    // }
  },
  list: {
    width: '100%',
  },
}))

const SortableItem = SortableElement(
  ({ value, item, onRemove }) =>
    <EventVideoItem
      value={value}
      item={item}
      src={item.thumbnail}
      onRemove={onRemove}
      draggable={true}
    />,
)

const SortableList = SortableContainer(({ items, onRemove }) => {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          value={items}
          item={item}
          onRemove={onRemove}
        />
      ))}
    </List>
  )
})

const SinglePlaylistForm = ({ classes }) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(initialState)
  const [stateVideo, setVideo] = useState(videoState)
  const [openAdd, setOpenAdd] = useState(false)

  const closeLink = () => {
    dispatch(flushVideoPlaylistErrors())
    dispatch(flushVideoPlaylist())
    history.push('/videos/playlists')
  }

  const handleChangeFields = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const handleChange = (value) => {
    setVideo({
      videos: [].concat(value),
    })
  }

  const save = () => {
    setLoading(true)
    const videos = stateVideo.videos.map(element => element.id)
    dispatch(addPlaylist({ name: state.name, videos })).
      then(
        res => {
          setLoading(false)
          if (res.type === ADD_VIDEO_PLAYLIST_FAILURE) {
            enqueueSnackbar(res.payload, { variant: 'error' })
          }

          if (res.type === ADD_VIDEO_PLAYLIST_SUCCESS) {
            enqueueSnackbar(res.payload.message, { variant: 'success' })
            closeLink()
          }
        },
      ).
      catch(err => enqueueSnackbar(err.message, { variant: 'error' }) &&
        setLoading(false))
  }


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TampleComponent loading={loading}/>
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={closeLink}
                      className={classes.closeButton}><ArrowBackIcon/></IconButton>
          <Box className={classes.container}>
            <Typography>
              Playlist
            </Typography>
          </Box>
        </Toolbar>
        <Divider/>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={4}>
              <form>
                <div className={classes.textField}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    className={classes.inputPadding}
                    value={state.name || ''}
                    onChange={handleChangeFields('name')}
                  />
                </div>
              </form>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <SortableList
                items={stateVideo.videos}
                onRemove={(newValue) => handleChange(newValue)}
                onSortEnd={({ oldIndex, newIndex }) => {
                  handleChange(arrayMove(stateVideo.videos, oldIndex, newIndex))
                }}
              />

              <AddItem onClick={() => setOpenAdd(true)}/>
              <VideoModal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                selected={stateVideo.videos}
                onSuccess={(newValue) => {
                  handleChange(newValue)
                  setOpenAdd(false)
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Divider/>
        <div className={classes.btn}>
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={save}
          >
            Save
          </Button>
        </div>
      </Paper>
    </div>
  )
}

SinglePlaylistForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default SinglePlaylistForm
