import {
    APPROVE_PENDING_IMAGE_FAILURE,
    APPROVE_PENDING_IMAGE_REQUEST,
    APPROVE_PENDING_IMAGE_SUCCESS,
    EDIT_PENDING_IMAGE,
    FETCH_IMAGE_FAILURE, FETCH_PENDING_IMAGE_DATA_FAILURE,
    FETCH_PENDING_IMAGE_DATA_REQUEST, FETCH_PENDING_IMAGE_DATA_SUCCESS,
    FLUSH_EDIT_PENDING_IMAGE_DATA
} from "../constants/images";
import {DialogMessagesStatuses} from "../../constatns/statuses";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        status: '',
        name: '',
        description: '',
        location: '',
        thumbnail: ''
    },
};

const pendingImage = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case APPROVE_PENDING_IMAGE_REQUEST:
        case FETCH_PENDING_IMAGE_DATA_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_PENDING_IMAGE_DATA_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_PENDING_IMAGE_DATA_SUCCESS:
            return {
                ...state,
                errors: '',
                isFetching: false,
                data: payload.data
            };
        case APPROVE_PENDING_IMAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message,
                data: payload.data,
            };
        case APPROVE_PENDING_IMAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
            };
        // case EDIT_PENDING_IMAGE:
        //     return {
        //         ...state,
        //         data: {
        //             ...state.data,
        //             location: payload
        //         }
        //     };
        case FLUSH_EDIT_PENDING_IMAGE_DATA:
            return {
                ...initialState,
                data: {
                    ...initialState.data
                }
            };
        default:
            return {
                ...state,
            }
    }
};

export default pendingImage;

export const getIsFetching = state => state.pendingImage.isFetching;
export const getImageErrors = state => state.pendingImage.errors;
export const getImageUpdateErrors = state => state.pendingImage.updateErrors;
export const getImageMessage = state => state.pendingImage.message;

export const getImageData = state => state.pendingImage.data;
