import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";


const Linear = ({height}) => {
    return (
        <LinearProgress style={height ? {height: height} : {}}/>
    );
}

export default Linear;


