import {useCallback, useState} from "react";

const usePagination = (currentPage , limit, pages) => {

  const [page, setPage] = useState(currentPage);

  const [perPage, setPerPage] = useState(limit);

  const handleChangePage = useCallback((value)=>{
    setPage(value + 1)
  }, [page , setPage]);

  const handleChangeLimit = useCallback((value)=>{
    setPerPage(value)
  }, [perPage , setPerPage]);


  return [page, perPage, handleChangePage, handleChangeLimit]
};
export default usePagination;