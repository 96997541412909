import {
  LOAD_STREAMS_SINGLE_REQUEST,
  LOAD_STREAMS_SINGLE_SUCCESS,
  LOAD_STREAMS_SINGLE_FAILURE, RESET_STREAM_TO_DEFAULT,
} from '../../constants/streams/single'

import {STREAMS_CREATE_REQUEST, STREAMS_CREATE_SUCCESS, STREAMS_CREATE_FAILURE} from "../../constants/streams/create";
import {STREAMS_REMOVE_REQUEST, STREAMS_REMOVE_FAILURE, STREAMS_REMOVE_SUCCESS} from "../../constants/streams/remove";
import {STREAMS_UPDATE_REQUEST, STREAMS_UPDATE_FAILURE, STREAMS_UPDATE_SUCCESS} from "../../constants/streams/update";
import { cloneDeep } from 'lodash/lang'

const initialState = {
  isFetching: false,

  id: 0,
  title: "",
  url: "",
  description: "",
  warning_flag: false,
  image: null
};

const singleStream = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LOAD_STREAMS_SINGLE_REQUEST:
    case STREAMS_CREATE_REQUEST:
    case STREAMS_REMOVE_REQUEST:
    case STREAMS_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case LOAD_STREAMS_SINGLE_SUCCESS:
    case STREAMS_UPDATE_SUCCESS:
      return {
        ...state,
        ...cloneDeep(payload.data),
        isFetching: false
      };
    case LOAD_STREAMS_SINGLE_FAILURE:
    case STREAMS_CREATE_SUCCESS:
    case STREAMS_CREATE_FAILURE:
    case STREAMS_REMOVE_SUCCESS:
    case STREAMS_REMOVE_FAILURE:
    case STREAMS_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case RESET_STREAM_TO_DEFAULT:
      return {
        ...cloneDeep(initialState),
        isFetching: false
      };
    default:
      return {
        ...state
      }
  }

};

export default singleStream;
