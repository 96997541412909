export const styles = theme => ({
    filterChip: {
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    filterChips: {},
    categories: {
        backgroundColor: theme.palette.chips.categories.main,
        color: theme.palette.chips.categories.contrastText,
    },
    tags: {
        backgroundColor: theme.palette.chips.tags.main,
        color: theme.palette.chips.tags.contrastText,
    },
    from: {
        backgroundColor: theme.palette.chips.date.main,
        color: theme.palette.chips.date.contrastText,
    },
    to: {
        backgroundColor: theme.palette.chips.date.main,
        color: theme.palette.chips.date.contrastText,
    },
});
