export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const FLUSH_USER_ERRORS = 'FLUSH_USER_ERRORS';
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const FLUSH_USER_STATE = 'FLUSH_USER_STATE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST';
export const FLUSH_USER_MESSAGES = 'FLUSH_USER_MESSAGES';
