import React, {useCallback} from 'react';
import Avatar from '@material-ui/core/Avatar'
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
const EventImage = ({classes, src, onRemove, imageKey, onClick}) => {

  const handleClick = useCallback(() =>{
    if (typeof onRemove !== 'function') {
      return false;
    }
    onRemove(imageKey)
  }, [onRemove]);

  return (
    <div className={classes.root}>
      <Avatar className={classes.image} src={src}/>
      <div className={classes.overlay}>
        <EditIcon className={classes.icon} onClick={onClick}/>
        <ClearIcon className={classes.icon} onClick={handleClick}/>
      </div>
    </div>
  );
};

export default EventImage;