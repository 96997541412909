import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import {singleStream} from "../../../store/actions/streams/single";
import {useSnackbar} from "notistack";
import StreamSingleInner from "./StreamSingleInner";
import {isFetchingSingleStream, singleStreamSelector} from "../../../store/reducers/streams/selectors";
import {createStream} from "../../../store/actions/streams/create";
import {removeStream} from "../../../store/actions/streams/remove";
import {updateStream} from "../../../store/actions/streams/update";
import { RESET_STREAM_TO_DEFAULT } from '../../../store/constants/streams/single'


const StreamSingle = ({classes}) => {

  const {id} = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();


  const initialValues = useSelector(singleStreamSelector);
  const isFetching = useSelector(isFetchingSingleStream);

  useEffect(() => {
    if (typeof id !== 'undefined') {
      dispatch(singleStream(id));
    }

    return () => dispatch({type: RESET_STREAM_TO_DEFAULT})
  }, [id]);


  const historyChange = useCallback((url) => {
    history.push(url)
  }, []);

  const handleUpdate = useCallback((values) => {
    dispatch(updateStream({
      ...values,
      image_id: values.image.id
    }))
      .then(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'success'})
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, [id]);

  const handleRemove = useCallback(() => {
    dispatch(removeStream(id))
      .then(() => {
        enqueueSnackbar("Stream successfully removed", {variant: 'success'});
        historyChange('/streams')
      })
      .catch(({message}) => {
        historyChange('/streams');
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, [id]);

  const handleCreate = useCallback((values) => {
    dispatch(createStream({
      ...values,
      image_id: values.image.id
    }))
      .then(({data: {data: {id}, message}}) => {
        enqueueSnackbar(message, {variant: 'success'});

        historyChange(`/streams/${id}/edit`)
      })
      .catch(({data: {message}}) => {
        enqueueSnackbar(message, {variant: 'error'})
      })
  }, []);

  const handleSave = useCallback((values) => {
    if (!id) {
      handleCreate(values);
    } else {
      handleUpdate(values);
    }
  }, [id, handleCreate, handleUpdate]);


  const title = useMemo(() => {
    return typeof id === 'undefined' ? "Create New Stream" : initialValues.title
  }, [id, initialValues]);

  const formInitialValues = useMemo(() => {
    return {
      ...initialValues,
    }
  }, [initialValues])

  return (
    <StreamSingleInner
      disabled={isFetching}
      item={initialValues}
      isFetching={isFetching}
      initialValues={formInitialValues}
      historyChange={historyChange}
      classes={classes}
      id={id}
      onSubmit={handleSave}
      handleRemove={handleRemove}
      title={title}
    />
  );
};

export default StreamSingle;
