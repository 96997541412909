export const styles = theme => ({
    statusBadge: {
        display: 'inline-block',
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        borderRadius: '4px',
        color: '#fff',
        padding: 0,
        fontSize: '0.75em',
    },
    disabled: {
        color: '#fff!important'
    },
    active: {
        backgroundColor: theme.palette.success.main
    },
    pending: {
        backgroundColor: theme.palette.warning.main
    },
    blocked: {
        backgroundColor: theme.palette.error.main
    },
    process: {
        backgroundColor: theme.palette.info.main
    }
});
