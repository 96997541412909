export const styles = theme => ({
    title: {
        textTransform: 'capitalize'
    },
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
});
