import React, {cloneElement, useCallback, useMemo} from 'react';
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const ResourceListItem = ({onClick, selectedItems, handleChangeSelectedItems, id, children}) => {

  const handleClick = useCallback((...rest) => {
    if (typeof onClick !== "function") {
      return false
    }
    onClick()
  }, [onClick]);

  const isSelected = useMemo(() => {

    if (typeof selectedItems === "undefined" || typeof handleChangeSelectedItems !== "function") {
      return false;
    }

    return selectedItems.includes(id)
  }, [selectedItems]);

  const onChange = useCallback((id) => {
    if (selectedItems.includes(id)) {
      handleChangeSelectedItems([
        ...selectedItems.filter(selected => selected !== id)
      ])
    } else {
      handleChangeSelectedItems([
        ...selectedItems,
        id
      ])
    }

  }, [selectedItems, handleChangeSelectedItems]);

  const bulk = useMemo(() => {

    if (typeof selectedItems === "undefined" || typeof handleChangeSelectedItems !== "function") {
      return null;
    }

    return <TableCell padding="checkbox">
      <Checkbox
        checked={isSelected}
        color="primary"
        onChange={() => onChange(id)}
      />
    </TableCell>

  }, [selectedItems, handleChangeSelectedItems, isSelected, onChange]);


  const rows = useMemo(() => {
    if (Array.isArray(children)) {
      return children.map((child, id) => {
        return <TableCell key={id} onClick={typeof child.props.onClick !== 'undefined'? child.props.onClick: handleClick}>{cloneElement(
          child,
          {
            ...child.props,
            onClick: typeof child.props.onClick !== 'undefined'? child.props.onClick: handleClick
          }
        )}</TableCell>
      })
    }

    if(typeof children === "object"){
      return <TableCell>{children}</TableCell>
    }

    return children

  }, [children]);

  return (
    <TableRow
      hover
      align="left"
      tabIndex={-1}
      key={id}
    >
      {bulk}
      {rows}
    </TableRow>
  );
};

export default ResourceListItem;
