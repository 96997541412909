export const styles = theme => ({
    fieldsWrapper: {
        maxWidth: '80%',
        flex: '1 1 auto'
    },
    textField: {
        marginBottom: theme.spacing(4)
    },
    imageWrapper: {
        width: '100%',
        height: 'auto',
        paddingBottom: theme.spacing(3),
        '& img': {
            width: '100%',
            height: 'auto',
        }
    },
    uploadBlock: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});
