import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Link, Redirect, withRouter} from "react-router-dom";
import {
    checkChangePasswordToken,
    flushLoginErrors,
    setNewPasswordAfterReset,
    setResetPasswordHash
} from "../../../store/actions/auth";
import {
    authResultMessage, canUserResetPassword,
    getAuthorizationError,
    getIsFetching, getResetPasswordHash, hasResetPasswordHash
} from "../../../store/reducers/auth";
import {connect} from "react-redux";
import FlashMessage from "react-flash-message";
import Recaptcha from 'react-google-invisible-recaptcha';
import Copyright from "../../../common/Copyright";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withSnackbar} from "notistack";
import {checkPassword} from "../../../helpers/validation";
import {messages} from "../../../helpers/constants";

const initialState = {
    password: '',
    confirmPassword: '',
    errors: '',
    message: '',
    redirect: false,
    status: '',
    blocked: false,
};

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onError = this.onError.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = initialState;
    }

    componentDidMount() {
        const {match, setResetPasswordHash, checkChangePasswordToken} = this.props;
        if (!match.params.s) {
            return <Redirect to="/login"/>
        }
        setResetPasswordHash(match.params.s);
        checkChangePasswordToken(match.params.s);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resetPasswordErrors, hasResetPasswordHash, canUserResetPassword, isFetching} = this.props;

        if (prevProps.resetPasswordErrors !== resetPasswordErrors) {
            this.setState({
                errors: resetPasswordErrors.message,
            });
        }
        if (prevState.password !== this.state.password ||
            prevState.confirmPassword !== this.state.confirmPassword) {
            this.setState({
                errors: ""
            });
        }
        if (prevProps.resultMessage !== this.props.resultMessage) {
            this.setState({
                message: this.props.resultMessage
            });
        }
        if (hasResetPasswordHash && !canUserResetPassword && !isFetching) {
            this.props.flushLoginErrors();
            return (this.props.history.push('/login'));
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState(state => ({
            ...state,
            blocked: true,
        }));
        const {password, confirmPassword} = this.state;
        if (password !== confirmPassword) {
            return this.setState({errors: messages.auth.passwordsMatch});
        }
        if ((password && !checkPassword(password)) || password.length > 16) {
            return this.setState({errors: messages.auth.passwordRequirements});
        }
        this.setState(state => ({
            ...state,
            blocked: true,
        }));
        this.recaptcha.execute();
    }

    onError() {
        this.setState(state => ({
            ...state,
            blocked: false,
        }));
    }

    onResolved() {
        this.props.setNewPassword({
            token: this.props.resetPasswordHash,
            password: this.state.password,
            gcaptcha: this.recaptcha.getResponse()
        }).then(() => {
            if (this.props.resetPasswordErrors.status !== 'error') {
                this.props.enqueueSnackbar(messages.auth.passwordChangeSuccess, {variant: 'success'});
                return setTimeout(() => {
                    this.props.flushLoginErrors();
                    return this.setState(state => ({redirect: true}))
                }, 2000);
            }
            this.props.enqueueSnackbar(this.props.resetPasswordErrors.message, {variant: 'error'});
        }, error => {
            // TODO: improve error handler
            this.props.enqueueSnackbar(messages.common.errors, {variant: 'error'});
            this.setState(state => ({
                ...state,
                blocked: false,
            }));
        });
    }

    onChange(e) {
        const {name, value} = e.target;
        this.setState(state => ({
            ...state,
            blocked: false,
            [name]: value
        }));
    }

    render() {
        const {classes, isFetching, canUserResetPassword} = this.props;
        const {password, confirmPassword, errors, redirect, blocked} = this.state;

        if (redirect) {
            return (<Redirect to="/login"/>)
        }

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    {isFetching &&
                    <div className={classes.onFetching}>
                        <CircularProgress/>
                    </div>}
                    <form
                        className={classes.form}
                        onSubmit={this.onSubmit}
                        style={isFetching ? {opacity: "0.5"} : {}}
                    >{canUserResetPassword &&
                    <>
                        <TextField
                            error={!!errors}
                            autoComplete="password"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label="New Password"
                            name="password"
                            autoFocus
                            disabled={isFetching}
                            value={password}
                            onChange={this.onChange}
                        />
                        <TextField
                            error={!!errors}
                            autoComplete="password"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            disabled={isFetching}
                            value={confirmPassword}
                            onChange={this.onChange}
                        />
                    </>}
                        {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={"alert alert-danger"}>Error: {errors}</h5></FlashMessage> : ''}
                        {canUserResetPassword &&
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!confirmPassword || !password || blocked}
                            className={classes.submit}
                        >
                            Set new password
                        </Button>}
                        <Grid container>
                            <Grid item xs>
                                <Link to="/login" variant="body2">
                                    Back to login.
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.MIX_GCAPTCHA_DEV_SITE_KEY}
                    onResolved={this.onResolved}/>
            </Container>
        )
    }
}

ResetPassword.propTypes = {
    checkChangePasswordToken: PropTypes.func.isRequired,
    resetPasswordError: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    hasResetPasswordHash: PropTypes.bool.isRequired,
    setResetPasswordHash: PropTypes.func.isRequired,
    resetPasswordHash: PropTypes.string,
    resultMessage: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    checkChangePasswordToken: checkChangePasswordToken,
    setResetPasswordHash: setResetPasswordHash,
    flushLoginErrors: flushLoginErrors,
    setNewPassword: setNewPasswordAfterReset,
};

const mapStateToProps = state => ({
    resetPasswordErrors: getAuthorizationError(state),
    resultMessage: authResultMessage(state),
    canUserResetPassword: canUserResetPassword(state),
    isFetching: getIsFetching(state),
    hasResetPasswordHash: hasResetPasswordHash(state),
    resetPasswordHash: getResetPasswordHash(state),
});


export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));
