import {useCallback, useState} from "react";

const useFilters = () => {
  const [appliedFilters , setAppliedFilters] = useState([]);

  const handleChange = useCallback((filters) => {
    setAppliedFilters([...filters]);
  }, [appliedFilters , setAppliedFilters]);

  return [appliedFilters , handleChange];
};
export default useFilters;
