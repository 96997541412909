import {useCallback, useState} from "react";

const useSearch = (searchBy = "title") => {
  const [searchValue, onChange] = useState('');
  const handleChange = useCallback(event => {
    onChange(event.target.value);
  }, [onChange]);

  return [searchValue, handleChange];
};
export default useSearch;
