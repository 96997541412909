import React, {Component} from 'react';
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SingleImagePendingToolbar from "./SingleImagePendingToolbar";
import SingleImagePendingSkeleton from "./SingleImagePendingSkeleton";
import SingleImagePendingForm from "./SingleImagePendingForm";
import Button from "@material-ui/core/Button";
import SingleImagePendingEditor from "./SingleImagePendingEditor";
import {approveSingleImage, fetchPendingImageData, flushPendingImageState} from "../../store/actions/imagePending";
import {getErrorMessage} from "../../helpers/validation";


class SingleImagePendingEdit extends Component {
  static getDerivedStateFromProps(props, state) {
    if(!state.title && !state.description) {
      if(!props.imageData.title || !props.imageData.description) {
        return {
          title: props.imageData.title,
          description: props.imageData.description
        }
      }
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
    }
  }

  componentDidMount() {
    this.props.fetchPendingImageData(this.props.hash);
  }

  isFetching = () => {
    return this.props.isFetching;
  };

  isNotFound = () => {
    const {isFetching, imageData} = this.props;
    return !isFetching && !imageData.location;
  };

  handleChangeFields = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  };

  handleBack = () => {
    this.props.flushPendingImageState();
    this.props.history.push(`/images/list/pending`);
  };

  handleApprove = () => {
    const {title, description} = this.state;
    const {approveSingleImage, enqueueSnackbar, hash, history} = this.props;
    approveSingleImage({
      hash,
      title,
      description,
    }).then(
      (res) => {
        enqueueSnackbar(res.data.message, {variant: 'success'});
        if (res && res.data && res.data.data && res.data.data.id) {
          history.push(`/images/image/${res.data.data.id}`)
        } else {
          history.push(`/images/list/used`)
        }
      }
    ).catch((err) => {
      enqueueSnackbar(getErrorMessage(err), {variant: 'error'})
    });
  };

  renderContent() {
    const {classes, imageData} = this.props;
    const {title, description} = this.state;

    const content = this.isNotFound() ?
      (
        <h2>Not found.</h2>
      ) : (
        <React.Fragment>
          <Container maxWidth="lg" className={classes.imageData}>
            <Grid container spacing={3}>
              <SingleImagePendingForm
                classes={classes}
                title={title}
                description={description}
                onChange={this.handleChangeFields}
              />
              <SingleImagePendingEditor
                classes={classes}
                url={imageData.location}
              />
            </Grid>
          </Container>
          <Divider/>
          <Container className={classes.actions}>
            <Grid container spacing={3}>
              <Grid item lg={12} className={classes.actionsBlock}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleApprove}
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Container>
        </React.Fragment>
      );


    return (
      <React.Fragment>
        <SingleImagePendingToolbar
          classes={classes}
          title={title}
          onAction={this.handleBack}
        />
        <Divider/>
        {content}
      </React.Fragment>
    )
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.paper}>
        {
          this.isFetching() ? (<SingleImagePendingSkeleton/>) : this.renderContent()
        }
      </Paper>
    )
  }
}

const mapStateToProps = ({pendingImage}, {match: {params: {hash}}}) => {
  return {
    hash,
    isFetching: pendingImage.isFetching,
    imageData: pendingImage.data,
    errors: pendingImage.errors
  }
};

const mapDispatchToProps = {fetchPendingImageData, flushPendingImageState, approveSingleImage};

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleImagePendingEdit));
