import {createSelector} from 'reselect';
import {
    LOAD_VIDEO_TAGS_REQUEST,
    LOAD_VIDEO_TAGS_FAILURE,
    LOAD_VIDEO_TAGS_ERRORS,
    LOAD_VIDEO_TAGS_SUCCESS
} from "../constants/videoTags";
import {DialogMessagesStatuses} from "../../constatns/statuses";
import {UPDATE_VIDEO_TAG_SUCCESS, REMOVE_VIDEO_TAG_FROM_LIST} from "../constants/videoTag";
import {
    CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST,
    CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS,
    UPDATE_VIDEO_TAGS_AFTER_BULK
} from "../constants/bulk";

const initialState = {
    isFetching: false,
    video_tags: [],
    errors: false,
    updateErrors: {},
    status: '',
    message: '',
    count: 0,
    page: 0,
    order: 'desc',
    orderBy: 'created_at',
    limit: 10,
};

const video_tags = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_VIDEO_TAGS_REQUEST:
        case CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_VIDEO_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                video_tags: payload.data,
                page: payload.current_page,
                count: payload.total,
                limit: parseInt(payload.per_page)
            };
        case UPDATE_VIDEO_TAG_SUCCESS:
            const {data} = payload;
            return {
                ...state,
                isFetching: false,
                video_tags: state.video_tags.map(tag => tag.id === data.id ? data : tag)
            };
        case REMOVE_VIDEO_TAG_FROM_LIST:
            return {
                ...state,
                video_tags: state.video_tags.filter(tag => tag.id !== payload),
            };
        case LOAD_VIDEO_TAGS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_VIDEO_TAGS_AFTER_BULK:
            return {
                ...state,
                video_tags: state.video_tags.filter(tag => !payload.includes(tag.id))
            };
        case LOAD_VIDEO_TAGS_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
            };
    }
};

export default video_tags;

export const getIsFetching = state => state.video_tags.isFetching;
export const getVideoTagsOrderBy = state => state.video_tags.orderBy;
export const getVideoTagsArr = state => state.video_tags.video_tags;
export const getVideoTagsCount = state => state.video_tags.count;
export const getVideoTagsPage = state => state.video_tags.page;
export const getVideoTagsLimit = state => state.video_tags.limit;
export const getVideoTagsOrder = state => state.video_tags.order;

export const videoTagsSelector = createSelector([
    getVideoTagsArr,
    getIsFetching,
    getVideoTagsCount,
    getVideoTagsPage,
    getVideoTagsLimit,
    getVideoTagsOrder,
    getVideoTagsOrderBy,
], (video_tags,
    isLoading,
    count,
    page,
    limit,
    order,
    orderBy,
) => {
    return {
        video_tags,
        isLoading,
        count,
        page,
        limit,
        order,
        orderBy,
    };
});
