export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';

export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

export const FLUSH_CATEGORY_ERRORS = 'FLUSH_CATEGORY_ERRORS';
export const FLUSH_CATEGORY_STATE = 'FLUSH_CATEGORY_STATE';

export const REMOVE_CATEGORY_FROM_LIST = 'REMOVE_CATEGORY_FROM_LIST';

export const FLUSH_CATEGORY_MESSAGES = 'FLUSH_CATEGORY_MESSAGES';
