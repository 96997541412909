import React, {useState, useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import * as PropTypes from "prop-types";
import LoadingFade from "../../common/LoadingFade";

const initialState = {
    name: '',
};

const SinglePage = ({
        classes,
        loading,
        closeLink,
        deleteAction,
        updateAction,
        addAction,
        useSelector,
        flushState,
        data,
        setDisableDelete,
        disableDelete,
        id,
        title,
        item,
    }) => {

    const [nameError, changeNameError] = useState(false);
    const [state, setState] = useState(initialState);
    const [open, setOpen] = useState(false);
    const [disable, setDisabled] = useState(true);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const onCloseLink = () => {
        closeLink();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const memoizedHandleChange = useCallback(name => event => {
        setState({...state, [name]: event.target.value});
        setDisabled(false);
        if(!addAction) {
            setDisableDelete(true);
        }
        if (!state.name) {
            return changeNameError(false)
        }
    }, []);

    useEffect(() => {
        setState({
            name: useSelector || '',
        })
    }, [useSelector]);

    useEffect(() => {
        return () => {
            dispatch(flushState());
        }
    }, []);

    const disabled = state.name === data;

    return (
        <Paper className={classes.paper}>
            <LoadingFade loading={loading}/>
            <Toolbar className={classes.root}>
                <IconButton onClick={onCloseLink} className={classes.closeButton}><ArrowBackIcon/></IconButton>
                <Typography>
                    {title}
                </Typography>
            </Toolbar>
            <Divider/>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <form className={classes.form}>
                            <div className={classes.inputWrap}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    error={nameError}
                                    disabled={loading}
                                    value={state.name || '' }
                                    onChange={memoizedHandleChange('name')}
                                />
                            </div>
                            <div>
                                {id ? (
                                    <div className={classes.btnWrap}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={loading || disableDelete || state.name === ''}
                                            onClick={() => handleClickOpen()}
                                        >
                                            Delete
                                        </Button>
                                        <ConfirmDeleteDialog
                                            open={open}
                                            onClose={handleClose}
                                            title={item}
                                            deleteItemAction={deleteAction}/>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={disabled || loading || disable}
                                            onClick={() => updateAction(state.name)}
                                        >
                                            Update
                                        </Button>
                                    </div>

                                ) : (
                                    <div className={classes.btn}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={() => addAction(state.name)}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
};


SinglePage.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    closeLink: PropTypes.func.isRequired,
    deleteAction: PropTypes.func,
    updateAction: PropTypes.func,
    addAction: PropTypes.func,
    useSelector: PropTypes.string,
    flushState: PropTypes.func,
    id: PropTypes.string,
    title: PropTypes.string,
    item: PropTypes.string,
    data: PropTypes.string,
    disableDelete: PropTypes.bool,
    setDisableDelete: PropTypes.func
};

export default SinglePage;
