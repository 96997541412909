import {
    FLUSH_VIDEO_CATEGORY_STATE,
    FLUSH_VIDEO_CATEGORY_ERRORS,
    FLUSH_VIDEO_CATEGORY_MESSAGES,
    FETCH_VIDEO_CATEGORY_SUCCESS,
    FETCH_VIDEO_CATEGORY_REQUEST,
    FETCH_VIDEO_CATEGORY_FAILURE,
    UPDATE_VIDEO_CATEGORY_SUCCESS,
    UPDATE_VIDEO_CATEGORY_REQUEST,
    UPDATE_VIDEO_CATEGORY_FAILURE,
    ADD_VIDEO_CATEGORY_SUCCESS,
    ADD_VIDEO_CATEGORY_REQUEST,
    ADD_VIDEO_CATEGORY_FAILURE,
    DELETE_VIDEO_CATEGORY_SUCCESS,
    DELETE_VIDEO_CATEGORY_REQUEST,
    DELETE_VIDEO_CATEGORY_FAILURE
} from "../constants/videoCategory";
import {DialogMessagesStatuses} from "../../constatns/statuses";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        name: '',
        cat_parent: '',
        related: ''
    },
};

const video_category = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_VIDEO_CATEGORY_REQUEST:
        case ADD_VIDEO_CATEGORY_REQUEST:
        case UPDATE_VIDEO_CATEGORY_REQUEST:
        case DELETE_VIDEO_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_VIDEO_CATEGORY_SUCCESS:
        case DELETE_VIDEO_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
            };
        case UPDATE_VIDEO_CATEGORY_FAILURE:
        case DELETE_VIDEO_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
                status: DialogMessagesStatuses.error,
                message: payload
            };
        case UPDATE_VIDEO_CATEGORY_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload
            };
        case FETCH_VIDEO_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_VIDEO_CATEGORY_SUCCESS:
        case ADD_VIDEO_CATEGORY_FAILURE:
            return {
                ...state,
                errors: '',
                status: '',
                isFetching: false,
                data: payload.data
            };
        case FLUSH_VIDEO_CATEGORY_STATE:
            return {
                ...state,
                data: initialState.data
            };
        case FLUSH_VIDEO_CATEGORY_ERRORS:
        case FLUSH_VIDEO_CATEGORY_MESSAGES:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: ''
            };
    }
};

export default video_category;

export const getIsFetching = state => state.video_category.isFetching;

export const getVideoCategoryData = state => state.video_category.data;
