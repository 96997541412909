import React from "react";
import {Route, Switch} from "react-router-dom";
import EventsSingle from "./EventsSingle";
import EventsTabs from "./EventsTabs";

const Events = () => {
    return (<Switch>
            <Route path="/events/in-use" component={EventsTabs}/>
            <Route path="/events/pending" component={EventsTabs}/>
            <Route
                exact
                path="/events/new"
                component={EventsSingle}
            />
            <Route
                exact
                path="/events/:eventId/edit"
                component={EventsSingle}
            />
        </Switch>
    );
};

export default Events;
