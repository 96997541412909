export const ADD_VIDEO_TAG_FAILURE = 'ADD_VIDEO_TAG_FAILURE';
export const ADD_VIDEO_TAG_REQUEST = 'ADD_VIDEO_TAG_REQUEST';
export const ADD_VIDEO_TAG_SUCCESS = 'ADD_VIDEO_TAG_SUCCESS';

export const UPDATE_VIDEO_TAG_FAILURE = 'UPDATE_VIDEO_TAG_FAILURE';
export const UPDATE_VIDEO_TAG_REQUEST = 'UPDATE_VIDEO_TAG_REQUEST';
export const UPDATE_VIDEO_TAG_SUCCESS = 'UPDATE_VIDEO_TAG_SUCCESS';

export const DELETE_VIDEO_TAG_FAILURE = 'DELETE_VIDEO_TAG_FAILURE';
export const DELETE_VIDEO_TAG_REQUEST = 'DELETE_VIDEO_TAG_REQUEST';
export const DELETE_VIDEO_TAG_SUCCESS = 'DELETE_VIDEO_TAG_SUCCESS';

export const FETCH_VIDEO_TAG_FAILURE = 'FETCH_VIDEO_TAG_FAILURE';
export const FETCH_VIDEO_TAG_REQUEST = 'FETCH_VIDEO_TAG_REQUEST';
export const FETCH_VIDEO_TAG_SUCCESS = 'FETCH_VIDEO_TAG_SUCCESS';

export const FLUSH_VIDEO_TAG_ERRORS = 'FLUSH_VIDEO_TAG_ERRORS';
export const FLUSH_VIDEO_TAG_STATE = 'FLUSH_VIDEO_TAG_STATE';

export const REMOVE_VIDEO_TAG_FROM_LIST = 'REMOVE_VIDEO_TAG_FROM_LIST';

export const FLUSH_VIDEO_TAG_MESSAGES = 'FLUSH_VIDEO_TAG_MESSAGES';
