import {
    BULK_UPLOAD_UPDATE,
    FLUSH_UPLOADED_ITEMS_STATE,
    REMOVE_UPLOADED_ITEM_FROM_STATE
} from "../constants/bulkVideoUpload";

export const bulkVideoUploadUpdate = items => dispatch => {
    dispatch({
        type: BULK_UPLOAD_UPDATE,
        payload: items
    });
};

export const removeUploadedItemFromState = item => dispatch => {
    dispatch({
        type: REMOVE_UPLOADED_ITEM_FROM_STATE,
        payload: item,
    })
};

export const flushUploadedState = () => dispatch => {
    dispatch({
        type: FLUSH_UPLOADED_ITEMS_STATE,
    })
}
