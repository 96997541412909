import React, {Fragment, useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {useUser} from "../../hooks/user";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InviteDialog from "../InviteDialog";
import Divider from "@material-ui/core/Divider";
import ChangePasswordDialog from "../ChangePasswordDialog";
import {
    deleteUser,
    flushUserErrors, flushUserMessages,
    flushUserState,
    removeUserFromList,
    updateUser
} from "../../store/actions/user";
import {connect, useDispatch} from "react-redux";
import {getUserMessage, getUserUpdateErrors} from "../../store/reducers/user";
import {useSnackbar} from "notistack";
import StatusChip from "../../common/StatusChip";
import GravatarIcon from "../../common/GravatarIcon";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import {getErrorMessage} from "../../helpers/validation";
import {getAuthUserId} from "../../store/reducers/auth";

const initialState = {
    blocked: false,
    name: '',
    email: '',
    user_id: null,
    userPhoto: {}
};

const SingleAdmin = ({
                         history,
                         match,
                         classes,
                         updateUserErrors,
                         currentUserId
                     }) => {
    const {user, loading} = useUser(match.params.id);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    // TODO: report for change request field to "userStatus"
    const [state, setState] = useState(initialState);
    const [open, setOpenDialog] = useState(false);
    const [openChangePassword, setOpenChangePasswordDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [deleteDialog, setOpenDeleteDialog] = useState(false);
    const isCurrentUser = state.user_id === parseInt(currentUserId);
    const handleChange = name => event => {
        setState({...state, [name]: event.target.checked});
    };
    const handleChangeFields = name => event => {
        setState({...state, [name]: event.target.value});
        setErrors({});
    };
    const changePasswordDialog = () => {
        setOpenChangePasswordDialog(!openChangePassword)
    };
    const inviteDialog = () => {
        setOpenDialog(!open);
    };
    const updateUserAction = () => {
        dispatch(updateUser(state)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(flushUserMessages());
                // getUserAction(state.user_id);
            }
        ).catch(err => enqueueSnackbar(getErrorMessage(err), {variant: 'error'}))
    };

    const closeLink = () => {
        dispatch(flushUserErrors());
        dispatch(flushUserState());
        history.push(`/users`)
    };

    useEffect(() => {
        setState({
            blocked: user.status === 'blocked',
            name: user.name || '',
            email: user.email || '',
            user_id: user.id || null
        })
    }, [user]);
    useEffect(() => {
        setErrors(updateUserErrors);
    }, [updateUserErrors]);

    const deleteUserAction = () => {
        dispatch(deleteUser(state.user_id)).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                dispatch(removeUserFromList(state.user_id));
                closeLink();
            }
        ).catch(err => {
            enqueueSnackbar(getErrorMessage(err), {variant: 'error'})
        })
    };

    // TODO: improve circular process
    // if (loading) {
    //     return (<LinearProgress/>)
    // }

    if (loading || !user.id) {
        return (<Box>
            <Paper className={classes.preloader}>
                <Box className={classes.updateProgress}><LinearProgress/></Box>
            </Paper>
        </Box>)
    }

    return (
        <Fragment>
            <Paper className={classes.paper}>
                {loading && <Box className={classes.updateProgress}><LinearProgress/></Box>}
                <Toolbar className={classes.root}>
                    <IconButton onClick={closeLink} className={classes.closeButton}><ArrowBackIcon/></IconButton>
                    <Typography>
                        {user.name}
                    </Typography>
                    <Box className={classes.inviteBlock}>
                        <StatusChip status={user.status} size="large"/>
                    </Box>
                </Toolbar>
                <Divider/>
                <Container maxWidth="lg" className={classes.userData}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <form
                                noValidate
                                autoComplete="off"
                                className={classes.form}
                            >
                                <div className={classes.textField}>
                                    <FormControlLabel
                                        control={<Switch
                                            disabled={user.status === 'pending' || isCurrentUser}
                                            classes={{
                                                switchBase: classes.active,
                                                track: classes.activeTrack,
                                                checked: classes.checked,
                                            }}
                                            checked={state.blocked}
                                            onChange={handleChange('blocked')}
                                            value="blocked"/>}
                                        label={state.blocked ? 'Blocked' : 'Active'}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <TextField
                                        name="name"
                                        error={errors && !!errors.name}
                                        helperText={errors && errors.name && errors.name}
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.name}
                                        onChange={handleChangeFields('name')}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <TextField
                                        name="email"
                                        error={errors && !!errors.email}
                                        helperText={errors && errors.email && errors.email}
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        fullWidth
                                        value={state.email}
                                        disabled
                                        onChange={handleChangeFields('email')}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <TextField
                                        id="created-date"
                                        label="Date created"
                                        value={user.created_at ? user.created_at : ''}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <Button
                                        disabled={user.status === 'pending'}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => changePasswordDialog()}
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className={classes.userPhotoBlock}>
                            <Box className={classes.avatarWrapper}>
                                {/*<Avatar*/}
                                {/*    variant="square"*/}
                                {/*    className={classes.userAvatar}*/}
                                {/*    // src="/assets/images/1.jpg"*/}
                                {/*/>*/}
                                <GravatarIcon hash={user.gravatar} size="160" variant="square" forceSize/>
                            </Box>
                            {/*<Box className={classes.uploadBlock}>*/}
                            {/*    /!* TODO: implement fileupload*!/*/}
                            {/*    <input*/}
                            {/*        name="userPhoto"*/}
                            {/*        accept="image/*"*/}
                            {/*        className={classes.input}*/}
                            {/*        style={{display: 'none'}}*/}
                            {/*        id="user-photo-upload"*/}
                            {/*        multiple*/}
                            {/*        type="file"*/}
                            {/*        onChange={handleChange("userPhoto")}*/}
                            {/*    />*/}
                            {/*    <label htmlFor="user-photo-upload">*/}
                            {/*        <Button*/}
                            {/*            variant="contained"*/}
                            {/*            component="span"*/}
                            {/*            color="primary"*/}
                            {/*            className={classes.button}>*/}
                            {/*            Upload foto*/}
                            {/*        </Button>*/}
                            {/*    </label>*/}

                            {/*</Box>*/}
                        </Grid>
                    </Grid>
                </Container>
                <Divider/>
                <Container className={classes.actions}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} className={classes.actionsBlock}>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => setOpenDeleteDialog(true)}
                                disabled={isCurrentUser}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item lg={6} className={classes.actionsBlock}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                onClick={() => updateUserAction()}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            <InviteDialog
                open={open}
                onClose={inviteDialog}
            />
            <ConfirmDeleteDialog
                id={state.user_id}
                open={deleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                deleteItemAction={deleteUserAction}
                title={user.name}
            />
            <ChangePasswordDialog
                open={openChangePassword}
                onClose={changePasswordDialog}
            />

        </Fragment>
    );
};

const mapStateToProps = state => ({
    updateUserErrors: getUserUpdateErrors(state),
    updateUserMessage: getUserMessage(state),
    currentUserId: getAuthUserId(state)
});
export default withRouter(connect(mapStateToProps, {})(SingleAdmin));
