import {
    FETCH_COUNTS_FAILURE,
    FETCH_COUNTS_REQUEST,
    FETCH_COUNTS_SUCCESS
} from "../constants/counts";

const initialState = {
    counts: [],
    isFetching: false,
};

const counts = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_COUNTS_REQUEST:
            return {
                ...state,
                isFetching: false,
            };
        case FETCH_COUNTS_SUCCESS:
            return {
                ...state,
                counts: payload,
                isFetching: true,
            };
        case FETCH_COUNTS_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return {
                ...state,
                isFetching: false,
            };
    }
};

export default counts;

export const getCountsArr = state => state.counts.counts;
