import React from "react";
import {Route, Switch} from "react-router-dom";
import SingleImage from "../SingleImage";
import SingleImageCreate from "../SingleImageCreate";
import SingleImagePendingEdit from "../SingleImagePendingEdit";


const Image = () => {

    return (
        <Switch>
            <Route path="/images/image/new" component={SingleImageCreate}/>
            <Route path="/images/image/pending/:hash" component={SingleImagePendingEdit}/>
            <Route path="/images/image/:id" component={SingleImage}/>
        </Switch>
    );
}

export default Image;


