import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getVideoPlaylistData} from "../store/reducers/playlist";
import {getVideoPlaylist} from "../store/actions/playlist";

export const useVideo = (id) => {
    const dispatch = useDispatch();
    const playlist = useSelector(getVideoPlaylistData);
    const loading = useSelector(state => state.video.isFetching);
    useEffect(() => {
        dispatch(getVideoPlaylist(id));
    }, [dispatch, id]);

    return {
        playlist,
        loading
    };
};
