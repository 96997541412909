import React, {Fragment, useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {flushUserErrors, flushUserMessages, updateUser} from "../../store/actions/user";
import {connect, useDispatch} from "react-redux";
import FlashMessage from "react-flash-message";
import {useParams} from "react-router-dom";
import {
    getIsFetching,
    getUserErrors,
    getUserMessage,
    getUserUpdateResult
} from "../../store/reducers/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import {checkPassword} from "../../helpers/validation";

const initialState = {
    password: '',
    confirmPassword: ''
};

const ChangePasswordDialog = ({
                                  open,
                                  onClose,
                                  changePasswordErrors,
                                  isFetching,
                                  classes
                              }) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState('');
    const {enqueueSnackbar} = useSnackbar();

    const handleChange = name => event => {
        setState({...state, [name]: event.target.value});
    };

    const changePasswordAction = (e) => {
        e.preventDefault();

        // TODO: add messages constants

        const {password, confirmPassword} = state;
        if (password !== confirmPassword) {
            return setErrors('Passwords don\'t match!')
        }

        if (password === confirmPassword && !checkPassword(password)) {
            return setErrors('The password must contain numbers, upper and lower case letters, special characters and at least 8 characters! ')
        }

        dispatch(updateUser({
            user_id: id,
            password: state.password
        })).then(
            res => {
                enqueueSnackbar(res.data.message, {variant: 'success'});
                onClose();
            }
        ).catch(err => {
            if (err.data) {
                setErrors(err.data.message);
                enqueueSnackbar(err.data.message, {variant: 'error'})
                setState(initialState);
            } else {
                enqueueSnackbar('Error!', {variant: 'error'})
            }
        })
    };

    const closeDialog = () => {
        setState(initialState);
        dispatch(flushUserMessages());
        dispatch(flushUserErrors());
        onClose();
    };
    useEffect(() => {
        setErrors(changePasswordErrors);
    }, [changePasswordErrors]);

    useEffect(() => {
        setErrors('');
    }, [state]);

    return (
        <Fragment>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <form onSubmit={changePasswordAction}>
                    <DialogTitle id="form-dialog-title">Change password</DialogTitle>
                    <DialogContent>
                        {isFetching &&
                        <div className={classes.onFetching}>
                            <CircularProgress/>
                        </div>}
                        <DialogContentText>
                            Please type current and set new password with confirm.
                        </DialogContentText>
                        <TextField
                            error={!!errors}
                            required
                            autoFocus
                            margin="dense"
                            name="password"
                            label="New password"
                            type="password"
                            autoComplete=""
                            fullWidth
                            disabled={isFetching}
                            value={state.password}
                            onChange={handleChange('password')}
                        />
                        <TextField
                            error={!!errors}
                            required
                            margin="dense"
                            name="confirmPassword"
                            label="Confirm password"
                            type="password"
                            autoComplete=""
                            fullWidth
                            disabled={isFetching}
                            value={state.confirmPassword}
                            onChange={handleChange('confirmPassword')}
                        />
                        {!!errors ? <FlashMessage duration={100000} persistOnHover={true}>
                            <h5 className={"alert alert-danger"}>Error: {errors}</h5></FlashMessage> : ''}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closeDialog}
                            color="secondary"
                            variant="contained"
                            disabled={isFetching}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isFetching}
                        >
                            Change password
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>);
};

ChangePasswordDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    changePasswordErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    isFetching: PropTypes.bool.isRequired,
    changePasswordResult: PropTypes.string,
    changePasswordMessage: PropTypes.string
};

const mapStateToProps = state => ({
    isFetching: getIsFetching(state),
    changePasswordErrors: getUserErrors(state),
    changePasswordResult: getUserUpdateResult(state),
    changePasswordMessage: getUserMessage(state),
});

export default connect(mapStateToProps, {})(ChangePasswordDialog);
