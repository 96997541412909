export const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    textField: {
        marginBottom: theme.spacing(4)
    },
    form: {
        // margin: '20px',
        width: '100%',
    },
    btnWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '35px',
    },
    inputWrap: {
        margin: '20px',
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    fieldsHolder: {
      marginBottom: '10px',
    },
    videoBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    wrapThumbnails: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "no-wrap",
        padding: "20px",
    },
    cardThumnails: {
        margin: "5px",
        width: "calc(20% - 5px)",
        display: "flex",
        overflow: "hidden",
        cursor: "pointer",
        border: "3px solid transparent",
    },
    activeThumbnail: {
        border: "3px solid #4169E1"
    },
    imageWrap: {
        height: "90px",
        overflow: "hidden",
        objectFit: 'cover',
    },
    imageLink: {
        display: "block",
        height: "100%",
        minWidth: "100%",
        verticalAlign: "middle",
    }
});
