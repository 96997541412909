import {
    apiRequest,
    fetchAllImagesCategories as apiFetchAllImagesCategories,
    fetchAllImagesTags as apiFetchAllImagesTags,
    fetchAllVideosCategories as apiFetchAllVideosCategories,
    fetchAllVideosTags as apiFetchAllVideosTags,
} from "../../helpers/api";
import {
    FETCH_IMAGES_TAX_CATEGORIES_FAILURE,
    FETCH_IMAGES_TAX_CATEGORIES_REQUEST,
    FETCH_IMAGES_TAX_CATEGORIES_SUCCESS,
    FETCH_IMAGES_TAX_TAGS_FAILURE,
    FETCH_IMAGES_TAX_TAGS_REQUEST,
    FETCH_IMAGES_TAX_TAGS_SUCCESS,
    FETCH_VIDEOS_TAX_CATEGORIES_FAILURE,
    FETCH_VIDEOS_TAX_CATEGORIES_REQUEST,
    FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS,
    FETCH_VIDEOS_TAX_TAGS_FAILURE,
    FETCH_VIDEOS_TAX_TAGS_REQUEST,
    FETCH_VIDEOS_TAX_TAGS_SUCCESS,
    FLUSH_VIDEOS_TAX_STATE,
    FLUSH_IMAGES_TAX_STATE
} from "../constants/taxonomies";

export const fetchAllImagesCategories = () => dispatch => {
    dispatch({type: FETCH_IMAGES_TAX_CATEGORIES_REQUEST});

    const request = () => apiFetchAllImagesCategories();

    return apiRequest(request, dispatch, FETCH_IMAGES_TAX_CATEGORIES_SUCCESS, FETCH_IMAGES_TAX_CATEGORIES_FAILURE);
};

export const fetchAllImagesTags = () => dispatch => {
    dispatch({type: FETCH_IMAGES_TAX_TAGS_REQUEST});

    const request = () => apiFetchAllImagesTags();

    return apiRequest(request, dispatch, FETCH_IMAGES_TAX_TAGS_SUCCESS, FETCH_IMAGES_TAX_TAGS_FAILURE);
};

export const fetchAllVideosCategories = () => dispatch => {
    dispatch({type: FETCH_VIDEOS_TAX_CATEGORIES_REQUEST});

    const request = () => apiFetchAllVideosCategories();

    return apiRequest(request, dispatch, FETCH_VIDEOS_TAX_CATEGORIES_SUCCESS, FETCH_VIDEOS_TAX_CATEGORIES_FAILURE);
};

export const fetchAllVideosTags = () => dispatch => {
    dispatch({type: FETCH_VIDEOS_TAX_TAGS_REQUEST});

    const request = () => apiFetchAllVideosTags();

    return apiRequest(request, dispatch, FETCH_VIDEOS_TAX_TAGS_SUCCESS, FETCH_VIDEOS_TAX_TAGS_FAILURE);
};

export const flushVideosTaxState = () => dispatch => {
    dispatch({type:FLUSH_VIDEOS_TAX_STATE});
};

export const flushImagesTaxState = () => dispatch => {
    dispatch({type:FLUSH_IMAGES_TAX_STATE});
};
