import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getVideoTagData} from "../store/reducers/videoTag";
import {getVideoTag} from "../store/actions/videoTag";

export const useVideoTag = (id) => {
    const dispatch = useDispatch();
    const tag = useSelector(getVideoTagData);
    const loading = useSelector(state => state.video_tag.isFetching);
    useEffect(() => {
        dispatch(getVideoTag(id));
    }, [dispatch, id]);

    return {
        tag,
        loading
    };
};
