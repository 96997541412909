export const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    textField: {
        marginBottom: theme.spacing(4)
    },
    form: {
        margin: '20px',
        width: '100%',
    },
    btnWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '35px',
    },
    inputWrap: {
        width: '80%',
        margin: '20px',
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    videoBox: {
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
    }
});
