import {
    DELETE_EVENT_PENDING_FAILURE,
    DELETE_EVENT_PENDING_REQUEST,
    DELETE_EVENT_PENDING_SUCCESS,
    UPDATE_EVENT_PENDING_FAILURE,
    UPDATE_EVENT_PENDING_REQUEST,
    UPDATE_EVENT_PENDING_SUCCESS,
} from "../../constants/events/eventPending";

const initialState = {
    isFetching: false,
    title: "",
    images: [],
    videos: [],
    status: false
};

const eventPending = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case DELETE_EVENT_PENDING_REQUEST:
        case UPDATE_EVENT_PENDING_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_EVENT_PENDING_SUCCESS:
        case DELETE_EVENT_PENDING_FAILURE:
        case UPDATE_EVENT_PENDING_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case UPDATE_EVENT_PENDING_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return {
                ...state
            }
    }

};

export default eventPending;
