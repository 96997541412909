export const styles = theme => ({
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    menuItem: {
        textTransform: 'capitalize'
    },
    datesInterval: {
        display: 'flex',
        '&>div': {
            marginRight: theme.spacing(),
        }
    },
    datePicker: {
        minWidth: '120px',
        maxWidth: '300px'
    },
    filterButtonWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        marginRight: theme.spacing(),
        '&>button': {
            marginRight: theme.spacing()
        }
    },
    bulkBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginRight: theme.spacing(),
    },
    selects: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    },
    filterChips: {},
    filterChip: {
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(),
    }
});
