export const styles = theme => ({
    hidden: {
        display: 'none'
    },
    shadow: {
        boxShadow: theme.shadows[1],
    },
    badge: {
        marginLeft: '10px',
        borderRadius: '5px',
        height: '24px',
        marginBottom: '0px!important',
    },
    linkStyle: {
        '& .MuiTab-wrapper': {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
        },
        '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
            marginBottom: '0px'
        },
    }
});
