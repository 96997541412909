export const ADD_VIDEO_PLAYLIST_FAILURE = 'ADD_VIDEO_PLAYLIST_FAILURE';
export const ADD_VIDEO_PLAYLIST_REQUEST = 'ADD_VIDEO_PLAYLIST_REQUEST';
export const ADD_VIDEO_PLAYLIST_SUCCESS = 'ADD_VIDEO_PLAYLIST_SUCCESS';

export const UPDATE_VIDEO_PLAYLIST_FAILURE = 'UPDATE_VIDEO_PLAYLIST_FAILURE';
export const UPDATE_VIDEO_PLAYLIST_REQUEST = 'UPDATE_VIDEO_PLAYLIST_REQUEST';
export const UPDATE_VIDEO_PLAYLIST_SUCCESS = 'UPDATE_VIDEO_PLAYLIST_SUCCESS';

export const DELETE_VIDEO_PLAYLIST_FAILURE = 'DELETE_VIDEO_PLAYLIST_FAILURE';
export const DELETE_VIDEO_PLAYLIST_REQUEST = 'DELETE_VIDEO_PLAYLIST_REQUEST';
export const DELETE_VIDEO_PLAYLIST_SUCCESS = 'DELETE_VIDEO_PLAYLIST_SUCCESS';


export const REMOVE_VIDEO_PLAYLIST_FROM_LIST = 'REMOVE_VIDEO_PLAYLIST_FROM_LIST';

export const FLUSH_VIDEO_PLAYLIST_MESSAGES = 'FLUSH_VIDEO_PLAYLIST_MESSAGES';


export const FETCH_VIDEO_PLAYLIST_FAILURE = 'FETCH_VIDEO_PLAYLIST_FAILURE';
export const FETCH_VIDEO_PLAYLIST_REQUEST = 'FETCH_VIDEO_PLAYLIST_REQUEST';
export const FETCH_VIDEO_PLAYLIST_SUCCESS = 'FETCH_VIDEO_PLAYLIST_SUCCESS';

export const FLUSH_VIDEO_PLAYLIST_ERRORS = 'FLUSH_VIDEO_PLAYLIST_ERRORS';

export const FLUSH_VIDEO_PLAYLIST_STATE = 'FLUSH_VIDEO_PLAYLIST_STATE';
