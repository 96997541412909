import React from "react";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";

const NotFoundComponent = ({classes, status, children}) => {
    return (
        <h2>Not found</h2>
    );
};

export default NotFoundComponent;


