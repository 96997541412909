export const styles = theme => ({
    statusBadge: {
        display: 'inline-block',
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        borderRadius: '4px',
        color: '#fff',
        padding: 0,
        fontSize: '0.75em',
    },
    disabled: {
        color: '#fff!important'
    },
    approved: {
        backgroundColor: theme.palette.success.main
    },
    pending: {
        backgroundColor: theme.palette.warning.main
    },
    blocked: {
        backgroundColor: theme.palette.error.main
    },
    expired: {
        backgroundColor: theme.palette.process.dark,
        color: theme.palette.process.contrastText
    },
    in_process: {
        backgroundColor: theme.palette.info.main
    }
});
