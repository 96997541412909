import {loadTagsList} from "../../helpers/api";
import { LOAD_TAGS_FAILURE, LOAD_TAGS_REQUEST, LOAD_TAGS_SUCCESS } from "../constants/tags";

export const getTagsList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search
) => dispatch => {
    dispatch({type: LOAD_TAGS_REQUEST});

    loadTagsList(page, orderBy, order, limit, search).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_TAGS_FAILURE, message: message })
            }
            return dispatch({
                type: LOAD_TAGS_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_TAGS_FAILURE, message: error})
    )
};
