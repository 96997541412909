import React from "react";
import * as PropTypes from 'prop-types';

const TrimmedTitle = ({length, title, classes}) => {
    if (!title) {
        return null;
    }

    if (!length) {
        return (<span className={classes.title}>{title}</span>);
    }

    const ellipsis = !!length && title.length > length ? '...' : '';
    const trimmed = title.slice(0, length).trim() + ellipsis;

    return (<span className={classes.title}>{trimmed}</span>);
};

TrimmedTitle.propTypes = {
    length: PropTypes.number,
    title: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default TrimmedTitle;


