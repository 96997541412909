import {
  LOAD_STREAMS_LIST_REQUEST,
  LOAD_STREAMS_LIST_SUCCESS,
  LOAD_STREAMS_LIST_FAILURE
} from "../../constants/streams/list";

import {
  STREAMS_UPDATE_REQUEST,
  STREAMS_UPDATE_SUCCESS,
  STREAMS_UPDATE_FAILURE,
} from "../../constants/streams/update";

import {
  STREAMS_REMOVE_REQUEST,
  STREAMS_REMOVE_SUCCESS,
  STREAMS_REMOVE_FAILURE,
} from "../../constants/streams/remove";

const initialState = {
  isFetching: false,
  isUpdating: false,
  message: '',
  errors: false,
  updateErrors: {},
  status: '',
  items: [],
  currentPage: 1,
  total:1,
  pages: 1,
  limit: 10
};

const streams = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LOAD_STREAMS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case STREAMS_UPDATE_REQUEST:
    case STREAMS_REMOVE_REQUEST:
      return {
        ...state,
        isUpdating: true
      };
    case STREAMS_UPDATE_SUCCESS:
      return{
        ...state,
        isUpdating: false
      }
    case STREAMS_UPDATE_FAILURE:
    case STREAMS_REMOVE_FAILURE:
    case STREAMS_REMOVE_SUCCESS:
      return {
        ...state,
        isUpdating: false
      };
    case LOAD_STREAMS_LIST_SUCCESS:
      return {
        ...state,
        items: payload.data.data,
        currentPage: payload.data.current_page,
        limit: parseInt(payload.data.per_page),
        pages: payload.data.last_page,
        total: payload.data.total,
        isFetching: false
      };
    case LOAD_STREAMS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return {
        ...state
      }
  }

};

export default streams;
