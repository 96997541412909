import {
    LOAD_CATEGORIES_SUCCESS,
    LOAD_CATEGORIES_FAILURE,
    LOAD_CATEGORIES_REQUEST,
    ADD_CATEGORY_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS
} from "../constants/categories";
import {
  addCategory as apiAddCategory,
  apiRequest,
  loadCategoriesList,
  updateCategory as apiUpdateCategory
} from "../../helpers/api";

export const getCategoriesList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search
) => dispatch => {
    dispatch({type: LOAD_CATEGORIES_REQUEST});

    loadCategoriesList(page, orderBy, order, limit, search).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_CATEGORIES_FAILURE, message: message })
            }
            return dispatch({
                type: LOAD_CATEGORIES_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_CATEGORIES_FAILURE, message: error})
    )
};

export const addCategory = (data) => dispatch => {
    dispatch({type: 'ADD_CATEGORY_REQUEST'});
    return apiAddCategory(data)
        .then(
        res => {
            if (res.data.status === "error") {
                return dispatch({
                    type: 'ADD_CATEGORY_FAILURE',
                    message: res.data.message
                });
            }
            if (res.data.status === 'success') {

                return dispatch({
                    type: 'ADD_CATEGORY_SUCCESS',
                    payload: res.data
                });
            }
        },
        error => {
            return dispatch({
                type: 'ADD_CATEGORY_FAILURE',
                payload: error.response.data.message
            })
        })
};

export const updateCategory = (data) => dispatch => {
  dispatch({type: UPDATE_CATEGORY_REQUEST});
  const request = () => apiUpdateCategory(data);
  return apiRequest(request, dispatch, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILURE)
};
