import grey from '@material-ui/core/colors/grey';

export default {
    palette: {
        // primary: {
        //     dark: grey[900],
        //     main: grey[700],
        //     light: grey[500],
        //     lighter: grey[100],
        // },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
        },
        warning: {
            light: "#ffd54f",
            main: "#ffc107",
            dark: "#ffa000",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        info: {
            light: "#64b5f6",
            main: "#2196f3",
            dark: "#1976d2",
            contrastText: "#fff",
        },
        success: {
            light: "#81c784",
            main: "#4caf50",
            dark: "#388e3c",
            contrastText: "#fff",
        },
        process: {
            light: "#e2e2e2",
            main: "#afafaf",
            dark: "#7b7b7b",
            contrastText: "#fff",
        },
        chips: {
            categories: {
                light: "#FFD5E5",
                main: "#FFBBB4",
                dark: "#E78FB3",
                contrastText: "#fff"
            },
            tags: {
                light: "#C3F0CA",
                main: "#A7E9AF",
                dark: "#91BD3A",
                contrastText: "#fff"
            },
            date: {
                light: "#9ACEFF",
                main: "#46B3E6",
                dark: "#3282B8",
                contrastText: "#fff"
            }
        }
    },
}
