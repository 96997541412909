import React from 'react';
import {Field} from 'redux-form'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormStreams from '../../../../components/ReduxFormFields/FormStreams'

const EventStreams = ({...rest}) => {
  return (
    <Box>
      <Typography>Streams</Typography>
      <Divider/>
      <Field
        name={"streams"}
        component={FormStreams}
        {...rest}
      />
    </Box>
  );
};

export default EventStreams;
