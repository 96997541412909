export const FETCH_VIDEO_FAILURE = "FETCH_VIDEO_FAILURE";
export const FETCH_VIDEO_REQUEST = "FETCH_VIDEO_REQUEST";
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS";
export const FLUSH_VIDEO_ERRORS = "FLUSH_VIDEO_ERRORS";
export const FLUSH_VIDEO_STATE = "FLUSH_VIDEO_STATE";
export const FLUSH_VIDEO_MESSAGES = "FLUSH_VIDEO_MESSAGES";
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE";
export const UPDATE_VIDEO_REQUEST = "UPDATE_VIDEO_REQUEST";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";
export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const REMOVE_VIDEO_FROM_LIST = "REMOVE_VIDEO_FROM_LIST";
