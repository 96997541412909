import {DialogMessagesStatuses} from "../../constatns/statuses";
import {
    BLOCK_USER_FAILURE,
    BLOCK_USER_REQUEST,
    BLOCK_USER_SUCCESS, DELETE_USER_FAILURE,
    DELETE_USER_REQUEST, DELETE_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FLUSH_USER_ERRORS,
    FLUSH_USER_STATE,
    INVITE_USER_FAILURE,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS
} from "../constants/user";

const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        blocked: '',
        name: '',
        email: '',
        gravatar: '',
        created_at: null
    },
};

const user = (state = initialState, action) => {
    const {type, payload, message} = action;

    switch (type) {
        case INVITE_USER_REQUEST:
        case FETCH_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case BLOCK_USER_REQUEST:
        case DELETE_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
                errors: false,
                updateErrors: {},
            };
        case INVITE_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
                status: DialogMessagesStatuses.error,
            };
        case UPDATE_USER_FAILURE:
        case BLOCK_USER_FAILURE:
        case DELETE_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message,
                data: initialState.data,
            };
        case BLOCK_USER_SUCCESS:
        case INVITE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.message,
                data: payload.data
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: payload,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                errors: '',
                status: '',
                isFetching: false,
                data: payload.data
            };
        case FLUSH_USER_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        case FLUSH_USER_STATE:
            return {
                ...state,
                data: initialState.data
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: '',
                status: '',
            }
    }
};

export default user;

export const getIsFetching = state => state.user.isFetching;
export const getUserErrors = state => state.user.errors;
export const getUserUpdateErrors = state => state.user.updateErrors;
export const getUserMessage = state => state.user.message;
export const getUserUpdateResult = state => state.user.status;
export const getInviteResult = state => state.user.status;

export const getUserData = state => state.user.data;

