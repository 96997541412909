import { useCallback, useState } from "react";

const useToggle = (toogled = false) => {
  const [open, setOpen] = useState(toogled);

  const onToggle = useCallback(() => {
    setOpen(state => !state);
  }, [open, setOpen]);

  return [open, onToggle];
};

export default useToggle;
