import React, {Fragment} from "react";
import {messages} from "../../helpers/constants";
import Resumablejs from 'resumablejs'
import {withSnackbar} from 'notistack';
import {getErrorMessage} from "../../helpers/validation";
import Box from "@material-ui/core/Box";
import {connect} from "react-redux";
import {
    bulkVideoUploadUpdate, flushUploadedState,
    removeUploadedItemFromState
} from "../../store/actions/bulkVideoUpload";
import Button from "@material-ui/core/Button";
import clsx from "clsx";


class UploadResumableField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaused: false,
            isUploadProcess: false,
            progressBar: 0,
            messageStatus: '',
            fileList: {
                files: []
            },
            files: []
        };
        this.resumable = null;
        this._mounted = false;
        this.cancelUpload = this.cancelUpload.bind(this);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        const {
            service,
            query,
            fileTypes,
            maxFiles,
            maxFileSize,
            onFileAddedError,
            onMaxFileSizeErrorCallback,
            testMethod,
            testChunks,
            headerObject,
            chunkSize,
            simultaneousUploads,
            fileParameterName,
            generateUniqueIdentifier,
            forceChunkSize,
            maxFilesErrorCallback,
            disableDragAndDrop,
            onFileAdded,
            fileNameServer,
            completedMessage,
            onFileSuccess,
            onUploadErrorCallback,
            enqueueSnackbar,
            onMounted,
            maxChunkRetries
        } = this.props;
        const {fileList} = this.state;
        let ResumableInstance = new Resumablejs({
            target: service,
            query: query || {},
            fileType: fileTypes,
            maxFiles: maxFiles,
            maxFileSize: maxFileSize,
            maxChunkRetries: maxChunkRetries,
            fileTypeErrorCallback: (file, errorCount) => {
                this.props.enqueueSnackbar(messages.bulkUpload.errors.fileType, {variant: "error"});
                if (typeof onFileAddedError === "function") {
                    onFileAddedError(file, errorCount);
                }
            },
            maxFileSizeErrorCallback: (file, errorCount) => {
                if (typeof onMaxFileSizeErrorCallback === "function") {
                    onMaxFileSizeErrorCallback(file, errorCount);
                }
            },
            testMethod: testMethod || 'post',
            testChunks: testChunks || false,
            headers: headerObject || {},
            chunkSize: chunkSize,
            simultaneousUploads: simultaneousUploads,
            fileParameterName: fileParameterName,
            generateUniqueIdentifier: generateUniqueIdentifier,
            forceChunkSize: forceChunkSize
        });

        if (typeof maxFilesErrorCallback === "function") {
            ResumableInstance.opts.maxFilesErrorCallback = maxFilesErrorCallback;
        }

        ResumableInstance.assignBrowse(this.uploader);

        if (disableDragAndDrop === false) {
            ResumableInstance.assignDrop(this.dropZone);
        }

        ResumableInstance.on('fileAdded', (file, event) => {
            if (this._mounted) {
                this.setState(state => ({
                    ...state,
                    messageStatus: messages.bulkUpload.start,
                    files: [].concat(state.files, file)
                }));
            }

            if (typeof onFileAdded === "function") {
                onFileAdded(file, this.resumable);
            } else if (ResumableInstance.files.length === 0) {
                ResumableInstance.upload();
            }
        });

        ResumableInstance.on('fileSuccess', (file, fileServer) => {
            this.props.enqueueSnackbar(JSON.parse(fileServer).message, {variant: "success"});
            this.props.bulkVideoUploadUpdate({
                files: ResumableInstance.files.map(file => ({name: file.fileName, id: file.uniqueIdentifier})),
                progress: 100,
                status: 'success',
                message: JSON.parse(fileServer).message,
                errors: []
            });
            this.resumable.removeFile(file);
            this.props.removeUploadedItemFromState(file.uniqueIdentifier);

            if (!this.props.bulkVideoUploadInProcess) {
                this.props.flushUploadedState();
            }
            if (fileNameServer) {
                let objectServer = JSON.parse(fileServer);
                file.fileName = objectServer[fileNameServer];
            } else {
                file.fileName = fileServer;
            }

            let currentFiles = fileList.files;
            currentFiles.push(file);
            if (this._mounted) {
                this.setState({
                    fileList: {files: currentFiles},
                    messageStatus: completedMessage + file.fileName || fileServer
                }, () => {
                    if (typeof onFileSuccess === "function") {
                        onFileSuccess(file, fileServer);
                    }
                });
            }
        });

        ResumableInstance.on('progress', () => {
            if (this._mounted) {
                this.setState({
                    isUploadProcess: ResumableInstance.isUploading()
                });
            }
            this.props.bulkVideoUploadUpdate({
                files: ResumableInstance.files.map(file => {
                    return {
                        name: file.fileName,
                        id: file.uniqueIdentifier
                    }
                }),
                progress: ResumableInstance.progress() * 100,
                status: 'load',
                errors: []
            });

            if ((ResumableInstance.progress() * 100) < 100) {
                if (this._mounted) {
                    this.setState({
                        messageStatus: parseInt(ResumableInstance.progress() * 100, 10) + '%',
                        progressBar: ResumableInstance.progress() * 100
                    });
                }
            } else {
                setTimeout(() => {
                    if (this._mounted) {
                        this.setState({
                            progressBar: 0
                        })
                    }
                }, 1000);
            }

        });

        ResumableInstance.on('error', (message, file) => {
            onUploadErrorCallback(message);
            this.props.bulkVideoUploadUpdate({
                files: ResumableInstance.files.map(file => ({name: file.fileName, id: file.uniqueIdentifier})),
                progress: ResumableInstance.progress() * 100,
                status: !ResumableInstance.isUploading() ? 'error' : 'load',
                errors: [
                    {
                        [file.uniqueIdentifier]: getErrorMessage(message, true)
                    }
                ],
            });
            this.resumable.removeFile(file);
            this.props.flushUploadedState();
            enqueueSnackbar(getErrorMessage(message, true), {variant: 'error'})
        });

        ResumableInstance.on('fileError', (file, errorCount) => {
            // onUploadErrorCallback(file, errorCount);
        });

        this.resumable = ResumableInstance;
        onMounted(this.resumable);
    };

    removeFile(e, file, index) {
        e.preventDefault();
        const {fileList} = this.state;
        const {onFileRemoved} = this.props;
        let currentFileList = fileList.files;
        delete currentFileList[index];
        if (this._mounted) {
            this.setState({
                fileList: {
                    files: currentFileList
                }
            });
        }
        onFileRemoved(file);
        this.resumable.removeFile(file);
    };

    createFileList() {
        const {fileList} = this.state;
        const {uploaderID, tmpDir} = this.props;
        let markup = fileList.files.map((file, index) => {

            let uniqID = uploaderID + '-' + index;
            let originFile = file.file;
            let media = '';

            if (file.file.type.indexOf('video') > -1) {
                media = <label className="video">{originFile.name}</label>;
                return <li className="thumbnail" key={uniqID}>
                    <label id={"media_" + uniqID}>{media}</label>
                    <a onClick={(event) => this.removeFile(event, file, index)} href="#">[X]</a>
                </li>;
            } else if (file.file.type.indexOf('image') > -1) if (tmpDir !== "") {
                let src = tmpDir + file.fileName;
                media = <img className="image" width="80" src={src} alt=""/>;
                return <li className="thumbnail" key={uniqID}>
                    <label id={"media_" + uniqID}>{media}</label>
                    <a onClick={(event) => this.removeFile(event, file, index)} href="#">[X]</a>
                </li>;

            } else {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(originFile);
                fileReader.onload = (event) => {
                    media = '<img class="image" width="80" src="' + event.target.result + '"/>';
                    document.querySelector("#media_" + uniqID).innerHTML = media;
                };
                return <li className="thumbnail" key={uniqID}>
                    <label id={"media_" + uniqID}/>
                    <a onClick={(event) => this.removeFile(event, file, index)} href="#">[X]</a>
                </li>;
            } else {
                media = <label className="document">{originFile.name}</label>;
                return <li className="thumbnail" key={uniqID}>
                    <label id={"media_" + uniqID}>{media}</label>
                    <a onClick={(event) => this.removeFile(event, file, index)} href="#">[X]</a>
                </li>;
            }
        });

        return <ul id={"items-" + uploaderID}>{markup}</ul>;
    };

    cancelUpload(e) {
        e.preventDefault();
        this.resumable.cancel();
        this.props.flushUploadedState();
        if (this._mounted) {
            this.setState({
                fileList: {files: []}
            });
        }
        this.props.onCancelUpload();
    };

    pauseUpload() {
        const {isPaused} = this.state;
        const {onPauseUpload, onResumeUpload} = this.props;
        if (!isPaused) {
            this.resumable.pause();
            if (this._mounted) {
                this.setState({
                    isPaused: true
                });
            }
            onPauseUpload();
        } else {
            this.resumable.upload();
            if (this._mounted) {
                this.setState({
                    isPaused: false
                });
            }
            onResumeUpload();
        }
    };

    startUpload() {
        this.resumable.upload();
        if (this._mounted) {
            this.setState({
                isPaused: false
            });
        }
        this.props.onStartUpload();
    };

    render() {
        const {
            showFileList,
            textLabel,
            fileTypesText,
            previousText,
            maxFilesText,
            startButton,
            cancelButton,
            pauseButton,
            dropTargetID,
            disableInput,
            uploaderID,
            fileAccept,
            maxFiles,
            classes,
        } = this.props;
        const {isUploadProcess, progressBar, files} = this.state;
        let fileListItems = null;
        if (showFileList) {
            fileListItems = <div className="resumable-list">{this.createFileList()}</div>;
        }

        let prevText = null;
        if (previousText) {
            if (typeof previousText === "string") {
                prevText = <p>{previousText}</p>
            } else {
                prevText = previousText;
            }
        }

        let textLabelTitle = null;
        if (textLabel) {
            textLabelTitle = textLabel;
        }

        let startButtonItem = null;
        if (startButton) {
            if (typeof startButton === "string" || typeof startButton === "boolean") {
                startButtonItem =
                    <label>
                        <button disabled={isUploadProcess} className="btn start"
                                onClick={this.startUpload}>{startButton && "upload"}
                        </button>
                    </label>;
            } else {
                startButtonItem = startButton;
            }
        }

        let cancelButtonItem = null;
        if (cancelButton) {
            if (typeof cancelButton === "string" || typeof cancelButton === "boolean") {

                cancelButtonItem =
                    <label>
                        <Button disabled={!isUploadProcess} className="btn cancel"
                                onClick={this.cancelUpload}>{cancelButton && "cancel"}
                        </Button>
                    </label>;
            } else {
                cancelButtonItem = cancelButton;
            }
        }

        let pauseButtonItem = null;
        if (pauseButton) {
            if (typeof pauseButton === "string" || typeof pauseButton === "boolean") {
                pauseButtonItem =
                    <label>
                        <button disabled={!isUploadProcess} className="btn pause"
                                onClick={this.pauseUpload}>{pauseButton && "pause"}
                        </button>
                    </label>;
            } else {
                pauseButtonItem = pauseButton;
            }
        }
        return (
            <Fragment>
                <div className={classes.dropzoneBlock} id={dropTargetID} ref={node => this.dropZone = node}>
                    {disableInput && <div className={classes.fadeBlock}></div>}
                    <Box className={clsx(classes.uploadBlock, classes[disableInput ? 'hiddenBlock' : ''] )}>
                        <input
                            ref={node => this.uploader = node}
                            className={classes.dropzoneInput}
                            type="file"
                            id={uploaderID}
                            name={uploaderID + '-upload'}
                            accept={fileAccept || '*'}
                            disabled={!!disableInput}
                        />
                        <label htmlFor={uploaderID}>
                            <p>{textLabelTitle}</p>
                            <p>{maxFilesText}</p>
                            <em>{fileTypesText}</em>
                            <p>
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    className={classes.button}
                                    disabled={disableInput}
                                >
                                    Upload video
                                </Button>
                            </p>
                        </label>
                    </Box>
                    {/*{fileListItems}*/}
                    {startButtonItem}
                    {pauseButtonItem}
                </div>
                {cancelButtonItem}
            </Fragment>
        );
    }
}

UploadResumableField.defaultProps = {
    maxFiles: undefined,
    uploaderID: 'resumable-filed',
    dropTargetID: 'dropTarget',
    fileTypes: [],
    fileAccept: '*',
    maxFileSize: 10240000,
    showFileList: true,
    onUploadErrorCallback: (file, errorCount) => {

    },
    onFileRemoved: function (file) {
        return file;
    },
    onCancelUpload: function () {
        return true;
    },
    onPauseUpload: function () {
        return true;
    },
    onResumeUpload: function () {
        return true;
    },
    onStartUpload: function () {
        return true;
    },
    disableDragAndDrop: false,
    fileNameServer: "",
    tmpDir: "",
    chunkSize: 1024 * 1024,
    simultaneousUploads: 1,
    fileParameterName: 'file',
    generateUniqueIdentifier: null,
    maxFilesErrorCallback: null,
    cancelButton: false,
    pause: false,
    startButton: null,
    pauseButton: null,
    previousText: "",
    headerObject: {},
    forceChunkSize: false,
    textLabel: ""
};


export default connect(
    (state) => ({
        bulkVideoUploadInProcess: !![].concat(state.bulkVideoUpload.items).filter(item => item.progress < 100).length,
    }),
    {
        bulkVideoUploadUpdate,
        removeUploadedItemFromState,
        flushUploadedState
    }
)(withSnackbar(UploadResumableField));
