import React from "react";
import {checkInternetConnection} from "../helpers/api";
// TODO: improve error display and connection check
export default function (ComposedComponent) {
    class NetworkDetector extends React.Component {
        constructor(props) {
            super(props);
            this.props = props;
            this.state = {
                isDisconnected: false
            };
            this.handleConnectionChange = this.handleConnectionChange.bind(this);
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange() {
            return;
            // const condition = navigator.onLine ? 'online' : 'offline';
            // if (condition === 'online') {
            //     const webPing = setInterval(
            //         () => {
            //             checkInternetConnection().then(() => {
            //                     this.setState({isDisconnected: false}, () => {
            //                         return clearInterval(webPing)
            //                     });
            //                 }).catch(() => this.setState({isDisconnected: true}))
            //         }, 4000);
            //     return;
            // }
            //
            // return this.setState({isDisconnected: true});
        }

        render() {
            const {isDisconnected} = this.state;

            return (<ComposedComponent isDisconnected={isDisconnected} {...this.props} />);
        }
    }

    return NetworkDetector;
}
