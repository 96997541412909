import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import {getAccessToken, getAuthUserId} from "../../store/reducers/auth";
import {logoutAction, setAuthUserId} from "../../store/actions/auth";
import {connect} from "react-redux";
import UploadResumableField from "../UploadResumableField";
import {messages, settings} from "../../helpers/constants";
import Box from "@material-ui/core/Box";
import UploadItemCard from "./UploadItemCard";
import {uploadItemUUid} from "../../store/reducers/bulkVideoUpload";
import {flushUploadedState} from "../../store/actions/bulkVideoUpload";


class ContentInside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputDisable: false,
            resumableInstance: {}
        }
    }

    onMounted = (instance) => {
        this.setState(state => ({
            ...state,
            resumableInstance: instance
        }))
    }

    cancelAction = () => {
        this.state.resumableInstance.cancel();
        this.props.flushUploadedState();
    };

    render() {
        return (<Fragment>
                <UploadResumableField
                    uploaderID="image-upload"
                    dropTargetID="myDropTarget"
                    fileTypes={settings.video.uploading.fileTypes}
                    maxFileSize={settings.video.uploading.maxFileSize}
                    fileAccept="*/*"
                    completedMessage="Complete!"
                    service={settings.video.uploading.endpoint}
                    maxChunkRetries={settings.video.uploading.maxChunkRetries}
                    headerObject={{authorization: `Bearer ${this.props.token}`}}
                    disableDragAndDrop={false}
                    textLabel={messages.bulkUpload.dropzone}
                    fileTypesText={messages.bulkUpload.dropzoneAdditionalText}
                    maxFilesText={messages.bulkUpload.maxFilesText + settings.video.uploading.maxFiles}
                    onFileSuccess={(file, message) => {
                        // this.props.setFiles(file, message);
                        // this.setState({inputDisable: false});
                    }}
                    onFileAdded={(file, resumable) => {
                        // this.setState({inputDisable: true});
                        resumable.upload();
                    }}
                    onFileRemoved={(file) => {
                        this.setState({inputDisable: false});
                        this.forceUpdate();
                        // console.log('file removed', file);
                    }}
                    onMaxFileSizeErrorCallback={(file, errorCount) => {
                        // console.log('Error! Max file size reached: ', file);
                        // console.log('errorCount: ', errorCount);
                    }}
                    fileNameServer="file"
                    maxFiles={settings.video.uploading.maxFiles}
                    simultaneousUploads={3}
                    // onUploadErrorCallback={(message) => this.props.enqueueSnackbar(message, {variant: 'error'})}
                    onFileAddedError={(file, errorCount) => {
                        // console.log('error file added', file, errorCount);
                    }}
                    maxFilesErrorCallback={(file, errorCount) => {
                        // console.log('maxFiles', file, errorCount);
                    }}
                    disableInput={this.props.bulkVideoUploadInProcess}
                    startButton={false}
                    pauseButton={false}
                    cancelButton={false}
                    onStartUpload={() => {
                        // this.setState({inputDisable: true});
                    }}
                    onCancelUpload={() => {
                        // this.setState({inputDisable: false});
                    }}
                    onPauseUpload={() => {
                        // this.setState({inputDisable: false});
                    }}
                    onResumeUpload={() => {
                        // this.setState({inputDisable: true});
                    }}
                    cancelButtonAction={() => {
                    }}
                    onMounted={this.onMounted}
                />

                <Box>
                    {this.props.bulkVideoUploadInProcess && this.props.bulkVideoUploadItems.map((item, i) => {
                        return (<UploadItemCard
                            key={uploadItemUUid(item)}
                            item={item}
                            onCancel={this.cancelAction}
                        />)
                    })}
                </Box>
            </Fragment>
        );
    }
}

ContentInside.propTypes = {
    children: PropTypes.node
};

class UploadField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            files: []
        };
    }

    setFiles = (file, message) => {

        // console.log('Message: ', message);

        let files = this.state.files.slice();
        files.push(file);

        this.setState({
            files: files
        });
    };

    submit = () => {
        // console.log('Files: ', this.state.files);
    };

    render() {
        return (
            <ContentInside
                bulkVideoUploadInProcess={this.props.bulkVideoUploadInProcess}
                bulkVideoUploadItems={this.props.bulkVideoUploadItems}
                flushUploadedState={this.props.flushUploadedState}
                setFiles={this.setFiles} token={this.props.access_token}
            />
        );
    }
}


const mapStateToProps = state => ({
    currentUser: getAuthUserId(state),
    access_token: getAccessToken(state),
    bulkVideoUploadItems: [].concat(state.bulkVideoUpload.items),
    bulkVideoUploadInProcess: !![].concat(state.bulkVideoUpload.items).filter(item => item.progress < 100).length,
});

const mapDispatchToProps = {logoutAction, setAuthUserId,flushUploadedState};

export default connect(mapStateToProps, mapDispatchToProps)(UploadField);


