import {
    LOAD_VIDEO_CATEGORIES_FAILURE,
    LOAD_VIDEO_CATEGORIES_SUCCESS,
    LOAD_VIDEO_CATEGORIES_REQUEST
} from "../constants/videoCategories";
import { loadVideosCategoryList} from "../../helpers/api";


export const getVideoCategoriesList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search
) => dispatch => {
    dispatch({type: LOAD_VIDEO_CATEGORIES_REQUEST});

    loadVideosCategoryList(page, orderBy, order, limit, search).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_VIDEO_CATEGORIES_FAILURE, message: message })
            }
            return dispatch({
                type: LOAD_VIDEO_CATEGORIES_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type:  LOAD_VIDEO_CATEGORIES_FAILURE, message: error})
    )
};
