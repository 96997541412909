import React, { useEffect, useState } from 'react'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import InviteButton from '../../common/InviteButton'
import AddButton from '../../common/AddButton/AddButton'
import Box from '@material-ui/core/Box'
import { TextField } from '@material-ui/core'

const ENTER_KEY = 13
const WAIT_INTERVAL = 2000

const ListActions = ({ openInviteDialog, classes, isInvite, isAdd, onClickAdd, searchHandler, waitInterval = WAIT_INTERVAL }) => {
  const [searchStr, setSearchStr] = useState('')
  let timer = null
  const setFindStr = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(triggerChange, waitInterval)
  }

  const handleChange = e => {
    setSearchStr(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      triggerChange()
    }
  }

  useEffect(() => {
    setFindStr()
    return () => clearTimeout(timer)
  }, [searchStr])

  const triggerChange = () => {
    searchHandler(searchStr)
  }

  return (<Box className={classes.actionBlock}>
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon/>
      </div>
      <InputBase
        value={searchStr}
        onChange={handleChange}
        placeholder="Search…"
        onKeyDown={handleKeyDown}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search', type: 'search' }}
      />
    </div>
    {isInvite && (<InviteButton onClick={openInviteDialog}/>)}
    {isAdd && (<AddButton onClick={onClickAdd}/>)}
  </Box>)
}

export default ListActions


