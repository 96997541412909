import React from 'react';
import {Field} from 'redux-form'
import FormTextField from "../../../../components/ReduxFormFields/FormTextField";

const Description = ({classes, ...rest}) => {
  return (
    <div className={classes.textField}>
      <Field
        name={'description'}
        label={"Description"}
        type={"text"}
        fullWidth
        variant="outlined"
        component={FormTextField}
        placeholder={"Description"}
        {...rest}
      />
    </div>
  );
};

export default Description;
