export const CREATE_IMAGES_BULK_ACTION_REQUEST = 'CREATE_IMAGES_BULK_ACTION_REQUEST';
export const CREATE_IMAGES_BULK_ACTION_FAILURE = 'CREATE_IMAGES_BULK_ACTION_FAILURE';
export const CREATE_IMAGES_BULK_ACTION_SUCCESS = 'CREATE_IMAGES_BULK_ACTION_SUCCESS';
export const CHANGE_IMAGES_STATUS_TO_PROGRESS = 'CHANGE_IMAGES_STATUS_TO_PROGRESS';
export const FLUSH_BULK_ACTION_STATE = 'FLUSH_BULK_ACTION_STATE';
export const CREATE_CATEGORIES_BULK_ACTION_REQUEST = 'CREATE_CATEGORIES_BULK_ACTION_REQUEST';
export const CREATE_CATEGORIES_BULK_ACTION_FAILURE = 'CREATE_CATEGORIES_BULK_ACTION_FAILURE';
export const CREATE_CATEGORIES_BULK_ACTION_SUCCESS = 'CREATE_CATEGORIES_BULK_ACTION_SUCCESS';
export const CREATE_TAGS_BULK_ACTION_REQUEST = 'CREATE_TAGS_BULK_ACTION_REQUEST';
export const CREATE_TAGS_BULK_ACTION_FAILURE = 'CREATE_TAGS_BULK_ACTION_FAILURE';
export const CREATE_TAGS_BULK_ACTION_SUCCESS = 'CREATE_TAGS_BULK_ACTION_SUCCESS';
export const CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST = 'CREATE_VIDEO_PLAYLISTS_BULK_ACTION_REQUEST';
export const CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE = 'CREATE_VIDEO_PLAYLISTS_BULK_ACTION_FAILURE';
export const CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS = 'CREATE_VIDEO_PLAYLISTS_BULK_ACTION_SUCCESS';
export const UPDATE_VIDEO_PLAYLISTS_AFTER_BULK = 'UPDATE_VIDEO_PLAYLISTS_AFTER_BULK';
export const CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST = 'CREATE_VIDEO_CATEGORIES_BULK_ACTION_REQUEST';
export const CREATE_VIDEO_CATEGORIES_BULK_ACTION_FAILURE = 'CREATE_VIDEO_CATEGORIES_BULK_ACTION_FAILURE';
export const CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS = 'CREATE_VIDEO_CATEGORIES_BULK_ACTION_SUCCESS';
export const UPDATE_VIDEO_CATEGORIES_AFTER_BULK = 'UPDATE_VIDEO_CATEGORIES_AFTER_BULK';
export const CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST = 'CREATE_VIDEO_TAGS_BULK_ACTION_REQUEST';
export const CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE = 'CREATE_VIDEO_TAGS_BULK_ACTION_FAILURE';
export const CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS = 'CREATE_VIDEO_TAGS_BULK_ACTION_SUCCESS';
export const UPDATE_VIDEO_TAGS_AFTER_BULK = 'UPDATE_VIDEO_TAGS_AFTER_BULK';
export const CREATE_VIDEOS_BULK_ACTION_REQUEST = 'CREATE_VIDEOS_BULK_ACTION_REQUEST';
export const CREATE_VIDEOS_BULK_ACTION_FAILURE = 'CREATE_VIDEOS_BULK_ACTION_FAILURE';
export const CREATE_VIDEOS_BULK_ACTION_SUCCESS = 'CREATE_VIDEOS_BULK_ACTION_SUCCESS';
export const CHANGE_VIDEOS_STATUS_TO_PROGRESS = 'CHANGE_VIDEOS_STATUS_TO_PROGRESS';
export const CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST = 'CREATE_IMAGES_PENDING_BULK_ACTION_REQUEST';
export const CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE = 'CREATE_IMAGES_PENDING_BULK_ACTION_FAILURE';
export const CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS = 'CREATE_IMAGES_PENDING_BULK_ACTION_SUCCESS';
