import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getTagData} from "../store/reducers/tag";
import {getTag} from "../store/actions/tag";

export const useTag = (id) => {
    const dispatch = useDispatch();
    const tag = useSelector(getTagData);
    const loading = useSelector(state => state.tag.isFetching);
    useEffect(() => {
        dispatch(getTag(id));
    }, [dispatch, id]);

    return {
        tag,
        loading
    };
};
