import React from "react";
import * as PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {
    flushVideoErrors,
    flushVideoState,
} from "../../store/actions/video";
import UploadField from "../../common/UploadField";

const VideoUpload = ({classes}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const closeLink = () => {
        dispatch(flushVideoErrors());
        dispatch(flushVideoState());
        history.push('/videos/video');
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar className={classes.root}>
                    <IconButton
                        onClick={closeLink}
                        className={classes.closeButton}
                    ><ArrowBackIcon/></IconButton>
                </Toolbar>
                <Divider/>
                <Grid container>
                    <form className={classes.form}>
                        <Grid container>
                            <Grid item lg={12}>
                                <UploadField/>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Paper>
        </div>
    )
};

VideoUpload.propTypes = {
    classes: PropTypes.object.isRequired
};

export default VideoUpload;
