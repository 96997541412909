import {LOAD_IMAGES_FAILURE, LOAD_IMAGES_REQUEST, LOAD_IMAGES_SUCCESS} from "../constants/images";
import {loadImagesList} from "../../helpers/api";

export const getImagesList = (
    page = 1,
    orderBy = 'id',
    order = 'desc',
    limit = 10,
    search,
    status,
    from,
    to,
    categories,
    tags
) => dispatch => {
    dispatch({type: LOAD_IMAGES_REQUEST});

    loadImagesList(page, orderBy, order, limit, search, status, from, to, categories, tags).then(
        res => {
            const {data, status, message} = res.data;

            if (status === "error") {
                return dispatch({type: LOAD_IMAGES_FAILURE, message: message})
            }
            return dispatch({
                type: LOAD_IMAGES_SUCCESS,
                payload: data
            })
        },
        error => dispatch({type: LOAD_IMAGES_FAILURE, message: error})
    )
};

export const getApprovedImagesList = (
  page = 1,
  orderBy = 'id',
  order = 'desc',
  limit = 10,
  search,
  status = 'approved',
) => dispatch => {
  dispatch({type: LOAD_IMAGES_REQUEST});

  loadImagesList(page, orderBy, order, limit, search, status).then(
    res => {
      const {data, status, message} = res.data;

      if (status === "error") {
        return dispatch({type: LOAD_IMAGES_FAILURE, message: message})
      }
      return dispatch({
        type: LOAD_IMAGES_SUCCESS,
        payload: data
      })
    },
    error => dispatch({type: LOAD_IMAGES_FAILURE, message: error})
  )
};

