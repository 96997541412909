import React from "react";
import * as PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const SideBarMenuItem = ({title, linkTo, active, icon, classes}) => {
    const item = <ListItem
        button
        disabled={active}
        className={active ? classes.disabled : ''}
    >
        <ListItemIcon>
            {icon ? icon : <></>}
        </ListItemIcon>
        <ListItemText primary={title}/>
    </ListItem>;

    return (!active ?
        <Link to={linkTo} variant="body2">
            {item}
        </Link> : item);
};

SideBarMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    linkTo: PropTypes.string,
    active: PropTypes.bool,
    icon: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

export default SideBarMenuItem;


