export const styles = theme => ({
  thumbnail: {
    height: "40px",
    width: "40px",

    background: "#D3D3D3",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    '& > *': {
      width: "100%",
      height: "100%"
    }
  },
  draggable: {
    cursor: 'move',
  }
});
