import React from "react";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";


const ImagePreloader = ({classes}) => {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.holder}>
            <CircularProgress/>
        </Box>
      </Box>
    );
}

export default ImagePreloader;


