import {
    FETCH_CATEGORY_FAILURE,
    FETCH_CATEGORY_SUCCESS,
    FLUSH_CATEGORY_ERRORS,
    FETCH_CATEGORY_REQUEST,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_REQUEST,
    FLUSH_CATEGORY_STATE, FLUSH_CATEGORY_MESSAGES
} from "../constants/category";
import {UPDATE_CATEGORY_FAILURE, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS} from "../constants/categories";
import {DialogMessagesStatuses} from "../../constatns/statuses";


const initialState = {
    isFetching: false,
    message: '',
    errors: false,
    updateErrors: {},
    status: '',
    data: {
        id: null,
        name: '',
        cat_parent: ''
    },
};

const category = (state = initialState, action) => {
    const {type, payload, message} = action;
    switch (type) {
        case FETCH_CATEGORY_REQUEST:
        case UPDATE_CATEGORY_REQUEST:
        case DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_CATEGORY_FAILURE:
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                updateErrors: payload,
                status: DialogMessagesStatuses.error,
                message: payload,
            };

        case UPDATE_CATEGORY_SUCCESS:
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                errors: '',
                status: DialogMessagesStatuses.success,
                message: payload.data
            };
        case FETCH_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: message,
            };
        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                errors: '',
                status: '',
                isFetching: false,
                data: payload.data
            };
        case FLUSH_CATEGORY_STATE:
            return {
                ...state,
                data: initialState.data
            };
        case FLUSH_CATEGORY_MESSAGES:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };

        case FLUSH_CATEGORY_ERRORS:
            return {
                ...state,
                errors: '',
                updateErrors: {},
            };
        default:
            return {
                ...state,
                isFetching: false,
                errors: ''
            };
    }
};

export default category;

export const getIsFetch = state => state.category.isFetching;
export const getCategoryArr = state => state.category.category;
export const getCategoryError = state => state.category.errors;
export const getCategoryUpdateError = state => state.category.updateErrors;
export const getCategoryMessages = state => state.category.message;
export const getCategoryResults = state => state.category.status;

export const getCategoryData = state => state.category.data;

