import React, { useEffect, useState } from 'react'
import md5 from 'md5'
import * as PropTypes from 'prop-types'
import TableComponent from '../TableComponent'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import { useResource } from '../../hooks/resource'
import { headCellsPendingImages } from '../../constatns/images'
import ListActions from '../ListActions'
import { pendingImagesSelector } from '../../store/reducers/imagesPending'
import { getPendingImagesList } from '../../store/actions/imagesPending'
import { getIsFetching } from '../../store/reducers/imagesPending'
import LoadingFade from '../../common/LoadingFade'
import { editPendingImage } from '../../store/actions/imagePending'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Linear from '../../common/Linear'
import {
  bulkActionForImagesPending,
  changeImagesStatusToProgress,
} from '../../store/actions/bulk'
import { getErrorMessage } from '../../helpers/validation'
import { useSnackbar } from 'notistack'
import { fetchCounts } from '../../store/actions/counts'
// import {fetchUserJobs} from "../../store/actions/jobs";
// import {getAuthUserId} from "../../store/reducers/auth";

const bulkActionsTypes = ['approve', 'delete']
const filterBy = ['status']
const statuses = ['approved', 'process']
const bulkActions = [
  {
    type: 'approve',
    message: 'Are you sure you want to approve the selected images?',
  },
  {
    type: 'delete',
    message: 'Are you sure you want to delete the selected images?',
  }
]

const ImagesListPending = ({ classes }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const userId = useSelector(getAuthUserId);
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: {
      images,
      count,
    },
    loading,
    page,
    filterStatus,
    handleChangePage,
    handleChangeOrder,
    handleChangeOrderBy,
    search,
    handleChangeSearch,
    rowsPerPage,
    handleChangeLimit,
    handleChangeStatusFilter,
    handleRefresh,
  } = useResource(pendingImagesSelector, getPendingImagesList, getIsFetching)

  const handleRowClick = () => link => {
    if (!loading) {
      history.push(`/images/image/pending/${md5(link.split('/').pop())}`)
    }
  }

  const handleAddClick = () => {
    history.push('/images/image/new')
  }

  const handleFilterSubmit = filter => {
    if (!!filter.status) {
      return handleChangeStatusFilter(filter.status)
    }
  }

  const applyBulkAction = bulkData => {
    const data = {
      hashes: bulkData.selected.map(link => md5(link.split('/').pop())),
      type: bulkData.actionType,
    }
    dispatch(bulkActionForImagesPending(data)).then(res => {
      const { data } = res
      handleRefresh();
      enqueueSnackbar(data.message, { variant: 'success' })
      dispatch(fetchCounts())
    }).catch(error => {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    })
  }

  if (loading && !count) {
    return (<Linear height={2}/>)
  }

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingFade loading={loading}/>
        <TableComponent
          type="imagesPending"
          rows={images}
          count={count}
          page={page}
          limit={rowsPerPage}
          actionButtons={<ListActions
            searchRequest={search}
            searchHandler={handleChangeSearch}
            isAdd
            onClickAdd={handleAddClick}
          />}
          headCells={headCellsPendingImages}
          onChangePage={handleChangePage}
          onChangeOrder={handleChangeOrder}
          onChangeOrderBy={handleChangeOrderBy}
          onChangeLimit={handleChangeLimit}
          handleRowClick={handleRowClick()}
          bulkActionsTypes={bulkActionsTypes}
          onApplyBulk={applyBulkAction}
          filterBy={filterBy}
          statuses={statuses}
          onSubmitFilter={handleFilterSubmit}
        />
      </Paper>
    </Box>)
}

ImagesListPending.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default ImagesListPending
