import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => {
  return ({
    root: {
      width: "100%"
    },
    icon: {
      fontSize: '3rem',
    },
    avatar: {
      backgroundColor: theme.palette.success.main,
    }
  })
});

const AddItem = ({onClick}) => {
  const classes = useStyles();
  return (
    <List className={classes.root} >
      <ListItem button={true} onClick={onClick}>
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            classes={{
              colorDefault: classes.avatar
            }}
          >
            <AddCircleOutlineIcon className={classes.icon}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={"Add element"}
        />
      </ListItem>
    </List>
  );
};

export default AddItem;
