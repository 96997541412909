export const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(0)
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        position: 'relative',
    },
    imageData: {
        paddingTop: theme.spacing(3),
    },
    actions: {
        paddingTop: theme.spacing(3),
    },
    actionsBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionBlock: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    updateProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: theme.spacing(2),
        width: '100%'
    }
});
