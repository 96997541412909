import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import VideosCategoryList from "../../VideosCategoryList";
import {
    SinglePageCategoryActions,
    SinglePageTagsActions,
    SinglePageTagsAddActions,
    SinglePageCategoryAddActions,
    VideoPageAddActions
} from "../SinglePageActions/SinglePageActions";
import VideosTagsList from "../../VideosTagsList";
import VideosPlaylist from "../../VideosPlaylist";
import VideoList from "../../VideoList";
import VideoPage from "../../VideoPage";
import SingleVideo from "../../SingleVideo";
import SinglePlaylistForm from "../../SinglePlaylistForm";
import VideoUpload from "../../VideoUpload";

const Videos = () => {
    return (<Switch>
            <Route exact path="/videos/playlists" component={VideosPlaylist}/>
            <Route
                path="/videos/playlists/:id/edit"
                component={SingleVideo}
            />
            <Route
                path="/videos/playlists/single"
                component={SinglePlaylistForm}
            />
            <Route
                path="/videos/categories/:id/edit"
                component={SinglePageCategoryActions}
            />
            <Route exact path="/videos/video" component={VideoList}/>
            <Route
                path="/videos/video/upload"
                component={VideoUpload}
            />
            <Route
                path="/videos/video/:id"
                component={VideoPage}
            />
            <Route
                exact
                path="/videos/categories"
                component={VideosCategoryList}
            />
            <Route
                path="/videos/categories/single"
                component={SinglePageCategoryAddActions}
            />
            <Route
                exact
                path="/videos/tags"
                component={VideosTagsList}
            />
            <Route
                path="/videos/tags/:id/edit"
                component={SinglePageTagsActions}
            />
            <Route
                path="/videos/tags/single"
                component={SinglePageTagsAddActions}
            />
            <Redirect to="/videos/categories" />
        </Switch>
    );
};

export default Videos;
