import React from "react";
import * as PropTypes from 'prop-types';
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FilterChips from "../FilterChips";

const TableToolbar = ({
                          numSelected,
                          classes,
                          actionButtons,
                          bulk,
                          filters,
                          chipsData,
                          onDeleteChips
                      }) => {

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <Grid container>
                <Grid item lg={8} sm={6}>
                    <Box className={classes.buttons}>
                        {bulk && bulk}
                        <Box className={classes.filterButton}>
                            {filters && filters}
                        </Box>
                    </Box>
                    {chipsData && !!chipsData.length &&
                    <FilterChips
                        chipsData={chipsData}
                        onDeleteChips={onDeleteChips}
                    />}
                </Grid>
                <Grid item lg={4} sm={6}>
                    {actionButtons && actionButtons}
                </Grid>
            </Grid>
        </Toolbar>
    );
};

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number,
    actionButtons: PropTypes.object,
    filters: PropTypes.object,
};
export default TableToolbar;
