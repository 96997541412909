export const checkPassword = password => {
    const strongRegex = new RegExp("^(?=.*[\\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&;*])[\\w!@#$%^&;*]{8,}");
    return strongRegex.test(password);
};

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export const getErrorMessage = (error = {}) => {
    if (typeof error === 'string' && isJsonString(error)){
        const {data} = JSON.parse(error);
        return  getErrorMessage(data);
    }

    if(!!error.response) {
      return getErrorMessage(error.response);
    }

    const defaultMessage = 'Current action fail!';
    const {status, data, message} = error;

    switch (true) {
        case status && status === 500:
            return defaultMessage;
        case data && !!data.message:
            return data.message;
        case !!message && typeof message === 'string':
            return message;
        case !!message && typeof message === 'object':
            return getErrorMessage(message);
        default:
            return defaultMessage;
    }
};
