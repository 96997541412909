import React, {Fragment, useMemo} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button/Button";
import ConfirmDeleteDialog from "../../../components/ConfirmDeleteDialog";
import {reduxForm} from "redux-form";
import Title from "./SingleItems/Title";
import StartDate from "./SingleItems/StartDate";
import LoadingFade from "../../../common/LoadingFade";
import {useSelector} from "react-redux";
import getFormValues from "redux-form/es/getFormValues";
import useToggle from "../../../hooks/useToggle";
import StatusChip from '../../../common/StatusChip'
import Url from './SingleItems/Url'
import Description from './SingleItems/Description'
import Image from "./SingleItems/Image";
import WarningFlag from './SingleItems/WarningFlag'


const StreamSingleInner = (
  props
) => {
  const {
    item,
    initialValue,
    id,
    isFetching,
    historyChange,
    classes,
    handleSubmit,
    handleRemove,
    title,
    dirty,
    disabled,
    form,
    valid
  } = props;
  const [open, handleToggle] = useToggle();

  const formValues = useSelector(getFormValues(form));

  const invalid = useMemo(() => {
    return !formValues.image
  }, [formValues])
  return (
    <Fragment>
      <LoadingFade loading={isFetching}/>
      <Paper>
        <Toolbar className={classes.toolbar}>
          <div>
            <IconButton
              onClick={() => historyChange('/streams')}
              className={classes.closeButton}
            ><ArrowBackIcon/></IconButton>
            <Grid container spacing={0} justify="space-between">
              <Grid item>
                <Typography>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <StatusChip status={item.status}/>
              </Grid>
            </Grid>
          </div>
        </Toolbar>

        <Divider/>

        <Grid container spacing={1} className={classes.root}>
          <Grid item sm={8}>
            <div className={classes.inputWrap}>
              <Title classes={classes} disabled={disabled}/>
              <Url classes={classes} disabled={disabled}/>
              <Description classes={classes} disabled={disabled}/>
              <WarningFlag classes={classes} disabled={disabled}/>
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={classes.inputWrap}>
              <Image />
              <StartDate classes={classes} name={"created_at"} disabled={true} label={"Create Date"}/>
            </div>
          </Grid>
        </Grid>
        <Divider/>
        <div className={classes.btnWrap}>
          <Button
            color="primary"
            variant="contained"
            disabled={isFetching || !dirty || invalid || disabled || !valid}
            onClick={handleSubmit}
          >
            Save
          </Button>
          {id && (
          <Button
          color="secondary"
          variant="contained"
          disabled={isFetching}
          onClick={handleToggle}
          >
          Delete
          </Button>
          )}

        </div>
        {id && (
          <ConfirmDeleteDialog
            open={open}
            onClose={handleToggle}
            id={'111'}
            title={title}
            deleteItemAction={handleRemove}
          />
        )}
      </Paper>

    </Fragment>
  );
};

export default reduxForm({
  form: "singleStream",
  enableReinitialize: true,
})(StreamSingleInner);
