import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import LoadingFade from '../../common/LoadingFade'
import {CircularProgress} from "@material-ui/core";

const SingleVideoSkeleton = ({classes}) => {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CircularProgress/>
      </Paper>
    </div>
  )
}

export default SingleVideoSkeleton
