import React, { useEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { useFetchVideo } from '../../hooks/videos'
import { useSnackbar } from 'notistack'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import {
  flushVideoErrors,
  flushVideoState,
  deleteVideoState,
  removeVideoFromList,
  updateVideoState,
} from '../../store/actions/video'
import { useVideosTaxonomies } from '../../hooks/videoTaxonomies'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SingleVideoThumbnails from '../SingleVideoThumbnails'
import SingleVideoSkeleton from '../SingleVideoSkeleton'
import { differenceBy } from 'lodash/array'

const initialState = {
  name: '',
  description: '',
  categories: [],
  tags: [],
  base64: '',
}

const VideoPage = ({ classes }) => {

  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { video, loading: videoIsFetching } = useFetchVideo(id)
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [state, setState] = useState(initialState)
  const { videosCategories, videosTags, loading: taxIsFetching  } = useVideosTaxonomies()

  const optionsCats = useMemo(() => {
    return videosCategories.filter(
      tax => !state.categories.map(item => item.id).includes(tax.id))
  }, [videosCategories, state.categories])

  const optionsTags = useMemo(() => {
    return videosTags.filter(
      tax => !state.tags.map(item => item.id).includes(tax.id))
  }, [videosTags, state.tags])

  const loading = useMemo(() => {
    return videoIsFetching || taxIsFetching || !video.id
  }, [videoIsFetching, taxIsFetching, video])

  useEffect(() => {
    const {name, description, categories, tags} = video;
    if(!video.id) {
      return;
    }
    setState({
      ...state,
      name,
      description,
      categories: [].concat(categories) || [],
      tags: [].concat(tags) || [],
    })
  }, [video])



  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const closeLink = () => {
    dispatch(flushVideoErrors())
    dispatch(flushVideoState())
    history.push('/videos/video')
  }

  const handleChangeFields = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const updateVideo = () => {
    dispatch(updateVideoState({
      video_id: video.id,
      name: state.name,
      description: state.description,
      categories: state.categories.map(item => (item.id)),
      tags: state.tags.map(item => (item.id)),
      ...(!!state.base64 ? {file: state.base64} : {})
    })).then(
      res => {
        setState({...state, base64: ''})
        enqueueSnackbar(res.data.message, { variant: 'success' })
      },
    ).catch(err => {
        enqueueSnackbar(err.data.message, { variant: 'error' })
      },
    )
  }

  const deleteVideo = () => {
    dispatch(deleteVideoState(video.id)).then(
      res => {
        enqueueSnackbar(res.data.message, { variant: 'success' })
        dispatch(removeVideoFromList(video.id))
        closeLink()
      },
    ).catch(err => {
        enqueueSnackbar(err.data.message, { variant: 'error' })
      },
    )
  }

  const onChangeAutocomplete = name => (e, val) => {
    setState({ ...state, [name]: val })
  }

  const hasChanges = useMemo(() => {
    return video.name !== state.name ||
      video.name !== state.name ||
      video.description !== state.description ||
      differenceBy(video.categories, state.categories, 'id').length > 0 ||
      differenceBy(state.categories, video.categories, 'id').length > 0 ||
      differenceBy(state.tags, video.tags, 'id').length > 0 ||
      differenceBy(video.tags, state.tags, 'id').length > 0 ||
      state.base64 !== '';
  }, [video, state])

  const disabled = !hasChanges
  const isDeleteDisable = loading || !video.id
  const isUpdateDisable = disabled || loading

  if(loading) {
    return (
      <SingleVideoSkeleton />
    )
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <IconButton
            onClick={closeLink}
            className={classes.closeButton}
          ><ArrowBackIcon/></IconButton>
          <Typography>
            {video.name}
          </Typography>
        </Toolbar>
        <Divider/>
        <Grid container>
          <div className={classes.form}>
            <Grid className={classes.fieldsHolder} container spacing={2} >
              <Grid item xs={12} md={6} lg={6}>
                <div className={classes.inputWrap}>
                  <div className={classes.textField}>
                    <TextField
                      margin="dense"
                      label="Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      disabled={loading}
                      value={state.name}
                      onChange={handleChangeFields('name')}
                    />
                  </div>
                  <div className={classes.textField}>
                    <TextField
                      name="desc"
                      label="Description"
                      multiline
                      rows="4"
                      fullWidth
                      variant="outlined"
                      type="text"
                      value={state.description || ''}
                      disabled={loading}
                      onChange={handleChangeFields('description')}
                    />
                  </div>
                  <div className={classes.textField}>
                    <Autocomplete
                      disabled={loading}
                      multiple
                      name="categories"
                      options={optionsCats}
                      getOptionLabel={option => option.name}
                      filterSelectedOptions
                      value={state.categories}
                      onChange={onChangeAutocomplete('categories')}
                      renderInput={params => (<TextField
                        {...params}
                        variant="outlined"
                        label="Categories"
                        placeholder="Category name"
                        fullWidth
                      />)}
                    />
                  </div>
                  <div className={classes.textField}>
                    <Autocomplete
                      disabled={loading}
                      multiple
                      name="tags"
                      options={optionsTags}
                      getOptionLabel={option => option.name}
                      filterSelectedOptions
                      value={state.tags}
                      onChange={onChangeAutocomplete('tags')}
                      renderInput={params => (<TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="Tag name"
                        fullWidth
                      />)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <Box className={classes.videoBox}>
                      <iframe
                        style={{ border: 'none', background: 'rgb(192,192,192)' }}
                        width="470"
                        height="265" frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen src={video.player_url}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <SingleVideoThumbnails
                      video={video}
                      onChange={(base64) => setState({...state, base64})}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider/>
            <div className={classes.btnWrap}>
              <Button
                color="secondary"
                variant="contained"
                disabled={isDeleteDisable}
                onClick={() => handleClickOpen()}
              >
                Delete
              </Button>
              <ConfirmDeleteDialog
                open={open}
                onClose={handleClose}
                id={id}
                title={state.name}
                deleteItemAction={deleteVideo}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={isUpdateDisable}
                onClick={() => updateVideo(state)}
              >
                Update
              </Button>
            </div>
          </div>
        </Grid>
      </Paper>
    </div>
  )
}

VideoPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default VideoPage
