import React from 'react'
import Typography from '@material-ui/core/Typography/'
import Avatar from '@material-ui/core/Avatar/'
import { makeStyles } from '@material-ui/core/styles'
import ResourceListItem
  from '../../../../components/ResourceList/ResourceListItem'

const useStyles = makeStyles(theme => ({
  size: {
    width: '100px',
    height: '100px',
  },
}))

const StreamListItem = ({ item, ...rest }) => {
  const classes = useStyles()

  return (
    <ResourceListItem
      key={item.id}
      {...rest}
      id={item.id}
    >
      <Typography>{item.id}</Typography>
      <Avatar className={classes.size} variant={'square'} sizes={'100px'}
              src={!!item.image ? item.image.thumbnail : null}/>
      <Typography>{item.title}</Typography>
      <Typography>{item.url}</Typography>
      <Typography>{item.created_at || '-'}</Typography>
    </ResourceListItem>
  )
}

export default StreamListItem
