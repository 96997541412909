import React, {cloneElement, useMemo} from 'react';
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: 300 + theme.spacing(4),
      "& .MuiOutlinedInput-input":{
        padding: "18.5px 14px!important"

      }
    },
    buttonBlock: {
        padding: theme.spacing(2),
        textAlign: 'right',
        '& > button:first-child': {
            marginRight: theme.spacing(),
        }
    },
    filterWrapper: {
        '& > div': {
            minWidth: '100%',
            marginBottom: theme.spacing(2)
        }
    },
    filtersBlock: {
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '300px',
        marginTop: theme.spacing(),

    }
}));

const NewFilters = ({filters, appliedFilters, handleChangeFilters, clearFilters}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenFilters = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilters = () => {
        setAnchorEl(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const isFiltersOpen = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const filtersOptions = useMemo(() => [
        {label: "-", value: ""},
        ...filters.map(
            item =>
                ({label: item.label, value: item.key})
        )], [filters]
    );


    const handleChange = event => {
        setSelectedFilter(event.target.value);
    };

    const filterComponent = useMemo(() => {
        let filterComponent = filters.find(f => f.key === selectedFilter);

        if (!filterComponent) {
            return null;
        }

        if (typeof filterComponent.component === 'undefined') {
            return null;
        }

        return cloneElement(
            filterComponent.component,
            {
                label: filterComponent.label,
                filterKey: filterComponent.key,
                taxonomyOptions: filterComponent.taxonomyOptions,
                appliedFilters,
                handleChangeFilters,
                classes,
            }
        )

    }, [selectedFilter, appliedFilters, handleChangeFilters]);

    return (
        <React.Fragment>
            <Button aria-describedby={id} variant="contained" color="primary" onClick={handleOpenFilters}>
                Filters
            </Button>
            <Popover
                id={id}
                open={isFiltersOpen}
                onClose={handleCloseFilters}
                anchorEl={anchorEl}
            >
                <Box className={classes.root}>
                    <Box className={classes.filtersBlock}>
                        <InputLabel id="filter-select-label">Filters</InputLabel>
                        <Select
                            labelId="filter-select-label"
                            id="filter-select-label"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={selectedFilter}
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.input}
                        >
                            {filtersOptions.map((item, id) => (
                                <MenuItem key={id} value={item.value}>{item.label}</MenuItem>))}
                        </Select>

                    </Box>
                    {filterComponent}
                </Box>
                <Box className={classes.buttonBlock}>
                    <Button
                        variant="contained"
                        onClick={clearFilters}
                    >
                        Clear
                    </Button>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

export default NewFilters;
