export const headCellsImages = [
    { id: 'bulk', numeric: false, disablePadding: true, label: '', sorted: false, size: "small"},
    // { id: 'id', numeric: true, disablePadding: true, label: 'ID', sorted: true, size: "small"},
    { id: 'thumbnail', numeric: false, disablePadding: true, label: 'Thumbnail', sorted: false},
    { id: 'title', numeric: false, disablePadding: false, label: 'Title', sorted: true },
    { id: 'categories', numeric: false, disablePadding: false, label: 'Categories', sorted: false },
    // { id: 'tags', numeric: false, disablePadding: false, label: 'Tags', sorted: false },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sorted: true },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Upload', sorted: true },
];

export const headCellsPendingImages = [
    { id: 'bulk', numeric: false, disablePadding: true, label: '', sorted: false, size: "small"},
    { id: 'thumbnail', numeric: false, disablePadding: true, label: 'Thumbnail', sorted: false},
    { id: 'location', numeric: false, disablePadding: false, label: 'File name', sorted: false },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sorted: true },
];

export const allowedImagesTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg'
];
