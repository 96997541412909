import React, {useMemo} from 'react';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Search from "./Search";
import {makeStyles} from "@material-ui/core";
import FilterChips from "../../FilterChips";
import BulkActions from "./BulkActions";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    '& > div': {
      marginRight: '5px'
    }
  },
  chips: {
    padding: theme.spacing(2),
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
  },
  toolbar: {
    flexDirection: 'column'
  },
  search: {
    flexGrow: 1,
    textAlign: 'right',
    '& > button:first-child': {
      marginRight: theme.spacing(),
    }
  },
  filterWrapper: {
    '& > div': {
      minWidth: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  filtersBlock: {
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '300px',
    marginTop: theme.spacing()
  }
}));

const ResourceToolbar = ({
                           searchValue,
                           handleChangeSearch,
                           filterControl,
                           appliedFilters,
                           handleChangeFilters,
                           selectedItems,
                           bulkActions,
                           primaryAction
                         }) => {

  const classes = useStyles();

  const search = useMemo(() => {
    if (typeof handleChangeSearch !== 'function' || typeof searchValue === 'undefined') {
      return null;
    }

    return <Search searchValue={searchValue} handleChangeSearch={handleChangeSearch}/>

  }, [searchValue, handleChangeSearch]);

  const filters = useMemo(() => {
    if (typeof filterControl === "undefined") {
      return null;
    }

    return filterControl;
  }, [filterControl]);

  const filtersChips = useMemo(
    () => <FilterChips chipsData={appliedFilters} onDeleteChips={handleChangeFilters}/>
    , [appliedFilters]);

  const primaryActions = useMemo(() => {
    if (typeof primaryAction === "undefined") {
      return null;
    }

    return <div>{primaryAction}</div>

  }, [primaryAction])

  return (
    <Toolbar
      className={classes.toolbar}
    >
      <div className={classes.root}>
        <div>
          <BulkActions selectedItems={selectedItems} bulkActions={bulkActions}/>
        </div>
        <div>
          {filters}
        </div>
        <div className={classes.search}>
          {search}
        </div>
        {primaryActions}
      </div>
      <div className={classes.chips}>
        {filtersChips}
      </div>
    </Toolbar>
  );
};

export default ResourceToolbar;