import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const FilterFormControlItem = ({name, value, menuItems, onChange, hasEmpty, classes, disabled, variant}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel
                id={`${name}-label`}
                className={classes.title}
            >
                {name}
            </InputLabel>
            <Select
                variant={variant}
                labelId={`${name}-label`}
                id={name}
                name={name}
                value={value}
                onChange={onChange(name)}
                input={<Input />}
                MenuProps={MenuProps}
                className={classes.title}
                disabled={disabled}
            >
                {hasEmpty && <MenuItem key={`${name}-empty`} value="">-</MenuItem>}
                {menuItems.map(name => (
                    <MenuItem key={name} value={name} className={classes.title}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default FilterFormControlItem;


