import React from "react";
import * as PropTypes from 'prop-types';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import {Checkbox} from "@material-ui/core";

const HeadCellComponent = ({data, orderBy, order, classes, sortHandler, onSelectAllClick, selectedAll}) => {
    // console.log(data, 'data')
    const item = data.sorted ?
        <TableSortLabel
            active={orderBy === data.id}
            direction={order}
            onClick={sortHandler(data.id)}
        >
            {data.label}
            {orderBy === data.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
            ) : null}
        </TableSortLabel> : data.label;

    return (<TableCell
        sortDirection={orderBy === data.id ? order : false}
        align="left"
        size={data.size}
        className={classes[data.id] && classes[data.id]}
    >
        {data.id !== 'bulk' ? item : <Checkbox color="primary" checked={selectedAll} onChange={() => onSelectAllClick()}/>}
    </TableCell>)
};

const TableHeadComponent = ({
                                classes,
                                order,
                                orderBy,
                                onRequestSort,
                                headCells,
                                onSelectAllClick,
                                selectedAll,
                            }) => {

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow
                className={classes.headCells}
            >
                {headCells.map(headCell => (
                    <HeadCellComponent
                        key={headCell.id}
                        data={headCell}
                        orderBy={orderBy}
                        order={order}
                        classes={classes}
                        sortHandler={createSortHandler}
                        onSelectAllClick={onSelectAllClick}
                        selectedAll={selectedAll}
                    />
                ))}
            </TableRow>
        </TableHead>
    );
};

TableHeadComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    headCells: PropTypes.array.isRequired,
    onSelectAllClick: PropTypes.func,
    selectedAll: PropTypes.bool
};
export default TableHeadComponent;
